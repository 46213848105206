import {useNavigate} from "react-router";
import {Button} from "components";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <section className="flex h-screen items-center bg-primary-active p-16 *:text-primary-light">
      <div className="container mx-auto my-8 flex flex-col items-center justify-center px-5">
        <div className="max-w-2xl text-center">
          <h2 className="mb-8 text-9xl font-extrabold text-primary-light ">
            <span className="sr-only">Error</span>404
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">
            Sorry, we couldn't find this page.
          </p>
          <p className="mb-8 mt-4 ">
            But dont worry, you can find plenty of other things on our homepage.
          </p>
          <Button
            onClick={() => navigate("/")}
            variant={"light"}
            textColor="primary"
            className="rounded px-8 py-3 font-semibold "
          >
            Back to Home
          </Button>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
