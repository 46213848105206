import {Button, Drawer, Icon, Status, Tabs, Text} from "components";
import {toggleProps, MakeFieldsRequired, ProductService} from "types";
import ChannelMessage from "./channel-message";
import SalesChannelMessage from "./sales-channel-message";
import {createElement} from "react";
import {useConverter} from "hooks";
import {
  useGetBackgroundJobChannelMessageQuery,
  useGetBackgroundJobSalesChannelMessageQuery,
} from "services/caching";

type Props = toggleProps & {
  job?: MakeFieldsRequired<ProductService.InternalJobsDto, "id">;
};

export default function Details({isOpen, toggle, job}: Props) {
  const {convertDate} = useConverter();

  const params = {
    pageNumber: 1,
    pageSize: 10,
    internalJobTag: job?.id,
  };

  const {
    data: channelMessageData,
    isLoading,
    isFetching,
  } = useGetBackgroundJobChannelMessageQuery(params, {skip: !isOpen});

  const {
    data: salesChannelMessageData,
    isLoading: isLoadingSaleschannel,
    isFetching: isFetchingSaleschannel,
  } = useGetBackgroundJobSalesChannelMessageQuery(params, {skip: !isOpen});

  const channelMessageLoading = isFetching || isLoading;
  const salesChannelMessageLoading =
    isFetchingSaleschannel || isLoadingSaleschannel;

  const tabs = [
    {
      label: "menu.channelMessages.root",
      component: ChannelMessage,
      loading: channelMessageLoading,
    },
    {
      label: "menu.salesChannelMessages.root",
      component: SalesChannelMessage,
      loading: salesChannelMessageLoading,
    },
  ];

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between">
          <span className="text-heading-2 font-semibold text-gray-800">
            Filters
          </span>
          <Button size="sm" onClick={toggle} variant={"light"}>
            <Icon icon="close" />
            <Text>global.buttons.close</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <div className="space-y-6 rounded border border-dashed border-gray-500 px-6 py-8">
            <span className="text-wrap text-heading-6 font-semibold leading-6 text-gray-800">
              {job?.title}
            </span>
            <div className="grid grid-cols-3 gap-4">
              <span className="text-body-base text-gray-800">
                <Text>internalJobs.createdAt</Text>:
              </span>
              <span className="col-span-2 text-heading-6 font-medium text-gray-800">
                {convertDate(job?.createdAt)}
              </span>
              <span className="text-body-base text-gray-800">
                <Text>internalJobs.updatedAt</Text>:
              </span>
              <span className="col-span-2 text-heading-6 font-medium text-gray-800">
                {convertDate(job?.lastProcessedAt)}
              </span>
              <span className="text-body-base text-gray-800">
                <Text>Status : </Text>
              </span>
              <span className="col-span-2 text-heading-6 font-medium text-gray-800">
                <Status.InternalJobsStatus id={job?.status as number} />
              </span>
            </div>
          </div>

          <Tabs activeKey={tabs[0].label}>
            <Tabs.ButtonGroup className="border-b border-gray-200">
              {tabs.map(e => (
                <Tabs.Button
                  key={e.label}
                  eventKey={e.label}
                  disabled={e.loading}
                >
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
            {tabs.map(e => (
              <Tabs.Item key={e.label} eventKey={e.label}>
                <div className="space-y-4">
                  {createElement(e.component, {
                    channelMessage: channelMessageData,
                    salesChannelMessage: salesChannelMessageData,
                    channelMessageLoading,
                    salesChannelMessageLoading,
                  })}
                </div>
              </Tabs.Item>
            ))}
          </Tabs>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
