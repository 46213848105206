import {URLS} from "services";
import MainApi from "services/caching/mainApi";
import {AccountService} from "types";

export const userGroupSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    userGroupList: builder.query<AccountService.UserGroupDtoPagedDto, any>({
      query: params => ({
        url: URLS.USER_GROUP_URL,
        params: {...params},
      }),
      keepUnusedDataFor: 3600,
      providesTags: ["USER-GROUPS"],
    }),
    userGroupDetails: builder.query<AccountService.UserGroupDetailDto, any>({
      query: ({id}) => ({
        url: URLS.GET_DETAILS_USER_GROUP_URL(id),
      }),
      transformResponse: (response: any) => {
        let result = JSON.parse(JSON.stringify(response));
        result.roles?.forEach((item: any, index: string) => {
          result.roles[index] = item.id;
        });
        return result;
      },
      providesTags: (_result, _error, {id}) => [
        {type: "USER-GROUPS-DETAILS", id},
      ],
    }),
    createUserGroup: builder.mutation<
      AccountService.CreateUserGroupRequest,
      void
    >({
      query: data => ({
        url: URLS.USER_GROUP_URL,
        method: "POST",
        data,
      }),
      invalidatesTags: ["USER-GROUPS"],
    }),
    updateUserGroup: builder.mutation<
      AccountService.UpdateUserGroupRequest,
      any
    >({
      query: ({data, id}) => ({
        url: URLS.PUT_USER_GROUP_DETAIL_URL(id),
        method: "PUT",
        data,
      }),
      invalidatesTags: (_result, _error, {id}) => [
        {type: "USER-GROUPS-DETAILS", id},
        {type: "USER-GROUPS"},
      ],
    }),
  }),
});

export const {
  useUserGroupListQuery,
  useUserGroupDetailsQuery,
  useUpdateUserGroupMutation,
  useCreateUserGroupMutation,
} = userGroupSlice;
