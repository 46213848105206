import {Button, Drawer, Icon, SearchBox, Text, Toggler} from "components";
import {useTranslate} from "hooks";
import {useEffect, useState} from "react";
import Select from "react-select";
import {config} from "constant";
import {useSearchParams} from "react-router-dom";
import {uniqBy} from "lodash";
import {
  useAuctionsQuery,
  useBrandsQuery,
  useCategoriesQuery,
  usePimProductFamiliesQuery,
  useProgramListQuery,
  useSuppliersQuery,
} from "services/caching";
import {getLabelByLanguage} from "utils";
//filters
interface Props {
  isOpen: boolean;
  loading: boolean;
  toggle: () => void;
  resetFilter: () => void;
  filterData: any;
  isFilter: boolean;
  params: any;
  data: any;
  channels: any;
  setParams: any;
}

export default function Filter({
  isOpen,
  toggle,
  isFilter,
  resetFilter,
  filterData,
  params,
  data,
  loading,
  channels,
  setParams,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");
  const translate = useTranslate();

  const [suppliers, setsuppliers] = useState<any>([]);
  const [brands, setBrands] = useState<any>([]);
  const [programs, setPrograms] = useState<any>([]);
  const [productFamilies, setProductFamilies] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [auctions, setAuctions] = useState<any>([]);

  const [options, setOptions] = useState<any>({
    brand: 1,
    supplier: 1,
    auction: 1,
  });

  const [supplierParams, setSupplierParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
    suppliertype: 0,
  });
  const [brandParams, setBrandParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
  });
  const [auctionParams, setAuctionParams] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  //Auction Service
  const {
    data: auctionData,
    isLoading: isLoadingAuction,
    isFetching: isFetchingAuction,
  } = useAuctionsQuery(auctionParams, {skip: !isOpen});
  const loadingAuction = isLoadingAuction || isFetchingAuction;

  useEffect(() => {
    if (!!auctionData?.items) {
      const clone = auctionData?.items?.map((item: any) => ({
        value: item.id,
        label: item.title,
      }));
      clone?.unshift({value: null, label: translate("global.all")});
      const uniqueByValue = uniqBy([...auctions, ...clone], "value");
      setAuctions(uniqueByValue);
    }
  }, [auctionData]);

  //Supplier Service
  const {
    data: suppliersData,
    isLoading: isLoadingSupplier,
    isFetching: isFetchingSupplier,
  } = useSuppliersQuery(supplierParams, {skip: !isOpen});
  const loadingSupplier = isLoadingSupplier || isFetchingSupplier;

  useEffect(() => {
    if (!!suppliersData?.items) {
      const clone = suppliersData?.items?.map((item: any) => ({
        value: item.supplierId,
        label: item.name,
      }));
      clone?.unshift({value: null, label: translate("global.all")});
      const uniqueByValue = uniqBy([...suppliers, ...clone], "value");
      setsuppliers(uniqueByValue);
    }
  }, [suppliersData]);

  //Brands Service
  const {
    data: brandData,
    isLoading: isLoadingBrand,
    isFetching: isFetchingBrand,
  } = useBrandsQuery(brandParams, {skip: !isOpen});
  const loadingBrand = isLoadingBrand || isFetchingBrand;

  useEffect(() => {
    if (!!brandData?.items) {
      const clone = brandData?.items?.map((item: any) => ({
        value: item.brandId,
        label: item.name,
      }));
      clone?.unshift({value: null, label: translate("global.all")});
      const uniqueByValue = uniqBy([...brands, ...clone], "value");
      setBrands(uniqueByValue);
    }
  }, [brandData]);

  //Program Service
  const {
    data: programsData,
    isLoading: isLoadingProgram,
    isFetching: isFetchingProgram,
  } = useProgramListQuery(params?.supplierId, {
    skip: !params?.supplierId,
  });
  const loadingProgram = isLoadingProgram || isFetchingProgram;

  useEffect(() => {
    if (!!programsData) {
      const clone = programsData?.map((item: any) => ({
        value: item.id,
        label: item.title,
      }));
      clone?.unshift({value: null, label: translate("global.all")});
      const uniqueByValue = uniqBy(clone, "value");
      setPrograms(uniqueByValue);
    }
  }, [programsData]);

  //Categories Service
  const {
    data: categoriesData,
    isLoading: isLoadingCategory,
    isFetching: isFetchingCategory,
  } = useCategoriesQuery(undefined, {skip: !isOpen});
  const loadingCategory = isLoadingCategory || isFetchingCategory;

  useEffect(() => {
    if (!!categoriesData) {
      const clone = categoriesData?.map((item: any) => ({
        value: item.productCategoryId,
        label: getLabelByLanguage(item?.translates),
      }));
      clone?.unshift({value: null, label: translate("global.all")});
      const uniqueByValue = uniqBy(clone, "value");
      setCategories(uniqueByValue);
    }
  }, [categoriesData]);

  //Families Service
  const {
    data: productfamiliesData,
    isLoading: isLoadingFamilies,
    isFetching: isFetchingFamilies,
  } = usePimProductFamiliesQuery(undefined, {skip: !isOpen});
  const loadingFamilies = isLoadingFamilies || isFetchingFamilies;

  useEffect(() => {
    if (!!productfamiliesData) {
      const clone = productfamiliesData?.map((item: any) => ({
        value: item.pimProductFamilyId,
        label: getLabelByLanguage(item?.translates),
      }));
      clone?.unshift({value: null, label: translate("global.all")});
      const uniqueByValue = uniqBy(clone, "value");
      setProductFamilies(uniqueByValue);
    }
  }, [productfamiliesData]);

  const createChannelsItems = () => {
    const clone = channels?.map((item: any) => ({
      value: item.code,
      label: item.title,
    }));
    return clone;
  };

  const createItems = (key: string) => {
    const clone = data?.[key]?.map((item: any) => ({
      value: item.value,
      label: item.title + "(" + item.count + ")",
    }));
    clone?.unshift({value: null, label: translate("global.all")});
    return clone;
  };
  const onChangeHandler = (key: string, val: any) => {
    setSearchParams({
      pageNumber: "1",
      pageSize: params.pageSize,
    });
    setParams((prev: any) => {
      return {...prev, pageNumber: 1, [key]: val};
    });
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.products.allProducts.filters</Text>
            </span>
            <div className="flex gap-x-2">
              {isFilter && (
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={resetFilter}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>
                    productManagement.products.allProducts.resetFilters
                  </Text>
                </Button>
              )}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>productManagement.products.allProducts.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <SearchBox
            value={keyword}
            onSubmit={filterData("keyword")}
            variant="gray"
            disabled={loading}
            resetFilter={() => setKeyword("")}
          />
          <label
            data-lang-map={"Published Channels"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"Published Channels"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createChannelsItems()?.find(
              (opt: any) => opt.value === params?.channelCode,
            )}
            options={createChannelsItems()}
            onChange={(e: any) => {
              onChangeHandler("channelCode", e?.value || null);
            }}
            isDisabled={loading}
          />
          {/* Product Family */}
          <>
            <label
              data-lang-map={"productManagement.products.Details.productFamily"}
              className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
            >
              <Text>{"productManagement.products.Details.productFamily"}</Text>
            </label>
            <Select
              defaultValue={{id: null, label: translate("global.all")}}
              options={productFamilies}
              value={
                productFamilies?.filter(
                  (item: any) => item?.value === params?.pimProductFamilyId,
                )?.[0]
              }
              onChange={(e: any) => {
                onChangeHandler("pimProductFamilyId", e?.value || null);
              }}
              isLoading={loadingFamilies}
              styles={config.styleSelectReact}
            />
          </>
          <label
            data-lang-map={"productGroup"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productGroup"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createItems("pimProductGroup")?.find(
              (opt: any) => opt.value === params?.groupId,
            )}
            options={createItems("pimProductGroup")}
            onChange={(e: any) => {
              onChangeHandler("groupId", e?.value || null);
            }}
            isDisabled={loading}
          />
          {/* Product Category */}
          <>
            <label
              data-lang-map={
                "productManagement.products.allProducts.productCategory"
              }
              className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
            >
              <Text>
                {"productManagement.products.allProducts.productCategory"}
              </Text>
            </label>
            <Select
              styles={config.styleSelectReact}
              defaultValue={{id: null, label: translate("global.all")}}
              value={
                categories?.filter(
                  (item: any) => item?.value === params?.categoryId,
                )?.[0]
              }
              options={categories}
              onChange={(e: any) => {
                onChangeHandler("categoryId", e?.value || null);
              }}
              isDisabled={loadingCategory}
              isLoading={loadingCategory}
            />
          </>
          {/* Brand */}
          <>
            <label
              data-lang-map={"productManagement.products.allProducts.brand"}
              className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
            >
              <Text>{"productManagement.products.allProducts.brand"}</Text>
            </label>
            <Select
              styles={config.styleSelectReact}
              defaultValue={{id: null, label: translate("global.all")}}
              value={
                brands?.filter(
                  (item: any) => item?.value === params?.brandId,
                )?.[0]
              }
              options={brands}
              onChange={(e: any) => {
                onChangeHandler("brandId", e?.value || null);
              }}
              onInputChange={e =>
                setBrandParams(p => ({...p, keyword: e, pageNumber: 1}))
              }
              onMenuScrollToBottom={() => {
                setBrandParams(p => ({
                  ...p,
                  pageNumber: options.brand + 1,
                }));
                setOptions((p: any) => ({...p, brand: p.brand + 1}));
              }}
              isLoading={loadingBrand}
            />
          </>
          {/* Supplier DropDown */}
          <>
            <label
              data-lang-map={"productManagement.products.allProducts.suppliers"}
              className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
            >
              <Text>{"productManagement.products.allProducts.suppliers"}</Text>
            </label>
            <Select
              styles={config.styleSelectReact}
              defaultValue={{value: null, label: translate("global.all")}}
              value={
                suppliers?.filter(
                  (item: any) => item?.value === params?.supplierId,
                )?.[0]
              }
              options={suppliers}
              onChange={(e: any) => {
                onChangeHandler("supplierId", e?.value || null);
              }}
              onInputChange={e =>
                setSupplierParams(p => ({...p, keyword: e, pageNumber: 1}))
              }
              onMenuScrollToBottom={() => {
                setSupplierParams(p => ({
                  ...p,
                  pageNumber: options.supplier + 1,
                }));
                setOptions((p: any) => ({...p, supplier: p.supplier + 1}));
              }}
              isLoading={loadingSupplier}
            />
          </>
          {/* Program */}
          <>
            <label
              data-lang-map={"productManagement.products.allProducts.program"}
              className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
            >
              <Text>{"productManagement.products.allProducts.program"}</Text>
            </label>
            <Select
              styles={config.styleSelectReact}
              defaultValue={{value: null, label: translate("global.all")}}
              options={programs}
              value={
                programs?.filter(
                  (item: any) => item?.value === params?.programId,
                )?.[0]
              }
              onChange={(e: any) => {
                onChangeHandler("programId", e?.value || null);
              }}
              isDisabled={!params?.supplierId || loadingProgram}
              isLoading={loadingProgram}
            />
          </>
          <label
            data-lang-map={"productManagement.products.allProducts.auctions"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productManagement.products.allProducts.auctions"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{value: null, label: translate("global.all")}}
            value={
              auctions?.filter(
                (item: any) => item?.value === params?.auctionId,
              )?.[0]
            }
            options={auctions}
            onChange={(e: any) => {
              onChangeHandler("auctionId", e?.value || null);
            }}
            onInputChange={e =>
              setAuctionParams(p => ({...p, keyword: e, pageNumber: 1}))
            }
            onMenuScrollToBottom={() => {
              setAuctionParams(p => ({
                ...p,
                pageNumber: options.auction + 1,
              }));
              setOptions((p: any) => ({...p, auction: p.auction + 1}));
            }}
            isLoading={loadingAuction}
          />
          <Toggler
            label={"productManagement.products.allProducts.hasAuction"}
            className="!mt-6"
            value={params?.hasAuction}
            setValue={e => onChangeHandler("hasAuction", e || null)}
          />
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
//end of file
