const PRINTER_DEFINITION = {
  PRINTER_DEFINITION_LIST: "/assetservice/api/printerdefinitions",
  CREATE_DEFINITION: "/assetservice/api/printerdefinitions",
  PRINTER_DEFINITION_DETAILS: id =>
    `/assetservice/api/printerdefinitions/${id}`,
  PRINTER_DEFINITION_UPDATE: id => `/assetservice/api/printerdefinitions/${id}`,
  PRINTER_DEFINITION_DELETE: id => `/assetservice/api/printerdefinitions/${id}`,
  PRINTER_DEFINITION_ALL: `/assetservice/api/printerdefinitions/all`,
  SEND_PRINT_REQUEST: "/assetservice/api/sendtoprinterrequests",
};

export default PRINTER_DEFINITION;
