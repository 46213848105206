import dateIcon from "assets/icons/attributes/date.svg";
import dateTimeIcon from "assets/icons/attributes/dateTime.svg";
import integerIcon from "assets/icons/attributes/integer.svg";
import measurementIcon from "assets/icons/attributes/measurement.svg";
import selectIcon from "assets/icons/attributes/select.svg";
import textIcon from "assets/icons/attributes/text.svg";
import textEditorIcon from "assets/icons/attributes/textEditor.svg";
import timeIcon from "assets/icons/attributes/time.svg";
import yesNoIcon from "assets/icons/attributes/yesNo.svg";

export const config = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  tenant: process.env.REACT_APP_TENANT,
  b2cTenant: process.env.REACT_APP_B2C_TENANT,
  clientId: process.env.REACT_APP_CLIENT_ID ?? "",
  b2cSigningPolicy: process.env.REACT_APP_B2C_SIGNIN_POLICY,
  redirectUrl: process.env.REACT_APP_REDIRECT_URL,
  scope: process.env.REACT_APP_SCOPE,
  subscriptionKey: process.env.REACT_APP_SUBSCRIPTION_KEY,
  lang: process.env.REACT_APP_DEFAULT_LANG,
  stimulsoft: {license: process.env.REACT_APP_STIMULSOFT_LICENSE as string},
  attributeTypesOptions: [
    {name: "Text", icon: textIcon, type: 10},
    {name: "Yes/No", icon: yesNoIcon, type: 20},
    {name: "Select", icon: selectIcon, type: 30},
    {name: "Date", icon: dateIcon, type: 40},
    {name: "Time", icon: timeIcon, type: 50},
    {name: "Date & Time", icon: dateTimeIcon, type: 60},
    {name: "Measurement", icon: measurementIcon, type: 70},
    {name: "Text Editor", icon: textEditorIcon, type: 80},
    {name: "Integer", icon: integerIcon, type: 90},
    {name: "Decimal", icon: integerIcon, type: 100},
  ],
  pageSize: 10,
  pageSizeItems: [
    {id: 5, name: "5"},
    {id: 10, name: "10"},
    {id: 20, name: "20"},
  ],
  styleSelectReact: {
    control: (baseStyles: any, isSelected: any) => ({
      ...baseStyles,
      "&:hover": {
        borderColor: "none",
      },
      borderColor: isSelected ? "#F5F8FA" : "#F5F8FA",
      backgroundColor: "#F5F8FA",
      height: "44px",
      borderRadius: "6px",
      boxShadow: "none",
      "&:focus": {
        border: "0 !important",
      },
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: "#345EAC",
      // opacity: "40%",
    }),
    clearIndicator: (base: any) => ({
      ...base,
      color: "#3F4254",
      opacity: "40%",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#F5F8FA" // رنگ بک‌گراند هنگام انتخاب
        : state.isFocused
          ? "#E9F0FF" // رنگ هنگام هاور
          : "white", // رنگ پیش‌فرض
      color: state.isSelected ? "#345EAC" : "black", // رنگ متن هنگام انتخاب
      cursor: "pointer",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "#5e6278",
      fontWeight: 400,
    }),
  },
  styleSelectReactInconversation: {
    control: (baseStyles: any, isSelected: any) => ({
      ...baseStyles,
      "&:hover": {
        borderColor: "none",
      },
      borderColor: isSelected ? "#F5F8FA" : "#F5F8FA",
      backgroundColor: "#F5F8FA",
      height: "36px",
      borderRadius: "6px",
      boxShadow: "none",
      "&:focus": {
        border: "0 !important",
      },
      fontSize: "12px",
      color: "#5e6278",
    }),

    dropdownIndicator: (base: any) => ({
      ...base,
      color: "#5e6278",
    }),
    clearIndicator: (base: any) => ({
      ...base,
      color: "#5e6278",
    }),
    container: (base: any) => ({
      ...base,
      flex: "1",
    }),
    option: (base: any) => ({
      ...base,
      fontSize: "12px",
    }),
    placeholder: (base: any) => ({
      ...base,
      color: "#5e6278",
    }),
  },
};
