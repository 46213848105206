import {NotificationManager} from "common/notifications";
import {
  Text,
  Skeleton,
  NoItems,
  RadioButton,
  Button,
  Accordion,
  Icon,
} from "components";
import {CheckListCompletenessStatus} from "enum";
import {useToggleState} from "hooks";
import {cloneDeep} from "lodash";
import {useContext, useEffect, useMemo, useState} from "react";
import {ServiceApi, URLS} from "services";
import {CompletenessContext} from "..";

type ItemType = {
  checkListId: string;
  title: string;
  status: number;
};

type Data = {
  checkListHistories?: ItemType[];
};

export default function Checklist() {
  const {
    data,
    setData,
    loading,
    getChecklistData,
    isChanged,
    toggle,
    updatePIMData,
    baseData,
    isOpen,
  } = useContext(CompletenessContext);
  const [submitLoading, toggleSubmitLoading] = useToggleState();
  const [filterType, setFilterType] = useState<null | number>(null);
  const checkListCompletenessStatus = [
    {
      name: "global.all",
      id: null,
    },
    {
      name: "global.types.checkListCompleteness.notStarted",
      id: 0,
      amount: useMemo(() => {
        return baseData?.checkListHistories?.filter(
          (i: ItemType) => i.status === 0,
        ).length;
      }, [baseData]),
    },
    {
      name: "global.types.checkListCompleteness.inProcess",
      id: 1,
      amount: useMemo(() => {
        return baseData?.checkListHistories?.filter(
          (i: ItemType) => i.status === 1,
        ).length;
      }, [baseData]),
    },
    {
      name: "global.types.checkListCompleteness.hasAProblem",
      id: 2,
      amount: useMemo(() => {
        return baseData?.checkListHistories?.filter(
          (i: ItemType) => i.status === 2,
        ).length;
      }, [baseData]),
    },
    {
      name: "global.types.checkListCompleteness.completed",
      id: 3,
      amount: useMemo(() => {
        return baseData?.checkListHistories?.filter(
          (i: ItemType) => i.status === 3,
        ).length;
      }, [baseData]),
    },
  ];

  const handleSetValue = (key: string, id: string) => {
    return (value: string | boolean | number) =>
      setData((p: Data) => {
        const data = cloneDeep(p);
        p?.checkListHistories?.map((item: ItemType, index: number) => {
          if (item.checkListId === id && data?.checkListHistories?.[index])
            data.checkListHistories[index] = {...item, [key]: value};
        });
        return data;
      });
  };

  const OnSubmitHandler = () => {
    toggleSubmitLoading();
    const url = URLS.UPDATE_CHECKLIST_COMPLETATION_STATE_URL;
    const body = {...data};
    ServiceApi.put(url, body)
      .then(() => {
        getChecklistData();
        updatePIMData();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => toggleSubmitLoading());
  };

  const generateList = () => {
    if (filterType !== null)
      return data?.checkListHistories?.filter(
        (item: ItemType, index: number) =>
          baseData.checkListHistories[index].status === filterType,
      );
    else return data.checkListHistories;
  };

  const getBgColor = (status: number) => {
    switch (status) {
      case CheckListCompletenessStatus.Completed:
        return "bg-success";
      case CheckListCompletenessStatus.HasAProblem:
        return "bg-danger";
      case CheckListCompletenessStatus.InProcess:
        return "bg-warning";
      case CheckListCompletenessStatus.NotStarted:
        return "bg-gray-400";
    }
  };
  const getLightBgColor = (status: number) => {
    switch (status) {
      case CheckListCompletenessStatus.Completed:
        return "bg-success-light";
      case CheckListCompletenessStatus.HasAProblem:
        return "bg-danger-light";
      case CheckListCompletenessStatus.InProcess:
        return "bg-warning-light";
      case CheckListCompletenessStatus.NotStarted:
        return "bg-gray-100";
    }
  };
  const getTextColor = (status: number) => {
    switch (status) {
      case CheckListCompletenessStatus.Completed:
        return "text-success-active";
      case CheckListCompletenessStatus.HasAProblem:
        return "text-danger-active";
      case CheckListCompletenessStatus.InProcess:
        return "text-warning-active";
      case CheckListCompletenessStatus.NotStarted:
        return "text-gray-700";
    }
  };
  const getText = (status: number) => {
    switch (status) {
      case CheckListCompletenessStatus.Completed:
        return "global.types.checkListCompleteness.completed";
      case CheckListCompletenessStatus.HasAProblem:
        return "global.types.checkListCompleteness.hasAProblem";
      case CheckListCompletenessStatus.InProcess:
        return "global.types.checkListCompleteness.inProcess";
      case CheckListCompletenessStatus.NotStarted:
        return "global.types.checkListCompleteness.notStarted";
    }
  };

  useEffect(() => {
    setFilterType(null);
  }, [isOpen]);

  return (
    <div className="!mb-16 space-y-6">
      <div className="flex gap-3 overflow-scroll py-3">
        {checkListCompletenessStatus?.map((type, index) => (
          <span
            key={index}
            className={`min-w-fit cursor-pointer rounded-2xl p-3 text-center text-heading-6 font-semibold ${
              filterType === type.id
                ? "bg-primary text-white"
                : "bg-gray-100 text-gray-500"
            } ${loading && "pointer-events-none opacity-30"}`}
            onClick={() => {
              setFilterType(type.id);
            }}
          >
            <Text>{type.name}</Text>
            {type.id !== null && <> ({type.amount})</>}
          </span>
        ))}
      </div>
      <div className="space-y-4">
        {loading && [1, 2, 3].map(i => <Skeleton.SimpleList key={i} />)}
        {!loading &&
          generateList()?.length !== 0 &&
          generateList()?.map(
            (item: ItemType) => (
              <Accordion key={item.checkListId}>
                <Accordion.Item className="rounded border border-gray-200 px-4">
                  <Accordion.Toggle>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center gap-2">
                        <div
                          className={`h-[25px] w-[10px] rounded-md ${getBgColor(item.status)}`}
                        ></div>
                        <h6 className="text-heading-6 font-normal text-gray-800">
                          {item.title}
                        </h6>
                      </div>
                      <div
                        className={`flex items-center gap-2 rounded ${getLightBgColor(item.status)} p-2`}
                      >
                        <span
                          className={`text-body-2 font-normal ${getTextColor(item.status)} `}
                        >
                          <Text>{getText(item.status)}</Text>
                        </span>
                        <Icon
                          icon={"chevron-down"}
                          size="sm"
                          className={` transition-transform group-[.accordion-item.active]:rotate-180 ${getTextColor(item.status)}`}
                        />
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Body>
                    <div className="grid grid-cols-2 gap-3 space-y-2 text-heading-5 text-gray-700">
                      <RadioButton
                        label="global.types.checkListCompleteness.notStarted"
                        value={item.status === 0}
                        setValue={() =>
                          handleSetValue("status", item.checkListId)(0)
                        }
                        parentClassName="!gap-2"
                        disabled={loading || submitLoading}
                      />
                      <RadioButton
                        label="global.types.checkListCompleteness.hasAProblem"
                        value={item.status === 2}
                        setValue={() =>
                          handleSetValue("status", item.checkListId)(2)
                        }
                        parentClassName="!gap-2"
                        disabled={loading || submitLoading}
                      />
                      <RadioButton
                        label="global.types.checkListCompleteness.inProcess"
                        value={item.status === 1}
                        setValue={() =>
                          handleSetValue("status", item.checkListId)(1)
                        }
                        parentClassName="!gap-2"
                        disabled={loading || submitLoading}
                      />
                      <RadioButton
                        label="global.types.checkListCompleteness.completed"
                        value={item.status === 3}
                        setValue={() =>
                          handleSetValue("status", item.checkListId)(3)
                        }
                        parentClassName="!gap-2"
                        disabled={loading || submitLoading}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ),
            // <div key={item.checkListId} className="border border-gray-200 space-y-5 p-4 rounded-lg">
            //   <div className="flex gap-2 items-center">
            //     <div className={`w-[10px] h-[25px] rounded-md ${getBgColor(item.status)}`}></div>
            //     <span className="text-heading-6 text-gray-800 flex-1">{item.title}</span>
            //   </div>
            //   <div className="grid grid-cols-2 gap-3 text-heading-5 text-gray-700 space-y-2">
            //     <RadioButton
            //       label="global.types.checkListCompleteness.notStarted"
            //       value={item.status === 0}
            //       setValue={() => handleSetValue("status", item.checkListId)(0)}
            //       parentClassName="!gap-2"
            //       disabled={loading || submitLoading}
            //     />
            //     <RadioButton
            //       label="global.types.checkListCompleteness.hasAProblem"
            //       value={item.status === 2}
            //       setValue={() => handleSetValue("status", item.checkListId)(2)}
            //       parentClassName="!gap-2"
            //       disabled={loading || submitLoading}
            //     />
            //     <RadioButton
            //       label="global.types.checkListCompleteness.inProcess"
            //       value={item.status === 1}
            //       setValue={() => handleSetValue("status", item.checkListId)(1)}
            //       parentClassName="!gap-2"
            //       disabled={loading || submitLoading}
            //     />
            //     <RadioButton
            //       label="global.types.checkListCompleteness.completed"
            //       value={item.status === 3}
            //       setValue={() => handleSetValue("status", item.checkListId)(3)}
            //       parentClassName="!gap-2"
            //       disabled={loading || submitLoading}
            //     />
            //   </div>
            // </div>
          )}
        {!loading && generateList()?.length === 0 && (
          <div className="flex w-full flex-col items-center space-y-4">
            <NoItems />
          </div>
        )}
      </div>
      <div className="fixed bottom-0 left-0 right-0 flex justify-between bg-white p-6">
        <Button size="sm" variant={"light"} onClick={toggle} type="button">
          <Text>global.cancel</Text>
        </Button>
        <Button
          size="sm"
          type="submit"
          loading={submitLoading}
          onClick={OnSubmitHandler}
          disabled={!isChanged || loading || submitLoading}
        >
          <Text>global.buttons.saveChanges</Text>
        </Button>
      </div>
    </div>
  );
}
