import MainApi from "../mainApi";

export const imageSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    getImageUrl: builder.query<Blob, any>({
      query: ({url}: any) => ({
        url: url,
        params: {
          withoutSubscriptionKey: true,
        },
        responseType: "blob",
      }),
    }),
  }),
});

export const {useLazyGetImageUrlQuery} = imageSlice;
