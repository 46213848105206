import {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import reverseArrayHelper from "./find-path";
import {LoadingSvg} from "components";
import {
  useLazyPimProductFamiliesQuery,
  usePimProductFamiliesQuery,
} from "services/caching";

type Props = {
  pimProductFamilyId: string;
  productData: any;
};

const ProductFamilyPath = ({pimProductFamilyId, productData}: Props) => {
  const {i18n} = useTranslation();
  const [productFamily, setProductFamily] = useState([]);
  const [productFamilyReverse, setProductFamilyReverse] = useState<any>([]);

  const [trigger, {isLoading, isFetching}] = useLazyPimProductFamiliesQuery();
  const {data: cachedData} = usePimProductFamiliesQuery();

  const loading = isFetching || isLoading;

  useEffect(() => {
    if (pimProductFamilyId) {
      getProductFamilyList();
    }
  }, [productData?.pimProductFamilyId]);

  useEffect(() => {
    setProductFamilyLocalization(productFamilyReverse);
  }, [i18n.language, productFamilyReverse]);

  const TranslateLabel = (
    data: any,
    currentLang = "de",
    translateLabelObjectName = "name",
  ) => {
    let translatedLabel = data.translates.find(
      (item: any) => item.language?.toUpperCase() === currentLang.toUpperCase(),
    );
    translatedLabel = translatedLabel
      ? translatedLabel[translateLabelObjectName]
      : null;
    return translatedLabel;
  };

  const convertData = (data: any) => {
    const reverseArray = reverseArrayHelper(
      data,
      pimProductFamilyId,
      "pimProductFamilyId",
    );
    setProductFamilyLocalization(reverseArray);
    setProductFamilyReverse(reverseArray);
  };

  const getProductFamilyList = async () => {
    if (!cachedData) {
      try {
        const result = await trigger().unwrap();
        convertData(result);
      } catch (error) {
        console.log(error);
      }
    } else {
      convertData(cachedData);
    }
  };

  const setProductFamilyLocalization = (productFamiliesList: any) => {
    let productFamilyList: any = [];
    productFamiliesList.map((item: any) => {
      const translatedLabel =
        TranslateLabel(item, i18n.language, "labelTitle") || item.code;
      productFamilyList.push(translatedLabel);
    });
    setProductFamily(productFamilyList);
  };

  return (
    <span>
      {loading ? (
        <LoadingSvg size="sm" />
      ) : (
        productFamily.map((item, index) => {
          return index + 1 === productFamily.length ? item : `${item} > `;
        })
      )}
    </span>
  );
};

export default ProductFamilyPath;
