import {addressConfiguration} from "./address-configuration";
import {globalSettings} from "./global-settings";
import {printerDefinition} from "./printer-definition";
import {userManagment} from "./user-managment";

export const configurations = {
  globalSettings: globalSettings,
  userManagment: userManagment,
  addressConfiguration: addressConfiguration,
  printerDefinition,
};
