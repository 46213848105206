import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  DeleteModal,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {useToggleState} from "hooks";
import {useState} from "react";
import {useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import AddModal from "./add-modal";
import {useApiKeysQuery} from "services/caching";

type Params = {
  keyword?: string;
  pageNumber?: string;
  pageSize?: string;
};

const originalFilterData: any = {
  pageNumber: "1",
  pageSize: config.pageSize,
  keyword: "",
};

function ApiKeysList() {
  const [loadingButton, setLoadingButton] = useState(false);
  const [selected, setSelected] = useState<any>();

  const [params, setParams] = useSearchParams(originalFilterData);
  const queryParams = Object.fromEntries(params.entries());

  const [isOpen, toggle] = useToggleState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);

  const {data, isLoading, isFetching, refetch} = useApiKeysQuery(queryParams);

  const loadingList = isFetching || isLoading;

  const handleSetParams = (key: keyof Params) => {
    const isPageNumber = key === "pageNumber";
    return (value: string | number | null) => {
      setParams(p => {
        const hasValue = !!String(value ?? "");
        hasValue ? p.set(key, `${value}`) : p.delete(key);
        !isPageNumber && p.set("pageNumber", `1`);
        return p;
      });
    };
  };

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.GET_DELETE_API_KEY_LIST_URL(id);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        refetch();
        toggleDeleteModal();
        setLoadingButton(false);
      })
      .catch(() => setLoadingButton(false));
  };

  const isFilter = ["keyword"].some(item => params.get(item));

  const resetFilter = () => {
    setParams(originalFilterData);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["AS_GenerateApiKey"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button onClick={() => toggle()}>
              <Icon className="mr-2" icon={"plus"} />
              <Text>configurations.globalSettings.apiKeys.generateAPIKey</Text>
            </Button>
          )}
        </WithPermission>
      </div>
      <div className="flex-between">
        <SearchBox
          value={params?.get("keyword") || ""}
          onSubmit={handleSetParams("keyword")}
          disabled={loadingList}
          totalItems={data?.totalItems}
          onReload={refetch}
          loading={loadingList}
          resetFilter={resetFilter}
        />
        {isFilter && (
          <Button
            disabled={loadingList}
            variant={"white"}
            textColor="danger"
            size="sm"
            onClick={resetFilter}
          >
            <Icon icon="circle-xmark"></Icon>
            <span className="ml-1">Reset Filter</span>
          </Button>
        )}
      </div>
      {loadingList ? (
        <>
          {[...Array(8)].map(() => (
            <Skeleton.List />
          ))}
        </>
      ) : (
        <>
          {data &&
          Object.keys(data).length !== 0 &&
          data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.apiKeyId}>
                      <td className="text-heading-6 text-gray-800">
                        <h6>{item.name}</h6>
                      </td>
                      <td className="text-body-base text-gray-800">
                        <Text>
                          configurations.globalSettings.apiKeys.description
                        </Text>
                        :<span className="ml-1">{item.truncated}</span>
                      </td>

                      <td>
                        <WithPermission permissions={["AS_DeleteApiKey"]}>
                          <Button
                            size="sm"
                            variant="danger"
                            light
                            onClick={() => {
                              setSelected(item);
                              toggleDeleteModal();
                            }}
                          >
                            <Icon icon={"trash"} />
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages || 1}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <AddModal isOpen={isOpen} toggle={toggle} getData={refetch} />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{name: selected?.name, id: selected?.apiKeyId}}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </div>
  );
}
export default withPermission(ApiKeysList, ["AS_GetApiKey"]);
