import {URLS} from "services";
import MainApi from "../../mainApi";
import {PimProduct, SalesService} from "types";

export const publishedProductsSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    getPublishedProducts: builder.query<
      SalesService.SalesToolsPimProductSearchResultDto,
      any
    >({
      query: data => ({
        url: URLS.GET_PUBLISHED_PRODUCT_LIST,
        method: "POST",
        data,
      }),
      providesTags: ["PUBLISHED-PRODUCTS-LIST"],
    }),
    getPublishedProductsDetails: builder.query<
      SalesService.SalesToolsPimProductDetailQueryDto,
      any
    >({
      query: ({productId, params}) => {
        return {
          url: URLS.GET_PUBLISHED_PRODUCT_DETAILS(productId),
          params: {...params},
        };
      },
    }),
    publishedProductInventory: builder.query<
      SalesService.GetPimProductAvailableInInventoryResultDto[],
      any
    >({
      query: ({productId, params}) => ({
        url: URLS.GET_PUBLISHED_PRODUCT_INVENTORY(productId),
        params: {...params},
      }),
    }),
    publishedProductPrintHistory: builder.query<
      PimProduct.PrintHistoryListType,
      any
    >({
      query: ({id, params}) => ({
        url: URLS.PRINT_REQUEST(id),
        params: {...params},
      }),
    }),
    publishedProductAvailabilityStatus: builder.query<
      SalesService.PimProductAvailabilityStatusDto[],
      any
    >({
      query: ({productId, params}) => ({
        url: URLS.GET_PIM_AVAILABILITY_STATUS(productId),
        params: {...params},
      }),
    }),
  }),
});

export const {
  useGetPublishedProductsQuery,
  usePublishedProductInventoryQuery,
  useGetPublishedProductsDetailsQuery,
  usePublishedProductPrintHistoryQuery,
  usePublishedProductAvailabilityStatusQuery,
} = publishedProductsSlice;
