import {createApi} from "@reduxjs/toolkit/query/react";
import customBaseQuery from "../customBasequery";

const MainApi = createApi({
  reducerPath: "api",
  baseQuery: customBaseQuery(),
  keepUnusedDataFor: 120,
  endpoints: () => ({}),
  tagTypes: [
    "USER-LIST",
    "ROLE-LIST",
    "PERMISSION",
    "BRAND-LIST",
    "USER-GROUPS",
    "PROGRAM-LIST",
    "COUNTRY-LIST",
    "CATEGORY-LIST",
    "SUPPLIER-LIST",
    "AUCTIONS-LIST",
    "PROGRAM-DETAILS",
    "AUCTION_DETAILS",
    "SUPPLIER-DETAILS",
    "PIM_PRODUCT-LIST",
    "PIM-PRODUCT-DETAILS",
    "USER-GROUPS-DETAILS",
    "PRODUCT-GROUPS-LIST",
    "BACKGROUND-JOB-LIST",
    "BACKGROUND-JOB-STATUS",
    "SHIPPING-METHODS-LIST",
    "PRODUCT-GROUPS_DETAILS",
    "PRINTER-DEFINITION-LIST",
    "PUBLISHED-PRODUCTS-LIST",
    "PRINTER-DEFINITION-DETAILS",
  ],
});

export const {updateQueryData} = MainApi.util;
export default MainApi;
