import MainApi from "../mainApi";
import {URLS} from "services";
import {
  ChannelManagerService,
  MakeFieldsRequired,
  ProductService,
  SalesService,
  WithPaging,
} from "types";

type Data = WithPaging<
  MakeFieldsRequired<ProductService.InternalJobsDto, "id">
>;

type Status = {
  status: number;
  itemCount: number;
};
export const bacgroundJobSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    getBackgroundJobs: builder.query<Data, any>({
      query: params => ({
        url: URLS.GET_INTERNALS_JOBS_LIST,
        params: {
          ...params,
          status: params.status === "all" ? "" : params.status,
        },
      }),
      providesTags: ["BACKGROUND-JOB-LIST"],
    }),
    getBackgroundJobChannelMessage: builder.query<
      ChannelManagerService.ChannelMessageQueryDtoPagedDto,
      any
    >({
      query: params => ({
        url: "/channelmanagerservice/api/channelmessage",
        params,
      }),
    }),
    getBackgroundJobSalesChannelMessage: builder.query<
      SalesService.SaleChannelMessageQueryDtoPagedDto,
      any
    >({
      query: params => ({
        url: "/salesservice/api/salechannelmessage",
        params,
      }),
    }),
    getBackgroundJobStatus: builder.query<Status[], void>({
      query: params => ({
        url: URLS.GET_INTERNAL_JOB_STATUS_REPORT,
        params,
      }),
      transformResponse: (response: Status[]) => {
        const status = [
          {status: 0, itemCount: 0},
          {status: 1, itemCount: 0},
          {status: 2, itemCount: 0},
          {status: 3, itemCount: 0},
        ];
        const updatedArray: any = status.map(item => {
          const matchingItem = response.find(
            (service: any) => service.status === item.status,
          );
          return matchingItem
            ? {...item, itemCount: matchingItem.itemCount}
            : item;
        });
        return updatedArray;
      },
      providesTags: ["BACKGROUND-JOB-STATUS"],
    }),
  }),
});

export const {
  useGetBackgroundJobsQuery,
  useGetBackgroundJobStatusQuery,
  useGetBackgroundJobChannelMessageQuery,
  useGetBackgroundJobSalesChannelMessageQuery,
} = bacgroundJobSlice;
