const salesReport = {
  all: "Alle",
  allSellers: "Alle Verkäufer",
  allStores: "Alle Geschäfte",
  totalSales: "Gesamtumsatz",
  excludeSideCost: "Seitliche Kosten ausschließen",
  salesCompare: "Umsatzvergleich",
  targetSellFactor: "Zielverkaufsfaktor",
  averageSellFactor: "Durchschnittlicher Verkaufsfaktor",
  date: "Datum",
  year: "Jahr",
  quarter: "Quartal",
  quarterItem: "Quartal {{ value }}",
  customDate: "Benutzerdefiniertes Datum",
  from: "Von",
  to: "Bis",
  cancel: "Abbrechen",
  apply: "Anwenden",
  similarToLastYear: "Ähnlich wie im letzten Jahr",
  selectedDate: "Ausgewähltes Datum",
  toDate: "Bis",
  stores: "Filialen"
};
export default salesReport;
