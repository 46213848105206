import {NotificationManager} from "common/notifications";
import {Button, Form, Icon, InputGroup, Text, Modal} from "components";
import {FormEvent, useEffect, useRef, useState} from "react";
import {isEmpty} from "lodash";
import {rules} from "constant";
import {usePermission} from "hooks";
import {
  useCreateShippingMethodsMutation,
  useUpdateShippingMethodsMutation,
} from "services/caching";

export default function AddShippingMethods(props: any) {
  const updatePermission = usePermission("PS_UpdateShippingMethod");
  const [data, setData] = useState<any>({
    title: "",
    code: "",
    shippingMethodId: "",
  });

  const [createShippingMethod, {isLoading: isLoadingCreate}] =
    useCreateShippingMethodsMutation();

  const [updateShippingMethod, {isLoading: isLoadingUpdate}] =
    useUpdateShippingMethodsMutation();

  const loading = isLoadingCreate || isLoadingUpdate;

  const formRef = useRef<any>();

  useEffect(() => {
    if (props.isOpen) {
      !isEmpty(props.shippingMethod)
        ? setData(props.shippingMethod)
        : setData({
            title: "",
            code: "",
            shippingMethodId: "",
          });
    }
  }, [props.isOpen]);

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  const onSubmitHandler = async (data: any) => {
    try {
      if (data?.shippingMethodId) {
        const id = data?.shippingMethodId;
        delete data?.shippingMethodId;
        await updateShippingMethod({data, id}).unwrap();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      } else {
        await createShippingMethod(data).unwrap();
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
      }
      props.toggle();
    } catch (error) {}
  };

  const onChangeHandler = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      width="w-[400px]"
      modalClassName="z-40"
    >
      <Modal.Header className="flex text-heading-2 font-semibold">
        <Text>
          {props.id
            ? "productManagement.masterData.shipping.editShipping"
            : "productManagement.masterData.shipping.addShipping"}
        </Text>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <div className="col-span-2">
          <Form
            ref={formRef}
            onSubmit={onSubmitHandler}
            id={`add-modal-shipping`}
            className="space-y-4"
          >
            <InputGroup
              ariaLabel="cy-create-shipping-code"
              value={data?.code}
              setValue={onChangeHandler("code")}
              label="productManagement.masterData.shipping.code"
              rules={rules.required}
              disabled={loading || data?.shippingMethodId}
              readOnly={loading || data?.shippingMethodId}
            />
            <InputGroup
              ariaLabel="cy-create-shipping-title"
              value={data?.title}
              setValue={onChangeHandler("title")}
              label="productManagement.masterData.shipping.title"
              rules={rules.required}
              disabled={
                loading || (!isEmpty(props.shippingMethod) && !updatePermission)
              }
              readOnly={loading}
            />
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button
          onClick={() => {
            props.toggle();
          }}
          variant="white"
        >
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.cancel</Text>
        </Button>
        <Button
          aria-label="cy-create-shipping-submit"
          type="button"
          size="sm"
          onClick={submit}
          loading={loading}
          disabled={!isEmpty(props.shippingMethod) && !updatePermission}
        >
          <Text>global.buttons.submit</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
