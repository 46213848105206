import Auction from "pages/sales-managment/auction";
import AuctionDetails from "pages/sales-managment/auction/details";

const AuctionRoute = [
  {
    path: "",
    element: <Auction />,
  },
  {
    path: ":auctionId",
    element: <AuctionDetails />,
  },
];

export default AuctionRoute;
