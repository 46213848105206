import {Status, Text, Wrapper, NoItems, Skeleton} from "components";
import {dateFormats} from "constant";
import {useConverter} from "hooks";
import {useState} from "react";
import {Fragment} from "react/jsx-runtime";
import {SalesService} from "types";
import {useParams} from "react-router";
import {useSearchParams} from "react-router-dom";
import {usePublishedProductAvailabilityStatusQuery} from "services/caching";

export default function AvailabilityStatus() {
  const {productId} = useParams();
  const {convertDate} = useConverter();
  let [searchParams] = useSearchParams();
  const [params] = useState<any>({
    channelCode: searchParams.get("channelCode"),
  });

  const {data, isLoading, isFetching} =
    usePublishedProductAvailabilityStatusQuery({
      productId,
      params,
    });

  const loading = isFetching || isLoading;

  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Body className="flex flex-col gap-4">
          {loading ? (
            <Skeleton.AvailableStatus />
          ) : (
            <Fragment>
              <>
                {data && data?.length ? (
                  <>
                    {data?.map(
                      (e: SalesService.PimProductAvailabilityStatusDto) => (
                        <Fragment key={e.id}>
                          <div className="flex w-full items-center gap-2 rounded-2xl border bg-white p-4 shadow-nested">
                            <Status.Availability
                              id={e.availabilityStatus as number}
                            />
                            <p className="flex-1 text-body-base font-normal text-gray-600">
                              {e.isDefault ? (
                                <Text>
                                  productManagement.products.Details.availabilityStatus.defaultStatus
                                </Text>
                              ) : e.validationPeriod ? (
                                <Text
                                  fromDate={
                                    convertDate(
                                      e.fromDate,
                                      dateFormats.onlyDate,
                                    ) || "--"
                                  }
                                  toDate={
                                    convertDate(
                                      e.toDate,
                                      dateFormats.onlyDate,
                                    ) || "--"
                                  }
                                >
                                  productManagement.products.Details.availabilityStatus.statusDate
                                </Text>
                              ) : null}
                            </p>
                          </div>
                        </Fragment>
                      ),
                    )}
                  </>
                ) : (
                  <NoItems />
                )}
              </>
            </Fragment>
          )}

          <div></div>
        </Wrapper.Body>
      </Wrapper>
    </Fragment>
  );
}
