import {URLS, MainApi} from "services";
import {ProductService} from "types";

export const productGroupsSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    productGroups: builder.query<
      ProductService.PimProductGroupDetailDtoPagedDto,
      any
    >({
      query: params => ({
        url: URLS.GET_PRODUCT_GROUP_LIST,
        params,
      }),
      keepUnusedDataFor: 1800,
      providesTags: ["PRODUCT-GROUPS-LIST"],
    }),
    // Product Group Details
    productGroupDetails: builder.query<
      ProductService.PimProductGroupDetailDto,
      any
    >({
      query: id => ({
        url: URLS.GET_PRODUCT_GROUP(id),
      }),
      providesTags: (_result, _error, id) => [
        {type: "PRODUCT-GROUPS_DETAILS", id},
      ],
    }),
    //Create Product Group
    createProductGroups: builder.mutation<
      ProductService.CreatePimProductGroupRequest,
      any
    >({
      query: data => ({
        url: URLS.ADD_PRODUCT_GROUP,
        method: "POST",
        data,
      }),
      invalidatesTags: ["PRODUCT-GROUPS-LIST"],
    }),
    //Update Product Group
    updateProductGroups: builder.mutation<
      ProductService.UpdatePimProductGroupRequest,
      any
    >({
      query: ({data, id}) => ({
        url: URLS.UPDATE_PRODUCT_GROUP(id),
        method: "PUT",
        data,
      }),
      invalidatesTags: (_res, _err, {id}) => [
        "PRODUCT-GROUPS-LIST",
        {type: "PRODUCT-GROUPS_DETAILS", id},
      ],
    }),
  }),
});

export const {
  useProductGroupsQuery,
  useProductGroupDetailsQuery,
  useUpdateProductGroupsMutation,
  useCreateProductGroupsMutation,
} = productGroupsSlice;
