import {Fragment, useContext, useState} from "react";
import {Select, Skeleton, Text, RadioButton} from "components";
import {AddUserListContext} from "..";
import {config, rules} from "constant";
import {useUserGroupListQuery} from "services/caching";
export default function UsersGroup() {
  const {data, setData} = useContext<any>(AddUserListContext);
  const [params, _setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  const {
    data: groupList,
    isLoading,
    isFetching,
  } = useUserGroupListQuery(params);

  const loadingList = isFetching || isLoading;

  return (
    <Fragment>
      <span className="text-heading-2 font-semibold">
        <Text>configurations.userManagment.userList.userGroup</Text>
      </span>
      {loadingList ? (
        <div className="space-y-3">
          <Skeleton.Input />
          <div className="space-y-3">
            <Skeleton.Button />
            <Skeleton.Button />
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          <label className="h6 block w-full truncate font-[400] text-gray-800 ">
            <Text>configurations.userManagment.userList.userType</Text>
          </label>
          <div className="space-y-4">
            <RadioButton
              label="configurations.userManagment.userList.admin"
              value={data.userType === 4}
              setValue={() =>
                setData((p: any) => ({
                  ...p,
                  userType: 4,
                  userGroupName: "",
                  userGroupId: "",
                }))
              }
            />
            <RadioButton
              label="configurations.userManagment.userList.user"
              value={data.userType === 8}
              setValue={() =>
                setData((p: any) => ({
                  ...p,
                  userType: 8,
                }))
              }
            />
          </div>
        </div>
      )}
      <div className={`${data.userType === 8 ? "opacity-100" : "opacity-0"}`}>
        {loadingList ? (
          <Skeleton.Input />
        ) : (
          <Select
            label="configurations.userManagment.userList.usersGroup"
            items={groupList?.items as []}
            value={data.userGroupId}
            disabled={data.userType === 4 ? true : false}
            setValue={e => {
              groupList?.items?.forEach((i: any) => {
                if (i.id === e) {
                  setData((p: any) => ({
                    ...p,
                    userGroupName: i.name,
                    userGroupId: i.id,
                  }));
                }
              });
            }}
            rules={data.userType === 4 ? [] : rules.required}
          />
        )}
      </div>
    </Fragment>
  );
}
