import {
  Breadcrumb,
  Button,
  DatePicker,
  Dropdown,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Select,
  Skeleton,
  Status,
  Table,
  Text,
} from "components";
import {config as appConfig} from "constant";
import {withPermission} from "hoc";
import {useConverter, useToggleState} from "hooks";
import {Fragment, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {ProductService, MakeFieldsRequired} from "types";
import CancelReason from "./cancel-reason";
import {status} from "constant";
import Details from "./details";
import {
  useGetBackgroundJobsQuery,
  useGetBackgroundJobStatusQuery,
} from "services/caching";

type Params = {
  keyword?: string;
  pageNumber?: string;
  pageSize?: string;
  status?: string;
  fromDate?: string;
  toDate?: string;
};

function BackgroundJob() {
  const initParams: Params = {
    pageNumber: "1",
    pageSize: appConfig.pageSize.toString(),
    status: "0",
  };

  const {convertDate} = useConverter();
  const [isOpen, toggle] = useToggleState();
  const [isOpenDetails, toggleDetails] = useToggleState();
  const [message, dispatchMessage] = useState<string | null | undefined>("");

  const [params, setParams] = useSearchParams(initParams);
  const queryParams = Object.fromEntries(params.entries());

  const [selectedJob, dispatchJob] =
    useState<MakeFieldsRequired<ProductService.InternalJobsDto, "id">>();

  const showDateValue = (key: string) => {
    const hasValue = params.has(key);
    const value = params.get(key) ?? "";
    if (hasValue) return new Date(value);
    return "";
  };

  const dateFilterList = [
    {id: "all", label: "All"},
    {id: "week", label: "Last 7 days"},
    {id: "month", label: "Last 30 days"},
    {id: "custom", label: "Custom range"},
  ];

  const [dateFilter, dispatchDateFilter] = useState({
    id: "all",
    label: "All",
  });

  const onDateFilterChange = (type: string) => {
    if (type === "all") {
      setParams(p => {
        p.delete("fromDate");
        p.delete("toDate");
        return p;
      });
      // setReqParams(p => ({...p, fromDate: "", toDate: ""}));
    } else if (type === "week") {
      const today = new Date();
      const todayISO = today.toISOString();
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(today.getDate() - 7);
      const sevenDaysAgoISO = sevenDaysAgo.toISOString();
      setParams(p => {
        p.set("fromDate", sevenDaysAgoISO);
        p.set("toDate", todayISO);
        return p;
      });
      // setReqParams(p => ({...p, fromDate: sevenDaysAgoISO, toDate: todayISO}));
    } else if (type === "month") {
      const today = new Date();
      const todayISO = today.toISOString();
      const Thirty = new Date();
      Thirty.setDate(today.getDate() - 30);
      const ThirtyISO = Thirty.toISOString();
      setParams(p => {
        p.set("fromDate", ThirtyISO);
        p.set("toDate", todayISO);
        return p;
      });
      // setReqParams(p => ({...p, fromDate: ThirtyISO, toDate: todayISO}));
    }
  };

  const handleSetParams = (key: keyof Params) => {
    const isPageNumber = key === "pageNumber";
    return (value: string | number | null) => {
      setParams(p => {
        const hasValue = !!String(value ?? "");
        hasValue ? p.set(key, `${value}`) : p.delete(key);
        !isPageNumber && p.set("pageNumber", `1`);
        return p;
      });
    };
  };

  const getSearchParams = () => {
    const config = {
      params: new URLSearchParams(params),
    };
    const fromDate = params.get("fromDate");
    const toDate = params.get("toDate");
    const pageNumber = params.get("pageNumber");
    const pageSize = params.get("pageSize");
    const keyword = params.get("keyword");
    const status = params.get("status");
    !!fromDate && config.params.set("fromDate", fromDate);
    !!toDate && config.params.set("toDate", toDate);
    !!keyword && config.params.set("keyword", keyword);
    !pageNumber && config.params.set("pageNumber", "1");
    !status && config.params.set("status", "0");
    status === "all" && config.params.set("status", "");
    !pageSize && config.params.set("pageSize", `${appConfig.pageSize}`);
  };

  const resetFilter = () => {
    dispatchDateFilter({
      id: "all",
      label: "All",
    });
    setParams(initParams);
  };

  useEffect(() => {
    getSearchParams();
  }, [params]);

  const isFilter = ["keyword", "fromDate", "toDate"].some(item =>
    params.get(item),
  );

  const {
    data: BackgroundJobData,
    isLoading,
    isFetching,
    refetch,
  } = useGetBackgroundJobsQuery(queryParams);

  const {
    data: BackgroundJobStatus,
    isLoading: isLoadingStatus,
    isFetching: isFetchingStatus,
    refetch: refetchStatus,
  } = useGetBackgroundJobStatusQuery();

  const loading = isFetching || isLoading;
  const loadingStatus = isFetchingStatus || isLoadingStatus;

  const hasData = !!BackgroundJobData?.items?.length;

  return (
    <>
      <div className="space-y-4">
        <Breadcrumb />
        <div className="flex-between">
          <SearchBox
            value={params.get("keyword")}
            onSubmit={handleSetParams("keyword")}
            disabled={loading}
            loading={loading}
            totalItems={BackgroundJobData?.totalItems}
            onReload={() => {
              refetch();
              refetchStatus();
            }}
          />
          {isFilter && (
            <Button
              disabled={loading}
              variant={"white"}
              textColor="danger"
              size="sm"
              onClick={resetFilter}
            >
              <Icon icon="circle-xmark"></Icon>
              <span className="ml-1">Reset Filter</span>
            </Button>
          )}
        </div>
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-2">
            <div className="col-span-1">
              <span className="text-heading-5 text-gray-700">
                <Text>salesManagment.commissionReports.createDate</Text>:
              </span>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                type="button"
                className="flex min-w-[10rem] space-x-2 rounded-md bg-white p-3 text-body-base font-medium uppercase text-primary"
              >
                <span>{dateFilter?.label} </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="mt-2 min-w-fit p-2">
                <ul className="w-max text-gray-700">
                  {dateFilterList?.map((filter: any) => (
                    <li
                      onClick={() => {
                        dispatchDateFilter(filter);
                        onDateFilterChange(filter.id);
                      }}
                      className={`h6 px-4 py-2 font-medium first:mb-1 ${
                        filter.code === dateFilter?.label &&
                        "bg-primary-light text-primary "
                      } cursor-pointer rounded-lg text-body-base hover:bg-primary-light hover:text-primary`}
                    >
                      {filter?.label}
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <div>
              <DatePicker
                dense
                value={showDateValue("fromDate")}
                maxDate={showDateValue("toDate") || new Date()}
                onChange={handleSetParams("fromDate")}
                disabled={dateFilter?.id !== "custom"}
              />
            </div>
            <div>
              <DatePicker
                dense
                placeholderText="priceEngine.purchasePrice.selectDate"
                value={showDateValue("toDate")}
                minDate={showDateValue("fromDate")}
                maxDate={new Date()}
                onChange={handleSetParams("toDate")}
                disabled={dateFilter?.id !== "custom"}
              />
            </div>
          </div>
          <Select
            placeholder="global.select"
            value={params?.get("status") ?? "0"}
            items={status.internalJobsStatus}
            setValue={handleSetParams("status")}
            size="sm"
          />
        </div>
        <div className="grid grid-cols-4 gap-4">
          {loadingStatus ? (
            [...Array(4)].map(() => (
              <div className="ml-auto mt-auto h-11 w-full animate-pulse rounded bg-white"></div>
            ))
          ) : (
            <>
              {BackgroundJobStatus?.map((status: any) => (
                <div className="flex-between rounded bg-white px-4 py-3 shadow-card">
                  <span className="text-heading-1 font-semibold text-gray-800">
                    {status.itemCount}
                  </span>
                  <Status.InternalJobsStatus id={status.status} />
                </div>
              ))}
            </>
          )}
        </div>
        {isLoading || isFetching ? (
          <>
            {[...Array(6)].map(() => (
              <Skeleton.List />
            ))}
          </>
        ) : (
          <>
            {hasData ? (
              <>
                <Table>
                  {BackgroundJobData?.items?.map(e => {
                    return (
                      <Fragment key={e.id}>
                        <tr className="*:text-left">
                          <td className="space-y-2">
                            <h6 className="truncate text-heading-6 text-gray-800">
                              {e.title}
                            </h6>
                            <div className="block space-y-2 xl:hidden">
                              <p className="text-body-2 text-gray-600">
                                <Text>internalJobs.createdAt</Text>:{" "}
                                <span>{convertDate(e.createdAt)}</span>
                              </p>
                              {e.lastProcessedAt && (
                                <p className="text-body-2 text-gray-600">
                                  <Text>internalJobs.updatedAt</Text>:{" "}
                                  <span>{convertDate(e.lastProcessedAt)}</span>
                                </p>
                              )}
                            </div>
                          </td>
                          <td className="hidden space-y-2 xl:table-cell">
                            <p className="text-body-2 text-gray-600">
                              <Text>internalJobs.createdAt</Text>:{" "}
                              <span>{convertDate(e.createdAt)}</span>
                            </p>
                            {e.lastProcessedAt && (
                              <p className="text-body-2 text-gray-600">
                                <Text>internalJobs.updatedAt</Text>:{" "}
                                <span>{convertDate(e.lastProcessedAt)}</span>
                              </p>
                            )}
                          </td>
                          <td>
                            <Status.InternalJobsStatus
                              id={e?.status as number}
                            />
                          </td>
                          <td>
                            {e.status === 2 && (
                              <Button
                                size="sm"
                                light
                                onClick={() => {
                                  toggle();
                                  dispatchMessage(e.errorMessage);
                                }}
                              >
                                <Icon icon="info" />
                              </Button>
                            )}
                          </td>
                          <td>
                            <Button
                              size="sm"
                              variant={"light"}
                              onClick={() => {
                                dispatchJob(e);
                                toggleDetails();
                              }}
                            >
                              <Text>global.buttons.details</Text>
                            </Button>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </Table>
                <Pagination
                  totalItems={BackgroundJobData?.totalItems}
                  totalPages={BackgroundJobData?.totalPages}
                  pageNumber={+(params.get("pageNumber") ?? 1)}
                  pageSize={+(params.get("pageSize") ?? appConfig.pageSize)}
                  setActivePage={handleSetParams("pageNumber")}
                  onPageSizeChange={handleSetParams("pageSize")}
                />
              </>
            ) : (
              <NoItems />
            )}
          </>
        )}
        <CancelReason isOpen={isOpen} toggle={toggle} message={message} />
      </div>
      <Details
        isOpen={isOpenDetails}
        toggle={toggleDetails}
        job={selectedJob}
      />
    </>
  );
}

export default withPermission(BackgroundJob, ["PS_GetInternalJob"]);
