import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
} from "components";
import {config, types} from "constant";
import {withPermission} from "hoc";
import {usePermission, useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import AddModal from "./add-modal";
import DetailsModal from "./details-modal";

type ItemsType = {
  id: string;
  title: string;
  description: string;
  isActive: boolean;
  objectType: number;
};

type ListType = {
  items?: ItemsType[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

function CheckListItems() {
  const controller = new AbortController();
  const [data, setData] = useState<ListType>();
  const [loadingList, toggleLoadingList] = useToggleState();
  const [selected, setSelected] = useState<ItemsType | null>();
  const [searchParams] = useSearchParams();
  const [filterType, setType] = useState<number | null>(null);
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();
  const [isOpen, toggle] = useToggleState(false);
  const [isOpenUpdate, toggleUpdate] = useToggleState(false);

  const updatePermission = usePermission("PS_UpdateCheckList");

  const handleSetParams = (key: any) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.PIM_CHECK_LIST_URL;
    const config = {params};
    toggleLoadingList();
    ServiceApi.get(url, {...config, signal: controller.signal})
      .then(res => {
        if (res?.data) {
          const {data} = res;
          setData(data);
        }
      })
      .finally(() => {
        toggleLoadingList();
      });
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["PS_CreateCheckList"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button onClick={() => toggle()}>
              <Icon className="mr-2" icon={"plus"} />
              <Text>productManagement.products.completeness.addCheckList</Text>
            </Button>
          )}
        </WithPermission>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onReload={getData}
        loading={loadingList}
      />
      <div className="flex items-center gap-4">
        <span
          className={`min-w-14 cursor-pointer rounded-xl p-3 text-center text-heading-6 font-semibold ${
            filterType === null
              ? "bg-primary text-white"
              : "bg-white text-gray-500"
          } ${loadingList && "pointer-events-none opacity-30"}`}
          onClick={() => {
            setParams((p: any) => ({...p, objectType: ""}));
            setType(null);
          }}
        >
          <Text>global.all</Text>
        </span>
        {types.checkListObjectType?.map((type, index) => (
          <span
            key={index}
            className={`min-w-14 cursor-pointer rounded-xl p-3 text-center text-heading-6 font-semibold ${
              filterType === type.id
                ? "bg-primary text-white"
                : "bg-white text-gray-500"
            } ${loadingList && "pointer-events-none opacity-30"}`}
            onClick={() => {
              setParams((p: any) => ({...p, objectType: type.id}));
              setType(type.id);
            }}
          >
            <Text>{type.name}</Text>
          </span>
        ))}
      </div>
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.id}>
                      <td className="min-w-44 text-heading-6 text-gray-800">
                        <button
                          type="button"
                          onClick={() => {
                            if (!updatePermission) return;
                            setSelected(item);
                            toggleUpdate();
                          }}
                          className=" w-fit space-y-3 text-wrap text-left leading-6"
                        >
                          <h6>{item.title}</h6>
                          <Status.checkListTtypeStatus id={item.objectType} />
                        </button>
                      </td>
                      <td className="text-wrap text-left text-body-base leading-6">
                        <span className="ml-1 text-gray-500">
                          {item.description}
                        </span>
                      </td>
                      <td className="space-x-4">
                        <Status.pimCheckList id={item?.isActive} />
                      </td>
                      <td>
                        <div>
                          <Button
                            type="button"
                            className="mr-2"
                            onClick={() => {
                              setSelected(item);
                              toggleUpdate();
                            }}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages || 1}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <AddModal isOpen={isOpen} toggle={toggle} getData={getData} />
      <DetailsModal
        isOpen={isOpenUpdate}
        toggle={toggleUpdate}
        getData={getData}
        selected={selected?.id}
      />
    </div>
  );
}
export default withPermission(CheckListItems, ["PS_GetCheckList"]);
