import {URLS, MainApi} from "services";
import {ProductService} from "types";

//supplier slice
export const supplierSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    suppliers: builder.query<ProductService.SupplierQueryDtoPagedDto, any>({
      query: params => ({
        url: URLS.GET_SUPPLIERS,
        keepUnusedDataFor: 1800,
        params,
      }),
      providesTags: ["SUPPLIER-LIST"],
    }),
    publishAllSuppliers: builder.mutation<
      ProductService.PublishAllSupplierToChannelRequest,
      any
    >({
      query: data => ({
        url: URLS.PUBLISH_ALL_TO_CHANNELS,
        method: "POST",
        data,
      }),
      invalidatesTags: ["BACKGROUND-JOB-LIST", "BACKGROUND-JOB-STATUS"],
    }),
    createSupplier: builder.mutation<ProductService.CreateSupplierRequest, any>(
      {
        query: data => ({
          url: URLS.CREATE_SUPPLIERS,
          method: "POST",
          data,
        }),
        invalidatesTags: ["SUPPLIER-LIST"],
      },
    ),
    uploadSupplierImage: builder.mutation<void, any>({
      query: ({id, data}) => ({
        url: URLS.UPLOAD_SUPPLIERS_IMAGES(id),
        method: "PATCH",
        data,
      }),
    }),
  }),
});

export const {
  useSuppliersQuery,
  useLazySuppliersQuery,
  useCreateSupplierMutation,
  usePublishAllSuppliersMutation,
  useUploadSupplierImageMutation,
} = supplierSlice;
