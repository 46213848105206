import {URLS, MainApi} from "services";
import {ProductService} from "types";

export const productCategorySlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    categories: builder.query<ProductService.ProductCategoryQueryDto[], void>({
      query: () => ({
        url: URLS.GET_CATEGORIES,
      }),
      keepUnusedDataFor: 1800,
      providesTags: ["CATEGORY-LIST"],
    }),
  }),
});

export const {useCategoriesQuery} = productCategorySlice;
