import {withPermission} from "hoc";
import {useToggleState} from "hooks";
import {useContext, useEffect, useState} from "react";
import {SuppliersContext} from "..";
import Details from "./program/details";
import ProgramList from "./program/program-list";
import {useProgramListQuery} from "services/caching";

function Program() {
  const {suppliersData} = useContext<any>(SuppliersContext);
  const [programId, setProgramId] = useState<string | undefined>("");
  const [isOpenCreateDrawer, toggleCreateDrawer] = useToggleState();

  const {data, isLoading, isFetching, refetch} = useProgramListQuery(
    suppliersData?.supplierId,
    {
      skip: !suppliersData.supplierId,
    },
  );

  const loading = isFetching || isLoading;

  useEffect(() => {
    if (!!data) {
      if (data?.length !== 0) setProgramId(data[0].id);
      else setProgramId(undefined);
    }
  }, [data]);

  return (
    <div className="flex h-full min-h-screen items-start gap-6">
      <div>
        <ProgramList
          programs={data}
          loading={loading}
          selectedId={programId}
          setSelectedId={setProgramId}
          updateList={refetch}
          suppliersData={suppliersData}
          isOpenCreateDrawer={isOpenCreateDrawer}
          toggleCreateDrawer={toggleCreateDrawer}
        />
      </div>
      <div className="sticky top-20 flex flex-1">
        <Details
          programId={programId}
          setProgramId={setProgramId}
          supplierData={suppliersData}
          getData={refetch}
          toggle={toggleCreateDrawer}
        />
      </div>
    </div>
  );
}
export default withPermission(Program, ["PS_GetSupplier"]);
