import {NotificationManager} from "common/notifications";
import {
  Button,
  CheckBox,
  Drawer,
  Icon,
  InputGroup,
  LazyImage,
  NoItems,
  SearchBox,
  Skeleton,
  Status,
  Text,
} from "components";
import {useConverter, useSelector, useToggleState} from "hooks";
import {createContext, useContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import {toggleProps, SalesService} from "types";
import {AuctionContext} from "..";
import FilterDrawer from "./filter-products";
import {
  useGetPublishedProductsQuery,
  useUpdateAuctionProductsMutation,
} from "services/caching";

type DrawerProps = toggleProps;

const initialParams = {
  pageNumber: 1,
  pageSize: 10,
  channelCode: null,
  keyword: null,
  searchFields: null,
  pimProductFamilyId: null,
  brandCode: null,
  pimProductCategoryCode: null,
  supplierId: null,
  groupId: null,
  programId: null,
  language: null,
  priceFrom: null,
  priceTo: null,
  salesToolsSortField: null,
  pimProductType: null,
  hasAuction: null,
  auctionId: null,
};
type Products = SalesService.PimBasicInfoDto & {price: string};

type ContextType = {
  data: SalesService.SalesToolsPimProductSearchResultDto;
  loading: boolean;
  isFilter: boolean;
  params: any;
  setParams: any;
  initialParams: any;
};

export const CreateAuctionContext = createContext<ContextType>({
  data: {},
  params: {},
  loading: false,
  isFilter: false,
  setParams: () => {},
  initialParams,
});

export default function AddProductsDrawer({isOpen, toggle}: DrawerProps) {
  const {auctionId} = useParams();
  const {existedProductsIds} = useContext(AuctionContext);
  const [params, setParams] = useState<any>(initialParams);

  const [updateAuction, {isLoading: submitLoading}] =
    useUpdateAuctionProductsMutation();

  const {
    data: publishedProductsData,
    isLoading,
    isFetching,
    refetch,
  } = useGetPublishedProductsQuery(params, {skip: !isOpen});
  const loading = isFetching || isLoading;

  const {convertAmount} = useConverter();
  const channels = useSelector(s => s.channels);
  const [channelsList, setChannels] = useState<any>([]);
  const [isOpenFilter, toggleFilter] = useToggleState();
  const [data, setData] =
    useState<SalesService.SalesToolsPimProductSearchResultDto>({});

  const [products, setProducts] = useState<any>([]);
  const [selectedProducts, dispatchSelectedProducts] = useState<
    SalesService.AuctionProductModel[]
  >([]);
  const [selectedProductsIds, dispatchSelectedProductsIds] = useState<string[]>(
    [],
  );

  const getChannels = () => {
    setChannels([{title: "All", code: null}, ...channels]);
  };

  useEffect(() => {
    if (isOpen) {
      getChannels();
      dispatchSelectedProductsIds([]);
      dispatchSelectedProducts([]);
    }
  }, [isOpen, params]);

  const callback = () => {
    console.log("sahraei", publishedProductsData?.searchResult);
    const clone = publishedProductsData?.searchResult?.map(
      (product: SalesService.PimBasicInfoDto) => ({
        ...product,
        price: product?.vk1Price?.amount || "0",
      }),
    );
    setProducts(clone);
    setData(data);
    dispatchSelectedProducts([]);
    dispatchSelectedProductsIds([]);
  };

  useEffect(() => {
    if (!!publishedProductsData) {
      callback();
    }
  }, [publishedProductsData]);

  const findChannelName = (channelCode: string) => {
    const channel: any = channelsList?.find(
      (channel: any) => channel.code === channelCode,
    );
    return channel ? channel.title : "";
  };

  const handleSetFilterData = (key: string) => {
    return (value: any) => {
      setParams((p: any) => ({
        ...p,
        filter: true,
        [key]: value,
      }));
    };
  };

  const toggleItem = (item: any) => {
    dispatchSelectedProductsIds((prevSelected: any) => {
      if (
        prevSelected.some(
          (selected: any) => selected === item.id + item.channelCode,
        )
      ) {
        dispatchSelectedProducts((prevProducts: any) =>
          prevProducts.filter(
            (selected: any) =>
              selected.id !== item.id &&
              selected.channelCode !== item.channelCode,
          ),
        );
        return prevSelected.filter(
          (selected: any) => selected !== item.id + item.channelCode,
        );
      } else {
        dispatchSelectedProducts((prevProducts: any) => [
          ...prevProducts,
          item,
        ]);
        return [...prevSelected, item.id + item.channelCode];
      }
    });
  };

  const onSubmitHandler = async () => {
    const products = selectedProducts?.map((product: any) => ({
      pimProductId: product.id,
      price: {
        currency: "EUR",
        amount: product?.price,
        currencySymbol: "€",
      },
      channelCode: product.channelCode,
    }));
    const body = {products};
    try {
      await updateAuction({data: body, id: auctionId}).unwrap();
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title",
      );
      toggle();
    } catch (error) {}
  };

  const onChangePriceHandler = (value: string, id: string) => {
    const clone = products?.map((product: Products) =>
      product.id === id ? {...product, price: value} : product,
    );
    const overwriteProduct = selectedProducts?.map((product: any) =>
      product.id === id ? {...product, price: value} : product,
    );
    setProducts(clone);
    dispatchSelectedProducts(overwriteProduct);
  };

  const isFilter = [
    "channelCode",
    "keyword",
    "searchFields",
    "pimProductFamilyId",
    "brandCode",
    "pimProductCategoryCode",
    "supplierId",
    "groupId",
    "programId",
    "language",
    "priceFrom",
    "priceTo",
    "salesToolsSortField",
    "pimProductType",
  ].some(item => params[item]);

  const resetFilter = () => {
    setParams(initialParams);
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle} size={"lg"}>
        <Drawer.Menu>
          <Drawer.Header className="flex-between">
            <span className="text-heading-2 font-semibold text-gray-800">
              <Text>salesManagment.auctions.addProducts</Text>
            </span>
            <div className="flex items-center gap-2">
              {isFilter && (
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={resetFilter}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>global.buttons.resetFilters</Text>
                </Button>
              )}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="close" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-4">
            <div className="flex items-center justify-between">
              <SearchBox
                value={params?.searchFields}
                onSubmit={handleSetFilterData("searchFields")}
                totalItems={data?.totalItems}
                loading={loading}
                onReload={refetch}
                variant="gray"
              />
              <button
                type="button"
                disabled={loading}
                onClick={toggleFilter}
                className={`flex-center h-[38px] w-[38px] cursor-pointer rounded-md bg-gray-100`}
              >
                <Icon size="1x" className="text-primary" icon={"filters"} />
              </button>
            </div>
            {loading ? (
              [...Array(7)].map(() => <Skeleton.ImageList />)
            ) : (
              <>
                {!!products?.length ? (
                  products?.map((item: any) => {
                    const existedProduct = existedProductsIds?.includes(
                      item?.id + item?.channelCode,
                    );
                    return (
                      <div
                        className={`flex flex-col rounded bg-white px-4 shadow-card ${existedProduct && "pointer-events-none opacity-50"}`}
                      >
                        <div className="flex items-center gap-4 ">
                          <div
                            className={` flex-center h-24 w-32 overflow-hidden rounded border border-gray-200 bg-gray-100`}
                          >
                            <LazyImage
                              className={`h-full w-full object-cover`}
                              src={item.avatarUrl}
                              alt={item.avatarUrl}
                              isDynamic
                              imageClassName={"w-full h-full"}
                            />
                          </div>
                          <div className="flex-1 space-y-2 text-wrap py-2">
                            <h6 className="text-heading-6 font-semibold leading-6 text-gray-800">
                              {item.originalName}
                            </h6>
                            <Status.PimProductType id={item.pimProductType} />

                            <div className="text-body-2">
                              <p className="text-gray-600">
                                <Text>
                                  salesManagment.auctions.articleNumber
                                </Text>
                                #: {item.articleNumber ?? "----------"}
                              </p>
                            </div>

                            <p className="text-body-2  leading-6 text-gray-600">
                              <Text>salesManagment.auctions.channel</Text> :{" "}
                              <span className="rounded bg-gray-100 px-2 py-1 text-body-3 font-medium text-primary">
                                {findChannelName(item.channelCode)}
                              </span>
                            </p>
                          </div>
                          <div className="flex flex-col gap-6">
                            <CheckBox
                              value={
                                selectedProductsIds?.includes(
                                  item?.id + item?.channelCode,
                                ) ||
                                existedProductsIds?.includes(
                                  item?.id + item?.channelCode,
                                )
                              }
                              setValue={() => {
                                toggleItem(item);
                              }}
                            />
                            <span className="text-body-2 text-gray-700">
                              vk1: {convertAmount(item?.vk1Price?.amount)}
                            </span>
                          </div>
                        </div>
                        {selectedProductsIds?.includes(
                          item?.id + item?.channelCode,
                        ) &&
                          item?.pimProductType !== 1 && (
                            <div className="border-t border-gray-200 py-4">
                              <div className="w-1/2">
                                <InputGroup
                                  label={
                                    "salesManagment.auctions.overWriteSalePrice"
                                  }
                                  value={item?.price}
                                  setValue={e => {
                                    if (+e <= +item?.vk1Price?.amount)
                                      onChangePriceHandler(e, item.id);
                                  }}
                                  type="price"
                                />
                              </div>
                            </div>
                          )}
                      </div>
                    );
                  })
                ) : (
                  <NoItems />
                )}
              </>
            )}
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-between">
            <Button size="sm" onClick={toggle} variant={"light"} type="button">
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button
              size="sm"
              type="button"
              disabled={loading || !!!selectedProducts?.length}
              loading={submitLoading}
              onClick={onSubmitHandler}
            >
              <Text>global.buttons.submit</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <CreateAuctionContext.Provider
        value={{
          data,
          loading,
          setParams,
          initialParams,
          isFilter,
          params,
        }}
      >
        <FilterDrawer isOpen={isOpenFilter} toggle={toggleFilter} />
      </CreateAuctionContext.Provider>
    </>
  );
}
