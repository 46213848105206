import {NotificationManager} from "common/notifications";
import {Button, CheckBox, Drawer, Icon, Text} from "components";
import {useSelector} from "hooks";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import {usePublishAllSuppliersMutation} from "services/caching";

type Props = {
  isOpen: boolean;
  toggle: () => void;
};
export default function PublishDrawer({isOpen, toggle}: Props) {
  const channels = useSelector(s => s.channels);
  const [params] = useSearchParams();
  const [selectedChannel, dispatchSelectedChannels] = useState<string[]>([]);
  const [withPrograms, togglePrograms] = useState<boolean>(true);
  const [channelsList, setChannels] = useState<any>([]);

  const [publishSuppliers, {isLoading}] = usePublishAllSuppliersMutation();

  useEffect(() => {
    if (isOpen) {
      const clone = channels?.map((channel: any) => channel?.code);
      dispatchSelectedChannels(clone);
      setChannels(channels);
      togglePrograms(true);
    } else {
      dispatchSelectedChannels([]);
      togglePrograms(false);
    }
  }, [channels, isOpen]);

  const toggleItem = (item: any) => {
    dispatchSelectedChannels(prevSelected => {
      if (prevSelected.some(selected => selected === item.code)) {
        return prevSelected.filter(selected => selected !== item.code);
      } else {
        return [...prevSelected, item.code];
      }
    });
  };

  const toggleAll = (action?: string) => {
    if (action === "select") {
      const clone = channelsList?.map((channel: any) => channel?.code);
      dispatchSelectedChannels(clone);
    } else {
      dispatchSelectedChannels([]);
    }
  };

  const onSubmitHandler = async () => {
    const keyword = params.get("keyword");
    const supplierType = Number(params.get("supplierType"));

    const body = {
      channelCodes: selectedChannel,
      includePrograms: withPrograms,
      ...(params.get("supplierType") && {supplierType}),
      ...(params.get("keyword") && {keyword}),
    };

    try {
      await publishSuppliers(body).unwrap();
      NotificationManager.success(
        "global.toast.publish-msg",
        "global.toast.publish-title",
      );
      toggle();
    } catch (error) {}
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between">
          <h2>
            <Text>
              productManagement.masterData.suppliers.publishAllSuppliers
            </Text>
          </h2>
          <Button size="sm" variant={"light"} onClick={toggle}>
            <Icon size="sm" icon={"close"} />
            <Text>global.buttons.close</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 py-8 *:text-gray-800">
          <p className="text-body-base font-normal">
            <Text>
              productManagement.masterData.suppliers.youCanPublishAllSuppliersInSelectedChannelsHere
            </Text>
          </p>
          <CheckBox
            label="productManagement.masterData.suppliers.publishProgramsWithSuppliers"
            value={withPrograms}
            labelClassName="text-heading-5"
            setValue={e => togglePrograms(e)}
            disabled={isLoading}
          />
          <p className="text-body-base font-normal">
            <Text>
              productManagement.masterData.suppliers.chooseChannelsThatYouWantToPublishSuppliers
            </Text>
            :
          </p>
          <div className="flex items-center justify-between">
            <CheckBox
              label="productManagement.masterData.suppliers.selectAll"
              value={selectedChannel?.length === channelsList?.length}
              labelClassName="text-heading-5"
              setValue={() => toggleAll("select")}
              disabled={isLoading}
            />
            <CheckBox
              label="productManagement.masterData.suppliers.deSelectAll"
              value={selectedChannel?.length === 0}
              labelClassName="text-heading-5"
              setValue={() => toggleAll()}
              disabled={isLoading}
            />
          </div>
          {channelsList?.map((channel: any) => {
            const isSelected = selectedChannel?.includes(channel?.code);
            return (
              <div
                key={channel?.code}
                className={`flex items-center rounded p-6 ${isSelected ? "bg-primary-light" : "bg-gray-100"}`}
              >
                <CheckBox
                  label={channel.title}
                  value={isSelected}
                  labelClassName="text-heading-5"
                  setValue={() => toggleItem(channel)}
                  disabled={isLoading}
                />
              </div>
            );
          })}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between">
          <Button
            disabled={isLoading}
            size="sm"
            variant={"light"}
            onClick={toggle}
          >
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button
            loading={isLoading}
            disabled={!!!selectedChannel?.length}
            size="sm"
            onClick={onSubmitHandler}
          >
            <Text>productManagement.masterData.suppliers.publish</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
