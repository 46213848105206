import {URLS} from "services";
import MainApi from "services/caching/mainApi";

export const dataStorageSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    cloudStorage: builder.query<any, void>({
      query: () => ({
        url: URLS.GET_PERSONAL_AZURE_BLOB_CONFIG,
      }),
      transformResponse: response => {
        return response || {};
      },
    }),
  }),
});

export const {useCloudStorageQuery, useLazyCloudStorageQuery} =
  dataStorageSlice;
