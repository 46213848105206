
import { URLS, MainApi } from "services";
import { ProductService } from "types";

export const shippingMethodsSlice = MainApi.injectEndpoints({
    endpoints: builder => ({
        shippingMethods: builder.query<ProductService.ShippingMethodQueryDtoPagedDto, void>({
            query: params => ({
                url: URLS.GET_SHIPPING_LIST,
                params
            }),
            providesTags: ["SHIPPING-METHODS-LIST"],
        }),
        createShippingMethods: builder.mutation<any, ProductService.CreateShippingMethodRequest>({
            query: data => ({
                url: URLS.ADD_SHIPPING_METHODS,
                method: 'POST',
                data
            }),
            invalidatesTags: ["SHIPPING-METHODS-LIST"],
        }),
        updateShippingMethods: builder.mutation<any, { data: ProductService.UpdateShippingMethodRequest, id: string }>({
            query: ({ data, id }) => ({
                url: URLS.PUT_SHIPPING_METHODS_DETAILS(id),
                method: 'PUT',
                data
            }),
            invalidatesTags: ["SHIPPING-METHODS-LIST"],
        }),
    }),
});

export const { useShippingMethodsQuery, useCreateShippingMethodsMutation, useUpdateShippingMethodsMutation } = shippingMethodsSlice;
