import {
  Breadcrumb,
  Skeleton,
  Tabs,
  Wrapper,
  Text,
  Button,
  Icon,
  DropdownButton,
} from "components";
import {createContext, createElement, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {
  General,
  Attribute,
  Media,
  BundleItems,
  Inventory,
  Locations,
} from "./tabs";
import {useToggleState} from "hooks";
import Info from "./info";
import Print from "./drawers/print";
import PrintHistory from "./tabs/print-history";
import {useSearchParams} from "react-router-dom";
import Share from "./drawers/share";
import AvailabilityStatus from "./tabs/availability-status/availability-status";
import PushToSellOff from "./drawers/push-to-selloff";
import SalesPersonCommission from "./tabs/salesPersonCommission";
import {
  useGetPublishedProductsDetailsQuery,
  useInventoryConfigExistedQuery,
} from "services/caching";

export const PimContext = createContext<any>({});
//Published Products
export default function PublishedProductsDetails() {
  const navigate = useNavigate();
  const {productId} = useParams();
  const [share, shareToggle] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  let [searchParams] = useSearchParams();

  const [params] = useState<any>({
    channelCode: searchParams.get("channelCode"),
  });
  const [activeKey, setActiveKey] = useState<string>();
  const [print, printToggle] = useToggleState();
  const [updatePrintHistory, toggleUpdatePrintHistory] = useToggleState();

  const {
    data: details,
    isLoading,
    isFetching,
    refetch,
  } = useGetPublishedProductsDetailsQuery({productId, params});

  const {data: inventoryConfigExisted} = useInventoryConfigExistedQuery();

  const loading = isFetching || isLoading;

  const tabs = [
    {
      label: "productManagement.products.Details.generalTab",
      component: General,
    },
    ...(details?.pimProductType
      ? [
          {
            label: "items",
            component: BundleItems,
          },
        ]
      : [
          {
            label: "productManagement.products.Details.attributesTab",
            component: Attribute,
          },
        ]),

    {
      label: "productManagement.products.Details.mediaTab",
      component: Media,
      permission: ["PS_GetPimProductMedia"],
    },
    {
      label: "productManagement.publishedProduct.Details.inventory",
      component: Inventory,
    },
    {
      label: "productManagement.publishedProduct.Details.printHistory",
      component: PrintHistory,
    },
    {
      label: "productManagement.products.Details.availabilityStatusTab",
      component: AvailabilityStatus,
    },
    ...(details?.pimProductType == 0
      ? [
          {
            label: "productManagement.products.Details.salesPersonCommission",
            component: SalesPersonCommission,
          },
        ]
      : []),
    {
      label: "productManagement.products.Details.locationsTab",
      component: Locations,
    },
  ];

  useEffect(() => {
    setActiveKey(tabs[0].label);
  }, [productId]);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div>
          <Breadcrumb backward />
        </div>
        <div className="flex items-center gap-2">
          <Button
            size="sm"
            light
            textColor="primary"
            onClick={toggle}
            disabled={loading}
            className="shadow-card"
          >
            <span>
              <Text>
                productManagement.publishedProduct.Details.pushToSellOffProduct
              </Text>
            </span>
          </Button>
          <DropdownButton
            aria-label="cy-create-product"
            size="sm"
            variant={"white"}
            items={[
              {
                id: "share",
                title: "productManagement.products.Details.shareButton",
                permission: true,
                onClick: shareToggle,
              },
              {
                id: "print",
                title: "productManagement.publishedProduct.Details.print",
                permission: true,
                onClick: printToggle,
              },
              {
                id: "details",
                title:
                  "productManagement.publishedProduct.Details.originalProductInPIM",
                permission: true,
                onClick: () => {
                  navigate(
                    `/productManagement/products/all-products/${productId}`,
                  );
                },
              },
            ]}
          >
            <Text>Action</Text>
          </DropdownButton>
        </div>
      </div>
      <Tabs
        activeKey={activeKey}
        // onSave={() => {
        //   setData(baseData);
        //   setAttributeChanged(false);
        // }}
      >
        <div className="space-y-6">
          <PimContext.Provider
            value={{
              details,
              loading,
              updatePrintHistory,
              getDetails: refetch,
            }}
          >
            <Wrapper>
              <Wrapper.Body>
                <Info />
              </Wrapper.Body>
              <Wrapper.Footer className="!py-0">
                {loading ? (
                  <div className="flex w-full gap-2 p-4">
                    {[...Array(10).map(() => <Skeleton />)]}
                  </div>
                ) : (
                  <Tabs.ButtonGroup>
                    {tabs.map(e => (
                      <Tabs.Button
                        key={e.label}
                        eventKey={e.label}
                        //   disabled={loading}
                        onClick={() => setActiveKey(e.label)}
                      >
                        <Text>{e.label}</Text>
                      </Tabs.Button>
                    ))}
                  </Tabs.ButtonGroup>
                )}
              </Wrapper.Footer>
            </Wrapper>
            {tabs.map(e => (
              <Tabs.Item key={e.label} eventKey={e.label}>
                {createElement(e.component)}
              </Tabs.Item>
            ))}
          </PimContext.Provider>
        </div>
      </Tabs>
      <Print
        isOpen={print}
        toggle={printToggle}
        id={productId}
        getData={toggleUpdatePrintHistory}
      />
      <Share isOpen={share} toggle={shareToggle} id={productId} />
      <PushToSellOff
        isOpen={isOpen}
        toggle={toggle}
        pimProductId={productId as string}
        channelCode={params.channelCode}
        inventoryConfigExisted={!!inventoryConfigExisted}
      />
    </div>
  );
}
