export const appPermissions = {
  AS_UserFullAccess: "AS_UserFullAccess",
  AS_CreateUser: "AS_CreateUser",
  AS_InviteUserIdP: "AS_InviteUserIdP",
  AS_UpdateUser: "AS_UpdateUser",
  AS_UpdateUserProviders: "AS_UpdateUserProviders",
  AS_GetUser: "AS_GetUser",
  AS_SearchUserAD: "AS_SearchUserAD",
  AS_ApiKeyFullAccess: "AS_ApiKeyFullAccess",
  AS_GenerateApiKey: "AS_GenerateApiKey",
  AS_DeleteApiKey: "AS_DeleteApiKey",
  AS_GetApiKey: "AS_GetApiKey",
  AS_CompanySSOFullAccess: "AS_CompanySSOFullAccess",
  AS_CreateCompanySSO: "AS_CreateCompanySSO",
  AS_UpdateCompanySSO: "AS_UpdateCompanySSO",
  AS_DeleteCompanySSO: "AS_DeleteCompanySSO",
  AS_GetCompanySSO: "AS_GetCompanySSO",
  AS_UserGroupFullAccess: "AS_UserGroupFullAccess",
  AS_CreateUserGroup: "AS_CreateUserGroup",
  AS_UpdateUserGroup: "AS_UpdateUserGroup",
  AS_GetUserGroup: "AS_GetUserGroup",
  AS_RoleFullAccess: "AS_RoleFullAccess",
  AS_CreateRole: "AS_CreateRole",
  AS_UpdateRole: "AS_UpdateRole",
  AS_GetRole: "AS_GetRole",
  AS_StoreFullAccess: "AS_StoreFullAccess",
  AS_CreateStore: "AS_CreateStore",
  AS_UpdateStore: "AS_UpdateStore",
  AS_DeleteStore: "AS_DeleteStore",
  AS_GetStore: "AS_GetStore",
  AS_DepartmentFullAccess: "AS_DepartmentFullAccess",
  AS_CreateDepartment: "AS_CreateDepartment",
  AS_UpdateDepartment: "AS_UpdateDepartment",
  AS_DeleteDepartment: "AS_DeleteDepartment",
  AS_GetDepartment: "AS_GetDepartment",
  AS_CompanyFullAccess: "AS_CompanyFullAccess",
  AS_UpdateMyCompany: "AS_UpdateMyCompany",
  AS_GetMyCompany: "AS_GetMyCompany",
  AS_CustomerFullAccess: "AS_CustomerFullAccess",
  AS_CreateCustomer: "AS_CreateCustomer",
  AS_UpdateCustomer: "AS_UpdateCustomer",
  AS_DeleteCustomer: "AS_DeleteCustomer",
  AS_GetCustomer: "AS_GetCustomer",
  AS_LegalEntityFullAccess: "AS_LegalEntityFullAccess",
  AS_CreateLegalEntity: "AS_CreateLegalEntity",
  AS_UpdateLegalEntity: "AS_UpdateLegalEntity",
  AS_GetLegalEntity: "AS_GetLegalEntity",
  AS_EmployeeFullAccess: "AS_EmployeeFullAccess",
  AS_GetEmployee: "AS_GetEmployee",
  AS_CountryFullAccess: "AS_CountryFullAccess",
  AS_CreateCountry: "AS_CreateCountry",
  AS_UpdateCountry: "AS_UpdateCountry",
  AS_GetCountry: "AS_GetCountry",
  AsS_BlobConfigFullAccess: "AsS_BlobConfigFullAccess",
  AsS_UpsertBlobConfig: "AsS_UpsertBlobConfig",
  AsS_DeleteBlobConfig: "AsS_DeleteBlobConfig",
  AsS_GetBlobConfig: "AsS_GetBlobConfig",
  AsS_EloConfigFullAccess: "AsS_EloConfigFullAccess",
  AsS_InsertEloConfig: "AsS_InsertEloConfig",
  AsS_UpdateEloConfig: "AsS_UpdateEloConfig",
  AsS_DeleteEloConfig: "AsS_DeleteEloConfig",
  AsS_GetEloConfig: "AsS_GetEloConfig",
  CMS_ErpConfigFullAccess: "CMS_ErpConfigFullAccess",
  CMS_CreateErpConfig: "CMS_CreateErpConfig",
  CMS_UpdateErpConfig: "CMS_UpdateErpConfig",
  CMS_GetErpConfig: "CMS_GetErpConfig",
  CMS_ChannelMessageFullAccess: "CMS_ChannelMessageFullAccess",
  CMS_GetChannelMessage: "CMS_GetChannelMessage",
  CMS_GetChannelMessageStatus: "CMS_GetChannelMessageStatus",
  CMS_CrmConfigFullAccess: "CMS_CrmConfigFullAccess",
  CMS_CreateCrmConfig: "CMS_CreateCrmConfig",
  CMS_UpdateCrmConfig: "CMS_UpdateCrmConfig",
  CMS_GetCrmConfig: "CMS_GetCrmConfig",
  CMS_IncidentFullAccess: "CMS_IncidentFullAccess",
  CMS_CreateIncident: "CMS_CreateIncident",
  CMS_UpdateIncident: "CMS_UpdateIncident",
  CMS_GetIncident: "CMS_GetIncident",
  PE_CalculationUnitFullAccess: "PE_CalculationUnitFullAccess",
  PE_GetCalculationUnit: "PE_GetCalculationUnit",
  PE_ModifyCalculationUnit: "PE_ModifyCalculationUnit",
  PE_GetCalculationUnitCustomization: "PE_GetCalculationUnitCustomization",
  PE_ModifyCalculationUnitCustomization:
    "PE_ModifyCalculationUnitCustomization",
  PE_GetPurchasePriceTemplate: "PE_GetPurchasePriceTemplate",
  PE_ModifyPurchasePriceTemplate: "PE_ModifyPurchasePriceTemplate",
  PE_GetSalePriceTemplate: "PE_GetSalePriceTemplate",
  PE_ModifySalePriceTemplate: "PE_ModifySalePriceTemplate",
  PE_GetPurchasePriceTemplateItem: "PE_GetPurchasePriceTemplateItem",
  PE_ModifyPurchasePriceTemplateItem: "PE_ModifyPurchasePriceTemplateItem",
  PE_GetSalePriceTemplateItem: "PE_GetSalePriceTemplateItem",
  PE_ModifySalePriceTemplateItem: "PE_ModifySalePriceTemplateItem",
  PE_GetSalePriceCalculation: "PE_GetSalePriceCalculation",
  PE_GetSalePriceSimulation: "PE_GetSalePriceSimulation",
  PE_GetPurchasePriceCalculation: "PE_GetPurchasePriceCalculation",
  PE_GetPurchasePriceSimulation: "PE_GetPurchasePriceSimulation",
  PS_SellOffProductFullAccess: "PS_SellOffProductFullAccess",
  PS_SellOffCommissionFullAccess: "PS_SellOffCommissionFullAccess",
  PS_GetSellOffCommission: "PS_GetSellOffCommission",
  PS_GetOwnSellOffCommission: "PS_GetOwnSellOffCommission",
  PS_SellPhaseTemplateFullAccess: "PS_SellPhaseTemplateFullAccess",
  PS_CheckListItemFullAccess: "PS_CheckListItemFullAccess",
  PS_PreparationPhaseFullAccess: "PS_PreparationPhaseFullAccess",
  PS_BrandFullAccess: "PS_BrandFullAccess",
  PS_CreateBrand: "PS_CreateBrand",
  PS_UpdateBrand: "PS_UpdateBrand",
  PS_GetBrand: "PS_GetBrand",
  PS_MeasurementFamilyFullAccess: "PS_MeasurementFamilyFullAccess",
  PS_CreateMeasurementFamily: "PS_CreateMeasurementFamily",
  PS_UpdateMeasurementFamily: "PS_UpdateMeasurementFamily",
  PS_DeleteMeasurementFamily: "PS_DeleteMeasurementFamily",
  PS_GetMeasurementFamily: "PS_GetMeasurementFamily",
  PS_AttributeTemplateFullAccess: "PS_AttributeTemplateFullAccess",
  PS_CreateAttributeTemplate: "PS_CreateAttributeTemplate",
  PS_UpdateAttributeTemplate: "PS_UpdateAttributeTemplate",
  PS_DeleteAttributeTemplate: "PS_DeleteAttributeTemplate",
  PS_GetAttributeTemplate: "PS_GetAttributeTemplate",
  PS_AttributeGroupTemplateFullAccess: "PS_AttributeGroupTemplateFullAccess",
  PS_CreateAttributeGroupTemplate: "PS_CreateAttributeGroupTemplate",
  PS_UpdateAttributeGroupTemplate: "PS_UpdateAttributeGroupTemplate",
  PS_DeleteAttributeGroupTemplate: "PS_DeleteAttributeGroupTemplate",
  PS_GetAttributeGroupTemplate: "PS_GetAttributeGroupTemplate",
  PS_PimProductFamilyFullAccess: "PS_PimProductFamilyFullAccess",
  PS_CreatePimProductFamily: "PS_CreatePimProductFamily",
  PS_UpdatePimProductFamily: "PS_UpdatePimProductFamily",
  PS_DeletePimProductFamily: "PS_DeletePimProductFamily",
  PS_GetPimProductFamily: "PS_GetPimProductFamily",
  PS_ProductCategoryFullAccess: "PS_ProductCategoryFullAccess",
  PS_CreateProductCategory: "PS_CreateProductCategory",
  PS_UpdateProductCategory: "PS_UpdateProductCategory",
  PS_DeleteProductCategory: "PS_DeleteProductCategory",
  PS_GetProductCategory: "PS_GetProductCategory",
  PS_PublishProductCategoryToChannel: "PS_PublishProductCategoryToChannel",
  PS_PimProductFullAccess: "PS_PimProductFullAccess",
  PS_CreatePimProduct: "PS_CreatePimProduct",
  PS_UpdatePimProduct: "PS_UpdatePimProduct",
  PS_ImportPimProduct: "PS_ImportPimProduct",
  PS_PublishPimProductToChannel: "PS_PublishPimProductToChannel",
  PS_GetPimProduct: "PS_GetPimProduct",
  PS_FavoriteProductFullAccess: "PS_FavoriteProductFullAccess",
  PS_GetFavoriteProduct: "PS_GetFavoriteProduct",
  PS_SupplierFullAccess: "PS_SupplierFullAccess",
  PS_CreateSupplier: "PS_CreateSupplier",
  PS_UpdateSupplier: "PS_UpdateSupplier",
  PS_DeleteSupplier: "PS_DeleteSupplier",
  PS_GetSupplier: "PS_GetSupplier",
  PS_PublishSupplierToChannel: "PS_PublishSupplierToChannel",
  PS_PimProductMediaFullAccess: "PS_PimProductMediaFullAccess",
  PS_CreatePimProductMedia: "PS_CreatePimProductMedia",
  PS_UpdatePimProductMedia: "PS_UpdatePimProductMedia",
  PS_DeletePimProductMedia: "PS_DeletePimProductMedia",
  PS_GetPimProductMedia: "PS_GetPimProductMedia",
  PS_LocationFullAccess: "PS_LocationFullAccess",
  PS_CreateLocation: "PS_CreateLocation",
  PS_UpdateLocation: "PS_UpdateLocation",
  PS_DeleteLocation: "PS_DeleteLocation",
  PS_GetLocation: "PS_GetLocation",
  PS_PositionFullAccess: "PS_PositionFullAccess",
  PS_CreatePosition: "PS_CreatePosition",
  PS_UpdatePosition: "PS_UpdatePosition",
  PS_DeletePosition: "PS_DeletePosition",
  PS_GetPosition: "PS_GetPosition",
  PS_InfoPageFullAccess: "PS_InfoPageFullAccess",
  PS_CreateInfoPage: "PS_CreateInfoPage",
  PS_UpdateInfoPage: "PS_UpdateInfoPage",
  PS_DeleteInfoPage: "PS_DeleteInfoPage",
  PS_GetInfoPage: "PS_GetInfoPage",
  PS_IwofurnConfigurationFullAccess: "PS_IwofurnConfigurationFullAccess",
  PS_CreateIwofurnConfiguration: "PS_CreateIwofurnConfiguration",
  PS_UpdateIwofurnConfiguration: "PS_UpdateIwofurnConfiguration",
  PS_GetIwofurnConfiguration: "PS_GetIwofurnConfiguration",
  PS_PimProductGroupFullAccess: "PS_PimProductGroupFullAccess",
  PS_CreatePimProductGroup: "PS_CreatePimProductGroup",
  PS_UpdatePimProductGroup: "PS_UpdatePimProductGroup",
  PS_GetPimProductGroup: "PS_GetPimProductGroup",
  PS_ShippingMethodFullAccess: "PS_ShippingMethodFullAccess",
  PS_CreateShippingMethod: "PS_CreateShippingMethod",
  PS_UpdateShippingMethod: "PS_UpdateShippingMethod",
  PS_GetShippingMethod: "PS_GetShippingMethod",
  PS_GetCheckList: "PS_GetCheckList",
  PS_CreateCheckList: "PS_CreateCheckList",
  PS_UpdateCheckList: "PS_UpdateCheckList",
  PS_CreateDiscussion: "PS_CreateDiscussion",

  SC_SaleServiceSupplierFullAccess: "SC_SaleServiceSupplierFullAccess",
  SC_GetSaleServiceSupplier: "SC_GetSaleServiceSupplier",
  SC_UpdateSaleServiceSupplier: "SC_UpdateSaleServiceSupplier",
  SC_UpsertSaleServiceErpInventoryConfig:
    "SC_UpsertSaleServiceErpInventoryConfig",
  SC_GetSaleServiceErpInventoryConfig: "SC_GetSaleServiceErpInventoryConfig",
  SC_SaleServiceOfferFullAccess: "SC_SaleServiceOfferFullAccess",
  SC_CreateSaleServiceOffer: "SC_CreateSaleServiceOffer",
  SC_UpdateSaleServiceOffer: "SC_UpdateSaleServiceOffer",
  SC_GetSaleServiceOffer: "SC_GetSaleServiceOffer",
  SC_MakeSaleServiceOrderFromSaleServiceOffer:
    "SC_MakeSaleServiceOrderFromSaleServiceOffer",
  SC_UpdateSaleServiceOrder: "SC_UpdateSaleServiceOrder",
  SC_GetSaleServiceOrder: "SC_GetSaleServiceOrder",
  SC_PublishSaleServiceOrder: "SC_PublishSaleServiceOrder",
  SC_OverwritePimProductPrice: "SC_OverwritePimProductPrice",
  SC_SaleServiceDocumentTemplateFullAccess:
    "SC_SaleServiceDocumentTemplateFullAccess",
  SC_CreateSaleServiceDocumentTemplate: "SC_CreateSaleServiceDocumentTemplate",
  SC_UpdateSaleServiceDocumentTemplate: "SC_UpdateSaleServiceDocumentTemplate",
  SC_DeleteSaleServiceDocumentTemplate: "SC_DeleteSaleServiceDocumentTemplate",
  SC_GetSaleServiceDocumentTemplate: "SC_GetSaleServiceDocumentTemplate",
  SC_SaleServiceIwofurnConfigurationFullAccess:
    "SC_SaleServiceIwofurnConfigurationFullAccess",
  SC_GetSaleServiceIwofurnConfiguration:
    "SC_GetSaleServiceIwofurnConfiguration",
  SC_SaleServiceCommissionGroupFullAccess:
    "SC_SaleServiceCommissionGroupFullAccess",
  SC_CreateSaleServiceCommissionGroup: "SC_CreateSaleServiceCommissionGroup",
  SC_UpdateSaleServiceCommissionGroup: "SC_UpdateSaleServiceCommissionGroup",
  SC_DeleteSaleServiceCommissionGroup: "SC_DeleteSaleServiceCommissionGroup",
  SC_GetSaleServiceCommissionGroup: "SC_GetSaleServiceCommissionGroup",
  SC_SaleChannelsFullAccess: "SC_SaleChannelsFullAccess",
  SC_CreateSaleChannel: "SC_CreateSaleChannel",
  SC_UpdateSaleChannel: "SC_UpdateSaleChannel",
  SC_GetSaleChannel: "SC_GetSaleChannel",
  SC_GetSaleChannelMessage: "SC_GetSaleChannelMessage",
  SC_GetSaleChannelMessageStatus: "SC_GetSaleChannelMessageStatus",
  SC_GetSupplier: "SC_GetSupplier",
  SC_UpdateSupplier: "SC_UpdateSupplier",
  SS_WinnerCalculationFullAccess: "SS_WinnerCalculationFullAccess",
  SS_ChangeWinnerCalculationStatusToPendingApproval:
    "SS_ChangeWinnerCalculationStatusToPendingApproval",
  SS_ChangeWinnerCalculationStatusToApproved:
    "SS_ChangeWinnerCalculationStatusToApproved",
  SS_ManageWinnerSettings: "SS_ManageWinnerSettings",
  SS_GetWinnerCacluations: "SS_GetWinnerCacluations",
  SC_CreateProductLabelTemplate: "SC_CreateProductLabelTemplate",
  SC_UpdateProductLabelTemplate: "SC_UpdateProductLabelTemplate",
  SC_DeleteProductLabelTemplate: "SC_DeleteProductLabelTemplate",
  SC_GetProductLabelTemplate: "SC_GetProductLabelTemplate",

  SC_CreateCheckListItem: "SC_CreateCheckListItem",
  SC_UpdateCheckListItem: "SC_UpdateCheckListItem",
  SC_DeleteCheckListItem: "SC_DeleteCheckListItem",
  SC_GetCheckListItem: "SC_GetCheckListItem",
  SC_UpsertPreparationPhase: "SC_UpsertPreparationPhase",
  SC_GetPreparationPhase: "SC_GetPreparationPhase",
  SC_CreateSellPhaseTemplate: "SC_CreateSellPhaseTemplate",
  SC_UpdateSellPhaseTemplate: "SC_UpdateSellPhaseTemplate",
  SC_GetSellPhaseTemplate: "SC_GetSellPhaseTemplate",
  SC_CreateSellOffProduct: "SC_CreateSellOffProduct",
  SC_UpdateSellOffProduct: "SC_UpdateSellOffProduct",
  SC_SetFastSalesProvisionAmount: "SC_SetFastSalesProvisionAmount",
  SC_DeleteSellOffProduct: "SC_DeleteSellOffProduct",
  SC_GetSellOffProduct: "SC_GetSellOffProduct",
  SC_GetPhase: "SC_GetPhase",
  SC_ManagePhase: "SC_ManagePhase",
  PS_GetInternalJob: "PS_GetInternalJob",
  SC_CreateAuction: "SC_CreateAuction",
  SC_UpdateAuction: "SC_UpdateAuction",
  SC_GetAuction: "SC_GetAuction",
  AS_B2bDashboardAccess: "AS_B2bDashboardAccess",
  //Without Permissions
  withoutPermission: "withoutPermission",
};
