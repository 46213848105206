import {NotificationManager} from "common/notifications";
import {
  Icon,
  ImageUploader,
  LazyImage,
  Text,
  WithPermission,
  DeleteModal,
  Button,
} from "components";
import {withPermission} from "hoc";
import {usePermission, useToggleState} from "hooks";
import {useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {PimContext} from "..";
import ImageSettingDrawer from "../drawers/image-setting";
import {assetTypes} from "constant";
import {AxiosRequestConfig} from "axios";
import imageLoadingGif from "assets/icons/image Loading.gif";
import SelectMediaType from "../drawers/select-media-type";
import {usePimProductMediasQuery} from "services/caching";
import {ProductService} from "types";
import {deepClone} from "react-advanced-cropper";

const MediaTab = () => {
  const {productData, channels} = useContext(PimContext);
  const [isOpen, toggle] = useToggleState();
  const [deleteModal, toggleDelete] = useToggleState();
  const [isOpenType, toggleType] = useToggleState();
  const [loadingFile, toggleLoadingFile] = useToggleState();
  const [uploadLoading, toggleUpload] = useToggleState();
  const [file, setFile] = useState<any>();
  const [selectedImage, setSelectedImage] = useState<any>();
  const [isOpenUploader, setUploaderToggle] = useToggleState();
  const [selectedChannel, setSelectedChannel] = useState<any>();
  const [channelDropdown, setchannelDropdown] = useState<any>([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState<number>();
  const [removeLoading, setRemoveLoading] = useToggleState();
  const [medias, setMedias] = useState<
    ProductService.PimProductMediaDto[] | null
  >();

  const {
    data: productMedias,
    isFetching,
    isLoading,
    refetch,
  } = usePimProductMediasQuery(productData?.id, {skip: !productData?.id});

  useEffect(() => {
    if (!!productMedias) {
      setMedias(productMedias?.items ? deepClone(productMedias?.items) : []);
    }
  }, [productMedias]);

  const loading = isLoading || isFetching;
  const updatePermission = usePermission("PS_UpdatePimProductMedia");

  useEffect(() => {
    setchannelDropdown([{code: "allChannel"}, ...channels]);
    if (!selectedChannel) {
      setSelectedChannel("allChannel");
    }
  }, [productData]);

  const onUploadHandler = (data: any) => {
    toggleUpload();

    const image = {
      thumbnailUrl: data.thumbnailUrl,
      url: data.url,
      type: data.type,
      pimProductId: productData?.id,
      mediaMetadata: data.metaData,
    };

    const url = URLS.CREATE_PIM_PRODUCT_MEDIA_URL;
    ServiceApi.post(url, image)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        toggleUpload();
        refetch();
        if (isOpenType) {
          toggleType();
        }
      })
      .catch(() => toggleUpload());
  };

  const imageSrcHandler = async (src: string) => {
    //url
    toggleLoadingFile();
    let dataUrl = src;
    if (dataUrl?.includes("/get-file")) {
      const config: AxiosRequestConfig = {
        responseType: "blob",
        params: {withoutSubscriptionKey: true},
      };
      await ServiceApi.get(dataUrl, config).then(({data}) => {
        dataUrl = URL.createObjectURL(data);
      });
    }
    toggleLoadingFile();
    return dataUrl;
  };

  const mediaTypeChecker = (type: string) => {
    if (type?.toLowerCase()?.includes("image")) {
      return {type: "image", icon: ""};
    }
    if (type?.toLowerCase()?.includes("excel")) {
      return {type, icon: "file-excel"};
    }
    if (type?.toLowerCase()?.includes("word")) {
      return {type, icon: "file-word"};
    }
    if (type?.toLowerCase()?.includes("pdf")) {
      return {type, icon: "file-pdf"};
    }
    if (type?.toLowerCase()?.includes("text")) {
      return {type, icon: "file-lines"};
    }
    if (
      type?.toLowerCase()?.includes("zip") ||
      type?.toLowerCase()?.includes("rar") ||
      type?.toLowerCase()?.includes("compressed")
    ) {
      return {type, icon: "file-zipper"};
    }
    return {type, icon: "file"};
  };

  const onDeleteHandler = () => {
    setRemoveLoading();
    const url = URLS.UPDATE_PIM_PRODUCT_MEDIA_URL(file?.id);
    ServiceApi.delete(url).then(() => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title",
      );
      setRemoveLoading();
      toggleDelete();
      refetch();
    });
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-4 xl:grid-cols-4 ">
        <WithPermission permissions={["PS_UpdatePimProductMedia"]}>
          <div
            className="flex-center flex h-[276px] cursor-pointer select-none flex-col gap-2 rounded-lg border-[0.5px] border-dashed border-primary bg-primary-light text-primary"
            onClick={toggleType}
          >
            <Icon icon="file-image" size="3x" className="text-primary" />
            <h5 className="text-gray-800">
              <Text>productManagement.products.Details.addNew</Text>
            </h5>
          </div>
        </WithPermission>
        {loading ? (
          [1, 2, 3].map(key => (
            <div
              className="w-[276px] animate-pulse rounded bg-white"
              key={key}
            ></div>
          ))
        ) : (
          <>
            {medias?.map((image: any, index: number) => {
              const mediaType = mediaTypeChecker(image.type);
              return (
                <>
                  {(image.channelsCodeList?.length === 0 ||
                    image.channelsCodeList?.includes(selectedChannel) ||
                    selectedChannel === "allChannel") && (
                    <>
                      {mediaType.type === "image" ? (
                        <LazyImage
                          isDynamic
                          key={image?.url}
                          src={image?.url}
                          className="max-w-lg flex-none cursor-pointer bg-gray-100"
                          imageClassName="h-[276px] w-full"
                          onClick={() => {
                            setSelectedImage(image);
                            if (!updatePermission) return;
                            toggle();
                          }}
                        />
                      ) : (
                        <div className="relative flex h-[276px] max-w-lg flex-none items-center justify-center rounded-lg bg-gray-100">
                          {loadingFile && selectedFileIndex === index ? (
                            <img src={imageLoadingGif} alt="Loading File" />
                          ) : (
                            <div
                              className="flex-center flex cursor-pointer flex-col space-y-4"
                              onClick={async () => {
                                setSelectedFileIndex(index);
                                const url = await imageSrcHandler(image.url);
                                setSelectedImage({...image, url, isFile: true});
                                if (!updatePermission) return;
                                toggle();
                                // window.open(url, "_blank");
                              }}
                            >
                              {updatePermission && (
                                <Button
                                  className="absolute right-4 top-4"
                                  light
                                  variant="danger"
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    setFile(image);
                                    toggleDelete();
                                  }}
                                >
                                  <Icon icon={"trash"} />
                                </Button>
                              )}
                              <Icon
                                icon={mediaType.icon}
                                className="text-primary"
                                size="4x"
                              />
                              <span className="text-primary">View</span>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </>
              );
            })}
          </>
        )}

        <ImageSettingDrawer
          isOpen={isOpen}
          toggle={toggle}
          image={selectedImage}
          getMedias={refetch}
        />
        <ImageUploader
          isOpen={isOpenUploader}
          toggle={setUploaderToggle}
          image={""}
          onUpload={image => onUploadHandler(image)}
          type={assetTypes.PimProduct}
        />
        <SelectMediaType
          isOpen={isOpenType}
          toggle={toggleType}
          toggleImageUploader={setUploaderToggle}
          onUploadHandler={onUploadHandler}
          loading={uploadLoading}
        />
        <DeleteModal
          isOpen={deleteModal}
          toggle={toggleDelete}
          selected={{name: "Media"}}
          onConfirm={onDeleteHandler}
          loading={removeLoading}
        />
      </div>
    </>
  );
};

export default withPermission(MediaTab, ["PS_GetPimProductMedia"]);
