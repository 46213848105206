import {URLS} from "services";
import MainApi from "services/caching/mainApi";
import {AccountService} from "types";

export const apiKeySlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    apiKeys: builder.query<AccountService.ApiKeysDtoPagedDto, any>({
      query: params => ({
        url: URLS.GET_API_KEY_LIST_URL,
        params: {...params},
      }),
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {useApiKeysQuery} = apiKeySlice;
