import {URLS} from "services";
import MainApi from "./mainApi";
import {AccountService} from "types";

export const commonSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    inventoryConfigExisted: builder.query<any, void>({
      query: () => ({
        url: URLS.GET_POST_ERP_INVENTORY_CONFIG,
      }),
      keepUnusedDataFor: 3600,
    }),
    pimProductFamilies: builder.query<any, void>({
      query: () => ({
        url: URLS.GET_PIM_PRODUCT_FAMILIES_URL,
      }),
      keepUnusedDataFor: 3600,
    }),
    graph: builder.query<AccountService.ConnectedPermissionDto[], void>({
      query: () => ({
        url: URLS.GET_PERMISSIONS_GRAPH_URL,
      }),
      keepUnusedDataFor: 3600,
    }),
    permission: builder.query<AccountService.PermissionsDto[], any>({
      query: params => ({
        url: URLS.GET_PERMISSIONS_URL,
        params,
      }),
      keepUnusedDataFor: 3600,
    }),
    country: builder.query<AccountService.CountryQueryDto[], void>({
      query: () => ({
        url: URLS.GET_POST_SELECTED_COUNTRIES_URL,
      }),
      providesTags: ["COUNTRY-LIST"],
      keepUnusedDataFor: 3600,
    }),
    defaultCountries: builder.mutation<
      AccountService.CreateCountryRequest,
      any
    >({
      query: ({ids}) => ({
        url: URLS.GET_POST_SELECTED_COUNTRIES_URL,
        method: "POST",
        data: {ids},
      }),
      invalidatesTags: ["COUNTRY-LIST"],
    }),
  }),
});

export const {
  useGraphQuery,
  useCountryQuery,
  usePermissionQuery,
  useDefaultCountriesMutation,
  usePimProductFamiliesQuery,
  useInventoryConfigExistedQuery,
  useLazyPimProductFamiliesQuery,
} = commonSlice;
