import {
  Breadcrumb,
  Button,
  DropdownButton,
  DuplicateModal,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {useConverter, usePermission, useToggleState} from "hooks";
import {createContext, useEffect, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import ConfigProduct from "./all-products-drawers/ConfigProduct";
import CreateDrawer from "./all-products-drawers/CreateDrawer";
import FilterDrawer from "./all-products-drawers/FilterDrawer";
import SelectFromBasket from "./all-products-drawers/SelectFromBasket";
import {NotificationManager} from "common/notifications";
import ImportFromExcel from "./details/drawers/import-excel";
import Completeness from "./all-products-drawers/completeness";
import PublishDrawer from "./all-products-drawers/publish-drawer";
import {usePimProductsQuery} from "services/caching";
import {ProductService} from "types";

export const ProductsContext = createContext<any>({id: ""});

function AllProducts() {
  const navigate = useNavigate();
  const importPermission = usePermission("PS_ImportPimProduct");
  const [data, setData] = useState<any>();
  const [pimProductType, setPimProductType] = useState<number>(0);
  const [isOpenExcel, toggleExcel] = useToggleState();
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [filterModal, setFilterModal] = useToggleState(false);
  const [duplicateToggle, toggle] = useToggleState(false);
  const [duplicateLoading, toggleDuplicateLoading] = useToggleState(false);
  const [isOpen, togglePublish] = useToggleState();
  //original filter data for reset
  const originalFilterData: ProductService.SearchPimRequest = {
    // productId: null,
    categoryId: null,
    keyword: "",
    language: null,
    pageNumber: 1,
    pageSize: config.pageSize,
    pimProductFamilyId: null,
    priceFrom: null,
    priceTo: null,
    programId: null,
    searchFields: "",
    sortType: null,
    supplierId: null,
    brandId: null,
    // title: null,
    // version: null,
    pimProductType: undefined,
    minCompletePercent: null,
    maxCompletePercent: null,
    checkListId: null,
  };
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedID, setSelectedID] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isOpenModalCreate, toggleModalCreate] = useToggleState(false);
  const [showConfigProduct, toggleConfigProduct] = useToggleState(false);
  const [showSelectFromBasket, toggleSelectFromBasket] = useToggleState(false);
  const {convertDate} = useConverter();
  const [params, setParams] = useState<any>(originalFilterData);
  const [filterType, setType] = useState(null);
  const [openCompleteness, toggleCompleteness] = useToggleState();
  const [checkLists, setCheckList] = useState([]);

  const {
    data: pimData,
    isLoading,
    isFetching,
    refetch,
  } = usePimProductsQuery(params);

  const loadingList = isFetching || isLoading;

  useEffect(() => {
    if (!!pimData) {
      setData(pimData);
      setKeyword(params.searchFields);
    }
  }, [pimData]);

  const getCheckList = () => {
    let params: any = {
      pageNumber: 1,
      pageSize: 100,
      objectType: 0,
    };
    const url = URLS.PIM_CHECK_LIST_URL;
    const config = {params};
    ServiceApi.get(url, {...config}).then(res => {
      if (res?.data) {
        const {data} = res;
        let list = data.items.map((item: any) => {
          return {id: item.id, name: item.title};
        });
        setCheckList(list);
      }
    });
  };

  useEffect(() => {
    getCheckList();
  }, []);

  const resetFilter = () => {
    setSearchParams({
      pageNumber: "1",
      pageSize: "10",
    });
    setParams({...originalFilterData, pageNumber: 1, pageSize: 10});
    setKeyword("");
  };

  const handleSetFilterData = (key: string) => {
    const pageNumber =
      key === "pageNumber"
        ? parseInt(searchParams.get("pageNumber") as string)
        : 1;
    return (value: any) => {
      if (key === "pageNumber") {
        setSearchParams({
          pageNumber: value,
          pageSize: params.pageSize,
        });
      } else if (key === "pageSize") {
        setSearchParams({
          pageNumber: params.pageNumber,
          pageSize: value,
        });
      } else {
        setSearchParams({
          pageNumber: "1",
          pageSize: params.pageSize,
        });
      }
      setParams((p: any) => ({
        ...p,
        pageNumber: pageNumber,
        filter: true,
        [key]: value,
      }));
    };
  };

  const getDetailsPermission = usePermission("PS_GetPimProduct");

  const onDuplicate = (product: any) => {
    toggleDuplicateLoading();
    const url = URLS.POST_CLONE_PRODUCT(product.id);
    ServiceApi.post(url)
      .then(({data}) => {
        NotificationManager.success(
          "global.toast.duplicate-msg",
          "global.toast.duplicate-title",
        );
        navigate(data);
        toggleDuplicateLoading();
        toggle();
      })
      .catch(() => toggleDuplicateLoading());
  };

  const handleSetPercentage = (
    completed: number,
    inProcess: number,
    productId: string,
  ) => {
    const newList: any[] = [];
    data?.searchResult?.map((item: any) => {
      if (item.id === productId)
        newList.push({
          ...item,
          completedPercentage: completed,
          inProcessPercentage: inProcess,
        });
      else newList.push(item);
    });
    setData({...data, searchResult: newList});
  };

  const productType = [
    {
      label: "productManagement.products.allProducts.all",
      value: null,
    },
    {
      label: "productManagement.products.allProducts.standard",
      value: 0,
    },
    {
      label: "productManagement.products.allProducts.bundle",
      value: 1,
    },
  ];

  //UI
  return (
    <>
      <div className="space-y-4">
        <div className="flex items-center">
          <div className="flex-1">
            <h3 className="text-heading-3">
              <Breadcrumb />
            </h3>
          </div>
          <div className="flex items-center gap-2">
            {loadingList ? (
              <Skeleton.Button />
            ) : (
              <Button
                variant={"white"}
                textColor="primary"
                size="sm"
                onClick={togglePublish}
              >
                <Text>
                  productManagement.products.allProducts.publishAllProducts
                </Text>
              </Button>
            )}
            <WithPermission permissions={["PS_CreatePimProduct"]}>
              {loadingList ? (
                <Skeleton.Button />
              ) : (
                <DropdownButton
                  aria-label="cy-create-product"
                  arrowIcon={false}
                  variant={"white"}
                  size="sm"
                  items={[
                    {
                      id: "edit",
                      title:
                        "productManagement.products.allProducts.createNewProduct",
                      permission: true,
                      onClick: () => {
                        setPimProductType(0);
                        toggleModalCreate();
                      },
                    },
                    {
                      id: "bundle",
                      title:
                        "productManagement.products.allProducts.createBundleProduct",
                      permission: true,
                      onClick: () => {
                        setPimProductType(1);
                        toggleModalCreate();
                      },
                    },
                    {
                      id: "configProduct",
                      title:
                        "productManagement.products.allProducts.createWithIwofurn",
                      permission: importPermission,
                      onClick: toggleConfigProduct,
                    },
                    {
                      id: "selectFromBasket",
                      title:
                        "productManagement.products.allProducts.selectFromIwofurn",
                      permission: importPermission,
                      onClick: toggleSelectFromBasket,
                    },
                    {
                      id: "addBranch",
                      title:
                        "productManagement.products.allProducts.importFromPool",
                      permission: importPermission,
                      onClick: (e: any) => {},
                    },
                    {
                      id: "importExcel",
                      title:
                        "productManagement.products.allProducts.importFromExcel",
                      permission: importPermission,
                      onClick: toggleExcel,
                    },
                  ]}
                >
                  <Icon className="mr-2" icon={"plus"} />
                  <Text>productManagement.products.allProducts.addProduct</Text>
                </DropdownButton>
              )}
            </WithPermission>
          </div>
        </div>
        <SearchBox
          value={keyword}
          onSubmit={handleSetFilterData("searchFields")}
          disabled={loadingList}
          totalItems={data?.totalItems}
          onFilter={() => setFilterModal()}
          resetFilter={!params?.filter ? undefined : resetFilter}
          onReload={refetch}
          loading={loadingList}
        />
        <div className="flex items-center gap-4">
          {productType?.map((type: any, index) => (
            <span
              key={index}
              className={`min-w-14 cursor-pointer rounded-lg p-3 text-center text-heading-6 font-semibold ${
                filterType === type.value
                  ? "bg-primary text-white"
                  : "bg-white text-gray-500"
              } ${loadingList && "pointer-events-none opacity-30"}`}
              onClick={() => {
                setParams((p: any) => ({...p, pimProductType: type.value}));
                setType(type.value);
              }}
            >
              <Text>{type.label}</Text>
            </span>
          ))}
        </div>
        {loadingList ? (
          <>
            <Skeleton.List />
            <Skeleton.List />
            <Skeleton.List />
            <Skeleton.List />
          </>
        ) : (
          <>
            {data?.searchResult && data?.searchResult?.length !== 0 ? (
              <>
                <Table>
                  {data?.searchResult?.map((item: any) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Link
                            to={getDetailsPermission ? item.id : ""}
                            className="flex w-fit gap-2"
                            replace={!getDetailsPermission}
                          >
                            <Table.Image
                              className="w-unset"
                              src={item?.avatarThumbnailUrl}
                              alt={item.originalName}
                              imageClassName="h-20 w-32"
                            />
                            <div className="space-y-2 text-wrap py-2">
                              <h6 className="text-heading-6 font-semibold leading-6 text-gray-800">
                                {item.originalName}
                              </h6>
                              <Status.PimProductType id={item.pimProductType} />
                              <div className="text-sm text-gray-500">
                                <Text>
                                  productManagement.products.allProducts.productFamily
                                </Text>
                                :
                                <span className="ml-1">
                                  {item.productFamily}
                                </span>
                              </div>
                              <div className="flex items-center space-x-4 text-body-2 font-medium text-gray-700">
                                {item.supplierName && (
                                  <div>
                                    <span>{item.supplierName}</span>
                                  </div>
                                )}
                                {item.brandName && (
                                  <div>
                                    <span>{item.brandName}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Link>
                        </td>
                        <td className="space-y-1 text-left text-body-2 font-medium text-gray-600">
                          <div className="text-body-2 font-medium text-gray-500">
                            <Text>
                              productManagement.products.allProducts.article
                            </Text>
                            <span
                              className="ml-1"
                              aria-label="cy-article-number"
                            >
                              #{item.articleNumber}
                            </span>
                          </div>
                          {item.supplierArticleNumber && (
                            <div>
                              <Text>
                                productManagement.products.allProducts.supplier
                              </Text>
                              #:
                              <span className="ml-1">
                                {item.supplierArticleNumber}
                              </span>
                            </div>
                          )}
                          {item.referenceKey && (
                            <div>
                              <Text>
                                productManagement.products.allProducts.referenceKey
                              </Text>
                              :<span className="ml-1">{item.referenceKey}</span>
                            </div>
                          )}
                          {item.lastModifiedAt && (
                            <div>
                              <Text>
                                productManagement.products.allProducts.updateAt
                              </Text>
                              :
                              <span className="ml-1">
                                {convertDate(item.lastModifiedAt)}
                              </span>
                            </div>
                          )}
                          {item.lastModifiedByName && (
                            <div>
                              <Text>
                                productManagement.products.allProducts.updateBy
                              </Text>
                              :
                              <span className="ml-1">
                                {item.lastModifiedByName}
                              </span>
                            </div>
                          )}
                          {/* <div className="block xl:hidden">
                          <h6 className="text-heading-6 font-normal text-gray-700">
                            {convertAmount(item?.price?.amount)}
                          </h6>
                        </div> */}
                        </td>
                        {item.checkListCount !== 0 && (
                          <td>
                            <div
                              className="inline-flex cursor-pointer flex-col justify-center gap-2 px-4 py-2"
                              onClick={() => {
                                setSelectedProduct(item);
                                toggleCompleteness();
                              }}
                            >
                              <div className=" flex h-[10px] w-[100px] rounded-[3px] bg-gray-200">
                                <div
                                  className="h-full rounded-l-[3px] bg-success"
                                  style={{width: item.completedPercentage}}
                                ></div>
                                <div
                                  className="h-full rounded-r-[3px] bg-warning"
                                  style={{width: item.inProcessPercentage}}
                                ></div>
                              </div>
                              <p className="text-start text-body-4 text-success">
                                <Text>
                                  productManagement.products.completeness.completed
                                </Text>{" "}
                                ({item.completedPercentage}%)
                              </p>
                              <p className="text-start text-body-4 text-warning">
                                <Text>
                                  productManagement.products.completeness.inprocess
                                </Text>{" "}
                                ({item.inProcessPercentage}%)
                              </p>
                            </div>
                          </td>
                        )}
                        <td className="flex flex-col gap-4 xl:table-cell">
                          <WithPermission permissions={["PS_GetPimProduct"]}>
                            <Button
                              to={item.id}
                              variant="light"
                              size="sm"
                              className="mr-2"
                              onClick={() => {
                                setSelectedProduct(item);
                                toggle();
                              }}
                            >
                              <Icon icon="copy" />
                            </Button>
                            <Button
                              as={Link}
                              to={item.id}
                              variant="light"
                              size="sm"
                            >
                              <Text>global.buttons.details</Text>
                            </Button>
                          </WithPermission>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
                <Pagination
                  totalItems={data?.totalItems}
                  totalPages={data?.totalPages}
                  pageNumber={parseInt(params?.pageNumber) || 1}
                  pageSize={params?.pageSize || config.pageSize}
                  setActivePage={handleSetFilterData("pageNumber")}
                  onPageSizeChange={handleSetFilterData("pageSize")}
                />
              </>
            ) : (
              <div className="flex w-full flex-col items-center space-y-4">
                <NoItems />
              </div>
            )}
          </>
        )}
        <ProductsContext.Provider value={{id: selectedID}}>
          <FilterDrawer
            isOpen={filterModal}
            data={data}
            toggle={setFilterModal}
            onChange={handleSetFilterData}
            resetFilter={resetFilter}
            keyword={keyword}
            setKeyword={setKeyword}
            setParams={setParams}
            params={params}
            checkLists={checkLists}
          />
          <CreateDrawer
            isOpen={isOpenModalCreate}
            toggle={toggleModalCreate}
            getData={refetch}
            pimProductType={pimProductType}
          />
          <ConfigProduct
            isOpen={showConfigProduct}
            toggle={toggleConfigProduct}
            getData={refetch}
          />
          <SelectFromBasket
            isOpen={showSelectFromBasket}
            toggle={toggleSelectFromBasket}
            getData={refetch}
          />
          <DuplicateModal
            isOpen={duplicateToggle}
            toggle={toggle}
            selected={{
              name: selectedProduct?.originalName,
              product: selectedProduct,
            }}
            loading={duplicateLoading}
            onConfirm={onDuplicate}
          />
          <Completeness
            isOpen={openCompleteness}
            toggle={toggleCompleteness}
            productId={selectedProduct.id}
            updatePIMData={(completed, inProcess) =>
              handleSetPercentage(completed, inProcess, selectedProduct.id)
            }
          />
          <ImportFromExcel isOpen={isOpenExcel} toggle={toggleExcel} />
        </ProductsContext.Provider>
      </div>
      <PublishDrawer isOpen={isOpen} toggle={togglePublish} params={params} />
    </>
  );
}

export default withPermission(AllProducts, ["PS_GetPimProduct"]);
