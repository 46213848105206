import {
  Wrapper,
  Text,
  Select,
  InputGroup,
  WithPermission,
  Button,
  Skeleton,
  NoItems,
} from "components";
import {useDataState, useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {PimContext} from "..";
import {useContext, useEffect} from "react";
import {NotificationManager} from "common/notifications";
import {types} from "constant";
import {useProgramDetailsQuery} from "services/caching";
export default function SalesPersonCommission() {
  const [submitLoading, toggleSubmitLoading] = useToggleState();
  const {productData, loading, getPIMProduct} = useContext(PimContext);
  const [data, setData, setBaseData, isChanged] = useDataState({
    amount: "",
    calculationType: 0,
  });

  const {
    data: programDetails,
    isFetching,
    isLoading,
  } = useProgramDetailsQuery(productData?.supplierProgramId, {
    skip: !productData?.supplierProgramId,
  });

  const loadingProgramInfo = isFetching || isLoading;

  const onSubmitHandler = () => {
    toggleSubmitLoading();
    const url = URLS.ADD_SALES_PERSON_COMMISSION(productData.id);
    const body = {...data};
    ServiceApi.post(url, body)
      .then(() => {
        getPIMProduct();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => toggleSubmitLoading());
  };

  useEffect(() => {
    setBaseData(
      productData?.salesPersonCommission || {
        amount: "",
        calculationType: 0,
      },
    );
  }, [productData]);

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <Text>productManagement.products.Details.salesPersonCommission</Text>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-4">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            <div className="col-span-2">
              <InputGroup
                label="productManagement.products.Details.amount"
                value={data?.amount}
                setValue={value => {
                  if (
                    data?.calculationType == 1 ||
                    (value >= 0 && value <= 100)
                  ) {
                    setData({...data, amount: value});
                  }
                }}
                keyfilter="pnum"
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <Select
                label="productManagement.products.Details.type"
                placeholder="global.select"
                value={data?.calculationType}
                items={types.amountCalculationType}
                setValue={value => {
                  setData({...data, calculationType: value, amount: ""});
                }}
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <label className="whitespace-nowrap text-heading-5 font-semibold">
              <Text>productManagement.masterData.suppliers.programs</Text>
            </label>
            <hr className="flex-1 bg-gray-200" />
          </div>
          {!!productData?.supplierProgramId ? (
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-4 *:text-heading-6">
                {loadingProgramInfo ? (
                  [...Array(2)].map(() => <Skeleton />)
                ) : (
                  <>
                    <p>
                      <Text>productManagement.products.Details.title</Text> :{" "}
                      <span className="text-gray-800">
                        {programDetails?.title}
                      </span>
                    </p>
                    <p>
                      <Text>productManagement.products.Details.code</Text> :{" "}
                      <span className="text-gray-800">
                        #{programDetails?.code}
                      </span>
                    </p>
                  </>
                )}
              </div>
              {loadingProgramInfo ? (
                <Skeleton />
              ) : (
                <p className="text-heading-6">
                  <Text>
                    productManagement.masterData.suppliers.salesPersonProvision
                  </Text>{" "}
                  :{" "}
                  <span className="text-gray-800">
                    {programDetails?.salesPersonProvision}%
                  </span>
                </p>
              )}
            </div>
          ) : (
            <NoItems />
          )}
        </Wrapper.Body>
      </Wrapper>
      {!loading && isChanged && (
        <WithPermission permissions={["PS_UpdatePimProduct"]}>
          <Wrapper className="sticky bottom-0 shadow-card">
            <Wrapper.Body className="flex items-center justify-end gap-4">
              <Button
                type="submit"
                variant="primary"
                disabled={!isChanged}
                onClick={onSubmitHandler}
                loading={submitLoading}
              >
                <Text>global.buttons.saveChanges</Text>
              </Button>
            </Wrapper.Body>
          </Wrapper>
        </WithPermission>
      )}
    </>
  );
}
