import {Button, Icon, Modal, Text} from "components";

type Props = {
  isOpen: boolean;
  message: string | null | undefined;
  toggle: () => void;
};

export default function CancelReason({isOpen, toggle, message}: Props) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header className="flex items-center">
        <span className="text-heading-2 font-semibold text-gray-800">
          <Text>internalJobs.failedReason</Text>
        </span>
      </Modal.Header>
      <Modal.Body>
        <span className="text-body-base text-danger-active">
          <Text>{`errors.${message}`}</Text>
        </span>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-center">
        <Button type="button" variant="white" size="sm" onClick={toggle}>
          <Icon icon="close" /> <Text>global.buttons.close</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
