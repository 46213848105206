import {useContext} from "react";
import {Wrapper, NoItems} from "components";
import {SalesService} from "types";
import {PimContext} from "..";
import {useConverter} from "hooks";

export default function Locations() {
  const {details} = useContext(PimContext);
  const {convertDate} = useConverter();
  return (
    <>
      <Wrapper>
        <Wrapper.Header>Locations</Wrapper.Header>
        <Wrapper.Body className="py-0">
          {!!details?.locations && !!details.locations.length ? (
            <>
              {details?.locations?.map(
                (location: SalesService.PimProductLocationQueryDto) => {
                  const keys = Object.keys(location);
                  return (
                    <>
                      <div className="grid grid-cols-2 items-center gap-2 border-b border-gray-200 py-4 last:border-none">
                        {keys?.map((key: any) => {
                          const value =
                            key === "eventTime"
                              ? convertDate(location["eventTime"])
                              : location[
                                  key as keyof SalesService.PimProductLocationQueryDto
                                ];
                          const title =
                            key === "eventTime"
                              ? "last Updated At"
                              : key.replace(/([a-z])([A-Z])/g, "$1 $2");
                          return (
                            <div className="flex items-center gap-2">
                              <span className="text-heading-5 text-gray-600">
                                {title} :
                              </span>
                              <span className="text-body-base text-gray-800">
                                {value}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  );
                },
              )}
            </>
          ) : (
            <NoItems />
          )}
        </Wrapper.Body>
      </Wrapper>
    </>
  );
}
