export const findValue = (
  attribute: any,
  values: any,
  id: string,
  channel?: string,
  language?: string,
  isDefault?: boolean,
) => {
  const dto = values?.find((item: any) => {
    return item.attributeTemplateId === id;
  });

  const innerDto = isDefault
    ? dto?.pimProductAttribute?.pimProductAttributeDefaultValueDto
    : dto?.pimProductAttribute?.pimProductAttributeValueDto;

  const perChannelAttribute = () => {
    return innerDto?.find((item: any) => {
      return item.channel === channel;
    });
  };
  const perLangAttribute = () => {
    return innerDto?.find((item: any) => {
      return item.language === language;
    });
  };

  const perLangAndChannel = () => {
    return innerDto?.find((item: any) => {
      return item.language === language && item.channel === channel;
    });
  };

  const noneDependent = () => {
    let valueObject = innerDto?.find((item: any) => {
      return !item.language && !item.channel;
    });

    if (isDefault) {
      if (!valueObject && innerDto?.length) {
        return innerDto[0];
      }
    }
    return innerDto?.find((item: any) => {
      return !item.language && !item.channel;
    });
  };

  if (isDefault) {
    let targetObject = attribute?.perLanguage
      ? perLangAttribute()
      : noneDependent();
    return {
      value: targetObject?.value || innerDto?.[0]?.value,
      target: innerDto,
      measurementFamilyUnitCode: targetObject?.measurementFamilyUnitCode,
    };
  }

  const targetObject =
    attribute?.perChannel && attribute?.perLanguage
      ? perLangAndChannel()
      : attribute?.perChannel
        ? perChannelAttribute()
        : attribute?.perLanguage
          ? perLangAttribute()
          : noneDependent();

  const output: any = {
    value: targetObject?.value,
    target: innerDto,
    measurementFamilyUnitCode: targetObject?.measurementFamilyUnitCode,
  };
  return output;
};
