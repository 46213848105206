import {URLS} from "services";
import MainApi from "services/caching/mainApi";
import {AccountService} from "types";

export const userListSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    userList: builder.query<AccountService.UserQueryDtoPagedDto, any>({
      query: params => ({
        url: URLS.GET_B2B_USERS_URL,
        params: {...params},
      }),
      keepUnusedDataFor: 3600,
      providesTags: ["USER-LIST"],
    }),
    userDetails: builder.query<AccountService.UserDetailQueryDto, any>({
      query: ({userId}) => ({
        url: URLS.B2B_USER_DETAIL_URL(userId),
      }),
    }),
    inviteUser: builder.mutation({
      query: ({data, isChecked}) => ({
        url: isChecked ? URLS.POST_INVITE_ADMIN_USER : URLS.POST_INVITE_USER,
        method: "POST",
        data,
      }),
      invalidatesTags: ["USER-LIST"],
    }),
    createUser: builder.mutation({
      query: data => ({
        url: URLS.ADD_B2B_USERS_URL,
        method: "POST",
        data,
      }),
      invalidatesTags: ["USER-LIST"],
    }),
  }),
});

export const {
  useUserListQuery,
  useInviteUserMutation,
  useCreateUserMutation,
  useUserDetailsQuery,
} = userListSlice;
