export const channelMessage = {
  list: {
    aggregateId: "Aggregat-ID",
    channel: "Kanal",
    createdAt: "Erstellt am",
    updatedAt: "Aktualisiert am",
    detailsBtn: "Details",
    errorCodes: {
      "001": "Konfiguration nicht gefunden",
      "002": "Konfiguration ist falsch",
      "003": "Synchronisationsfehler",
    },
  },
  details: {
    title: "Nachrichtendetails",
    closeBtn: "Schließen",
    dataTab: "Daten",
    outDataTab: "Ausgehende Daten",
    json: "JSON",
    noData: "Keine Daten",
  },
  filter: {
    filters: "Filter",
    resetFilterBtn: "Filter zurücksetzen",
    closeBtn: "Schließen",
    status: "Status",
    channel: "Kanal",
    dateRange: "Datumsbereich",
    from: "Von",
    to: "Bis",
  },
  steps: {
    requirementGathering: "Anforderungserfassung",
    configurationValidation: "Konfigurationsvalidierung",
    dataTransmission: "Datenübertragung",
    acknowledgeReceipt: "Empfang bestätigen",
  },
  topics: {
  Store: "Filiale",
  PublishedSupplier: "Veröffentlichter Lieferant",
  PublishedSupplierProgram: "Veröffentlichtes Lieferantenprogramm",
  UnPublishedSupplier: "Unveröffentlichter Lieferant",
  PublishedPimProduct: "Veröffentlichtes PIM-Produkt",
  UnPublishedPimProduct: "Unveröffentlichtes PIM-Produkt",
  PublishedProductCategory: "Veröffentlichte Produktkategorie",
  UnPublishedProductCategory: "Unveröffentlichte Produktkategorie",
  PublishedSalesOrder: "Veröffentlichter Verkaufsauftrag",
  PublishedSalesOrderStatusUpdate: "Statusaktualisierung des veröffentlichten Verkaufsauftrags",
  ErpDocumentLinkAdded: "ERP-Dokumentlink hinzugefügt",
  LegalEntity: "Rechtseinheit",
  Customer: "Kunde",
  CustomerAccount: "Kundenkonto",
  Employee: "Mitarbeiter",
  UnPublishedSupplierProgram: "Unveröffentlichtes Lieferantenprogramm",
  PublishedSupplierContactPerson: "Veröffentlichte Lieferantenkontaktperson",
  UnPublishedSupplierContactPerson: "Unveröffentlichte Lieferantenkontaktperson",
  PublishedSupplierHoliday: "Veröffentlichter Lieferantenurlaub",
  UnPublishedSupplierHoliday: "Unveröffentlichter Lieferantenurlaub",
  PublishedSellOffProduct: "Veröffentlichte Ausverkaufsware",
  RePublishedSalesOrder: "Neu veröffentlichter Verkaufsauftrag",
  SalesOrderCommissionGroupChange: "Änderung der Provisionsgruppe des Verkaufsauftrags",
  SalesOrderCommissionGroupRemove: "Entfernen der Provisionsgruppe des Verkaufsauftrags",
  Department: "Abteilung",
  DepartmentRemoved: "Abteilung entfernt",
  SalesOrderLineItemPickupRequest: "Abholanfrage für Verkaufsauftragsposition",
  PublishedSaleChannel: "Veröffentlichter Verkaufskanal",
  },
};
