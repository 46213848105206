import {URLS, MainApi} from "services";
import {SalesService} from "types";

export const auctionSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    auctions: builder.query<SalesService.AuctionQueryDtoPagedDto, any>({
      query: params => ({
        url: URLS.AUCTION_LIST,
        params,
      }),
      providesTags: ["AUCTIONS-LIST"],
    }),
    auctionDetails: builder.query<SalesService.AuctionQueryDto, any>({
      query: id => ({
        url: URLS.DETAILS_AUCTION(id),
      }),
      providesTags: (_res, _err, id) => [{type: "AUCTION_DETAILS", id}],
    }),
    createAuction: builder.mutation<SalesService.CreateAuctionRequest, any>({
      query: data => ({
        url: URLS.CREATE_AUCTION,
        method: "POST",
        data,
      }),
      invalidatesTags: ["AUCTIONS-LIST"],
    }),
    updateAuction: builder.mutation<SalesService.UpdateAuctionRequest, any>({
      query: ({data, id}) => ({
        url: URLS.UPDATE_AUCTION(id),
        method: "PUT",
        data,
      }),
      invalidatesTags: (_res, _err, {id}) => [
        {type: "AUCTION_DETAILS", id},
        "AUCTIONS-LIST",
      ],
    }),
    updateAuctionProducts: builder.mutation<
      SalesService.UpdateAuctionRequest,
      any
    >({
      query: ({data, id}) => ({
        url: URLS.ADD_PRODUCTS_TO_AUCTION(id),
        method: "POST",
        data,
      }),
      invalidatesTags: (_res, _err, {id}) => [
        {type: "AUCTION_DETAILS", id},
        "AUCTIONS-LIST",
      ],
    }),
    updateAuctionsingleProduct: builder.mutation<
      {data: SalesService.UpdateAuctionProductsRequest; id: string},
      any
    >({
      query: ({data, id}) => ({
        url: URLS.UPDATE_AUCTION_PRODUCT(id),
        method: "PUT",
        data,
      }),
      invalidatesTags: (_res, _err, {id}) => [
        {type: "AUCTION_DETAILS", id},
        "AUCTIONS-LIST",
      ],
    }),
    removeAuction: builder.mutation<string, any>({
      query: id => ({
        url: URLS.DELETE_AUCTION(id),
        method: "DELETE",
      }),
      invalidatesTags: ["AUCTIONS-LIST"],
    }),
    removeAuctionProduct: builder.mutation<string, any>({
      query: ({params, id}) => ({
        url: URLS.REMOVE_PRODUCT_FROM_AUCTION(id),
        method: "DELETE",
        params,
      }),
      invalidatesTags: (_res, _err, {id}) => [
        {type: "AUCTION_DETAILS", id},
        "AUCTIONS-LIST",
      ],
    }),
  }),
});

export const {
  useAuctionsQuery,
  useAuctionDetailsQuery,
  useCreateAuctionMutation,
  useRemoveAuctionMutation,
  useUpdateAuctionMutation,
  useRemoveAuctionProductMutation,
  useUpdateAuctionProductsMutation,
  useUpdateAuctionsingleProductMutation,
} = auctionSlice;
