import {NotFound} from "pages";
import {MainLayout} from "../layuots";
import applications from "./applications";
import channelMessages from "./channel-message";
import company from "./company";
import configurations from "./configurations";
import customers from "./customers";
import dashboard from "./dashboard";
import productManagement from "./product-managment";
import profile from "./profile";
import salesManagment from "./sales-managment";
import thirdParty from "./third-party";
import priceEngine from "./price-engine";
import salesChannelMessages from "./sales-channel-message";
import backgroundJob from "./background-job";

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      ...dashboard,
      ...productManagement,
      ...salesManagment,
      ...applications,
      ...thirdParty,
      ...configurations,
      ...profile,
      ...company,
      ...customers,
      ...channelMessages,
      ...priceEngine,
      ...salesChannelMessages,
      ...backgroundJob,
    ],
  },
  {
    path: "/not-b2b-user",
    element: <>Not b2b User </>,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
export default routes;
