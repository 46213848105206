import {NotificationManager} from "common/notifications";
import {Drawer, Text, Icon, Button, InputGroup, Skeleton} from "components";
import {useConverter, useDataState, useToggleState} from "hooks";
import {useContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import {ServiceApi, URLS} from "services";
import {PimContext} from "..";
import {isEmpty} from "lodash";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  item: any;
};

export default function PrinterDrawer({isOpen, toggle, item}: Props) {
  const [loading, toggleLoading] = useToggleState();
  const [submitLoading, toggleSubmitLoading] = useToggleState();
  const [printers, setPrinters] = useState([]);
  const [selected, dispatchSelected] = useState<any>({});

  const getPrinters = () => {
    toggleLoading();
    const url = URLS.PRINTER_DEFINITION_ALL;
    ServiceApi.get(url)
      .then(({data}) => {
        setPrinters(data);
        toggleLoading();
      })
      .catch(() => {
        toggleLoading();
      });
  };

  useEffect(() => {
    if (isOpen) {
      getPrinters();
      dispatchSelected({});
    }
  }, [isOpen]);

  const onClickHandler = () => {
    toggleSubmitLoading();
    const url = URLS.SEND_PRINT_REQUEST;
    const data = {
      printerDefinitionId: selected?.id,
      channelCode: selected?.channelCode,
      productLabelTemplatePrinterRequest: {
        printRequestId: item?.id,
        productLabelTemplateId: item?.productLabelTemplateId,
        pimProductId: item?.pimProductId,
      },
      documentFileUrl: item?.documentFile?.fileUrl,
    };
    ServiceApi.post(url, data)
      .then(() => {
        toggleSubmitLoading();
        toggle();
      })
      .catch(() => toggleSubmitLoading());
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between text-heading-2 font-semibold">
            <span>
              <Text>Select Printer</Text>
            </span>
            <div className="space-x-2">
              <Button
                variant={"light"}
                size="sm"
                className="flex items-center"
                onClick={toggle}
              >
                <span>
                  <Text>global.buttons.close</Text>
                </span>
                <Icon icon="close" className="ml-2" />
              </Button>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-4">
            {loading ? (
              [...Array(6)].map(() => <Skeleton.List />)
            ) : (
              <>
                {printers?.map((printer: any) => {
                  return (
                    <div
                      className={`cursor-pointer space-y-4 rounded p-4 shadow-card ${selected?.id === printer?.id ? "bg-primary-light" : "bg-white"}`}
                      onClick={() => {
                        dispatchSelected(printer);
                      }}
                    >
                      <h6 className="truncate text-heading-6 text-gray-800">
                        {printer.name}
                      </h6>
                      <div className="space-y-2">
                        <p className="text-body-2 text-gray-600">
                          <Text>configurations.printerDefinition.ip</Text>:{" "}
                          <span>{printer.ip ?? "----------"}</span>
                        </p>
                        <p className="text-body-2 text-gray-600">
                          <Text>configurations.printerDefinition.port</Text>:{" "}
                          <span>{printer.port ?? "----------"}</span>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button type="button" variant={"light"} onClick={toggle}>
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button
              type="button"
              onClick={onClickHandler}
              loading={submitLoading}
              disabled={isEmpty(selected)}
            >
              <Text>Select</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
    </>
  );
}
