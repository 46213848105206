import {Details} from "./details";

export const publishedProduct = {
  list: {
    allAuctionButton: "All DE",
    auctionsButton: "Auctions DE",
    noAuctionsButton: "No Auctions DE",
    auctionBadge: "Auctions DE",
  },
  Details,
};
