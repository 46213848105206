import { URLS, MainApi } from "services";
import { ProductService } from "types";

export const pimProductsSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    pimProducts: builder.query<ProductService.PimProductSearchResultDto, any>({
      query: data => ({
        url: URLS.POST_SEARCH_LIST_PRODUCTS,
        method: "POST",
        data,
      }),
      transformResponse: (response: any) => {
        let newData = response;
        newData?.searchResult?.forEach((item: any) => {
          newData?.pimProductFamily?.forEach((productFamily: any) => {
            if (productFamily.value === item.pimProductFamilyId) {
              item["productFamily"] = productFamily.title;
            }
          });
          newData?.brand?.forEach((brand: any) => {
            if (brand.value === item.brandId) {
              item["brandName"] = brand.title;
            }
          });
          newData?.supplier?.forEach((supplier: any) => {
            if (supplier.value === item.supplierId) {
              item["supplierName"] = supplier.title;
            }
          });
          item["completedPercentage"] = !!item.checkListCompletionStatus
            ?.completedCheckListCount
            ? Math.min(
              Math.round(
                100 *
                (item.checkListCompletionStatus?.completedCheckListCount /
                  item.checkListCount),
              ),
              100,
            )
            : 0;
          item["inProcessPercentage"] = !!item.checkListCompletionStatus
            ?.inProcessCheckListCount
            ? Math.min(
              Math.round(
                100 *
                (item.checkListCompletionStatus?.inProcessCheckListCount /
                  item.checkListCount),
              ),
              100,
            )
            : 0;
          item["notStartCheckListCount"] = !!item.checkListCompletionStatus
            ?.notStartCheckListCount
            ? Math.min(
              Math.round(
                100 *
                (item.checkListCompletionStatus?.notStartCheckListCount /
                  item.checkListCount),
              ),
              100,
            )
            : 0;
          item["hasProblemCheckListCount"] = !!item.checkListCompletionStatus
            ?.hasProblemCheckListCount
            ? Math.min(
              Math.round(
                100 *
                (item.checkListCompletionStatus?.hasProblemCheckListCount /
                  item.checkListCount),
              ),
              100,
            )
            : 0;
        });
        return newData || [];
      },
      providesTags: ["PIM_PRODUCT-LIST"],
    }),
    publishTochannels: builder.mutation<
      ProductService.PublishAllPimProductsToChannelRequest,
      any
    >({
      query: data => ({
        url: URLS.PUBLISH_ALL_PRODUCTS_TO_CHANNELS,
        method: "POST",
        data,
      }),
      invalidatesTags: ["BACKGROUND-JOB-LIST", "BACKGROUND-JOB-STATUS"],
    }),
    updateProduct: builder.mutation<
      ProductService.UpdatePimProductRequest,
      any
    >({
      query: ({ data, id }) => ({
        url: URLS.PATCH_PIM_PRODUCT_URL(id),
        method: "PUT",
        data,
      }),
      invalidatesTags: ["PIM_PRODUCT-LIST"],
    }),
    pimProductDetails: builder.query<
      ProductService.PimProductSearchResultDto,
      any
    >({
      query: id => ({
        url: URLS.GET_PIM_PRODUCT_BY_ID_URL(id),
      }),
      providesTags: (_result, _error, { id }) => [
        { type: "PIM-PRODUCT-DETAILS", id },
      ],
    }),
    pimProductMedias: builder.query<ProductService.PimProductMediaDtoPagedDto, void>({
      query: id => ({
        url: URLS.GET_PIM_PRODUCT_MEDIA_LIST_URL(id, 1, 100)
      })
    })
  }),
});

export const {
  usePimProductsQuery,
  useUpdateProductMutation,
  usePimProductMediasQuery,
  usePimProductDetailsQuery,
  usePublishTochannelsMutation,
} = pimProductsSlice;
