import {useState, useEffect} from "react";
import {Button, Modal, Form, InputGroup, Text, Icon} from "components";
import {useSelector} from "hooks";
import {rules} from "constant";
import {NotificationManager} from "common/notifications";
import {useNavigate} from "react-router";
import {useCreateRoleMutation} from "services/caching";

type addModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
};

export default function AddModal(props: addModalType) {
  const [createRole, {isLoading}] = useCreateRoleMutation();

  const [data, setData] = useState<any>(null);
  const profile = useSelector(s => s.profile);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isOpen) {
      setData({name: "", description: "", permissions: []});
    }
  }, [profile, props.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = async () => {
    try {
      const result = await createRole(data).unwrap();

      props.toggle();
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title",
      );
      navigate(`/configurations/user-managment/roles/${result}?tab=1`);
    } catch (error) {}
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="z-40">
      <Form onSubmit={submit} id="create-attributes-groups">
        <Modal.Header className="flex items-center justify-between">
          <div>
            <h2 className="text-heading-2 text-gray-800">
              <Text>configurations.userManagment.roles.addRole</Text>
            </h2>
          </div>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto">
          <div className="space-y-6">
            <InputGroup
              label="configurations.userManagment.roles.name"
              value={data?.name}
              setValue={name => setData((p: any) => ({...p, name}))}
              rules={rules.required}
            />
            <InputGroup
              as="short-textarea"
              label="configurations.userManagment.roles.shortDescription"
              value={data?.description}
              setValue={description =>
                setData((p: any) => ({...p, description}))
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between">
          <Button type="button" onClick={props.toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button type="submit" loading={isLoading}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
