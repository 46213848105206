import {URLS} from "services";
import MainApi from "services/caching/mainApi";
import {AssetService, WithPaging} from "types";

export const printerDefinitionSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    printerDefinition: builder.query<
      AssetService.PrinterDefinitionQueryDetailDtoPagedDto,
      any
    >({
      query: params => ({
        url: URLS.PRINTER_DEFINITION_LIST,
        params: {...params},
      }),
      providesTags: ["PRINTER-DEFINITION-LIST"],
    }),
    createPrinterDefinition: builder.mutation<
      AssetService.CreatePrinterDefinitionRequest,
      any
    >({
      query: data => ({
        url: URLS.PRINTER_DEFINITION_LIST,
        method: "POST",
        data,
      }),
      invalidatesTags: ["PRINTER-DEFINITION-LIST"],
    }),
    removePrinterDefinition: builder.mutation<void, string>({
      query: id => ({
        url: URLS.PRINTER_DEFINITION_DELETE(id),
        method: "DELETE",
      }),
      invalidatesTags: ["PRINTER-DEFINITION-LIST"],
    }),
    updatePrinterDefinition: builder.mutation<
      AssetService.CreatePrinterDefinitionRequest,
      any
    >({
      query: ({data, id}) => ({
        url: URLS.PRINTER_DEFINITION_UPDATE(id),
        method: "PUT",
        data: {...data},
      }),
      invalidatesTags: (_result, _error, {id}) => ["PRINTER-DEFINITION-LIST"],
    }),
  }),
});

export const {
  usePrinterDefinitionQuery,
  useCreatePrinterDefinitionMutation,
  useRemovePrinterDefinitionMutation,
  useUpdatePrinterDefinitionMutation,
} = printerDefinitionSlice;
