export const Details = {
  generalTab: "Allgemein",
  pricesTab: "Preise",
  attributesTab: "Attribute",
  mediaTab: "Medien",
  categoriesTab: "Kategorien",
  locationsTab: "Standorte",
  channelsTab: "Kanäle",
  shareButton: "Teilen",
  articleNumber: "Artikelnummer",
  supplierArticleNumber: "Lieferantenartikelnummer",
  importVersion: "Importversion",
  currentProductVersion: "Aktuelle Produktversion",
  productFamily: "Produktfamilie",
  category: "Kategorie",
  creation: "Erstellung",
  modify: "Änderung",
  active: "Aktiv",
  notActive: "Nicht aktiv",
  supplierPrice: "UVP",
  salesPrice: "Basispreis",
  addPriceDate: "Preis/Datum hinzufügen",
  addMedia: "Medien hinzufügen",
  assignPlace: "Standorte zuweisen",
  availableTill: "Verfügbar bis",
  supplier: "Lieferant",
  suppliers: "Lieferanten",
  program: "Programm",
  brand: "Marke",
  selectDate: "Datum auswählen ...",
  select: "Auswählen ...",
  previewCurrentVersion: "Vorschau der aktuellen Version",
  publishedVersion: "Veröffentlichte Version",
  unPublish: "Veröffentlichung aufheben",
  rePublish: "Erneut veröffentlichen",
  publishCurrentVersion: "Aktuelle Version veröffentlichen",
  assignCategory: "Kategorie zuweisen",
  missingRequiredAttribute: "Fehlendes Pflichtattribut",
  changedAttribute: "Der Attributwert wurde geändert",
  channels: "Kanäle",
  language: "Sprache",
  saveAttributes: "Attribute speichern",
  productCategory: "Produktkategorie",
  selectCategory: "Kategorie auswählen ...",
  fileInfo: "Dateiinformationen",
  imageSetting: "Bildeinstellung",
  deleteImage: "Löschen",
  imagePublicationInChannels: "Bildveröffentlichung in Kanälen",
  imageInfo: "Bildinformationen",
  placeHolder: "Platzhalter",
  description: "Beschreibung",
  productPreviewFor: "Produktvorschau für",
  basicInfo: "Grundlegende Informationen",
  prices: "Preise",
  price: "Verkaufspreis",
  purchasePrice: "Einkaufspreis",
  shareProduct: "Produkt teilen",
  sendEmail: "E-Mail senden",
  copyUrl: "URL kopieren",
  url: "URL",
  startDate: "Startdatum",
  endDate: "Enddatum",
  noData: "Keine Daten verfügbar",
  compareValues: "Werte vergleichen",
  setValueForAllChannel: "Wert für alle Kanäle festlegen",
  noAttributeFound: "Kein Attribut gefunden",
  allInGroupFilter: "Alle",
  showInvisibleAttributes: "Unsichtbare Attribute anzeigen",
  searchCategory: "Kategorie suchen",
  thereIsNoChannelEnabledForYourCompany:
    "Für Ihr Unternehmen ist kein Kanal aktiviert",
  aiTextGenerator: "AI TEXT",
  aiDescription: "Generieren Sie einen KI-Text für das folgende Produkt",
  basicInfo: "Produkt Titel oder Info",
  wordCount: "Wortanzahl",
  generate: "Generieren",
  copy: "Kopie",
  setValue: "Wert festlegen",
  productGroup: "Warengruppe",
  allAtributeFilter: "Alle Attribute",
  missingRequiredAtributeFilter: "Fehlende erforderliche Attribute",
  allMissingAtributeFilter: "Alle fehlenden Attribute",
  hasWarningAtributeFilter: "Attribute mit Warnung",
  publishInAllChannels: "Veröffentlichen Sie das Produkt in allen Kanälen",
  unPublishFromAllChannels: "Alle Veröffentlichungen aufheben",
  published: "Veröffentlicht",
  sent: "Gesendet",
  sendFailed: "Senden fehlgeschlagen",
  init: "Initialisiert",
  synced: "Im Ziel synchronisiert",
  syncFailed: "Synchronisierung fehlgeschlagen",
  at: "at DE",
  selectLegalEntities: "Rechteinheit auswählen",
  closeBtn: "schließen",
  cancelBtn: "Abbrechen",
  submitBtn: "Absenden",
  shippingLogestic: "Versand und Logistik",
  addShipping: "Versandart hinzufügen",
  infoDetails: "Weitere Informationen",
  moreInfo: "Mehr Info",
  type: "Typ",
  assignedPriceTemplate: "Zugeordnete Preisvorlage",
  assignedPriceTemplateChart: "Zugeordnete Vorlagenliste",
  recalculate: "Erneut berechnen",
  appliedPriceTemplate: "Verwendete Preisvorlage",
  pimProductPriceTemplate: "Produktpreis-Vorlage",
  brandPriceTemplate: "Markenpreis-Vorlage",
  supplierPriceTemplate: "Lieferantenpreis-Vorlage",
  supplierProgramPriceTemplate: "Programmpreis-Vorlage",
  addNew: "Neu hinzufügen",
  addNewPhoto: "Neues Foto",
  None: "keine",
  EMV: "EMV",
  Iwofurn: "Iwofurn",
  Excel: "Excel",
  shipping: "Versand",
  warning: "Warnung",
  NoAssignedPriceTemplate: "Keine Preisvorlage zugewiesen",
  NoAssignedTemplate: "Keine Vorlage zugewiesen",
  requirementGathering: "Anforderungserfassung",
  configurationValidation: "validiere Konfiguration",
  dataTransmission: "Datenübertragung",
  acknowledgeReceipt: "Annahme bestätigt",
  publishIn: "Veröffentlicht",
  priceTemplates: "Preisvorlagen",
  refrenceKey: "Refrence Key",
  addNewBundleItem: "Neues Set Produkt hinzufügen",
  code: "Code",
  legalEntity: "Rechtseinheit",
  noDiscount: "No Discount",
  NoPromotion: "No Promotion",
  availabilityStatusTab: "Verfügbarkeitsstatus",
  salesPersonCommission: "Verkaufsprovision",
  amount: "Betrag",
  percent: "Prozent",
  availabilityStatus: {
    // list
    defaultStatus: "Default",
    statusDate: "von {{ fromDate }} bis {{ toDate }}",
    addStatusButton: "neuer Status",
    // form
    addStatusTitle: "Status hinzufügen",
    editStatusTitle: "Status bearbeiten",
    closeButton: "schliessen",
    status: "Status",
    addValidationPeriod: "Gültigkeitszeitraum festlegen",
    fromDate: "von",
    toDate: "bis",
    cancelButton: "Abrrechen",
    addButton: "Status hinzufügen",
    updateButton: "Status erneuern",
    addSuccessMessage: "Verfügbarkeitsstatus wurde erstellt",
    editSuccessMessage: "Verfügbarkeitsstatus wurde geändert",
    // confirm
    removeStatusTitle: "Status entfernen",
    removeStatusText:
      "Dieser Schritt kann nicht rückgängig gemacht werden. Sind Sie sicher? ",
    cancelRemoveButton: "Abbruch",
    removeStatusButton: "Status entfernen",
    removeSuccessMessage: "Verfügbarkeitsstatus wurde entfernt.",
    dateConflict: "Datumsbereich steht in Konflikt zu anderen Eingaben",
  },
  uvpPrice: "uvpPrice",
  title: "Title",
};
