export const Details = {
  print: "Drucken",
  chooseTemplates: "Vorlagen auswählen",
  priceInOtherhannels: "Preisübersicht Kanäle",
  searchChannelByCode: "Kanal nach Code suchen",
  priceInThisChannel: "Preis in diesem Kanal",
  thisChannel: "dieser Kanal",
  otherChannels: "andere Kanäle",
  results: "Ergebnisse",
  printHistory: "Druckverlauf",
  printDetails: "Druckdetails",
  download: "Herunterladen",
  title: "Titel",
  requestDate: "Anfragedatum",
  status: "Status",
  error: "Fehler",
  overWritedPrice: "Überschriebener Preis",
  priceNow: "Aktueller Preis",
  availabilityStatus: "Verfügbarkeitsstatus",
  priceWarning: {
    warningMessage:
      "Achtung, der kalkulierte und / oder errechnete Preis verletzt ggf. die Festlegungen Ihrer Organisation",
    errorMessage:
      "Achtung. Dieser Preis ist nicht zu erzeugen. Bitte Überprüfen Sie die Parameter",
  },
  inventory: "Bestand",
  priceInfo: "Preisinfo",
  linePrice: "Stückpreis",
  changedPrice: "Geänderter Preis",
  editPrice: "Produktpreis VK1 überschreiben",
  enterPrice: "Preis eingeben",
  overwritePrice: "Preis überschreiben",
  uvpPrice: "UVP",
  originalProductInPIM: "Original product in PIM",
  pushToSellOffProduct: "Push to sell-off product",
};
