import {NotificationManager} from "common/notifications";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Skeleton,
  Text,
  WithPermission,
} from "components";
import {FormEvent, useEffect, useRef, useState} from "react";

import {rules} from "constant";
import {usePermission, useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {
  useCreateProductGroupsMutation,
  useProductGroupDetailsQuery,
  useUpdateProductGroupsMutation,
} from "services/caching";

export default function AddDrawer(props: any) {
  const updatePermission = usePermission("PS_UpdatePimProductGroup");
  // const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({
    name: "",
    code: "",
    taxPercent: "19",
    description: "",
  });
  const formRef = useRef<any>();

  const [createProductGroup, {isLoading: isLoadingCreate}] =
    useCreateProductGroupsMutation();
  const [updateProductGroup, {isLoading: isLoadingUpdate}] =
    useUpdateProductGroupsMutation();

  const loading = isLoadingCreate || isLoadingUpdate;

  const {
    data: details,
    isLoading,
    isFetching,
  } = useProductGroupDetailsQuery(props.id, {
    skip: !props.id,
  });
  const inputLoading = isLoading || isFetching;

  useEffect(() => {
    if (!!details) {
      setData({
        name: details.name,
        code: details.code,
        taxPercent: details.taxPercent,
        description: details.description,
      });
    }
  }, [details, props.isOpen]);

  useEffect(() => {
    if (!props.id) {
      setData({
        name: "",
        code: "",
        taxPercent: "19",
        description: "",
      });
    }
    if (!props.isOpen) {
      props.clearSelected("");
    }
  }, [props.isOpen, props.id]);

  const onSubmitForm = async (formData: any, condition: any) => {
    const id = formData.id;
    delete formData.id;
    try {
      if (condition) {
        await updateProductGroup({data: formData, id}).unwrap();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      } else {
        await createProductGroup(formData).unwrap();
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
      }
      props.toggle();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, {...data, id: props.id}, !!props.id);
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="flex items-center justify-between">
          <h2 className="mt-2 text-heading-2 text-gray-800">
            {props.id ? (
              <Text>
                productManagement.masterData.productGroup.editProductGroup
              </Text>
            ) : (
              <Text>
                productManagement.masterData.productGroup.addProductGroup
              </Text>
            )}
          </h2>
          <Button
            type="button"
            variant="light"
            size="sm"
            className="ml-2"
            onClick={() => props.toggle()}
          >
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body>
          <Form
            ref={formRef}
            onSubmit={onSubmitForm}
            className="space-y-6 pr-4"
            id={"create-drawer"}
          >
            {inputLoading ? (
              [1, 2, 3].map(item => <Skeleton.Input key={item} />)
            ) : (
              <>
                <InputGroup
                  ariaLabel="cy-product-group-name"
                  label="productManagement.masterData.productGroup.name"
                  value={data.name}
                  setValue={handleSetData("name")}
                  rules={rules.required}
                />
                {!props.id && (
                  <InputGroup
                    ariaLabel="cy-product-group-code"
                    label="productManagement.masterData.productGroup.code"
                    value={data.code}
                    setValue={handleSetData("code")}
                    rules={rules.required}
                  />
                )}
                <InputGroup
                  ariaLabel="cy-product-group-tax"
                  label="productManagement.masterData.productGroup.tax"
                  value={data.taxPercent}
                  setValue={handleSetData("taxPercent")}
                  type="number"
                  rules={rules.required}
                />
                <InputGroup
                  ariaLabel="cy-product-group-describtion"
                  as="short-textarea"
                  label="productManagement.masterData.productGroup.shortDescription"
                  value={data.description}
                  setValue={handleSetData("description")}
                />
              </>
            )}
          </Form>
        </Drawer.Body>
        <WithPermission permissions={["PS_CreateBrand"]}>
          <Drawer.Footer className="flex justify-between">
            <Button
              variant="white"
              type="button"
              onClick={() => props.toggle()}
            >
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button
              aria-label="cy-product-group-submit"
              type="button"
              variant="primary"
              onClick={submit}
              disabled={props.id && !updatePermission}
              loading={loading}
            >
              <Text>global.buttons.submit</Text>
            </Button>
          </Drawer.Footer>
        </WithPermission>
      </Drawer.Menu>
    </Drawer>
  );
}
