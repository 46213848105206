export const auctions = {
  add: "Hinzufügen",
  startAt: "Beginnt am",
  endAt: "Endet am",
  title: "Titel",
  shortDescription: "Kurze Beschreibung",
  general: "Allgemein",
  products: "Produkte",
  basicInfo: "Grundlegende Informationen",
  disableOptionalDiscounts: "Optionale Rabatte deaktivieren",
  disableIndividualDiscounts: "Individuelle Rabatte deaktivieren",
  addProducts: "Produkte hinzufügen",
  articleNumber: "Artikelnummer",
  channel: "Kanal",
  overWriteSalePrice: "Verkaufspreis überschreiben",
  editProduct: "Produkt bearbeiten",
};
