import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {usePermission, useToggleState} from "hooks";
import {Link, useSearchParams} from "react-router-dom";
import AddModal from "./add-modal";
import {useRoleListQuery} from "services/caching";

type Params = {
  keyword?: string;
  pageNumber?: string;
  pageSize?: string;
};

function Roles() {
  const [isOpen, toggle] = useToggleState(false);

  const originalFilterData: any = {
    pageNumber: "1",
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams(originalFilterData);
  const queryParams = Object.fromEntries(params.entries());

  const {data, isLoading, isFetching, refetch} = useRoleListQuery(queryParams);

  const loadingList = isFetching || isLoading;

  const handleSetParams = (key: keyof Params) => {
    const isPageNumber = key === "pageNumber";
    return (value: string | number | null) => {
      setParams(p => {
        const hasValue = !!String(value ?? "");
        hasValue ? p.set(key, `${value}`) : p.delete(key);
        !isPageNumber && p.set("pageNumber", `1`);
        return p;
      });
    };
  };

  const getDetailsPermission = usePermission("AS_GetRole");

  const isFilter = ["keyword"].some(item => params.get(item));

  const resetFilter = () => {
    setParams(originalFilterData);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["AS_CreateRole"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button type="button" onClick={() => toggle()} size="sm">
              <Icon className="mr-2" icon={"plus"} />
              <Text>configurations.userManagment.roles.addRole</Text>
            </Button>
          )}
        </WithPermission>
      </div>
      <div className="flex-between">
        <SearchBox
          value={params?.get("keyword") || ""}
          onSubmit={handleSetParams("keyword")}
          disabled={loadingList}
          totalItems={data?.totalItems}
          onReload={refetch}
          loading={loadingList}
        />
        {isFilter && (
          <Button
            disabled={loadingList}
            variant={"white"}
            textColor="danger"
            size="sm"
            onClick={resetFilter}
          >
            <Icon icon="circle-xmark"></Icon>
            <span className="ml-1">Reset Filter</span>
          </Button>
        )}
      </div>
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <Link
                          to={getDetailsPermission ? `${item.id}?tab=0` : ""}
                          className="flex-center inline-flex w-fit gap-2"
                          replace={!getDetailsPermission}
                        >
                          <div>
                            <h6 className="text-heading-6 font-semibold text-gray-800">
                              {item.name}
                            </h6>
                          </div>
                        </Link>
                      </td>
                      <td>
                        <div className="text-wrap text-left text-body-base font-normal leading-6">
                          <span className="text-gray-600">
                            {item.description}
                          </span>
                        </div>
                      </td>
                      <td>
                        <WithPermission permissions={["AS_GetRole"]}>
                          <Button
                            as={Link}
                            to={`${item.id}?tab=0`}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams(p => ({...p, pageSize: e}))
                }
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
          <AddModal isOpen={isOpen} toggle={toggle} />
        </>
      )}
    </div>
  );
}
export default withPermission(Roles, ["AS_GetRole"]);
