import {NotificationManager} from "common/notifications";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  LoadingSvg,
  SelectLang,
  Skeleton,
  StimulsoftDesigner,
  Text,
  Toggler,
  WithPermission,
} from "components";
import {rules} from "constant";
import {useDataState, useSelector, useToggleState} from "hooks";
import {FormEvent, useEffect, useRef, useState} from "react";
import {ServiceApi, URLS} from "services";
import {ProductLabelTemplate} from "types";

export default function EditProductLabelTemplateDrawer(props: {
  isOpen: boolean;
  toggle: () => void;
  getData: () => void;
  selected: ProductLabelTemplate.Item;
}) {
  const isNew = props.selected.id == "";
  const [loading, setLoading] = useToggleState();
  const [displayError, setDisplayError] = useState(false);
  const [submitLoading, setSubmitLoading] = useToggleState();
  const [showDesignerModal, toggleDesignerModal] = useToggleState(false);
  const formRef = useRef<any>();
  const [data, setData, setBaseData, isChanged] = useDataState<
    ProductLabelTemplate.Item | undefined
  >(undefined);
  const [deleteLoading, setDeleteLoading] = useToggleState();
  const [items, setItems] = useState<any>([]);
  const companySetting = useSelector(s => s.companySetting);
  const hasFile = !!data?.uploadedFile;

  const getData = () => {
    setLoading();
    const url = URLS.UPDATE_PRODUCT_LABEL_TEMPLATE_URL(props.selected.id);
    ServiceApi.get(url)
      .then(({data}) => {
        setBaseData(data);
      })
      .finally(() => {
        setLoading();
      });
  };

  const onSubmitForm = (formData: any) => {
    setDisplayError(true);
    if (!formData?.uploadedFile) return;
    setSubmitLoading();
    const body = formData;
    if (!formData.id) {
      const url = URLS.PRODUCT_LABEL_TEMPLATE_URL;
      ServiceApi.post(url, body)
        .then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title",
          );
          props.toggle();
          props.getData();
        })
        .finally(() => setSubmitLoading());
    } else {
      const url = URLS.UPDATE_PRODUCT_LABEL_TEMPLATE_URL(formData.id);
      ServiceApi.put(url, body)
        .then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
          props.toggle();
          props.getData();
        })
        .finally(() => setSubmitLoading());
    }
  };

  const onChangeHandler = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  const handleSetFile = (file: any) => {
    // setFile(file);
    setData((p: any) => ({
      ...p,
      uploadedFile: {
        type: file?.type,
        url: file?.url,
      },
    }));
  };
  const deletetemplateHandler = () => {
    setDeleteLoading();
    const url = URLS.UPDATE_PRODUCT_LABEL_TEMPLATE_URL(props.selected.id);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        props.toggle();
        props.getData();
      })
      .finally(() => setDeleteLoading());
  };

  useEffect(() => {
    if (props.isOpen) {
      if (props.selected.id) getData();
      else setBaseData(props.selected);
    }
  }, [props.selected, props.isOpen]);

  useEffect(() => {
    if (companySetting) {
      let arr: any = {};
      let arrItem: any = [];
      companySetting?.contentLanguages?.forEach((item: any) => {
        arr[item] = item;
        arrItem.push({
          name: item,
          id: item,
        });
      });
      setItems(arrItem);
    }
  }, [companySetting]);

  return (
    <>
      <Drawer isOpen={props.isOpen} toggle={props.toggle}>
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pr-4 pt-6">
            <div className="flex items-center justify-between">
              <h2 className="text-heading-2 text-gray-800">
                {isNew ? (
                  <Text>
                    productManagement.masterData.productLabelTemplate.addProductLabelTemplate
                  </Text>
                ) : (
                  <Text>
                    productManagement.masterData.productLabelTemplate.editProductLabelTemplate
                  </Text>
                )}
              </h2>
              <div className="flex items-center justify-between">
                <WithPermission permissions={["SC_DeleteProductLabelTemplate"]}>
                  {!isNew && (
                    <Button
                      type="button"
                      variant="danger"
                      light
                      onClick={() => deletetemplateHandler()}
                      className="!px-4"
                    >
                      {deleteLoading ? (
                        <LoadingSvg size="sm" />
                      ) : (
                        <Icon icon={"trash"} />
                      )}
                    </Button>
                  )}
                </WithPermission>
                <Button
                  type="button"
                  variant="light"
                  className="ml-2"
                  onClick={() => props.toggle()}
                >
                  <Icon className="mr-2" icon={"times"} />
                  <span className="text-body-2">
                    {" "}
                    <Text>global.buttons.close</Text>
                  </span>
                </Button>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body>
            {loading ? (
              <div className="mt-8 space-y-5">
                <Skeleton.Input key={1} />
                <Skeleton.Input key={2} />
              </div>
            ) : (
              data && (
                <Form
                  ref={formRef}
                  onSubmit={onSubmitForm}
                  className="mt-8 space-y-5"
                  id={"create-drawer"}
                >
                  <InputGroup
                    label="productManagement.masterData.productLabelTemplate.title"
                    value={data.title}
                    setValue={onChangeHandler("title")}
                    rules={rules.required}
                    ariaLabel="cy-product-label-template-title"
                  />
                  <Toggler
                    label={
                      "productManagement.masterData.productLabelTemplate.active"
                    }
                    value={data.isActive}
                    setValue={onChangeHandler("isActive")}
                  />
                  <Toggler
                    label={
                      "productManagement.masterData.productLabelTemplate.isGlobal"
                    }
                    value={data.isGlobal}
                    setValue={onChangeHandler("isGlobal")}
                  />
                  <div className="flex items-center gap-2">
                    <label className="whitespace-nowrap text-heading-5 font-semibold">
                      <Text>
                        productManagement.masterData.productLabelTemplate.file
                      </Text>
                      <span className="text-danger">*</span>
                    </label>
                    <hr className="flex-1 bg-gray-200" />
                  </div>
                  <div
                    onClick={toggleDesignerModal}
                    className="flex cursor-pointer justify-between gap-4 rounded-xl bg-gray-100 p-4"
                  >
                    <div className="flex gap-4">
                      <Icon
                        icon="file-arrow-up"
                        className="text-primary"
                        size="3x"
                      />
                      <div className="flex flex-1 flex-col justify-center gap-3">
                        {hasFile ? (
                          <h5 className="text-heading-5 ">{data?.title}</h5>
                        ) : (
                          <h5 className="text-heading-5 ">
                            <Text>
                              productManagement.masterData.productLabelTemplate.designTemplate
                            </Text>
                          </h5>
                        )}
                      </div>
                    </div>
                    <Button
                      type="button"
                      className="flex !h-12 !w-12 items-center justify-center !rounded-full !p-0"
                      variant={"primary"}
                      light
                    >
                      <Icon icon={hasFile ? "edit" : "upload"} size="lg" />
                    </Button>
                  </div>
                  {!data?.uploadedFile && displayError && (
                    <p className="text-xs text-danger">
                      <i className="bi bi-info-circle mr-1"></i>
                      <Text>errors.required</Text>
                    </p>
                  )}
                  <div className="flex items-center gap-2">
                    <label className="whitespace-nowrap text-heading-5 font-semibold">
                      <Text>
                        productManagement.masterData.productLabelTemplate.language
                      </Text>
                    </label>
                    <hr className="flex-1 bg-gray-200" />
                  </div>
                  <div className="flex py-3">
                    <h6 className="flex-1 font-normal">
                      <Text>productManagement.products.Details.language</Text>
                    </h6>
                    <SelectLang
                      value={data.language}
                      items={items}
                      onChangeLanguage={onChangeHandler("language")}
                      className="bg-white"
                    />
                  </div>
                </Form>
              )
            )}
          </Drawer.Body>
          <WithPermission
            permissions={["SC_UpdateSaleServiceDocumentTemplate"]}
          >
            <Drawer.Footer className="flex justify-between">
              <Button
                variant="white"
                type="button"
                onClick={() => props.toggle()}
              >
                <Text>global.buttons.cancel</Text>
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={submit}
                loading={submitLoading}
                disabled={!isChanged}
                aria-label="cy-product-label-template-submit"
              >
                <Text>global.buttons.submit</Text>
              </Button>
            </Drawer.Footer>
          </WithPermission>
        </Drawer.Menu>
      </Drawer>
      <StimulsoftDesigner
        isOpen={showDesignerModal}
        toggle={toggleDesignerModal}
        type={7}
        value={data?.uploadedFile}
        setValue={handleSetFile}
        dataSourceUrl="/productservice/api/stimulsoftsampledatasources/product-label-template"
      />
    </>
  );
}
