export const menu = {
  dashboard: "DASHBOARD",
  productManagement: {
    root: "PRODUKT MANAGEMENT",
    masterData: {
      root: "STAMMDATEN",
      Attributes: "Produkt Attribute",
      AttributeGroups: "Attributgruppen",
      ProductFamilies: "Produktfamilie",
      ProductCategories: "Produktkategorien",
      MeasurementUnits: "Maßeinheiten",
      Suppliers: "Lieferanten",
      Brands: "Marken",
      ProductGroup: "Warengruppe",
      ShippingMethods: "Versandmethoden",
      ProductLabelTemplates: "Etiketten Templates",
    },
    products: {
      root: "PRODUKTMANAGEMENT",
      AllProducts: "Alle Produkte",
      ImportProduct: "Produkte importieren",
      publishedProducts: "Veröffentlichte Produkte",
      checkList: "Checklisten Produktmanagement",
    },
    setting: {
      root: "EINSTELLUNGEN",
      Configurations: "Produkt Konfiguration",
    },
  },
  salesManagement: {
    root: "VERKAUFSMANAGEMENT",
    sellOffSales: {
      root: "ABVERKAUFSPROZESS",
      sellOffProducts: "Abverkaufsartikel",
      salesTemplates: "Vorlagen",
      salesSettings: "Verkaufseinstellungen",
      SellOffCommissions: "Abverkaufprovisions",
      salesChecklist: "Checkliste",
      preparationPhase: "Vorbereitungsphase",
    },
    settings: {
      root: "EINSTELLUNGEN",
      salesChannels: "Verkaufskanäle",
    },
    reports: "BERICHTE",
    kitchenSales: {
      root: "KÜCHEN",
      salesList: "Verkaufsliste",
      salesReport: "Verkaufsbericht",
      configuration: "Konfiguration",
    },
    salesArea: {
      root: "VERKAUFSFLÄCHE",
      locations: "Fläche",
    },
    priceTemplates: {
      root: "PREISVORLAGEN",
    },
    generalDiscount: {
      root: "ALLGEMEINE RABATTE",
    },
    salesChannels: {
      root: "PRODUKTKANÄLE",
    },
    commissionReports: {
      root: "Provisions-Bericht",
    },
    auction: {
      root: "Auction",
    },
  },
  applications: {
    root: "ANWENDUNGEN",
    mobileApp: {
      root: "MOBILE APP",
      positions: "Positionen",
      infoPages: "Info Seiten",
      contentManagement: "Inhaltsverwaltung",
      settings: "Einstellungen",
    },
  },
  thirdParty: {
    root: "THIRD-PARTY",
    settings: "Einstellungen",
    crmConfiguration: "CRM Konfiguration",
    erpConfiguration: "ERP Konfiguration",
    nopCommerce: "ONLINE SHOP",
    iwofurn: "IWOFURN",
  },
  company: {
    root: "UNTERNEHMEN",
    profile: "Unternehmensprofil",
    stores: "Filialen",
    legalEntities: "Selbständige Betriebseinheiten",
    employees: "Mitarbeiter",
  },
  customers: {
    root: "KUNDENVERWALTUNG",
    customerList: "Kundenliste",
    settings: "Einstellungen",
    Appointments: "Termine",
  },
  configurations: {
    root: "EINSTELLUNGEN",
    notifications: {
      root: "BENACHRICHTIGUNGEN",
      alerts: "Alarmmeldungen",
      actionGroups: "Aktionsgruppen",
    },
    globalSettings: {
      root: "GLOBALE EINSTELLUNGEN",
      apiKeys: "API Keys",
      ssoSettings: "SSO Konfiguration",
      dataStorage: "DMS konfiguration",
      templates: "VORLAGEN",
    },
    userManagement: {
      root: "BENUTZERVERWALTUNG",
      usersList: "Benutzer",
      userGroups: "Benutzergruppe",
      roles: "Rollen",
    },
    addressConfiguration: {
      root: "ADRESSENKONFIGURATION",
      countries: "Länder",
    },
    printerDefinition: {
      root: "Printer Definition",
    },
  },
  priceEngine: {
    root: "PRICE ENGINE",
    priceTemplates: {
      root: "Preisvorlagen",
      purchasePrice: "Einkaufspreis",
      salesPrice: "Verkaufspreis",
      iwofurnSalesPrice: "Iwofurn Verkaufspreis",
      catalogSalesPrice: "Katalog Verkaufspreis",
    },
    calculationUnites: {
      root: "Kalkulationseinheiten",
    },
    calculationLevelManagements: {
      root: "Kalkulationslevel",
    },
    priceSimulator: {
      root: "Preis Simulator",
    },
  },
  channelMessages: {
    root: "Kommunikationskanaldaten",
  },
  salesChannelMessages: {
    root: "Verkaufskanal Nachrichten",
  },
  backgroundJob: {
    root: "Background Job",
  },
};
