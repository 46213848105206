import {URLS, MainApi} from "services";
import {ProductService} from "types";

export const programsSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    programDetails: builder.query<
      ProductService.SupplierProgramDetailQueryDto,
      any
    >({
      query: id => ({
        url: URLS.GET_PROGRAM_DETAILS(id),
      }),
      providesTags: (_res, _err, id) => [{type: "PROGRAM-DETAILS", id}],
    }),
    programList: builder.query<ProductService.SupplierProgramQueryDto[], any>({
      query: id => ({
        url: URLS.GET_SUPPLIER_PROGRAMS(id),
      }),
      providesTags: (_res, _err, id) => [{type: "PROGRAM-LIST", id}],
    }),
  }),
});

export const {useProgramDetailsQuery, useProgramListQuery} = programsSlice;
