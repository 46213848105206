export const channelMessage = {
  list: {
    aggregateId: "Aggregate ID",
    channel: "Channel",
    createdAt: "Created at",
    updatedAt: "Updated at",
    detailsBtn: "Details",
    errorCodes: {
      "001": "Config Not Found",
      "002": "Config Is Wrong",
      "003": "Sync Error",
    },
  },
  details: {
    title: "Message details",
    closeBtn: "CLose",
    dataTab: "Data",
    outDataTab: "Out data",
    json: "JSON",
    noData: "No data",
  },
  filter: {
    filters: "Filters",
    resetFilterBtn: "Reset filter",
    closeBtn: "CLose",
    status: "Status",
    channel: "Channel",
    dateRange: "Date Range",
    from: "From",
    to: "To",
  },
  steps: {
    requirementGathering: "Requirement Gathering",
    configurationValidation: "Configuration Validation",
    dataTransmission: "Data Transmission",
    acknowledgeReceipt: "Acknowledge Receipt",
  },
  topics: {
    Store: "Store",
    PublishedSupplier: "PublishedSupplier",
    PublishedSupplierProgram: "PublishedSupplierProgram",
    UnPublishedSupplier: "UnPublishedSupplier",
    PublishedPimProduct: "PublishedPimProduct",
    UnPublishedPimProduct: "UnPublishedPimProduct",
    PublishedProductCategory: "PublishedProductCategory",
    UnPublishedProductCategory: "UnPublishedProductCategory",
    PublishedSalesOrder: "PublishedSalesOrder",
    PublishedSalesOrderStatusUpdate: "PublishedSalesOrderStatusUpdate",
    ErpDocumentLinkAdded: "ErpDocumentLinkAdded",
    LegalEntity: "LegalEntity",
    Customer: "Customer",
    CustomerAccount: "CustomerAccount",
    Employee: "Employee",
    UnPublishedSupplierProgram: "UnPublishedSupplierProgram",
    PublishedSupplierContactPerson: "PublishedSupplierContactPerson",
    UnPublishedSupplierContactPerson: "UnPublishedSupplierContactPerson",
    PublishedSupplierHoliday: "PublishedSupplierHoliday",
    UnPublishedSupplierHoliday: "UnPublishedSupplierHoliday",
    PublishedSellOffProduct: "PublishedSellOffProduct",
    RePublishedSalesOrder: "RePublishedSalesOrder",
    SalesOrderCommissionGroupChange: "SalesOrderCommissionGroupChange",
    SalesOrderCommissionGroupRemove: "SalesOrderCommissionGroupRemove",
    Department: "Department",
    DepartmentRemoved: "DepartmentRemoved",
    SalesOrderLineItemPickupRequest: "SalesOrderLineItemPickupRequest",
    PublishedSaleChannel: "PublishedSaleChannel",
  },
};
