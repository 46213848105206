import {useState, useContext, useEffect} from "react";
import {Button, Icon, Text} from "components";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {config} from "constant";
import Chooser from "../common/chooser";
import {SimulationContext} from "pages/price-engine/price-simulator";
import Info from "../common/info";
import {useSuppliersQuery} from "services/caching";

export default function Supplier() {
  const {setSimulateData, simulateData} = useContext(SimulationContext);
  const [isOpen, toggle] = useToggleState();
  const [suppliers, setSuppliers] = useState<any>([]);
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: undefined,
    suppliertype: 0,
  });

  const {
    data: supplierData,
    isLoading,
    isFetching,
    refetch,
  } = useSuppliersQuery(params, {skip: !isOpen});

  const loadingSupplier = isFetching || isLoading;

  useEffect(() => {
    if (!!supplierData) {
      const clone = supplierData?.items?.map((supplier: any) => ({
        id: supplier.supplierId,
        name: supplier.name,
        code: supplier.code,
      }));
      setSuppliers(clone);
    }
  }, [supplierData]);

  return (
    <>
      <div className="space-y-4">
        <div>
          <span className="text-body-base text-gray-800">
            <Text>priceEngine.simulator.supplier</Text> :
          </span>
        </div>
        {simulateData["supplier"] ? (
          <Info dataKey="supplier" />
        ) : (
          <Button light size="sm" onClick={toggle}>
            <Icon icon="plus" className="mr-2" />
            <Text>priceEngine.simulator.addSupplier</Text>
          </Button>
        )}
      </div>
      <Chooser
        isOpen={isOpen}
        toggle={toggle}
        title="priceEngine.simulator.suppliers"
        data={suppliers}
        getData={refetch}
        loading={loadingSupplier}
        setParams={setParams}
        params={params}
        dataKey="supplier"
        setSimulateData={setSimulateData}
      />
    </>
  );
}
