export const auctions = {
  add: "Add",
  startAt: "Start at",
  endAt: "End at",
  title: "Title",
  shortDescription: "Short Description",
  general: "General",
  products: "Products",
  basicInfo: "Basic Info",
  disableOptionalDiscounts: "Disable Optional Discounts",
  disableIndividualDiscounts: "Disable Individual Discounts",
  addProducts: "Add Products",
  articleNumber: "Article Number",
  channel: "Channel",
  overWriteSalePrice: "Overwrite Sale Price",
  editProduct: "edit Product",
};
