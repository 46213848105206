import React, {ComponentProps, ReactNode, useContext, useRef} from "react";
import {Transition} from "react-transition-group";
import {twMerge} from "tailwind-merge";
import {sizeType, TransitionClasses} from "types";
import Portal from "./Portal";
type ModalContextType = {
  closeBtn: boolean;
  toggle: () => void;
};
type ModalProps = {
  isOpen: boolean;
  toggle?: () => void;
  children: ReactNode;
  closeBtn?: boolean;
  size?: sizeType;
  className?: string;
  modalClassName?: string;
  width?: string;
};
type ModalHeader = ComponentProps<"div">;
type ModalBody = ComponentProps<"div">;
type ModalFooter = ComponentProps<"div">;

const ModalContext = React.createContext({} as ModalContextType);
function Modal({
  isOpen,
  toggle = () => {},
  closeBtn = false,
  size = "md",
  className = "",
  modalClassName = "",
  children,
  width = "w-full",
}: ModalProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const transitionClasses: TransitionClasses = {
    entering: "opacity-100 pointer-events-auto active",
    entered: "opacity-100 pointer-events-auto active",
    exiting: "opacity-0 pointer-events-none",
    exited: "opacity-0 pointer-events-none",
    unmounted: "",
  };
  const transitionDialogClasses: TransitionClasses = {
    entering: "translate-y-0",
    entered: "translate-y-0",
    exiting: "translate-y-[25vh]",
    exited: "translate-y-[25vh]",
    unmounted: "",
  };
  return (
    <Portal>
      <Transition nodeRef={divRef} in={isOpen} timeout={300} unmountOnExit>
        {state => (
          <div
            ref={divRef}
            className={twMerge(
              "modal group pointer-events-none fixed inset-0 !z-[100] !m-0 flex flex-col bg-black/20 opacity-0 transition-opacity [&.active]:pointer-events-auto [&.active]:opacity-100",
              transitionClasses[state],
              modalClassName,
            )}
          >
            <button
              type="button"
              onClick={() => toggle()}
              className="absolute inset-0 cursor-default opacity-0"
            />
            <div
              className={twMerge(
                `modal-dialog modal-${size} relative m-auto flex flex-col bg-white ${width} max-h-[97.5%] translate-y-[25vh] rounded shadow transition-transform min-[576px]:max-w-[500px] min-[992px]:[&.modal-lg]:max-w-[800px] min-[576px]:[&.modal-sm]:max-w-[300px] min-[992px]:[&.modal-xl]:max-w-[800px] min-[1200px]:[&.modal-xl]:max-w-[1140px]`,
                transitionDialogClasses[state],
                className,
              )}
            >
              <ModalContext.Provider value={{closeBtn, toggle}}>
                {children}
              </ModalContext.Provider>
            </div>
          </div>
        )}
      </Transition>
    </Portal>
  );
}
function ModalHeader({className, children, ...props}: ModalHeader) {
  const {closeBtn, toggle} = useContext(ModalContext);
  return (
    <div className="flex-center border-gray w-full gap-2 rounded-t border-b bg-white p-6">
      {closeBtn && <span className="h-6 w-6" />}
      <div className={twMerge("flex-1 text-center", className)} {...props}>
        {children}
      </div>
      {closeBtn && (
        <button
          type="button"
          onClick={toggle}
          className="bi bi-x-lg h-6 w-6 rounded p-1 text-dark transition-colors hover:text-danger"
        />
      )}
    </div>
  );
}
function ModalBody({className, children, ...props}: ModalBody) {
  return (
    <div className={twMerge("flex-1 overflow-auto p-6", className)} {...props}>
      {children}
    </div>
  );
}
function ModalFooter({className, children, ...props}: ModalFooter) {
  return (
    <div
      className={twMerge(
        "border-gray bottom-0 w-full rounded-b border-t bg-white p-6",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
export default Modal;
