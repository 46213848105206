export const allProducts = {
  addProduct: "New Product",
  skuName: "Product Original Name",
  sku: "Product Original Name",
  article: "Article",
  productFamily: "Product Family",
  created: "Created",
  modified: "Modified",
  completeness: "Completeness",
  keyword: "Keyword",
  productCategory: "Product Category",
  status: "Status",
  brand: "Brand",
  suppliers: "Suppliers",
  program: "Program",
  store: "Store",
  priceRange: "Price Range",
  to: "To",
  from: "From",
  useAsProductNameInThisDashboard: "Use as product name in this dashboard",
  filters: "Filters",
  resetFilters: "Reset Filters",
  close: "Close",

  // iwofurn
  importIwofurn: "Import (IWOFURN)",
  closeBtn: "Close",
  articleNumber: "Article number",
  supplier: "Supplier",
  program: "Program",
  store: "Store",
  chooseSupplier: "Choose supplier",
  chooseProgram: "Choose program",
  chooseStore: "Choose Store",
  cancelBtn: "Cancel",
  addProductBtn: "Import products",
  chooseProductFamily: "Choose Product family",
  productFamily: "Product family",
  importBtn: "Import",
  successMessage: "Products imported successfully.",
  // basket
  addBasketFromIwofurn: "Add basket from IWOFURN",
  changeBasketBtn: "Change basket",
  chooseBasket: "Choose basket",
  basketTitle: "Basket",
  price: "Price",
  purchasePrice: "Purchase Price",
  createdAt: "Created at",
  basketInfo: "Basket info",
  basketInternalTag: "Basket internal tag",
  basketTag: "Tag",
  senderName: "Sender name",
  iwofurnBasketPrice: "Iwofurn basket price",
  iwofurnBasketPurchasePrice: "Iwofurn basket purchase price",
  products: "Products",
  active: "Active",
  removeBasketAfterImport: "Remove basket after import",
  importBasket: "Import basket",
  backBtn: "Back",
  createNewProduct: "Create New Product",
  createWithIwofurn: "Config product (IWOFURN)",
  selectFromIwofurn: "Select from IWOFURN basket",
  importFromPool: "Import from product pool",
  supplier: "Supplier",
  referenceKey: "Reference Key",
  ThereIsNoIwofurnCodeAvailable: "There Is No IwofurnCode Available",
  importFromExcel: "Import From Excel",
  importProductFromExcel: "Import Product From Excel",
  import: "import",
  pleaseWait: "Please Wait...",
  updateAt: "Update At",
  updateBy: "Update By",
  supplierILN: "SupplieriLN",
  selectPimProduct: "Select PIM product",
  quantity: "Quantity",
  addItem: "Add Item",
  editBundleItem: "Edit bundle item",
  bundleLabel: "Bundle Label",
  items: "Items",
  createBundleProduct: "Create Bundle Product",
  standard: "Standard",
  bundle: "Bundle",
  all: "All",
  overwrittenSalePrice: "overwritten Sale Price",
  addOverwrittenSalePrice: "addOverwritten Sale Price",
  completeness: "Completeness",
  needToWork: "Need to work",
  done: "Done",
  note: "Note",
  descriptionPlaceHolder: "Type something about this...",
  doneItems: "Done items",
  needToWorkItems: "Need to work items",
  completed: "Completed",
  completenessFilterComment:
    "You can choose to check products with [Done completeness] or [Need to work completeness with range percent]",
  done: "Done",
  needToWork: "Need to work",
  completenessRange: "Completeness Range",
  incompleteChecklist: "Incomplete checklist",
  checklistTab: "Checklist",
  conversationTab: "Conversations",
  duplicate: "Duplicate product",
  uvpPrice: "Uvp Price",
  publishAllProducts: "Publish All Products",
  chooseChannelsThatYouWantToPublishProducts:
    "Choose Channels That You Want To Publish Products",
  selectAll: "Select All",
  deSelectAll: "DeSelect All",
  hasAuction: "hasAuction",
  auctions: "auctions",
};
