// api/customBaseQuery.js
import ServiceApi from "./service-api";
import type {BaseQueryFn} from "@reduxjs/toolkit/query";
import {AxiosError, AxiosRequestConfig} from "axios";

type baseQueryType = {
  url?: string;
  method?: AxiosRequestConfig["method"];
  data?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
  headers?: AxiosRequestConfig["headers"];
  responseType?: AxiosRequestConfig["responseType"];
};

const customBaseQuery =
  (
    {baseUrl}: {baseUrl: string} = {baseUrl: ""},
  ): BaseQueryFn<baseQueryType, unknown, unknown> =>
  async ({url, method, data, params, responseType}, api) => {
    const {signal} = api;
    try {
      const result = await ServiceApi({
        url: baseUrl + url,
        method,
        data,
        params,
        responseType,
        signal,
      });
      return {data: result.data};
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      return {
        error: {
          status: error.response?.status,
          data: error.response?.data || error.message,
        },
      };
    }
  };

export default customBaseQuery;
