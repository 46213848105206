import {URLS, MainApi} from "services";
import {ProductService} from "types";

export const brandsSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    brands: builder.query<ProductService.BrandDtoPagedDto, any>({
      query: params => ({
        url: URLS.GET_BRANDS_URL,
        params,
      }),
      keepUnusedDataFor: 1800,
      providesTags: ["BRAND-LIST"],
    }),
  }),
});

export const {useBrandsQuery} = brandsSlice;
