import {useEffect, useState} from "react";
import {config, calculationLevelEnum} from "constant";
import {InfoSection, LeftSide} from "../common";
import {Button, Icon, Wrapper} from "components";
import {useProgramListQuery, useSuppliersQuery} from "services/caching";

export default function Program() {
  const [suppliers, setSuppliers] = useState<any>([]);
  const [programs, setPrograms] = useState<any>([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedProgramId, setSelectedProgramId] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState<any>({});
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: undefined,
    suppliertype: 0,
  });

  const {
    data: supplierData,
    isLoading: isLoadingSupplier,
    isFetching: isFetchingSupplier,
  } = useSuppliersQuery(params);

  const isLoading = isFetchingSupplier || isLoadingSupplier;

  useEffect(() => {
    if (!!supplierData) {
      const clone = supplierData?.items?.map((supplier: any) => ({
        id: supplier.supplierId,
        name: supplier.name,
        code: supplier.code,
      }));
      setSuppliers(clone);
    }
  }, [supplierData]);

  const [paramsProgram, setParamsProgram] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  });

  const {
    data: programsData,
    isLoading: isLoadingProgram,
    isFetching,
  } = useProgramListQuery(selectedId, {
    skip: !selectedId,
  });

  const loading = isFetching || isLoadingProgram;

  useEffect(() => {
    if (!!programsData) {
      const clone = programsData?.map((program: any) => ({
        id: program.id,
        name: program.title,
        code: program.code,
      }));
      setPrograms(clone);
    }
  }, [programsData]);

  const returnSection = () => {
    return (
      <Wrapper className="shadow-card">
        <Wrapper.Body className="flex items-center justify-between !p-4">
          <span className="text-body-base font-semibold text-gray-800">
            {selectedSupplier.name}
          </span>
          <Button light size="sm" onClick={() => setSelectedId("")}>
            <Icon icon="arrows-retweet" />
          </Button>
        </Wrapper.Body>
      </Wrapper>
    );
  };

  return (
    <div className="flex gap-8">
      {selectedId ? (
        <LeftSide
          title="priceEngine.calculationLevel.program"
          loading={loading || isLoading}
          list={programs}
          selectedId={selectedProgramId}
          setSelectedId={setSelectedProgramId}
          setParams={setParamsProgram}
          params={paramsProgram}
          setSelectedItem={setSelectedItem}
          returnSection={returnSection}
        />
      ) : (
        <LeftSide
          title="priceEngine.calculationLevel.suppliers"
          loading={loading || isLoading}
          list={suppliers}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setParams={setParams}
          params={params}
          setSelectedItem={setSelectedSupplier}
        />
      )}

      <div className="w-full space-y-4">
        {selectedProgramId && (
          <InfoSection
            refId={selectedProgramId}
            level={calculationLevelEnum.program}
            refCode={selectedItem?.code}
            refTitle={selectedItem?.name}
          />
        )}
      </div>
    </div>
  );
}
