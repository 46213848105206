import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  CheckBox,
  Footer,
  NoItems,
  SearchBox,
  Skeleton,
  Text,
} from "components";
import {withPermission} from "hoc";
import {useDataState, usePermission} from "hooks";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import {useCountryQuery, useDefaultCountriesMutation} from "services/caching";
import {Country} from "types/country";

function Countries() {
  const {i18n} = useTranslation();
  const [defaultCountries, setDefaultCountries, setBaseData, isChanged] =
    useDataState<Country[]>([]);

  const {data: selectedCountries, isFetching, isLoading} = useCountryQuery();
  const loadingSelectedCountryList = isFetching || isLoading;
  const [updateDefaultcountry, {isLoading: isSubmitting}] =
    useDefaultCountriesMutation();
  const [data, setData] = useState<Country[]>();
  const [loadingDefaultCountryList, setLoadingDefaultCountryList] =
    useState(true);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    keyword: "",
  };
  const [params, setParams] = useSearchParams();

  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? prev.set(key, value)
          : prev.delete(key);
        return prev;
      });
    };
  };

  const handleToggleCountry = (country: Country, active: boolean) => {
    if (defaultCountries) {
      let defaultList = defaultCountries;
      defaultCountries?.map((item, index) => {
        if (item.id === country.id) {
          defaultList[index].isSelceted = active;
        }
      });
      setDefaultCountries([...defaultList]);
    }
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_DEFAULT_COUNTRIES_URL;
    const config = {params};
    setLoadingDefaultCountryList(true);
    ServiceApi.get(url, config)
      .then(({data}) => {
        setData(data);
      })
      .finally(() => {
        setLoadingDefaultCountryList(false);
      });
  };

  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data && selectedCountries) {
      let list = data;
      list.map((data, index) => {
        list[index].isSelceted = false;
        selectedCountries.map(selectedCountry => {
          if (data.countryIso2 === selectedCountry.countryIso2)
            list[index].isSelceted = true;
        });
      });
      setBaseData(list);
    }
  }, [data, selectedCountries]); // eslint-disable-line react-hooks/exhaustive-deps

  const updatePermission = usePermission("AS_UpdateCountry");

  const onSubmitHandler = async () => {
    let ids = new Array<string>();
    defaultCountries.map(country => {
      if (country.isSelceted) ids.push(country.id);
    });

    try {
      await updateDefaultcountry({ids}).unwrap();
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title",
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingDefaultCountryList || loadingSelectedCountryList}
        loading={loadingDefaultCountryList || loadingSelectedCountryList}
        totalItems={defaultCountries?.length}
        onReload={getData}
      />
      <div className="rounded-lg bg-white px-6">
        {loadingDefaultCountryList || loadingSelectedCountryList ? (
          [...Array(20)].map(index => <Skeleton.SimpleList key={index} />)
        ) : (
          <>
            {defaultCountries?.length !== 0 ? (
              <>
                <table className="min-w-full [&_td:first-child]:text-left [&_td:last-child]:text-right [&_td]:py-4 [&_tr]:border-b-2">
                  <tbody>
                    {defaultCountries?.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <div>
                              <h6 className="text-heading-6 font-semibold text-gray-800">
                                {updatePermission && selectedCountries ? (
                                  <CheckBox
                                    label={
                                      item.translates?.find(
                                        (item: any) =>
                                          item.language === i18n.language,
                                      )?.labelTitle
                                    }
                                    value={item.isSelceted || false}
                                    setValue={active =>
                                      handleToggleCountry(item, active)
                                    }
                                  />
                                ) : (
                                  <>
                                    {
                                      item.translates?.find(
                                        (item: any) =>
                                          item.language === i18n.language,
                                      )?.labelTitle
                                    }
                                  </>
                                )}
                              </h6>
                            </div>
                          </td>
                          <td>
                            <img
                              src={`/flags/${item.countryIso2}.svg`}
                              alt={item.countryIso2}
                              className=" inline-block h-[30px] w-10 rounded-lg"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Footer show={!isChanged}>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={
                      !isChanged || loadingDefaultCountryList || isSubmitting
                    }
                    onClick={onSubmitHandler}
                    loading={
                      loadingDefaultCountryList ||
                      loadingSelectedCountryList ||
                      isSubmitting
                    }
                  >
                    <Text>global.buttons.saveChanges</Text>
                  </Button>
                </Footer>
              </>
            ) : (
              <div className="flex w-full flex-col items-center space-y-4">
                <NoItems />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default withPermission(Countries, ["AS_GetCountry"]);
