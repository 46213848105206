const AUCTION = {
  CREATE_AUCTION: "/salesservice/api/auctions",
  AUCTION_LIST: "/salesservice/api/auctions",
  UPDATE_AUCTION: id => `/salesservice/api/auctions/${id}`,
  DELETE_AUCTION: id => `/salesservice/api/auctions/${id}`,
  DETAILS_AUCTION: id => `/salesservice/api/auctions/${id}`,
  ALL_AUCTION: `/salesservice/api/auctions/all`,
  ADD_PRODUCTS_TO_AUCTION: id =>
    `/salesservice/api/auctions/${id}/add-products`,
  UPDATE_AUCTION_PRODUCT: id =>
    `/salesservice/api/auctions/${id}/update-product`,
  REMOVE_PRODUCT_FROM_AUCTION: id =>
    `/salesservice/api/auctions/${id}/remove-product`,
};

export default AUCTION;
