import {
  Button,
  DatePicker,
  Form,
  InputGroup,
  LazyImage,
  NoItems,
  Skeleton,
  Text,
  WithPermission,
  Wrapper,
} from "components";
//imports
import {config, rules} from "constant";
import {useToggleState, usePermission} from "hooks";
import {isEmpty} from "lodash";
import {useContext, useEffect, useState} from "react";
import Select from "react-select";
import {ServiceApi, URLS} from "services";
import {PimContext} from "..";
import {
  useProductGroupsQuery,
  useProgramListQuery,
  useSuppliersQuery,
} from "services/caching";
//General
const GeneralTab = () => {
  const {
    productData,
    loading,
    setData,
    isChanged,
    editProduct,
    submitLoading,
    setPatchData,
    setBrandId,
  } = useContext(PimContext);
  const editPermission = usePermission("PS_UpdatePimProduct");
  const brandPermission = usePermission("PS_GetBrand");
  const supplierPermission = usePermission("PS_GetSupplier");
  const [programs, setPrograms] = useState<any>([]);
  const [suppliers, setsuppliers] = useState<any>([]);
  const [productGroups, setProductGroups] = useState<any>([]);
  const [programBrand, setProgramBrand] = useState<any>();
  const [programInfo, setProgramInfo] = useState<any>();

  const [loadingBrandInfo, setLoadingBrandInfo] = useToggleState(false);
  const [selectedOptions, setSelectedOptions] = useState<any>();
  const [selectedOptionsProgram, setSelectedOptionsProgram] = useState<any>([]);
  const [selectedOptionsGroup, setSelectedOptionsGroup] = useState<any>([]);

  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
    suppliertype: 0,
  });
  const [productGroupParams, setProductGroupsParams] = useState({
    pageNumber: 1,
    pageSize: 100,
    keyword: "",
  });

  const {data: supplierData, isFetching, isLoading} = useSuppliersQuery(params);

  const {
    data: productGroupsData,
    isLoading: isLoadingProductGroups,
    isFetching: isFetchingProductGroups,
  } = useProductGroupsQuery(productGroupParams);

  useEffect(() => {
    if (!!supplierData) {
      const suppliers = supplierData?.items?.map((supplier: any) => ({
        ...supplier,
        id: supplier.supplierId,
        label: supplier.name + " - " + supplier.code,
        value: supplier.code,
        code: supplier.code,
      }));
      setsuppliers(suppliers);
    }
  }, [supplierData]);

  useEffect(() => {
    if (!!productGroupsData) {
      const groups = productGroupsData?.items?.map((group: any) => ({
        ...group,
        id: group.id,
        label: group.name,
        value: group.code,
        code: group.code,
      }));
      setProductGroups(groups);
    }
  }, [productGroupsData]);

  const getSupplier = () => {
    if (productData?.supplierId) {
      const urlSupplers = URLS.GET_SUPPLIER_DETAILS(productData?.supplierId);
      ServiceApi.get(urlSupplers).then(({data}) => {
        setSelectedOptions({
          ...data,
          label: data.name + " - " + data.code,
          value: data.code,
        });
      });
    }
  };
  const getProductGroup = () => {
    if (productData?.pimProductGroupId) {
      const url = URLS.GET_PIM_PRODUCT_GROUPS_DETAILS(
        productData?.pimProductGroupId,
      );
      ServiceApi.get(url).then(({data}) => {
        setSelectedOptionsGroup({
          ...data,
          label: data.name,
          value: data.code,
        });
      });
    }
  };

  const {
    data: programsData,
    isLoading: isLoadingProgram,
    isFetching: isFetchingProgram,
  } = useProgramListQuery(productData?.supplierId, {
    skip: !(productData?.supplierId && supplierPermission),
  });

  const loadingPrograms = isLoadingProgram || isFetchingProgram;

  useEffect(() => {
    if (!!programsData) {
      const program = programsData?.map((item: any) => ({
        ...item,
        id: item.id,
        code: item.code,
        value: item.title,
        label: item.title,
      }));
      setPrograms(program);
      if (productData?.supplierProgramId && program?.length > 0) {
        let programInfo: any = program?.find(
          (item: any) => item.id === productData?.supplierProgramId,
        );
        setSelectedOptionsProgram(programInfo);
      } else {
        setSelectedOptionsProgram([]);
      }
    }
  }, [programsData]);

  useEffect(() => {
    //setBrand

    if (productData?.supplierId && programInfo?.brandId && brandPermission) {
      findBrandInfo();
    } else {
      setProgramBrand({});
    }
  }, [programInfo?.brandId]);

  useEffect(() => {
    if (programs?.length) {
      let programInfo: any = programs?.find(
        (item: any) => item.id === productData?.supplierProgramId,
      );

      setProgramInfo(programInfo);
    }
  }, [programs]);

  useEffect(() => {
    if (supplierPermission) getSupplier();
  }, [productData?.supplierId]);
  useEffect(() => {
    getProductGroup();
  }, [productData?.pimProductGroupId]);

  const findBrandInfo = (id?: string) => {
    if (productData?.supplierProgramId || programInfo?.brandId) {
      setLoadingBrandInfo();
      setBrandId(programInfo?.brandId);
      const idUrl = URLS.GET_BRAND_DETAILS(programInfo?.brandId);
      ServiceApi.get(idUrl)
        .then(({data}) => {
          setProgramBrand(data);
          setLoadingBrandInfo();
        })
        .catch(() => setLoadingBrandInfo());
    }
  };

  const onSupplierChange = (event: any) => {
    let supplierInfo: any = event;
    setSelectedOptions(event);
    if (!event) {
      setSelectedOptionsProgram(null);
      setPrograms([]);
    }
    setPatchData((p: any) => ({
      ...p,
      supplierId: supplierInfo?.supplierId || null,
      supplierProgramId: null,
    }));
    setData((p: any) => ({
      ...p,
      supplierInfo: supplierInfo || null,
      supplierId: supplierInfo?.supplierId || null,
      supplierProgramId: null,
    }));
    setProgramInfo(null);
  };

  const onProgramChange = (event: any) => {
    let programInfo: any = programs?.find(
      (item: any) => item.code === event?.code,
    );
    setSelectedOptionsProgram(event);

    setPatchData((p: any) => ({
      ...p,
      supplierProgramId: event || null,
    }));
    setData((p: any) => ({
      ...p,
      programInfo: programInfo || null,
      supplierProgramId: programInfo?.id || null,
    }));
    setProgramInfo(programInfo);
  };

  const onChangeHandler = (key: string) => {
    return (value: any) => {
      setPatchData((p: any) => ({...p, [key]: value}));
      return setData((p: any) => ({...p, [key]: value}));
    };
  };

  const onProductGroupChange = (event: any) => {
    setSelectedOptionsGroup(event);
    setPatchData((p: any) => ({
      ...p,
      pimProductGroupId: event?.id,
    }));
    setData((p: any) => ({
      ...p,
      pimProductGroupId: event?.id,
    }));
  };

  return (
    <Form className="space-y-6" onSubmit={editProduct}>
      <Wrapper>
        <Wrapper.Body>
          <div className="grid grid-cols-2 gap-16">
            {loading ? (
              [1, 2].map(key => <Skeleton.Input key={key} />)
            ) : (
              <>
                <div>
                  <InputGroup
                    label={
                      productData?.pimProductType
                        ? "productManagement.products.allProducts.bundleLabel"
                        : "productManagement.products.allProducts.sku"
                    }
                    placeholder={
                      productData?.pimProductType
                        ? "bundle label"
                        : "productManagement.products.allProducts.sku"
                    }
                    value={productData?.originalName}
                    setValue={onChangeHandler("originalName")}
                    rules={rules.required}
                    disabled={!editPermission}
                  />
                </div>
                {!productData?.pimProductType && (
                  <>
                    <DatePicker
                      minDate={new Date()}
                      placeholderText="productManagement.products.Details.selectDate"
                      label="productManagement.products.Details.availableTill"
                      value={
                        productData?.endOfLifeDate
                          ? new Date(productData?.endOfLifeDate)
                          : null
                      }
                      disabled={!editPermission}
                      onChange={onChangeHandler("endOfLifeDate")}
                    />
                    <InputGroup
                      label="productManagement.products.Details.supplierArticleNumber"
                      placeholder="productManagement.products.Details.supplierArticleNumber"
                      value={productData?.supplierArticleNumber}
                      setValue={onChangeHandler("supplierArticleNumber")}
                      disabled={!editPermission}
                    />
                  </>
                )}
                <div className="space-y-4">
                  <label
                    data-lang-map={
                      "productManagement.products.Details.productGroup"
                    }
                    className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
                  >
                    <Text>
                      {"productManagement.products.Details.productGroup"}
                    </Text>
                  </label>
                  <Select
                    value={selectedOptionsGroup}
                    options={productGroups}
                    onInputChange={e =>
                      setProductGroupsParams(p => ({...p, keyword: e}))
                    }
                    onChange={onProductGroupChange}
                    styles={config.styleSelectReact}
                    isClearable
                    isDisabled={!editPermission}
                    isLoading={
                      isLoadingProductGroups || isFetchingProductGroups
                    }
                  />
                </div>
              </>
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      <div className="grid grid-cols-2 gap-6">
        <WithPermission permissions={["PS_GetSupplier"]}>
          <Wrapper>
            <Wrapper.Header>
              <Text>productManagement.products.Details.supplier</Text>
            </Wrapper.Header>
            <Wrapper.Body className="space-y-6">
              {loading ? (
                [1, 2].map(key => <Skeleton.Input key={key} />)
              ) : (
                <>
                  <label
                    data-lang-map={
                      "productManagement.products.Details.suppliers"
                    }
                    className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
                  >
                    <Text>
                      {"productManagement.products.Details.suppliers"}
                    </Text>
                  </label>
                  <Select
                    value={selectedOptions}
                    options={suppliers}
                    onInputChange={e => setParams(p => ({...p, keyword: e}))}
                    onChange={onSupplierChange}
                    styles={config.styleSelectReact}
                    isClearable
                    isDisabled={!editPermission}
                    isLoading={isFetching || isLoading}
                  />
                  <label
                    data-lang-map={"productManagement.products.Details.program"}
                    className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
                  >
                    <Text>{"productManagement.products.Details.program"}</Text>
                  </label>
                  <Select
                    value={selectedOptionsProgram}
                    onChange={onProgramChange}
                    isSearchable
                    name="color"
                    styles={config.styleSelectReact}
                    isLoading={loadingPrograms}
                    isDisabled={loadingPrograms}
                    options={programs}
                    isClearable
                  />
                </>
              )}
              {selectedOptionsProgram?.pimProductGroupId && (
                <div className="space-y-4">
                  <label
                    data-lang-map={
                      "productManagement.products.Details.productGroup"
                    }
                    className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
                  >
                    <Text>
                      {"productManagement.products.Details.productGroup"}
                    </Text>
                  </label>
                  <div className="space-y-3 rounded-xl bg-white p-4 shadow-card">
                    <p className="text-heading-6 font-semibold text-gray-800">
                      {selectedOptionsProgram?.pimProductGroupName}
                    </p>
                    <p className=" text-body-2 text-gray-500">
                      #{selectedOptionsProgram?.pimProductGroupCode}
                    </p>
                  </div>
                </div>
              )}
            </Wrapper.Body>
          </Wrapper>
        </WithPermission>
        {/* Brand */}
        <WithPermission permissions={["PS_GetBrand"]}>
          <Wrapper>
            <Wrapper.Header>
              <Text>productManagement.products.Details.brand</Text>
            </Wrapper.Header>
            <Wrapper.Body className="space-y-6">
              {loadingBrandInfo || loading ? (
                <div className="w-full max-w-sm  rounded-md shadow ">
                  <div className="flex animate-pulse flex-col space-y-4">
                    <div className="h-20 w-20 rounded bg-slate-200"></div>
                    <div className="flex-1 space-y-6 py-1">
                      <div className="h-2 w-32 rounded bg-slate-200"></div>
                    </div>

                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2 h-2 rounded bg-slate-200"></div>
                      </div>
                      <div className="h-2 rounded bg-slate-200"></div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col space-y-6">
                  {!isEmpty(programBrand) ? (
                    <>
                      <span>
                        <LazyImage
                          isDynamic
                          src={programBrand?.logoUrl}
                          className="w-40"
                        />
                      </span>
                      <span>{programBrand?.name}</span>
                      <span className="text-body-2 leading-5">
                        {programBrand?.description}
                      </span>
                    </>
                  ) : (
                    <div className="flex w-full flex-col items-center">
                      <NoItems />
                    </div>
                  )}
                </div>
              )}
            </Wrapper.Body>
          </Wrapper>
        </WithPermission>
      </div>
      {!loading && isChanged && (
        <WithPermission permissions={["PS_UpdatePimProduct"]}>
          <Wrapper className="sticky bottom-0 shadow-card">
            <Wrapper.Body className="flex items-center justify-end gap-4">
              <Button
                type="submit"
                variant="primary"
                disabled={!isChanged}
                // onClick={editProduct}
                loading={submitLoading}
              >
                <Text>global.buttons.saveChanges</Text>
              </Button>
            </Wrapper.Body>
          </Wrapper>
        </WithPermission>
      )}
    </Form>
  );
};

export default GeneralTab;
