import {Icon, InputGroup, Skeleton, Wrapper, Text} from "components";
import {useContext, useEffect, useState} from "react";
import {ProgramContext} from "../details";
import {config, rules} from "constant";
import {NotificationManager} from "common/notifications";
import AsyncSelect from "react-select/async";
import {ServiceApi, URLS} from "services";

export default function General(props: any) {
  const {
    loading,
    data,
    setData,
    selectedOptionsBrand,
    setSelectedOptionsBrand,
  } = useContext(ProgramContext);
  const [selectedOptionsGroup, setSelectedOptionsGroup] = useState<any>([]);

  const handleSearchBrands = async (filter: string) => {
    const url = URLS.GET_SELL_OFF_BRANDS;
    const params = {
      pageNumber: 1,
      pageSize: 1000,
      keyword: filter,
    };
    return ServiceApi.get(url, {params}).then(({data}) => {
      const brands = data.items.map((brand: any) => ({
        value: brand.brandId,
        label: brand.name,
      }));
      return brands;
    });
  };

  const promiseOptionsBrand = (inputValue: string) =>
    new Promise<any[]>(resolve => {
      setTimeout(() => {
        resolve(handleSearchBrands(inputValue));
      }, 200);
    });

  const onChangeHandlerBrand = (selected: any) => {
    if (!selected) {
      setSelectedOptionsBrand(null);
    } else {
      setSelectedOptionsBrand(selected);
    }
    setData((p: any) => ({
      ...p,
      brandId: selected?.value,
      brandName: selected?.label,
    }));
  };

  const handleSearchGroups = async (filter: string) => {
    const url = URLS.GET_PIM_PRODUCT_GROUPS;
    const params = {
      pageNumber: 1,
      pageSize: 100,
      keyword: filter,
    };
    return ServiceApi.get(url, {params}).then(({data}) => {
      const groups = data.items.map((group: any) => ({
        ...group,
        id: group.id,
        label: group.name,
        value: group.code,
        code: group.code,
      }));
      return groups;
    });
  };

  const onProductGroupChange = (event: any) => {
    setSelectedOptionsGroup(event);
    setData((p: any) => ({
      ...p,
      pimProductGroupId: event?.id,
    }));
  };

  const promiseGroupOptions = (inputValue: string) =>
    new Promise<any[]>(resolve => {
      setTimeout(() => {
        resolve(handleSearchGroups(inputValue));
      }, 200);
    });

  const getProductGroup = () => {
    if (data?.pimProductGroupId) {
      const url = URLS.GET_PIM_PRODUCT_GROUPS_DETAILS(data?.pimProductGroupId);
      ServiceApi.get(url).then(({data}) => {
        setSelectedOptionsGroup({
          ...data,
          label: data.name,
          value: data.code,
        });
      });
    }
  };

  useEffect(() => {
    setSelectedOptionsGroup([]);
    getProductGroup();
  }, [data?.pimProductGroupId]);

  return (
    <Wrapper>
      <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        {loading ? (
          <Skeleton.Input />
        ) : (
          <InputGroup
            label="productManagement.masterData.suppliers.title"
            value={data.title}
            className="border-white"
            setValue={title => setData((p: any) => ({...p, title}))}
            rules={rules.required}
          />
        )}
        {loading ? (
          <Skeleton.Input />
        ) : (
          <InputGroup
            label="productManagement.masterData.suppliers.code"
            value={data.code}
            disabled
            append={
              <span
                className="input-group-text"
                onClick={() => {
                  window.navigator.clipboard.writeText(data.code);
                  NotificationManager.success(
                    "global.toast.copy-msg",
                    "global.toast.copy-title",
                  );
                }}
              >
                <Icon
                  className="ml-6 cursor-pointer text-primary"
                  icon={"files"}
                />
              </span>
            }
          />
        )}
        {loading ? (
          <Skeleton.Input />
        ) : (
          <InputGroup
            label="productManagement.masterData.suppliers.modelNumber"
            value={data.modelNumber}
            setValue={modelNumber => setData((p: any) => ({...p, modelNumber}))}
            rules={rules.required}
          />
        )}
        {loading ? (
          <Skeleton.Input />
        ) : (
          <InputGroup
            label="productManagement.masterData.suppliers.iwofurnCode"
            value={data.iwofurnCode}
            setValue={iwofurnCode => setData((p: any) => ({...p, iwofurnCode}))}
          />
        )}
        {loading ? (
          <Skeleton.Input />
        ) : (
          <InputGroup
            label="productManagement.masterData.suppliers.zrNumber"
            value={data.zrNumber}
            type="number"
            setValue={zrNumber => setData((p: any) => ({...p, zrNumber}))}
          />
        )}
        {loading ? (
          <Skeleton.Input />
        ) : (
          <InputGroup
            label="productManagement.masterData.suppliers.salesPersonProvision"
            type="number"
            append={<span className="text-gray-500">%</span>}
            value={data.salesPersonProvision}
            setValue={salesPersonProvision => {
              if (+salesPersonProvision <= 100) {
                setData((p: any) => ({...p, salesPersonProvision}));
              }
            }}
          />
        )}
        <div className="space-y-8">
          {loading ? (
            <Skeleton.Input />
          ) : (
            <div>
              <label
                data-lang-map={"productManagement.masterData.suppliers.brand"}
                className="h6 mb-3 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
              >
                <Text>{"productManagement.masterData.suppliers.brand"}</Text>
              </label>
              <AsyncSelect
                value={selectedOptionsBrand}
                cacheOptions
                loadOptions={promiseOptionsBrand}
                defaultOptions
                styles={config.styleSelectReact}
                onChange={onChangeHandlerBrand}
                isClearable
                className="z-40"
              />
            </div>
          )}
        </div>
        {loading ? (
          <Skeleton.Input />
        ) : (
          <div>
            <label
              data-lang-map={
                "productManagement.masterData.suppliers.productGroup"
              }
              className="h6 mb-3 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
            >
              <Text>
                {"productManagement.masterData.suppliers.productGroup"}
              </Text>
            </label>
            <AsyncSelect
              value={selectedOptionsGroup}
              cacheOptions
              loadOptions={promiseGroupOptions}
              defaultOptions
              onChange={onProductGroupChange}
              styles={config.styleSelectReact}
              isClearable
              className="z-30"
            />
          </div>
        )}
        {loading ? (
          <Skeleton.TextArea />
        ) : (
          <InputGroup
            as="textarea"
            className="[&_textarea]:h-40 [&_textarea]:!min-h-12"
            label="productManagement.masterData.suppliers.description"
            value={data.shortDescription}
            setValue={shortDescription =>
              setData((p: any) => ({...p, shortDescription}))
            }
          />
        )}
      </Wrapper.Body>
    </Wrapper>
  );
}
