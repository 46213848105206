import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
} from "components";
import {config as appConfig} from "constant";
import {withPermission} from "hoc";
import {useConverter, usePermission, useSelector, useToggleState} from "hooks";
import {Fragment, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {SalesChannelMessage} from "types";
import ChannelMessageDetails from "./ChannelMessageDetails";
import FilterDrawer from "./FilterDrawer";
import {useGetSaleschannelMessagesQuery} from "services/caching";

type Params = SalesChannelMessage.Params;

function SalesChannelMessages() {
  const initParams: Params = {
    pageNumber: "1",
    pageSize: appConfig.pageSize.toString(),
  };
  const {convertDate} = useConverter();
  const channels = useSelector(s => s.channels);
  const DETAILS_PERMISSION = usePermission("SC_GetSaleChannelMessageStatus");

  const [params, setParams] = useSearchParams(initParams);
  const queryParams = Object.fromEntries(params.entries());

  const [activeId, setActiveId] = useState("");
  const [showFilter, toggleFilter] = useToggleState(false);
  const [channelsList, setChannels] = useState<any>([]);

  const {data, isLoading, isFetching, refetch} =
    useGetSaleschannelMessagesQuery(queryParams);

  const hasData = !!data?.items?.length;
  const loading = isFetching || isLoading;

  const isFilter = [
    "keyword",
    "aggregateType",
    "status",
    "channelCode",
    "fromDate",
    "toDate",
  ].some(item => params.get(item));

  const clearActiveId = () => {
    setActiveId("");
  };

  const handleSetParams = (key: keyof Params) => {
    return (value: string | number | null) => {
      setParams(p => {
        const hasValue = !!String(value ?? "");
        hasValue ? p.set(key, `${value}`) : p.delete(key);
        return p;
      });
    };
  };

  const resetFilter = () => {
    setParams(initParams);
  };

  const getChannels = () => {
    const data = channels.map((element: any) => ({
      name: element.title,
      id: element.code,
    }));
    setChannels([{name: "All", code: null, id: ""}, ...data]);
  };

  useEffect(() => {
    getChannels();
  }, [channels]);

  return (
    <div className="space-y-4">
      <Breadcrumb />
      <SearchBox
        value={params.get("keyword")}
        onSubmit={handleSetParams("keyword")}
        disabled={loading}
        loading={loading}
        totalItems={data?.totalItems}
        onFilter={toggleFilter}
        onReload={refetch}
        {...(isFilter && {resetFilter})}
      />
      {loading ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {hasData ? (
            <>
              <Table>
                {data?.items?.map(e => {
                  const hasError = !!e.errorCode;
                  return (
                    <Fragment key={e.saleChannelMessageId}>
                      <tr>
                        <td className="space-y-2">
                          <h6 className="text-heading-6 text-gray-800">
                            {e.aggregateType}
                          </h6>
                          <p className="text-body-2 text-gray-500">
                            <Text>channelMessage.list.aggregateId</Text>:{" "}
                            {e.aggregateIdentifier}
                          </p>
                          <div className="block space-y-2 xl:hidden">
                            <p className="text-body-2 text-gray-600">
                              <Text>channelMessage.list.channel</Text>:{" "}
                              <span className="font-bold uppercase">
                                {e.channelCode}
                              </span>
                            </p>
                            <p className="text-body-2 text-gray-600">
                              <Text>channelMessage.list.channel</Text>:{" "}
                              <span className="font-bold uppercase">
                                {e.saleChannel?.title ?? "-----"}
                              </span>
                            </p>
                            <p className="text-body-2 text-gray-600">
                              <Text>channelMessage.list.createdAt</Text>:{" "}
                              <span className="font-bold">
                                {convertDate(e.createdAt)}
                              </span>
                            </p>
                            <p className="text-body-2 text-gray-600">
                              <Text>channelMessage.list.updatedAt</Text>:{" "}
                              <span className="font-bold">
                                {convertDate(e.updatedAt) || "-----"}
                              </span>
                            </p>
                            <Status.PimChannelStatus id={e?.status} />
                            {hasError && (
                              <p className="text-wrap text-body-2 leading-5 text-warning-active">
                                <Icon icon="circle-info" />{" "}
                                <Text>{`errors.${e.errorCode}`}</Text>
                              </p>
                            )}
                          </div>
                        </td>
                        <td className="hidden space-y-2 text-start xl:table-cell">
                          <p className="text-body-2 text-gray-600">
                            <Text>channelMessage.list.channel</Text>:{" "}
                            <span className="font-bold uppercase">
                              {e.channelCode}
                            </span>
                          </p>
                          <p className="text-body-2 text-gray-600">
                            <Text>channelMessage.list.channel</Text>:{" "}
                            <span className="font-bold uppercase">
                              {e.saleChannel?.title ?? "-----"}
                            </span>
                          </p>
                          <p className="text-body-2 text-gray-600">
                            <Text>channelMessage.list.createdAt</Text>:{" "}
                            <span className="font-bold">
                              {convertDate(e.createdAt)}
                            </span>
                          </p>
                          <p className="text-body-2 text-gray-600">
                            <Text>channelMessage.list.updatedAt</Text>:{" "}
                            <span className="font-bold">
                              {convertDate(e.updatedAt) || "-----"}
                            </span>
                          </p>
                        </td>
                        <td className="hidden space-y-2 xl:table-cell">
                          <Status.PimChannelStatus id={e?.status} />
                          {hasError && (
                            <p className="text-wrap text-body-2 leading-5 text-warning-active">
                              <Icon icon="circle-info" />{" "}
                              <Text>{`errors.${e.errorCode}`}</Text>
                            </p>
                          )}
                        </td>
                        {DETAILS_PERMISSION && (
                          <td>
                            <Button
                              type="button"
                              variant="light"
                              size="sm"
                              onClick={() =>
                                setActiveId(e.saleChannelMessageId)
                              }
                            >
                              <Text>channelMessage.list.detailsBtn</Text>
                            </Button>
                          </td>
                        )}
                      </tr>
                      <ChannelMessageDetails
                        channelMessageId={e.saleChannelMessageId}
                        channelCode={e.channelCode}
                        isOpen={e.saleChannelMessageId === activeId}
                        toggle={clearActiveId}
                      />
                    </Fragment>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={+(params.get("pageNumber") ?? 1)}
                pageSize={+(params.get("pageSize") ?? appConfig.pageSize)}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={handleSetParams("pageSize")}
              />
            </>
          ) : (
            <NoItems />
          )}
        </>
      )}
      <FilterDrawer
        isOpen={showFilter}
        toggle={toggleFilter}
        params={params}
        setParams={handleSetParams}
        resetFilter={resetFilter}
        channels={channelsList}
      />
    </div>
  );
}

export default withPermission(SalesChannelMessages, [
  "SC_GetSaleChannelMessage",
]);
