import {Dropdown, Icon} from "components";
import React, {
  ElementType,
  forwardRef,
  HTMLInputTypeAttribute,
  ReactNode,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import {useTranslation} from "react-i18next";
import {twMerge} from "tailwind-merge";
import {
  KeyFilter,
  convertNumber,
  convertPrice,
  debounce,
  replaceNonDigits,
} from "utils";
import {useTranslate} from "../hooks";
import {rulesType} from "../types";
import Text from "./Text";
import {Mention, MentionsInput} from "react-mentions";

type SuggestionData = {
  id: string;
  display: string;
};

type inputGroupProps<E extends ElementType> = {
  as?: E | string;
  value: any;
  setValue?: (val: any) => void;
  onClick?: () => void;
  label?: any;
  rules?: rulesType;
  append?: ReactNode;
  prepend?: ReactNode;
  placeholder?: string;
  helperText?: string;
  readOnly?: boolean;
  hiddenPriceSymbol?: boolean;
  disabled?: boolean;
  center?: boolean;
  type?: HTMLInputTypeAttribute | "price";
  keyfilter?: string;
  size?: string;
  className?: string;
  hint?: any;
  flag?: string;
  actions?: any;
  ariaLabel?: string;
  unit?: string;
  snippet?: boolean;
  suggestionData?: SuggestionData[];
  trigger?: string;
  onPressEnter?: (e: any) => void;
  code?: string;
  onSelectMention?: (e: any) => void;
};
const InputGroup = forwardRef(function InputGroup<E extends ElementType>(
  {
    as,
    value = "",
    label = "",
    rules = [],
    setValue = () => {},
    onClick = () => {},
    append = null,
    prepend = null,
    type = "text",
    placeholder,
    readOnly,
    disabled,
    helperText,
    keyfilter,
    size,
    hint,
    flag,
    actions,
    className,
    ariaLabel,
    hiddenPriceSymbol = false,
    unit,
    snippet = false,
    suggestionData,
    trigger = "#",
    code,
    center,
    onPressEnter = () => {},
  }: inputGroupProps<E>,
  ref: any,
) {
  const isTextarea = as === "textarea";
  // @ts-ignore: Unreachable code error
  const isShortTextarea = as === "short-textarea";
  // const isDate = type === "date";
  const isPrice = type === "price";
  const isNumber = type === "number";
  const isColor = type === "color";
  // const isText = type === "text";
  const {i18n} = useTranslation();
  const Component = useMemo(() => {
    if (isShortTextarea) return "textarea";
    return as || "input";
  }, [as, isShortTextarea]);
  const translate = useTranslate();
  const inputId = useId();
  const inputGroupRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const hasRule = !!rules.length;
  const hasError = hasRule && !!errorMessage;
  const handleValue = useMemo(() => {
    if (isPrice) return convertPrice(String(value ?? ""));
    // if (isDate) return convertDate(value);
    if (isNumber) return convertNumber(`${value ?? ""}`);
    return value ?? "";
  }, [isNumber, isPrice, i18n.language, value]);
  const onKeyPress = (event: KeyboardEvent) => {
    if (keyfilter) {
      KeyFilter.onKeyPress(event, keyfilter);
    }
  };
  const onPaste = (event: KeyboardEvent) => {
    if (keyfilter) {
      KeyFilter.onPaste(event, keyfilter);
    }
  };
  const handleChange = (e: any) => {
    // if (isPrice || isNumber) {
    //   if (isNaN(e.target.value)) {
    //     return;
    //   }
    // }
    if (isPrice) return setValue(replaceNonDigits(e.target.value));
    if (isNumber) return setValue(replaceNonDigits(e.target.value as string));
    setValue(e.target.value);
  };

  const handleClick = () => {
    // if (isDate) return toggleCalendar();
    onClick();
  };
  const handleType = useMemo(() => {
    if (isPrice) return "tel";
    if (isNumber) return "tel";
    if (isColor) return "text";
    // if (isDate) return "text";
    return type;
  }, [isPrice, isNumber, isColor, type]);
  const handleHeight = useMemo(() => {
    if (isTextarea) return "min-h-[12.5rem] py-2 leading-5";
    if (isShortTextarea) return "min-h-[5.25rem] py-2 leading-5";
    if (size === "sm") return "h-9";
    return "h-11";
  }, [isTextarea, isShortTextarea, size]);
  const handleInputMode = useMemo(() => {
    if (isNumber) return "numeric";
    return undefined;
  }, [isNumber]);
  const handleReadOnly = useMemo(() => {
    // if (isColor) return true;
    return readOnly || disabled;
  }, [readOnly, disabled]);
  useEffect(() => {
    const formControl = inputGroupRef.current?.querySelector(".form-control");
    // @ts-ignore: Unreachable code error
    formControl.onValid = () => {
      return rules.every(rule => {
        const ruleValue = rule(value);
        const isValid = ruleValue === true;
        setErrorMessage(isValid ? "" : ruleValue);
        return isValid;
      });
    };
  }, [rules, value]);

  const requierdRules = () => {
    const rule = rules.every(rule => {
      return rule.toString().includes("required");
    });
    return rule;
  };

  return (
    <React.Fragment>
      <div
        ref={inputGroupRef}
        className={twMerge(
          "input-group group w-full space-y-4",
          hasRule && "required",
          hasError && "error",
        )}
      >
        {label || flag || hint ? (
          <div className="flex  items-center justify-between">
            {label && (
              // <div>
              <label
                htmlFor={inputId}
                data-lang-map={label}
                className={`h6 block w-full truncate font-[400] text-gray-800 ${
                  requierdRules() &&
                  `group-[.input-group.required]:after:content-['*']`
                } after:text-danger`}
              >
                {typeof label === "object" ? label : <Text>{label}</Text>}
              </label>
              // </div>
            )}
            {(flag || hint) && (
              <div className="flex flex-none items-center gap-2">
                {hint && (
                  <span
                    className={`text-body-2 font-normal text-${hint?.color}`}
                  >
                    {hint?.label}
                  </span>
                )}
                {flag && <img className="block " src={flag} alt="" />}
                {actions?.length > 0 && (
                  <Dropdown onSelect={setSelectedItem}>
                    <Dropdown.Toggle
                      as="button"
                      icon={true}
                      type="button"
                      className="text-body-base font-medium uppercase text-gray-600"
                    >
                      <Icon icon="ellipsis-vertical"></Icon>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-2 min-w-fit p-2">
                      <ul className="w-max text-gray-700">
                        {actions?.map((e: any) => (
                          <li
                            key={e.id}
                            onClick={value => {
                              setSelectedItem(e.id);
                              e.onClick(value);
                            }}
                            className={`h6 px-4 py-2 font-medium first:mb-1 ${
                              e.id === selectedItem &&
                              "bg-primary-light text-primary "
                            } cursor-pointer rounded-lg text-body-base hover:bg-primary-light hover:text-primary`}
                          >
                            {e.title}
                          </li>
                        ))}
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        <div
          className={`input-box flex w-full items-center justify-between gap-2 rounded-md border border-gray-100 bg-gray-100 px-2 text-heading-5 font-[400] text-gray-700 transition-colors focus-within:border-gray-500 group-[.input-group.error]:border-danger [&>*:first-child]:rounded-l-md [&>*:last-child]:rounded-r-md [&>*]:min-w-0 ${
            disabled && "bg-gray-300"
          } ${className}`}
          onClick={handleClick}
        >
          {prepend && <div>{prepend}</div>}
          {snippet ? (
            <MentionsInput
              aria-label={ariaLabel}
              readOnly={handleReadOnly}
              value={handleValue}
              onChange={(_event, _newValue, newPlainTextValue, _mention) => {
                handleChange({target: {value: newPlainTextValue}});
              }}
              className={`form-control ${
                readOnly ? (prepend ? "w-[22px]" : "") : "flex-1"
              }  ${handleHeight}`}
              placeholder={!!placeholder ? translate(placeholder) || "" : ""}
              inputRef={ref}
            >
              <Mention
                trigger={trigger}
                data={suggestionData as SuggestionData[]}
                appendSpaceOnAdd
                renderSuggestion={entry => {
                  return entry?.display;
                }}
              />
            </MentionsInput>
          ) : (
            <Component
              data-code={code}
              aria-label={ariaLabel}
              className={`form-control ${
                readOnly ? (prepend ? "w-[22px]" : "") : "flex-1"
              } ${center && "text-center"} ${handleHeight}`}
              placeholder={!!placeholder ? translate(placeholder) || "" : ""}
              readOnly={handleReadOnly}
              value={handleValue}
              onChange={handleChange}
              inputMode={handleInputMode}
              type={handleType}
              onPaste={onPaste}
              onKeyPress={onKeyPress}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  onPressEnter(e);
                }
              }}
            />
          )}

          {append}
          {isPrice && !hiddenPriceSymbol && (
            <span className="input-group-text text-lg text-gray-500">
              &euro;
            </span>
          )}
          {unit && (
            <span className="input-group-text">
              <Text>{unit}</Text>
            </span>
          )}
          {isColor && (
            <span className="input-group-text">
              <div
                style={{backgroundColor: handleValue || "#000"}}
                className="h-8 w-10 overflow-hidden rounded border border-gray-500 transition-colors"
              >
                <input
                  type="color"
                  className="h-full w-full cursor-pointer overflow-hidden opacity-0"
                  defaultValue={handleValue || "#000000"}
                  onChange={e => {
                    debounce(() => handleChange(e), 250);
                  }}
                />
              </div>
            </span>
          )}
          {!!rules.length && !label && (
            <i className="bi bi-asterisk input-group-text text-secondary text-xs" />
          )}
        </div>
        {helperText && (
          <div className="m-0 !mt-2">
            <span className="text-body-2 font-normal text-gray-500">
              <Text>{helperText}</Text>
            </span>
          </div>
        )}
        {hasError && (
          <p className="mt-1 text-xs text-danger">
            <i className="bi bi-info-circle mr-1" />
            <Text>{errorMessage}</Text>
          </p>
        )}
      </div>
    </React.Fragment>
  );
});

export default InputGroup;

// export default function InputGroup<E extends ElementType>({
//   as,
//   value = "",
//   label = "",
//   rules = [],
//   setValue = () => { },
//   onClick = () => { },
//   append = null,
//   prepend = null,
//   type = "text",
//   placeholder,
//   readOnly,
//   disabled,
//   helperText,
//   keyfilter,
//   size,
//   hint,
//   flag,
//   actions,
//   className,
//   ariaLabel,
//   hiddenPriceSymbol = false,
//   unit,
//   snippet = false,
//   suggestionData,
//   trigger = "#",
//   code,
//   center,
//   onPressEnter = () => { },
//   onSelectMention,
//   ref
// }: inputGroupProps<E>) {
//   const isTextarea = as === "textarea";
//   // @ts-ignore: Unreachable code error
//   const isShortTextarea = as === "short-textarea";
//   // const isDate = type === "date";
//   const isPrice = type === "price";
//   const isNumber = type === "number";
//   const isColor = type === "color";
//   // const isText = type === "text";
//   const { i18n } = useTranslation();
//   const Component = useMemo(() => {
//     if (isShortTextarea) return "textarea";
//     return as || "input";
//   }, [as, isShortTextarea]);
//   const translate = useTranslate();
//   const inputId = useId();
//   const inputGroupRef = useRef<HTMLDivElement>(null);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [selectedItem, setSelectedItem] = useState("");
//   const hasRule = !!rules.length;
//   const hasError = hasRule && !!errorMessage;
//   const handleValue = useMemo(() => {
//     if (isPrice) return convertPrice(String(value ?? ""));
//     // if (isDate) return convertDate(value);
//     if (isNumber) return convertNumber(`${value ?? ""}`);
//     return value ?? "";
//   }, [isNumber, isPrice, i18n.language, value]);
//   const onKeyPress = (event: KeyboardEvent) => {
//     if (keyfilter) {
//       KeyFilter.onKeyPress(event, keyfilter);
//     }
//   };
//   const onPaste = (event: KeyboardEvent) => {
//     if (keyfilter) {
//       KeyFilter.onPaste(event, keyfilter);
//     }
//   };
//   const handleChange = (e: any) => {
//     if (isPrice) return setValue(replaceNonDigits(e.target.value));
//     if (isNumber) return setValue(replaceNonDigits(e.target.value as string));
//     setValue(e.target.value);
//   };
//   const handleClick = () => {
//     // if (isDate) return toggleCalendar();
//     onClick();
//   };
//   const handleType = useMemo(() => {
//     if (isPrice) return "tel";
//     if (isNumber) return "tel";
//     if (isColor) return "text";
//     // if (isDate) return "text";
//     return type;
//   }, [isPrice, isNumber, isColor, type]);
//   const handleHeight = useMemo(() => {
//     if (isTextarea) return "min-h-[12.5rem] py-2 leading-5";
//     if (isShortTextarea) return "min-h-[5.25rem] py-2 leading-5";
//     if (size === "sm") return "h-9";
//     return "h-11";
//   }, [isTextarea, isShortTextarea, size]);
//   const handleInputMode = useMemo(() => {
//     if (isNumber) return "numeric";
//     return undefined;
//   }, [isNumber]);
//   const handleReadOnly = useMemo(() => {
//     // if (isColor) return true;
//     return readOnly || disabled;
//   }, [readOnly, disabled]);
//   useEffect(() => {
//     const formControl = inputGroupRef.current?.querySelector(".form-control");
//     // @ts-ignore: Unreachable code error
//     formControl.onValid = () => {
//       return rules.every(rule => {
//         const ruleValue = rule(value);
//         const isValid = ruleValue === true;
//         setErrorMessage(isValid ? "" : ruleValue);
//         return isValid;
//       });
//     };
//   }, [rules, value]);

//   const requierdRules = () => {
//     const rule = rules.every(rule => {
//       return rule.toString().includes("required");
//     });
//     return rule;
//   };

//   return (
//     <React.Fragment>
//       <div
//         ref={inputGroupRef}
//         className={twMerge(
//           "input-group group w-full space-y-4",
//           hasRule && "required",
//           hasError && "error",
//         )}
//       >
//         {label || flag || hint ? (
//           <div className="flex  items-center justify-between">
//             {label && (
//               // <div>
//               <label
//                 htmlFor={inputId}
//                 data-lang-map={label}
//                 className={`h6 block w-full truncate font-[400] text-gray-800 ${requierdRules() &&
//                   `group-[.input-group.required]:after:content-['*']`
//                   } after:text-danger`}
//               >
//                 {typeof label === "object" ? label : <Text>{label}</Text>}
//               </label>
//               // </div>
//             )}
//             {(flag || hint) && (
//               <div className="flex flex-none items-center gap-2">
//                 {hint && (
//                   <span
//                     className={`text-body-2 font-normal text-${hint?.color}`}
//                   >
//                     {hint?.label}
//                   </span>
//                 )}
//                 {flag && <img className="block " src={flag} alt="" />}
//                 {actions?.length > 0 && (
//                   <Dropdown onSelect={setSelectedItem}>
//                     <Dropdown.Toggle
//                       as="button"
//                       icon={true}
//                       type="button"
//                       className="text-body-base font-medium uppercase text-gray-600"
//                     >
//                       <Icon icon="ellipsis-vertical"></Icon>
//                     </Dropdown.Toggle>
//                     <Dropdown.Menu className="mt-2 min-w-fit p-2">
//                       <ul className="w-max text-gray-700">
//                         {actions?.map((e: any) => (
//                           <li
//                             key={e.id}
//                             onClick={value => {
//                               setSelectedItem(e.id);
//                               e.onClick(value);
//                             }}
//                             className={`h6 px-4 py-2 font-medium first:mb-1 ${e.id === selectedItem &&
//                               "bg-primary-light text-primary "
//                               } cursor-pointer rounded-lg text-body-base hover:bg-primary-light hover:text-primary`}
//                           >
//                             {e.title}
//                           </li>
//                         ))}
//                       </ul>
//                     </Dropdown.Menu>
//                   </Dropdown>
//                 )}
//               </div>
//             )}
//           </div>
//         ) : (
//           <></>
//         )}
//         <div
//           className={`input-box flex w-full items-center justify-between gap-2 rounded-md border border-gray-100 bg-gray-100 px-2 text-heading-5 font-[400] text-gray-700 transition-colors focus-within:border-gray-500 group-[.input-group.error]:border-danger [&>*:first-child]:rounded-l-md [&>*:last-child]:rounded-r-md [&>*]:min-w-0 ${disabled && "bg-gray-300"
//             } ${className}`}
//           onClick={handleClick}
//         >
//           {prepend && <div>{prepend}</div>}
//           {snippet ? (
//             <MentionsInput
//               aria-label={ariaLabel}
//               readOnly={handleReadOnly}
//               value={handleValue}
//               onChange={(_event, _newValue, newPlainTextValue, _mentions) => {
//                 handleChange({ target: { value: newPlainTextValue } });
//               }}
//               className={`form-control ${readOnly ? (prepend ? "w-[22px]" : "") : "flex-1"
//                 }  ${handleHeight}`}
//               placeholder={!!placeholder ? translate(placeholder) || "" : ""}
//               id='mention'
//             >
//               <Mention
//                 trigger={trigger}
//                 data={suggestionData as SuggestionData[]}
//                 appendSpaceOnAdd
//                 renderSuggestion={entry => {
//                   return entry?.display;
//                 }}
//                 onAdd={(id, display) => {
//                   onSelectMention?.(id);
//                 }}
//               />
//             </MentionsInput>
//           ) : (
//             <Component
//               ref={ref}
//               data-code={code}
//               aria-label={ariaLabel}
//               className={`form-control ${readOnly ? (prepend ? "w-[22px]" : "") : "flex-1"
//                 } ${center && "text-center"} ${handleHeight}`}
//               placeholder={!!placeholder ? translate(placeholder) || "" : ""}
//               readOnly={handleReadOnly}
//               value={handleValue}
//               onChange={handleChange}
//               inputMode={handleInputMode}
//               type={handleType}
//               onPaste={onPaste}
//               onKeyPress={onKeyPress}
//               onKeyDown={(e: any) => {
//                 if (e.key === "Enter") {
//                   onPressEnter(e);
//                 }
//               }}
//             />
//           )}

//           {append}
//           {isPrice && !hiddenPriceSymbol && (
//             <span className="input-group-text text-lg">&euro;</span>
//           )}
//           {unit && (
//             <span className="input-group-text">
//               <Text>{unit}</Text>
//             </span>
//           )}
//           {isColor && (
//             <span className="input-group-text">
//               <div
//                 style={{ backgroundColor: handleValue || "#000" }}
//                 className="h-8 w-10 overflow-hidden rounded border border-gray-500 transition-colors"
//               >
//                 <input
//                   type="color"
//                   className="h-full w-full cursor-pointer overflow-hidden opacity-0"
//                   defaultValue={handleValue || "#000000"}
//                   onChange={e => {
//                     debounce(() => handleChange(e), 250);
//                   }}
//                 />
//               </div>
//             </span>
//           )}
//           {!!rules.length && !label && (
//             <i className="bi bi-asterisk input-group-text text-secondary text-xs" />
//           )}
//         </div>
//         {helperText && (
//           <div className="m-0 !mt-2">
//             <span className="text-body-2 font-normal text-gray-500">
//               <Text>{helperText}</Text>
//             </span>
//           </div>
//         )}
//         {hasError && (
//           <p className="mt-1 text-xs text-danger">
//             <i className="bi bi-info-circle mr-1" />
//             <Text>{errorMessage}</Text>
//           </p>
//         )}
//       </div>
//     </React.Fragment>
//   );
// }

// {isDate && (
//   <Modal
//     modalClassName="z-40"
//     isOpen={isOpenCalendar}
//     toggle={toggleCalendar}
//   >
//     {label && (
//       <Modal.Header>
//         <Text>{label}</Text>
//       </Modal.Header>
//     )}
//     <Modal.Body>
//       <Calendar
//         value={value}
//         // onlyMonthPicker={onlyMonth}
//         shadow={false}
//         className="mx-auto flex-center !border-0"
//         onChange={({ year, month, day }: any) => {
//           const items = [year, month.name, day];
//           const date = items.join("-");
//           const time = "07:00";
//           const value = new Date(`${date} ${time}`);
//           setValue(value);
//           toggleCalendar();
//         }}
//       />
//     </Modal.Body>
//     <Modal.Footer className="flex-center">
//       <Button light onClick={toggleCalendar}>
//         <Text>Back</Text>
//       </Button>
//     </Modal.Footer>
//   </Modal>
// )}
