import {DatePicker, InputGroup, Select} from "components";
import {rules} from "constant";
import {Fragment, useContext} from "react";
import {AddUserListContext} from "..";

export default function Basic() {
  const {data, setData} = useContext<any>(AddUserListContext);

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  return (
    <Fragment>
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        <InputGroup
          label="configurations.userManagment.userList.firstName"
          value={data.firstName}
          setValue={handleSetData("firstName")}
          rules={rules.required}
        />
        <InputGroup
          label="configurations.userManagment.userList.lastName"
          value={data.lastName}
          setValue={handleSetData("lastName")}
          rules={rules.required}
        />
        {/* <InputGroup
          label="configurations.userManagment.userList.personalNumber"
          value={data.personalNumber}
          setValue={handleSetData("personalNumber")}
        /> */}
        <InputGroup
          label="configurations.userManagment.userList.emailAddress"
          value={data.emailAddress}
          setValue={handleSetData("emailAddress")}
          rules={rules.emailRequired}
        />
        <InputGroup
          label="configurations.userManagment.userList.alternativeEmailAddress"
          value={data.alternativeEmailAddress}
          setValue={handleSetData("alternativeEmailAddress")}
          rules={rules.emailAddress}
        />
        <Select
          label="configurations.userManagment.userList.gender"
          items={[
            {id: 0, name: "global.gender.rather"},
            {id: 1, name: "global.gender.male"},
            {id: 2, name: "global.gender.female"},
          ]}
          value={data.gender}
          setValue={handleSetData("gender")}
        />
        <DatePicker
          label="configurations.userManagment.userList.dateOfBirth"
          showYearDropdown
          yearDropdownItemNumber={30}
          maxDate={new Date()}
          showMonthDropdown
          value={data?.dateOfBirth}
          onChange={handleSetData("dateOfBirth")}
        />
        <InputGroup
          label="configurations.userManagment.userList.phoneNumber"
          value={data.phoneNumber}
          setValue={handleSetData("phoneNumber")}
        />
        <InputGroup
          label="configurations.userManagment.userList.mobileNumber"
          value={data.mobileNumber}
          setValue={handleSetData("mobileNumber")}
        />
      </div>
    </Fragment>
  );
}
