import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  Icon,
  Tabs,
  Text,
  Wrapper,
  Image,
  Skeleton,
  DuplicateModal,
} from "components";
import {useDataState, useSelector, useToggleState} from "hooks";
import {createContext, createElement, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {ServiceApi, URLS} from "services";
import {priceFormatter} from "utils";
import Share from "./drawers/share";
import Info from "./info";
import AttributesTab from "./tabs/attributes";
import CategoriesTab from "./tabs/categories";
import ChannelsTab from "./tabs/channels";
import GeneralTab from "./tabs/general";
import LocationsTab from "./tabs/locations";
import MediaTab from "./tabs/media";
import PricesTab from "./tabs/prices";
import ShippingLogestic from "./tabs/shipping-logestic";
import ShippingTab from "./tabs/shipping-location-logestic";
import NotFound from "assets/image/404.svg";
import PriceCalculation from "./tabs/priceCalculation";
import ItemsTab from "./tabs/items";
import {title} from "process";
import {isEmpty} from "lodash";
import AvailabilityStatus from "./tabs/availability-status/availability-status";
import SalesPersonCommission from "./tabs/salesPersonCommission";
import {
  useCategoriesQuery,
  usePimProductDetailsQuery,
  useUpdateProductMutation,
} from "services/caching";
import {ProductService} from "types";

export const PimContext = createContext<any>({});

export default function PimProductDetails() {
  const {productId} = useParams();
  const navigate = useNavigate();
  const [updateProduct, {isLoading}] = useUpdateProductMutation();
  const submitLoading = isLoading;
  const channels = useSelector(s => s.channels);
  const [totalVks, setTotalVks] = useState([]);
  const [data, setData, setBaseData, isChanged, baseData] = useDataState<any>(
    {},
  );
  const [patchData, setPatchData] = useState({});
  const [channelsList, setChannels] = useState([]);
  const [share, shareToggle] = useToggleState();
  const [copy, copyToggle] = useToggleState();
  const [notFound, toggleNotFound] = useToggleState();
  const [attributeChanged, setAttributeChanged] = useState(false);
  const [productCategory, setProductCategory] = useState<
    ProductService.ProductCategoryQueryDto[]
  >([]);
  const [brandId, setBrandId] = useState("");
  const [ids, setIds] = useState<string[]>([]);
  const [overwrittenPrices, setOverwrittenPrices] = useState<string[]>([]);
  const [status, toggleStatus] = useToggleState();
  const [duplicateLoading, toggleDuplicateLoading] = useToggleState();

  const {
    data: pimData,
    isLoading: isLoadingData,
    isFetching,
    error,
    refetch,
  } = usePimProductDetailsQuery(productId);

  if (error) {
    if (typeof error === "object" && "status" in error) {
      if (error.status === 418) {
        toggleNotFound();
      }
    } else {
      console.error("Unexpected Error:", error);
    }
  }

  const {data: categoriesData} = useCategoriesQuery();

  const loading = isLoadingData || isFetching;

  useEffect(() => {
    if (!!pimData) {
      setBaseData(pimData);
      setPatchData({});
    }
  }, [pimData]);

  useEffect(() => {
    if (!!categoriesData) {
      setProductCategory(categoriesData);
    }
  }, [categoriesData]);

  const tabs = [
    {
      label: "productManagement.products.Details.generalTab",
      component: GeneralTab,
    },
    ...(data?.pimProductType
      ? [
          {
            label: "items",
            component: ItemsTab,
          },
        ]
      : [
          {
            label: "productManagement.products.Details.pricesTab",
            component: PricesTab,
          },
        ]),
    {
      label: "productManagement.products.Details.attributesTab",
      component: AttributesTab,
    },
    {
      label: "productManagement.products.Details.mediaTab",
      component: MediaTab,
      permission: ["PS_GetPimProductMedia"],
    },
    {
      label: "productManagement.products.Details.categoriesTab",
      component: CategoriesTab,
    },
    {
      label: "productManagement.products.Details.shippingLogestic",
      component: ShippingTab,
    },
    {
      label: "productManagement.products.Details.channelsTab",
      component: ChannelsTab,
    },
    {
      label: "productManagement.products.Details.availabilityStatusTab",
      component: AvailabilityStatus,
    },
  ];
  const [activeKey, setActiveKey] = useState<string>();

  const getChannels = () => {
    const clone: any = [{code: "CH000D", title: "default"}, ...channels];
    setChannels(clone);
  };

  useEffect(() => {
    getChannels();
    setActiveKey(tabs[0].label);
  }, [productId, channels]);

  const editProduct = async () => {
    const patch = {
      originalName: data.originalName,
      supplierArticleNumber: data.supplierArticleNumber,
      supplierId: data.supplierId,
      supplierProgramId: data.supplierProgramId,
      price: priceFormatter(data.price?.amount),
      purchasePrice: priceFormatter(data.purchasePrice?.amount),
      uvpPrice: data.uvpPrice?.amount,
      endOfLifeDate: data.endOfLifeDate,
      pimProductGroupId: data.pimProductGroupId,
      purchasePriceType: data.purchasePriceType,
      noDiscount: data.noDiscount,
      noPromotion: data.noPromotion,
    };
    try {
      await updateProduct({data: patch, id: productId}).unwrap();
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title",
      );
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      const ids = data?.bundleItems?.map((bundle: any) => bundle.id);
      setIds(ids);
      getOverwrittenPrices();
    }
  }, [data]);

  const getOverwrittenPrices = () => {
    const clone: any = [];
    toggleStatus();
    const urls = data?.bundleItems?.map((item: any) => {
      const body = {
        productId: data?.id,
        supplierId: item.supplierId,
        programId: item.supplierProgramId,
        brandId: item.brandId,
        basePrice: item.purchasePrice?.amount,
        purchasePriceType: item.purchasePriceType,
      };
      return ServiceApi.post(URLS.CALC_PIM_SALE_PRICE, body);
    });
    Promise.all(urls)
      .then(([...responses]) => {
        responses.map((res: any) => clone.push(res?.data));
      })
      .finally(() => {
        setOverwrittenPrices([...clone]);
        const totalVks = data?.bundleItems?.map((item: any, index: number) => {
          return {
            vk1: clone[index]?.vk1 * item.quantity,
            vk2: clone[index]?.vk2 * item.quantity,
            linePrice: item.overwrittenSalePrice
              ? item.overwrittenSalePrice.amount * item.quantity
              : clone[index]?.vk1 * item.quantity,
          };
        });
        setTotalVks(totalVks);
        toggleStatus();
      });
  };

  useEffect(() => {}, [attributeChanged]);

  const onDuplicate = (product: any) => {
    toggleDuplicateLoading();
    const url = URLS.POST_CLONE_PRODUCT(product.id);
    ServiceApi.post(url)
      .then(({data}) => {
        NotificationManager.success(
          "global.toast.duplicate-msg",
          "global.toast.duplicate-title",
        );
        toggleDuplicateLoading();
        copyToggle();
        if (data) {
          navigate(`/productManagement/products/all-products/${data}`);
        }
      })
      .catch(() => toggleDuplicateLoading());
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div>
          <Breadcrumb backward />
        </div>
        <Button
          size="sm"
          variant={"white"}
          onClick={copyToggle}
          disabled={loading}
        >
          <Icon icon="copy" className="mr-2" />
          <span>
            <Text>Duplicate product</Text>
          </span>
        </Button>
      </div>
      {
        <>
          {notFound ? (
            <div className="flex-center !mt-12 flex h-full w-full">
              <Image src={NotFound} className="h-[297px] w-[267px]" />
            </div>
          ) : (
            <>
              <Tabs
                activeKey={activeKey}
                checkSave={isChanged || attributeChanged}
                onSave={() => {
                  setData(baseData);
                  setAttributeChanged(false);
                }}
              >
                <div className="space-y-6">
                  <Wrapper>
                    <Wrapper.Body className="flex flex-wrap justify-between gap-3">
                      <Info
                        productData={data}
                        setData={setData}
                        setPatchData={setPatchData}
                        loading={loading}
                        getPIMProduct={refetch}
                        totalVks={totalVks}
                        activeKey={activeKey}
                        status={status}
                      />
                    </Wrapper.Body>
                    <Wrapper.Footer className="!py-0">
                      {loading ? (
                        <div className="flex w-full gap-2 p-4">
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                        </div>
                      ) : (
                        <Tabs.ButtonGroup>
                          {tabs.map(e => (
                            <Tabs.Button
                              key={e.label}
                              eventKey={e.label}
                              disabled={loading}
                              onClick={() => setActiveKey(e.label)}
                            >
                              <Text>{e.label}</Text>
                            </Tabs.Button>
                          ))}
                        </Tabs.ButtonGroup>
                      )}
                    </Wrapper.Footer>
                  </Wrapper>
                  {tabs.map(e => (
                    <PimContext.Provider
                      value={{
                        productData: data,
                        setData,
                        baseData,
                        getPIMProduct: refetch,
                        loading,
                        channels: channelsList,
                        isChanged,
                        editProduct,
                        submitLoading,
                        setAttributeChanged,
                        setPatchData,
                        productCategory,
                        attributeChanged,
                        setBrandId,
                        brandId,
                        totalVks,
                        setTotalVks,
                        ids,
                        overwrittenPrices,
                        status,
                      }}
                    >
                      <Tabs.Item key={e.label} eventKey={e.label}>
                        {createElement(e.component)}
                      </Tabs.Item>
                    </PimContext.Provider>
                  ))}
                </div>
              </Tabs>
              <Share isOpen={share} toggle={shareToggle} id={productId} />
              <DuplicateModal
                isOpen={copy}
                toggle={copyToggle}
                selected={{
                  name: data?.originalName,
                  product: data,
                }}
                loading={duplicateLoading}
                onConfirm={onDuplicate}
              />
            </>
          )}
        </>
      }
    </div>
  );
}
