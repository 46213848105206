import {URLS} from "services";
import MainApi from "services/caching/mainApi";
import {AccountService} from "types";

export const roleSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    roleList: builder.query<AccountService.RoleDtoPagedDto, any>({
      query: params => ({
        url: URLS.GET_ROLES_URL,
        params: {...params},
      }),
      keepUnusedDataFor: 3600,
      providesTags: ["ROLE-LIST"],
    }),
    roleDetails: builder.query<AccountService.RoleDto, any>({
      query: roleId => ({
        url: URLS.GET_DETAILS_ROLES_URL(roleId),
      }),
    }),
    createRole: builder.mutation<AccountService.CreateRoleRequest, any>({
      query: data => ({
        url: URLS.ADD_ROLES_URL,
        method: "POST",
        data,
      }),
      invalidatesTags: ["ROLE-LIST"],
    }),
    updateRole: builder.mutation<AccountService.CreateRoleRequest, any>({
      query: ({data, id}) => ({
        url: URLS.GET_DETAILS_ROLES_URL(id),
        method: "PUT",
        data,
      }),
      invalidatesTags: ["ROLE-LIST"],
    }),
  }),
});

export const {
  useRoleListQuery,
  useCreateRoleMutation,
  useRoleDetailsQuery,
  useUpdateRoleMutation,
} = roleSlice;
