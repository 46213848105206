import {NotificationManager} from "common/notifications";
import {
  Accordion,
  Button,
  CheckBox,
  Drawer,
  Icon,
  InputGroup,
  Select,
  Skeleton,
  Text,
  HandleName,
} from "components";
import {useToggleState} from "hooks";
import {isEmpty} from "lodash";
import {useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {useTranslation} from "react-i18next";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  productData: any;
  setData: any;
  setPatchData: any;
  getPIMProduct: any;
  productCategory: any;
};

const AssignCategoryDrawer = ({
  isOpen,
  toggle,
  productData,
  getPIMProduct,
  productCategory,
}: drawerProps) => {
  const {i18n} = useTranslation();
  const [loadChildren, setChildrenLoading] = useToggleState();
  const [submitLoading, toggleSubmit] = useToggleState();
  const [roots, setRoots] = useState([{id: "", name: ""}]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [categoryChildrenList, setCategoryChildrenList] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [publishedCategory, setPublishedCategory] = useState<any>({});
  const [categoryData, setCategoryData] = useState<any>();
  const [categoryIds, setCategoryIds] = useState<any>();

  useEffect(() => {
    const roots = productCategory?.filter(
      (category: any) => !category.parentId,
    );
    let rootsItem = roots?.map((category: any) => {
      const label = category?.translates?.find(function (item: any) {
        return (
          i18n.language?.toLocaleLowerCase() ===
            item.language?.toLocaleLowerCase() && item
        );
      });

      return {
        id: category.productCategoryId,
        name:
          label?.labelTitle || `No name in ${i18n.language} [${category.code}]`,
      };
    });
    const ids = productData?.pimProductCategories?.map(
      (category: any) => category.id,
    );
    setRoots(rootsItem);
    setCategoryData(productCategory);
    setCategoryIds(ids);
    setPublishedCategory({});
  }, [productCategory, i18n.language]);

  useEffect(() => {
    setSelectedCategory("");
  }, [isOpen]);

  const findChildrens = (data: any, id: string, parent: any) => {
    const parentCopy = {...parent};
    const childrens = data.filter((item: any) => item.parentId === id);
    if (childrens.length > 0) {
      parentCopy.items = childrens;
      childrens.map((category: any) => {
        findChildrens(data, category.productCategoryId, category);
      });
    }
    return [parentCopy];
  };

  const getCategoryChildren = (categoryId: string) => {
    let parent = categoryData.find(
      (item: any) => item.productCategoryId === categoryId,
    );
    const categoryList = findChildrens(categoryData, categoryId, parent);
    setCategoryList(categoryList);
  };

  const onSelectCategoryHandler = (categoryId: string) => {
    setSelectedCategory(categoryId);
    getCategoryChildren(categoryId);
  };
  const createCategoryAccordion = (categoryArr: any) => {
    return categoryArr?.map((category: any) => {
      if (category) {
        return (
          <Accordion key={category?.code}>
            <Accordion.Item
              className={`mb-4 rounded-2xl border bg-white px-4 shadow-nested`}
            >
              <Accordion.Toggle className="flex items-center justify-between">
                <span className="text-heading-6 font-medium">
                  {category?.items && (
                    <Icon
                      icon={"caret-right"}
                      className="mr-2 transition-transform group-[.accordion-item.active]:rotate-90"
                    />
                  )}
                  {
                    <HandleName
                      translations={category?.translates}
                      code={category?.code}
                      keyName="labelTitle"
                    />
                  }
                </span>
                <p className="text-body-2 font-medium">{category?.code}</p>
                <div>
                  <CheckBox
                    key={`checkbox_${category?.code}`}
                    disabled={categoryIds?.includes(
                      category?.productCategoryId,
                    )}
                    value={
                      publishedCategory.productCategoryId ===
                        category?.productCategoryId ||
                      categoryIds?.includes(category?.productCategoryId)
                    }
                    setValue={e => {
                      if (e) {
                        setPublishedCategory(category);
                      } else {
                        setPublishedCategory({});
                      }
                    }}
                  />
                </div>
              </Accordion.Toggle>
              {category?.items && (
                <>
                  <Accordion.Body className="px-4">
                    {createCategoryAccordion(category?.items)}
                  </Accordion.Body>
                </>
              )}
            </Accordion.Item>
          </Accordion>
        );
      }
      return null;
    });
  };

  const onSubmit = () => {
    toggleSubmit();
    const url = URLS.ADD_CATEGORY_TO_PIM(productData.id);
    ServiceApi.post(url, {
      productCategoryId: publishedCategory.productCategoryId,
    }).then(() => {
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title",
      );
      getPIMProduct();
      toggleSubmit();
    });
  };

  useEffect(() => {
    if (categoryChildrenList) {
      let clone: any = [];
      clone = categoryChildrenList?.filter((category: any) => {
        const name = category.translations?.[0].name
          ? category.translations?.[0].name.toLowerCase()
          : "";
        const code = category.code.toLowerCase();
        if (name?.includes(searchValue) || code?.includes(searchValue)) {
          return category;
        }
      });
      if (clone.length === categoryChildrenList?.length) {
        let parent = categoryChildrenList.find(
          (item: any) => item.parentId === null,
        );
        const categoryList = findChildrens(
          categoryChildrenList,
          parent?.productCategoryId,
          parent,
        );
        setCategoryList(categoryList);
      } else {
        setCategoryList(clone);
      }
    }
  }, [searchValue]);

  const onSearchHandler = (e: string) => {
    setSearchValue(e);
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle} size={"lg"}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.products.Details.assignCategory</Text>
            </span>
            <div className="flex gap-x-2">
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-6 pr-4">
          <div className="space-y-2">
            <Select
              label="productManagement.products.Details.productCategory"
              placeholder="productManagement.products.Details.selectCategory"
              items={roots}
              value={selectedCategory}
              setValue={onSelectCategoryHandler}
            />
            {categoryChildrenList.length > 0 && (
              <div className="grid grid-cols-2 items-center gap-4">
                <InputGroup
                  value={searchValue}
                  setValue={onSearchHandler}
                  placeholder="productManagement.products.Details.searchCategory"
                  disabled={loadChildren}
                />
                <p className="text-body-2">
                  {searchValue.length === 0
                    ? categoryChildrenList.length
                    : categoryList.length}{" "}
                  <Text>global.items</Text>
                </p>
              </div>
            )}
          </div>
          {selectedCategory && (
            <div className="space-y-4">
              {createCategoryAccordion(categoryList)}
            </div>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex justify-end">
          <Button
            size="sm"
            onClick={onSubmit}
            disabled={isEmpty(publishedCategory)}
            loading={submitLoading}
          >
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
};

export default AssignCategoryDrawer;
