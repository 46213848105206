export const completeness = {
  addCheckList: "Checkliste hinzufügen",
  checkListDetails: "Checklisten-Details",
  title: "Titel",
  shortDescription: "Kurzbeschreibung",
  active: "Aktiv",
  type: "Typ",
  subject: "Betreff",
  description: "Beschreibung",
  descriptionPlaceHolder: "Fügen Sie eine Beschreibung hinzu...",
  by: "Von",
  assignToItem: "Zu Element zuweisen",
  assignedCheckListItem: "Zugewiesenes Checklisten-Element",
  attachements: "Anhänge",
  replies: "Antworten",
  replyTo: "Antworten an:",
  hideReply: "Antwort verbergen",
  hideReplies: "Antworten verbergen",
  checkListItems: "Checklisten-Elemente: ",
  newest: "Neueste",
  oldest: "Älteste",
  person: "Person",
  checkListItem: "Checklisten-Element",
  sortBy: "Sortieren nach",
  repliesTo: "Antwort an",
  closeReplies: "Antwort schliessen",
  completed: "komplett",
  inprocess: "in Bearbeitung",
};
