export const channelMessage = [
  {
    name: "global.all",
    id: "",
  },
  {
    name: "crmchannel",
    id: "crmchannel",
  },
  {
    name: "erpchannel",
    id: "erpchannel",
  },
  {
    name: "onlineshopchannel",
    id: "onlineshopchannel",
  },
];
