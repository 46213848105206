import {useState, useContext, useMemo} from "react";
import {Wrapper, Icon, Text, Button, DeleteModal} from "components";
import {ServiceApi, URLS} from "services";
import AddShippingMethods from "../drawers/shipping-methods";
import {useToggleState} from "hooks";
import {PimContext} from "..";
import {NotificationManager} from "common/notifications";
import {useShippingMethodsQuery} from "services/caching";

type pimProductShippingMethodsItems = {
  id: string;
  code: string;
  title: string;
};

export default function ShippingLogestic() {
  const {productData, getPIMProduct} = useContext(PimContext);
  const [selectedShipping, setSelectedShipping] =
    useState<pimProductShippingMethodsItems>();
  const [isOpen, toggle] = useToggleState();
  const [isOpenDelete, toggleDelete] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();

  const {data: shippingList} = useShippingMethodsQuery();

  const onDeleteHandler = () => {
    setDeleteLoading();
    const url = URLS.DELETE_SHIPPING_METHOD_FROM_PIM(productData?.id);
    const body = {
      shippingMethodId: selectedShipping?.id,
    };
    ServiceApi.post(url, body).then(() => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title",
      );
      toggleDelete();
      setDeleteLoading();
      getPIMProduct();
    });
  };

  const ids = useMemo(() => {
    return productData?.pimProductShippingMethods?.map(
      (item: pimProductShippingMethodsItems) => item.id,
    );
  }, [productData?.pimProductShippingMethods]);

  return (
    <div className="space-y-4">
      <Wrapper>
        <Wrapper.Header>
          <Text>productManagement.products.Details.shipping</Text>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            {productData?.pimProductShippingMethods?.map(
              (item: pimProductShippingMethodsItems) => {
                return (
                  <div className="flex items-center justify-between rounded bg-white p-4 shadow-card">
                    <div className="flex flex-col space-y-4" key={item.id}>
                      <span>{item.title}</span>
                      <span className="text-sm text-gray-500">{item.code}</span>
                    </div>
                    <Button
                      variant={"danger"}
                      light
                      size="sm"
                      onClick={() => {
                        setSelectedShipping(item);
                        toggleDelete();
                      }}
                    >
                      <Icon icon="trash" />
                    </Button>
                  </div>
                );
              },
            )}
          </div>
          <div
            onClick={toggle}
            className="flex-center flex h-[52px] cursor-pointer select-none gap-4 rounded-xl border border-dashed border-primary bg-gray-100 text-primary"
          >
            <Icon
              icon="plus"
              size="xs"
              className="rounded-md bg-primary-light p-2"
            />
            <h5 className="text-primary">
              <Text>productManagement.products.Details.addShipping</Text>
            </h5>
          </div>
        </Wrapper.Body>
      </Wrapper>
      <AddShippingMethods
        isOpen={isOpen}
        toggle={toggle}
        shippingList={shippingList}
        pimShippingIds={ids}
      />
      <DeleteModal
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        loading={deleteLoading}
        selected={{name: selectedShipping?.title}}
        onConfirm={onDeleteHandler}
      />
    </div>
  );
}
