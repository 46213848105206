import {Fragment, useState} from "react";
import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
} from "components";
import {useSearchParams} from "react-router-dom";
import {usePrinterDefinitionQuery} from "services/caching";
import {useToggleState} from "hooks";
import CreateDrawer from "./create";
import EditDrawer from "./edit";
import {AssetService} from "types";

type Params = {
  keyword?: string;
  pageNumber?: string;
  pageSize?: string;
};

export default function PrinterDefinition() {
  const initParams: Params = {
    pageNumber: "1",
    pageSize: "10",
  };

  const [params, setParams] = useSearchParams(initParams);
  const queryParams = Object.fromEntries(params.entries());
  const [isOpen, toggle] = useToggleState();
  const [isOpenEdit, toggleEdit] = useToggleState();
  const [selectedPrinter, dispatchPrinter] =
    useState<AssetService.PrinterDefinitionQueryDetailDto>();

  const handleSetParams = (key: keyof Params) => {
    const isPageNumber = key === "pageNumber";
    return (value: string | number | null) => {
      setParams(p => {
        const hasValue = !!String(value ?? "");
        hasValue ? p.set(key, `${value}`) : p.delete(key);
        !isPageNumber && p.set("pageNumber", `1`);
        return p;
      });
    };
  };

  const {data, isLoading, isFetching, refetch} =
    usePrinterDefinitionQuery(queryParams);

  const loading = isFetching || isLoading;

  const hasData = !!data?.items?.length;

  const isFilter = ["keyword"].some(item => params.get(item));

  const resetFilter = () => {
    setParams(initParams);
  };

  return (
    <div className="space-y-4">
      <div className="flex-between">
        <Breadcrumb />
        <Button size="sm" onClick={toggle} disabled={loading}>
          <Icon icon="plus" />
          <Text>configurations.printerDefinition.addPrinterDefinition</Text>
        </Button>
      </div>
      <div className="flex-between">
        <SearchBox
          value={params.get("keyword")}
          onSubmit={handleSetParams("keyword")}
          disabled={loading}
          loading={loading}
          totalItems={data?.totalItems}
          onReload={() => {
            refetch();
          }}
        />
        {isFilter && (
          <Button
            disabled={loading}
            variant={"white"}
            textColor="danger"
            size="sm"
            onClick={resetFilter}
          >
            <Icon icon="circle-xmark"></Icon>
            <span className="ml-1">
              <Text>global.buttons.resetFilters</Text>
            </span>
          </Button>
        )}
      </div>
      {loading ? (
        <>
          {[...Array(6)].map(() => (
            <Skeleton.List />
          ))}
        </>
      ) : (
        <>
          {hasData ? (
            <>
              <Table>
                {data?.items?.map(e => {
                  return (
                    <Fragment key={e.id}>
                      <tr className="*:text-left">
                        <td className="space-y-2">
                          <h6 className="truncate text-heading-6 text-gray-800">
                            {e.name}
                          </h6>
                          <div className="space-y-2">
                            <p className="text-body-2 text-gray-600">
                              <Text>configurations.printerDefinition.ip</Text>:{" "}
                              <span>{e.ip ?? "----------"}</span>
                            </p>
                            <p className="text-body-2 text-gray-600">
                              <Text>configurations.printerDefinition.port</Text>
                              : <span>{e.port ?? "----------"}</span>
                            </p>
                          </div>
                        </td>
                        <td className="space-y-2">
                          <p className="text-body-2 text-gray-600">
                            <Text>
                              configurations.printerDefinition.protocol
                            </Text>
                            : <span>{e.protocol ?? "----------"}</span>
                          </p>
                          <p className="text-body-2 text-gray-600">
                            <Text>
                              configurations.printerDefinition.location
                            </Text>
                            : <span>{e.location ?? "----------"}</span>
                          </p>
                          <p className="text-body-2 text-gray-600">
                            <Text>
                              configurations.printerDefinition.channelCode
                            </Text>
                            : <span>{e.channelCode ?? "----------"}</span>
                          </p>
                        </td>
                        <td>
                          <Button
                            size="sm"
                            variant={"light"}
                            onClick={() => {
                              dispatchPrinter(e);
                              toggleEdit();
                            }}
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={+(params.get("pageNumber") ?? 1)}
                pageSize={+(params.get("pageSize") ?? "10")}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={handleSetParams("pageSize")}
              />
            </>
          ) : (
            <NoItems />
          )}
        </>
      )}
      <CreateDrawer isOpen={isOpen} toggle={toggle} />
      <EditDrawer
        isOpen={isOpenEdit}
        toggle={toggleEdit}
        printer={selectedPrinter}
      />
    </div>
  );
}
