import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
  Status,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {usePermission, useToggleState} from "hooks";
import {useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import PublishDrawer from "./publish-deawer";
import {useSuppliersQuery} from "services/caching";

type Params = {
  keyword?: string;
  pageNumber?: string;
  pageSize?: string;
  supplierType?: string;
};

function SuppliersList() {
  const supplierType = [
    {
      label: "All",
      value: "",
    },
    {
      label: "TradingGoods",
      value: 0,
    },
    {
      label: "ConsumableGoods",
      value: 1,
    },
  ];

  const [isOpen, toggle] = useToggleState();

  //original filter data for reset
  const originalFilterData: Params = {
    pageNumber: "1",
    pageSize: "10",
    keyword: "",
    supplierType: "",
  };

  const [params, setParams] = useSearchParams(originalFilterData);
  const [filterType, setType] = useState("");
  const queryParams = Object.fromEntries(params.entries());

  const {data, isLoading, isFetching, refetch} = useSuppliersQuery(queryParams);
  const loadingList = isLoading || isFetching;

  const handleSetParams = (key: keyof Params) => {
    const isPageNumber = key === "pageNumber";
    return (value: string | number | null) => {
      setParams(p => {
        const hasValue = !!String(value ?? "");
        hasValue ? p.set(key, `${value}`) : p.delete(key);
        !isPageNumber && p.set("pageNumber", `1`);
        return p;
      });
    };
  };

  const getDetailsPermission = usePermission("PS_GetSupplier");

  return (
    <>
      <div className="space-y-4">
        <div className="flex items-center">
          <div className="flex-1">
            <h3 className="text-heading-3">
              <Breadcrumb />
            </h3>
          </div>
          <div className="flex items-center gap-2">
            {loadingList ? (
              <Skeleton.Button />
            ) : (
              <Button
                size="sm"
                variant={"white"}
                textColor="primary"
                onClick={toggle}
              >
                <Text>
                  productManagement.masterData.suppliers.publishAllSuppliers
                </Text>
              </Button>
            )}
            <WithPermission permissions={["PS_CreateSupplier"]}>
              {loadingList ? (
                <Skeleton.Button />
              ) : (
                <Button
                  size="sm"
                  as={Link}
                  to="new"
                  aria-label="cy-create-supplier"
                >
                  <Icon className="mr-2" size="sm" icon={"plus"} />
                  <Text>
                    productManagement.masterData.suppliers.addSupplier
                  </Text>
                </Button>
              )}
            </WithPermission>
          </div>
        </div>
        <SearchBox
          value={params?.get("keyword") || ""}
          onSubmit={handleSetParams("keyword")}
          disabled={loadingList}
          totalItems={data?.totalItems}
          onReload={refetch}
          loading={loadingList}
        />
        <div className="flex items-center gap-4">
          {supplierType?.map((type: any, index) => (
            <span
              key={index}
              className={`min-w-14 cursor-pointer rounded-full px-4 py-3 text-center text-heading-6 font-semibold ${
                filterType === type.value
                  ? "bg-primary text-white"
                  : "bg-white text-gray-500"
              }`}
              onClick={() => {
                handleSetParams("supplierType")(type.value);
                setType(type.value);
              }}
            >
              <Text>{type.label}</Text>
            </span>
          ))}
        </div>
        {loadingList ? (
          <>
            <Skeleton.List />
            <Skeleton.List />
            <Skeleton.List />
            <Skeleton.List />
          </>
        ) : (
          <>
            {data &&
            Object.keys(data).length !== 0 &&
            data?.items?.length !== 0 ? (
              <>
                <Table>
                  {data?.items?.map(item => {
                    return (
                      <tr key={item.supplierId}>
                        <td className="w-[500px]">
                          <Link
                            to={
                              getDetailsPermission
                                ? (item.supplierId as string)
                                : ""
                            }
                            className="flex-center inline-flex w-fit gap-2"
                            replace={!getDetailsPermission}
                          >
                            <Table.Image
                              src={item?.logo?.thumbnailUrl}
                              alt={item.name as string}
                            />
                            <div className="space-y-2">
                              <h6 className="text-heading-6">{item.name}</h6>
                              {item.code ? (
                                <p className="text-body-2 font-medium text-gray-500">
                                  <Text>
                                    productManagement.masterData.suppliers.code
                                  </Text>{" "}
                                  : {item.code}
                                </p>
                              ) : (
                                ""
                              )}

                              <p className="text-body-2 font-medium text-gray-500">
                                <Text>
                                  productManagement.masterData.suppliers.iln
                                </Text>
                                :
                                <span className="ml-1">
                                  {item.iln ? item.iln : "----------"}
                                </span>
                              </p>
                            </div>
                          </Link>
                        </td>
                        <td>
                          <Status.SupplierType
                            id={item?.supplierType as number}
                          />
                        </td>
                        <td>
                          <WithPermission permissions={["PS_GetSupplier"]}>
                            <Button
                              as={Link}
                              to={item.supplierId}
                              variant="light"
                              size="sm"
                            >
                              <Text>global.buttons.details</Text>
                            </Button>
                          </WithPermission>
                        </td>
                      </tr>
                    );
                  })}
                </Table>

                <Pagination
                  totalItems={data?.totalItems}
                  totalPages={data?.totalPages}
                  pageNumber={
                    params?.get("pageNumber")
                      ? parseInt(params?.get("pageNumber") as string)
                      : 1
                  }
                  pageSize={
                    params?.get("pageSize")
                      ? parseInt(params?.get("pageSize") as string)
                      : config.pageSize
                  }
                  setActivePage={handleSetParams("pageNumber")}
                  onPageSizeChange={(e: any) =>
                    setParams(p => ({...p, pageSize: e}))
                  }
                />
              </>
            ) : (
              <div className="flex w-full flex-col items-center space-y-4">
                <NoItems />
              </div>
            )}
          </>
        )}
      </div>
      <PublishDrawer isOpen={isOpen} toggle={toggle} />
    </>
  );
}
export default withPermission(SuppliersList, ["PS_GetSupplier"]);
