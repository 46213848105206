import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {usePermission, useToggleState} from "hooks";
import {Link, useSearchParams} from "react-router-dom";
import InviteSearchModal from "./invite/invite-search-modal";
import {useUserListQuery} from "services/caching";

type Params = {
  keyword?: string;
  pageNumber?: string;
  pageSize?: string;
  userGroupId?: string;
};

function UserList() {
  const [isOpen, toggle] = useToggleState(false);
  //original filter data for reset
  const originalFilterData: Params = {
    pageNumber: "1",
    userGroupId: "",
    pageSize: String(config.pageSize),
    keyword: "",
  };

  const [params, setParams] = useSearchParams(originalFilterData);
  const queryParams = Object.fromEntries(params.entries());

  const {data, isLoading, isFetching, refetch} = useUserListQuery(queryParams);

  const loadingList = isFetching || isLoading;

  const handleSetParams = (key: keyof Params) => {
    const isPageNumber = key === "pageNumber";
    return (value: string | number | null) => {
      setParams(p => {
        const hasValue = !!String(value ?? "");
        hasValue ? p.set(key, `${value}`) : p.delete(key);
        !isPageNumber && p.set("pageNumber", `1`);
        return p;
      });
    };
  };

  const isFilter = ["keyword", "userGroupId"].some(item => params.get(item));

  const getDetailPermission = usePermission("AS_GetUser");

  const resetFilter = () => {
    setParams(originalFilterData);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <WithPermission permissions={["AS_InviteUserIdP"]}>
            {loadingList ? (
              <Skeleton.Button />
            ) : (
              <Button
                type="button"
                variant="white"
                textColor="primary"
                size="sm"
                onClick={() => toggle()}
              >
                <Icon className="mr-2" icon={"plus"} />
                <Text>configurations.userManagment.userList.inviteUser</Text>
              </Button>
            )}
          </WithPermission>
          <WithPermission permissions={["AS_CreateUser"]}>
            {loadingList ? (
              <Skeleton.Button />
            ) : (
              <Button type="button" as={Link} to="new" size="sm">
                <Icon className="mr-2" icon={"plus"} />
                <Text>configurations.userManagment.userList.addUser</Text>
              </Button>
            )}
          </WithPermission>
        </div>
      </div>
      <div className="flex-between">
        <SearchBox
          value={params?.get("keyword") || ""}
          onSubmit={handleSetParams("keyword")}
          disabled={loadingList}
          totalItems={data?.totalItems}
          onReload={refetch}
          loading={loadingList}
        />
        {isFilter && (
          <Button
            disabled={loadingList}
            variant={"white"}
            textColor="danger"
            size="sm"
            onClick={resetFilter}
          >
            <Icon icon="circle-xmark"></Icon>
            <span className="ml-1">Reset Filter</span>
          </Button>
        )}
      </div>
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.userId}>
                      <td>
                        <Link
                          to={
                            getDetailPermission ? (item.userId as string) : ""
                          }
                          className="flex-center flex w-fit gap-2"
                          replace={!getDetailPermission}
                        >
                          <Table.Image
                            placeholder="user"
                            src={item?.avatar?.url}
                            alt={item.firstName as string}
                            className="h-[78px] w-[78px]"
                          />
                          <div className="space-y-2">
                            <div>
                              <h6 className="text-heading-6 font-semibold text-gray-800">{`${item.firstName} ${item.lastName}`}</h6>
                            </div>
                            <div className="flex items-center">
                              <Icon
                                className="mr-2 font-black text-gray-500 "
                                icon="envelope"
                                size="1x"
                              />
                              <span className="text-body-2 font-medium text-gray-500">
                                {item.emailAddress}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td>
                        <div className="text-left text-body-base  font-normal">
                          <span className="text-gray-800">
                            <Text>
                              configurations.userManagment.userList.group
                            </Text>
                          </span>
                          :
                          <span className="ml-1 text-gray-600">
                            {item.userGroupName ?? "----------"}
                          </span>
                        </div>
                      </td>
                      <td>
                        <Status.roles id={item.userType as number} />
                      </td>
                      <td>
                        <WithPermission permissions={["AS_GetUser"]}>
                          <Button
                            as={Link}
                            to={item.userId}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams(p => ({...p, pageSize: e}))
                }
              />
              <InviteSearchModal isOpen={isOpen} toggle={toggle} />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default withPermission(UserList, ["AS_GetUser"]);
