import {
  Button,
  Drawer,
  Icon,
  InputGroup,
  Text,
  Select as SelectComponent,
  RadioButton,
} from "components";
import {useTranslate} from "hooks";
import Select from "react-select";
import {config} from "constant";
import {useEffect, useState} from "react";
import {
  useBrandsQuery,
  useCategoriesQuery,
  usePimProductFamiliesQuery,
  useProgramListQuery,
  useSuppliersQuery,
} from "services/caching";
import {uniqBy} from "lodash";
import {getLabelByLanguage} from "utils";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  resetFilter: () => void;
  onChange: (key: any) => any;
  data: any;
  params: any;
  setParams: (key: any) => void;
  setKeyword: (key: any) => void;
  keyword: any;
  checkLists: {id: string; name: string}[];
};

const FilterDrawer = ({
  isOpen,
  data,
  params,
  toggle,
  onChange,
  resetFilter,
  setParams,
  setKeyword,
  keyword,
  checkLists,
}: drawerProps) => {
  const translate = useTranslate();
  const [suppliers, setsuppliers] = useState<any>([
    {value: null, label: translate("global.all")},
  ]);
  const [brands, setBrands] = useState<any>([
    {value: null, label: translate("global.all")},
  ]);
  const [programs, setPrograms] = useState<any>([]);
  const [productFamilies, setProductFamilies] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);

  const [options, setOptions] = useState<any>({
    brand: 1,
    supplier: 1,
  });

  const [supplierParams, setSupplierParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
    suppliertype: 0,
  });
  const [brandParams, setBrandParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    keyword: "",
  });

  //Supplier Service
  const {
    data: suppliersData,
    isLoading: isLoadingSupplier,
    isFetching: isFetchingSupplier,
  } = useSuppliersQuery(supplierParams, {skip: !isOpen});
  const loadingSupplier = isLoadingSupplier || isFetchingSupplier;

  useEffect(() => {
    if (!!suppliersData?.items) {
      const clone = suppliersData?.items?.map((item: any) => ({
        value: item.supplierId,
        label: item.name,
      }));
      const uniqueByValue = uniqBy([...suppliers, ...clone], "value");
      setsuppliers(uniqueByValue);
    }
  }, [suppliersData]);

  //Brands Service
  const {
    data: brandData,
    isLoading: isLoadingBrand,
    isFetching: isFetchingBrand,
  } = useBrandsQuery(brandParams, {skip: !isOpen});
  const loadingBrand = isLoadingBrand || isFetchingBrand;

  useEffect(() => {
    if (!!brandData?.items) {
      const clone = brandData?.items?.map((item: any) => ({
        value: item.brandId,
        label: item.name,
      }));
      const uniqueByValue = uniqBy([...brands, ...clone], "value");
      setBrands(uniqueByValue);
    }
  }, [brandData]);

  //Program Service
  const {
    data: programsData,
    isLoading: isLoadingProgram,
    isFetching: isFetchingProgram,
  } = useProgramListQuery(params?.supplierId, {
    skip: !params?.supplierId,
  });
  const loadingProgram = isLoadingProgram || isFetchingProgram;

  useEffect(() => {
    if (!!programsData) {
      const clone = programsData?.map((item: any) => ({
        value: item.id,
        label: item.title,
      }));
      clone?.unshift({value: null, label: translate("global.all")});
      const uniqueByValue = uniqBy(clone, "value");
      setPrograms(uniqueByValue);
    }
  }, [programsData]);

  //Categories Service
  const {
    data: categoriesData,
    isLoading: isLoadingCategory,
    isFetching: isFetchingCategory,
  } = useCategoriesQuery(undefined, {skip: !isOpen});
  const loadingCategory = isLoadingCategory || isFetchingCategory;

  useEffect(() => {
    if (!!categoriesData) {
      const clone = categoriesData?.map((item: any) => ({
        value: item.productCategoryId,
        label: getLabelByLanguage(item?.translates),
      }));
      clone?.unshift({value: null, label: translate("global.all")});
      const uniqueByValue = uniqBy(clone, "value");
      setCategories(uniqueByValue);
    }
  }, [categoriesData]);

  //Families Service
  const {
    data: productfamiliesData,
    isLoading: isLoadingFamilies,
    isFetching: isFetchingFamilies,
  } = usePimProductFamiliesQuery(undefined, {skip: !isOpen});
  const loadingFamilies = isLoadingFamilies || isFetchingFamilies;

  useEffect(() => {
    if (!!productfamiliesData) {
      const clone = productfamiliesData?.map((item: any) => ({
        value: item.pimProductFamilyId,
        label: getLabelByLanguage(item?.translates),
      }));
      clone?.unshift({value: null, label: translate("global.all")});
      const uniqueByValue = uniqBy(clone, "value");
      setProductFamilies(uniqueByValue);
    }
  }, [productfamiliesData]);

  const onChangeHandler = (key: string, val: any) => {
    setParams((prev: any) => {
      return {...prev, filter: true, pageNumber: 1, [key]: val};
    });
  };

  const submit = (e: any) => {
    e.preventDefault();
    setParams((prev: any) => {
      return {...prev, pageNumber: 1, filter: true, searchFields: keyword};
    });
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.products.allProducts.filters</Text>
            </span>
            <div className="flex gap-x-2">
              {params.filter && (
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={() => resetFilter()}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>
                    productManagement.products.allProducts.resetFilters
                  </Text>
                </Button>
              )}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>productManagement.products.allProducts.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 px-6 py-8">
          <div className="flex-center flex gap-2">
            <form onSubmit={submit} className="w-full">
              <InputGroup
                label="productManagement.products.importProduct.keyword"
                value={keyword}
                placeholder="global.placeholders.search"
                setValue={value => setKeyword(value)}
              />
            </form>
            <div
              className="mt-8 flex h-[46px] w-[46px] cursor-pointer items-center justify-center rounded-md bg-gray-100 p-3"
              onClick={() => {
                setParams((prev: any) => {
                  return {...prev, pageNumber: 1, searchFields: keyword};
                });
              }}
            >
              <Icon
                className="h-5 w-5 !leading-6 text-primary"
                icon={"search"}
              />
            </div>
          </div>
          {/* Product Family */}
          <>
            <label
              data-lang-map={"productManagement.products.Details.productFamily"}
              className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
            >
              <Text>{"productManagement.products.Details.productFamily"}</Text>
            </label>
            <Select
              defaultValue={{id: null, label: translate("global.all")}}
              options={productFamilies}
              value={
                productFamilies?.filter(
                  (item: any) => item?.value === params?.pimProductFamilyId,
                )?.[0]
              }
              onChange={(e: any) => {
                onChangeHandler("pimProductFamilyId", e?.value || null);
              }}
              isLoading={loadingFamilies}
              styles={config.styleSelectReact}
            />
          </>
          {/* Product Category */}
          <>
            <label
              data-lang-map={
                "productManagement.products.allProducts.productCategory"
              }
              className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
            >
              <Text>
                {"productManagement.products.allProducts.productCategory"}
              </Text>
            </label>
            <Select
              styles={config.styleSelectReact}
              defaultValue={{id: null, label: translate("global.all")}}
              value={
                categories?.filter(
                  (item: any) => item?.value === params?.categoryId,
                )?.[0]
              }
              options={categories}
              onChange={(e: any) => {
                onChangeHandler("categoryId", e?.value || null);
              }}
              isDisabled={loadingCategory}
              isLoading={loadingCategory}
            />
          </>
          {/* Brand */}
          <>
            <label
              data-lang-map={"productManagement.products.allProducts.brand"}
              className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
            >
              <Text>{"productManagement.products.allProducts.brand"}</Text>
            </label>
            <Select
              styles={config.styleSelectReact}
              defaultValue={{id: null, label: translate("global.all")}}
              value={
                brands?.filter(
                  (item: any) => item?.value === params?.brandId,
                )?.[0]
              }
              options={brands}
              onChange={(e: any) => {
                onChangeHandler("brandId", e?.value || null);
              }}
              onInputChange={e =>
                setBrandParams(p => ({...p, keyword: e, pageNumber: 1}))
              }
              onMenuScrollToBottom={() => {
                setBrandParams(p => ({
                  ...p,
                  pageNumber: options.brand + 1,
                }));
                setOptions((p: any) => ({...p, brand: p.brand + 1}));
              }}
              isLoading={loadingBrand}
            />
          </>
          {/* Supplier DropDown */}
          <>
            <label
              data-lang-map={"productManagement.products.allProducts.suppliers"}
              className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
            >
              <Text>{"productManagement.products.allProducts.suppliers"}</Text>
            </label>
            <Select
              styles={config.styleSelectReact}
              defaultValue={{value: null, label: translate("global.all")}}
              value={
                suppliers?.filter(
                  (item: any) => item?.value === params?.supplierId,
                )?.[0]
              }
              options={suppliers}
              onChange={(e: any) => {
                onChangeHandler("supplierId", e?.value || null);
              }}
              onInputChange={e =>
                setSupplierParams(p => ({...p, keyword: e, pageNumber: 1}))
              }
              onMenuScrollToBottom={() => {
                setSupplierParams(p => ({
                  ...p,
                  pageNumber: options.supplier + 1,
                }));
                setOptions((p: any) => ({...p, supplier: p.supplier + 1}));
              }}
              isLoading={loadingSupplier}
            />
          </>
          {/* Program */}
          <>
            <label
              data-lang-map={"productManagement.products.allProducts.program"}
              className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
            >
              <Text>{"productManagement.products.allProducts.program"}</Text>
            </label>
            <Select
              styles={config.styleSelectReact}
              defaultValue={{value: null, label: translate("global.all")}}
              options={programs}
              value={
                programs?.filter(
                  (item: any) => item?.value === params?.programId,
                )?.[0]
              }
              onChange={(e: any) => {
                onChangeHandler("programId", e?.value || null);
              }}
              isDisabled={!params?.supplierId || loadingProgram}
              isLoading={loadingProgram}
            />
          </>
          <div className="grid grid-cols-2 gap-2 gap-y-4">
            <span className="col-span-2 text-sm text-gray-800">
              <Text>productManagement.products.allProducts.priceRange</Text>
            </span>
            <InputGroup
              value={params?.priceFrom}
              setValue={onChange("priceFrom")}
              type="price"
            />
            <InputGroup
              value={params?.priceTo}
              setValue={onChange("priceTo")}
              type="price"
            />
          </div>
          <div className="space-y-6">
            <div className="flex items-center gap-3">
              <p className="shrink-0 text-heading-5 font-semibold text-gray-800">
                <Text>productManagement.products.allProducts.completeness</Text>
              </p>
              <hr className="bg-gray-300" />
            </div>
            <p className="text-heading-6 leading-5 text-gray-800">
              <Text>
                productManagement.products.allProducts.completenessFilterComment
              </Text>
            </p>
            <div className="flex flex-wrap gap-6">
              <RadioButton
                label="productManagement.products.allProducts.needToWork"
                value={
                  params?.minCompletePercent == 0 &&
                  params?.maxCompletePercent == 99
                }
                setValue={() => {
                  onChange("minCompletePercent")(0);
                  onChange("maxCompletePercent")(99);
                }}
                parentClassName="!w-auto !gap-2"
              />
              <RadioButton
                label="productManagement.products.allProducts.done"
                value={
                  params?.minCompletePercent == 100 &&
                  params?.maxCompletePercent == 100
                }
                setValue={() => {
                  onChange("minCompletePercent")(100);
                  onChange("maxCompletePercent")(100);
                }}
                parentClassName="!w-auto !gap-2"
              />
            </div>
            <div className="grid grid-cols-2 gap-2 gap-y-4">
              <span className="col-span-2 text-sm text-gray-800">
                <Text>
                  productManagement.products.allProducts.completenessRange
                </Text>
              </span>
              <InputGroup
                value={params?.minCompletePercent}
                append={"%"}
                setValue={value => {
                  if (value >= 0 && value <= 100) {
                    onChange("minCompletePercent")(value);
                  }
                }}
                keyfilter="pnum"
              />
              <InputGroup
                value={params?.maxCompletePercent}
                append={"%"}
                setValue={value => {
                  if (value >= 0 && value <= 100) {
                    onChange("maxCompletePercent")(value);
                  }
                }}
                keyfilter="pnum"
              />
            </div>
            <SelectComponent
              label="productManagement.products.allProducts.incompleteChecklist"
              value={params?.CheckListId}
              setValue={onChange("CheckListId")}
              items={checkLists}
              className="w-full"
            />
          </div>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
};

export default FilterDrawer;
