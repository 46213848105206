import {NotificationManager} from "common/notifications";
import {
  Button,
  CheckBox,
  DeleteModal,
  Drawer,
  Icon,
  InputGroup,
  Select,
  Skeleton,
  Text,
} from "components";
import {useDataState, useSelector, useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {
  useRemovePrinterDefinitionMutation,
  useUpdatePrinterDefinitionMutation,
} from "services/caching";
import {toggleProps, AssetService} from "types";

type Props = toggleProps & {
  printer?: AssetService.PrinterDefinitionQueryDetailDto;
};

export default function EditDrawer({isOpen, toggle, printer}: Props) {
  const channels = useSelector(s => s.channels);
  const [data, setData, setBaseData, isChanged] =
    useDataState<AssetService.PrinterDefinitionQueryDetailDto>({});
  const [updateDefinition, {isLoading}] = useUpdatePrinterDefinitionMutation();
  const [removeDefinition, {isLoading: deleteLoading}] =
    useRemovePrinterDefinitionMutation();
  const [isChecked, toggleCheck] = useToggleState();
  const [channelsList, setChannels] = useState<any>([]);
  const [isOpenDelete, toggleDelete] = useToggleState();

  const getChannels = () => {
    const data = channels.map((element: any) => ({
      name: element.title,
      id: element.code,
    }));
    setChannels(data);
  };

  useEffect(() => {
    getChannels();
  }, [channels]);

  const handleSetValue = (
    key: keyof AssetService.PrinterDefinitionQueryDetailDto,
  ) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  useEffect(() => {
    if (!!printer) {
      setBaseData(printer);
    }
  }, [printer, isOpen]);

  const OnSubmitHandler = async () => {
    const clone = {...data};
    delete clone.id;
    try {
      const result = await updateDefinition({
        data: clone,
        id: printer?.id,
      }).unwrap();
      console.log(result);
      toggle();
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title",
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteHandler = async () => {
    try {
      await removeDefinition(printer?.id as string).unwrap();
      toggle();
      toggleDelete();
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title",
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header className="flex-between">
            <h2 className="text-heading-2">
              <Text>
                configurations.printerDefinition.editPrinterDefinition
              </Text>
            </h2>
            <div className="flex items-center gap-2">
              <Button
                type="button"
                variant="danger"
                light
                size="sm"
                onClick={toggleDelete}
              >
                <Icon icon="trash" /> <Text>global.buttons.delete</Text>
              </Button>
              <Button type="button" variant="light" size="sm" onClick={toggle}>
                <Icon icon="close" /> <Text>global.buttons.close</Text>
              </Button>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-4">
            <>
              <InputGroup
                disabled={isLoading}
                label={"configurations.printerDefinition.name"}
                value={data?.name}
                setValue={handleSetValue("name")}
              />
              <InputGroup
                disabled={isLoading}
                label={"configurations.printerDefinition.ip"}
                value={data?.ip}
                setValue={handleSetValue("ip")}
              />
              <InputGroup
                disabled={isLoading}
                label={"configurations.printerDefinition.port"}
                value={data?.port}
                type="number"
                setValue={handleSetValue("port")}
              />
              <InputGroup
                disabled={isLoading}
                label={"configurations.printerDefinition.protocol"}
                value={data?.protocol}
                setValue={handleSetValue("protocol")}
              />
              <InputGroup
                disabled={isLoading}
                label={"configurations.printerDefinition.location"}
                value={data?.location}
                setValue={handleSetValue("location")}
              />
              <InputGroup
                disabled={isLoading}
                label={"configurations.printerDefinition.additionalSettings"}
                value={data?.additionalSettings}
                setValue={handleSetValue("additionalSettings")}
              />
              <CheckBox
                label="configurations.printerDefinition.editChannelCode"
                value={isChecked}
                disabled={isLoading}
                setValue={e => {
                  toggleCheck();
                  handleSetValue("channelCode")(e);
                }}
              />
              <Select
                label="channelMessage.filter.channel"
                disabled={!isChecked}
                placeholder="global.select"
                value={data?.channelCode}
                items={channelsList}
                setValue={handleSetValue("channelCode")}
              />
              <InputGroup
                disabled={isLoading}
                as="textarea"
                label={"configurations.printerDefinition.description"}
                value={data?.description}
                setValue={handleSetValue("description")}
              />
            </>
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button size="sm" variant={"light"} onClick={toggle} type="button">
              <Text>global.cancel</Text>
            </Button>
            <Button
              size="sm"
              type="button"
              disabled={!isChanged}
              loading={isLoading}
              onClick={OnSubmitHandler}
            >
              <Text>configurations.printerDefinition.updateInfo</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <DeleteModal
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        selected={{name: printer?.name}}
        loading={deleteLoading}
        onConfirm={onDeleteHandler}
      />
    </>
  );
}
