export const brand = {
  addBrand: "Marke hinzufügen",
  code: "Code (Optional)",
  automaticGenerateBySystem: "Wenn leer, erfolgt Generierung durch System",
  name: "Name",
  title: "Titel",
  description: "Beschreibung",
  shortDescription: "Kurzbeschreibung",
  showBrandLogoInProductList: "Markenlogo in Produktliste anzeigen",
  generalTab: "Allgemein",
};
