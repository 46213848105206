import {SalesChannelMessage, SalesService, WithPaging} from "types";
import MainApi from "../mainApi";

type Data = WithPaging<SalesChannelMessage.Item>;

type ChannelMessageDetailsProps = {
  channelMessageId: string;
  channelCode: string;
};

export const saleschannelMessageSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    getSaleschannelMessages: builder.query<Data, any>({
      query: params => ({
        url: "/salesservice/api/salechannelmessage",
        params,
      }),
    }),
    saleschannelMessageDetails: builder.query<
      SalesService.SaleChannelMessageDetailQueryDto,
      ChannelMessageDetailsProps
    >({
      query: ({channelMessageId, channelCode}) => ({
        url: `/salesservice/api/salechannelmessage/${channelMessageId}/${channelCode}`,
      }),
    }),
  }),
});

export const {
  useGetSaleschannelMessagesQuery,
  useSaleschannelMessageDetailsQuery,
} = saleschannelMessageSlice;
