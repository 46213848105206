import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
} from "components";
import {config as appConfig} from "constant";
import {withPermission} from "hoc";
import {useConverter, useToggleState} from "hooks";
import {Fragment} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import CreateDrawer from "./create";
import {dateFormats} from "constant";
import {useAuctionsQuery} from "services/caching";

type Params = {
  keyword?: string;
  pageNumber?: string;
  pageSize?: string;
  status?: string;
  fromDate?: string;
  toDate?: string;
};

function Auction() {
  const {convertDate} = useConverter();
  const navigate = useNavigate();
  const initParams: Params = {
    pageNumber: "1",
    pageSize: appConfig.pageSize.toString(),
  };

  const [params, setParams] = useSearchParams(initParams);
  const queryParams = Object.fromEntries(params.entries());
  const [create, toggleCreate] = useToggleState(false);

  const handleSetParams = (key: keyof Params) => {
    const isPageNumber = key === "pageNumber";
    return (value: string | number | null) => {
      setParams(p => {
        const hasValue = !!String(value ?? "");
        hasValue ? p.set(key, `${value}`) : p.delete(key);
        !isPageNumber && p.set("pageNumber", `1`);
        return p;
      });
    };
  };

  const {data, isLoading, isFetching, refetch} = useAuctionsQuery(queryParams);

  const loading = isFetching || isLoading;
  const hasData = !!data?.items?.length;

  const isFilter = ["keyword"].some(item => params.get(item));

  const resetFilter = () => {
    setParams(initParams);
  };

  return (
    <>
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <Breadcrumb />
          <Button size="sm" onClick={toggleCreate} disabled={loading}>
            <Icon icon="plus" />
            <Text>salesManagment.auctions.add</Text>
          </Button>
        </div>
        <div className="flex-between">
          <SearchBox
            value={params.get("keyword")}
            onSubmit={handleSetParams("keyword")}
            disabled={loading}
            loading={loading}
            totalItems={data?.totalItems}
            onReload={refetch}
            {...(!!params.get("keyword") && {resetFilter})}
          />
          {isFilter && (
            <Button
              disabled={loading}
              variant={"white"}
              textColor="danger"
              size="sm"
              onClick={resetFilter}
            >
              <Icon icon="circle-xmark"></Icon>
              <span className="ml-1">Reset Filter</span>
            </Button>
          )}
        </div>
        {loading ? (
          <>
            {[...Array(6)].map(() => (
              <Skeleton.List />
            ))}
          </>
        ) : (
          <>
            {hasData ? (
              <>
                <Table>
                  {data?.items?.map(e => {
                    return (
                      <Fragment key={e.id}>
                        <tr className="*:text-left">
                          <td className="space-y-2">
                            <h6 className="truncate text-heading-6 text-gray-800">
                              {e.title}
                            </h6>
                            <p className="text-body-base">
                              <Text>salesManagment.auctions.startAt</Text> :{" "}
                              {convertDate(e.startDate, dateFormats.onlyDate)}
                            </p>
                            <p className="text-body-base">
                              <Text>salesManagment.auctions.endAt</Text> :{" "}
                              {convertDate(e.endDate, dateFormats.onlyDate)}
                            </p>
                          </td>
                          <td>
                            <p className="text-wrap text-left text-body-base leading-5">
                              {e.shortDescription}
                            </p>
                          </td>
                          <td>
                            <Button
                              size="sm"
                              variant={"light"}
                              onClick={() => navigate(e.id as string)}
                            >
                              <Text>global.buttons.details</Text>
                            </Button>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </Table>
                <Pagination
                  totalItems={data?.totalItems}
                  totalPages={data?.totalPages}
                  pageNumber={+(params.get("pageNumber") ?? 1)}
                  pageSize={+(params.get("pageSize") ?? appConfig.pageSize)}
                  setActivePage={handleSetParams("pageNumber")}
                  onPageSizeChange={handleSetParams("pageSize")}
                />
              </>
            ) : (
              <NoItems />
            )}
          </>
        )}
      </div>
      <CreateDrawer isOpen={create} toggle={toggleCreate} getData={refetch} />
    </>
  );
}

export default withPermission(Auction, ["SC_GetAuction"]);
