export const templates = {
  code: "Code",
  customTemplate: "Benutzerdefiniertes Template",
  requiredToBeSigned: "Unterschrift erforderlich",
  edit: "Bearbeiten",
  templateName: "Template-Name",
  downloadDefaultTemplate: "Standard-Template herunterladen",
  uploadWarning:
    "Sie können das Standard-Template überschreiben und Ihre angepasste Vorlage hier hochladen:",
  dragDrop: "Dateien per Drag & Drop oder",
  clickHere: "Hier klicken",
  keyword: "Stichwort",
  templateType: "Templatetyp",
  default: "Standard",
  custom: "Benutzerdefiniert",
  all: "Alle",
  active: "Aktiv",
  notActive: "Inaktiv",
  erp: "ERP",
  crm: "CRM",
  createdFor: "Erstellt für",
  offers: "Angebote",
  orders: "Bestellungen",
  filters: "Filter",
};
