import {useId, ReactNode, useRef, forwardRef, useEffect} from "react";
import Text from "./Text";
import Icon from "./Icon";

type checkBoxProps = {
  label?: string;
  className?: string;
  labelClassName?: string;
  children?: ReactNode;
  value: boolean;
  disabled?: boolean;
  setValue?: (val: boolean) => void;
  indeterminate?: boolean;
};

const CheckBox = forwardRef<HTMLInputElement, checkBoxProps>(
  (
    {
      value,
      setValue = () => {},
      label,
      disabled = false,
      children,
      className,
      labelClassName,
      indeterminate = false,
    }: checkBoxProps,
    ref,
  ) => {
    const id = useId();
    const internalRef = useRef<HTMLInputElement | null>(null);
    function synchronizeRefs(el: HTMLInputElement | null) {
      // Update the internal ref.
      internalRef.current = el;

      // Update the provided ref.
      if (!ref) {
        // nothing to update
      } else if (typeof ref === "object") {
        ref.current = el;
      } else {
        // must be a callback ref
        ref(el);
      }
    }
    useEffect(() => {
      if (internalRef.current) {
        internalRef.current.indeterminate = indeterminate;
      }
    }, [indeterminate]);
    return (
      <label
        data-active={value || indeterminate}
        data-lang-map={label}
        className="checkbox flex-center group w-full gap-4"
      >
        <input
          id={id}
          ref={synchronizeRefs}
          type="checkbox"
          checked={value}
          disabled={disabled}
          onChange={() => setValue(!value)}
          hidden
        />
        <span
          className={`${className} flex-center h-6 w-6 cursor-pointer rounded-md border bg-gray-200 transition-colors group-data-active:bg-primary ${
            disabled && "group-data-active:opacity-50"
          }`}
        >
          {/* <i
            className={`bi bi-check-lg h-full w-full text-lg text-white opacity-0 transition-opacity group-data-active:opacity-100 `}
          /> */}
          <Icon
            icon={`${indeterminate ? "dash" : "check"}`}
            size="1x"
            className="text-white opacity-0 transition-opacity after:!opacity-100 group-data-active:opacity-100"
          />
        </span>
        {label && (
          <span className={`${labelClassName || ""} flex-1`}>
            <Text>{label}</Text>
          </span>
        )}
        {children && <div className="flex-1">{children}</div>}
      </label>
    );
  },
);

export default CheckBox;
