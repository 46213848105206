import {NotificationManager} from "common/notifications";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  MultiSelect,
  Skeleton,
  Text,
} from "components";
import {config, rules} from "constant";
import {usePermission} from "hooks";
import {Fragment, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {
  useRoleListQuery,
  useUpdateUserGroupMutation,
  useUserGroupDetailsQuery,
} from "services/caching";

export default function Edit(props: any) {
  const updatePermission = usePermission("AS_UpdateUserGroup");
  const [data, setData] = useState<any>({});
  const [updateUserGroup, {isLoading: loadingButton}] =
    useUpdateUserGroupMutation();

  const {
    data: cachedData,
    isLoading,
    isFetching,
  } = useUserGroupDetailsQuery({id: props.selected}, {skip: !props.selected});

  const originalFilterData: any = {
    pageNumber: "1",
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params] = useSearchParams(originalFilterData);
  const queryParams = Object.fromEntries(params.entries());

  const {
    data: chooseRoleItems,
    isLoading: isLoadingRoles,
    isFetching: isFetchingRoles,
  } = useRoleListQuery(queryParams, {skip: !props.isOpen});

  const loadingList = isLoading || isFetching;

  const callBack = (cachedData: any) => {
    setData(cachedData);
  };

  useEffect(() => {
    if (!!cachedData) {
      callBack(cachedData);
    }
  }, [cachedData]);

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const onSubmitForm = async () => {
    data.roleIds = data.roles;
    try {
      await updateUserGroup({data, id: data.id}).unwrap();
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title",
      );
      props.toggle();
    } catch (error) {}
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Form
        className="space-y-4 pr-4"
        onSubmit={onSubmitForm}
        id={"edit-modal"}
      >
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pr-4 pt-6">
            <div className="flex items-center justify-between">
              <h2 className="text-heading-2 text-gray-800">
                <Text>configurations.userManagment.userGroups.editGroup</Text>
              </h2>
              <div className="flex items-center justify-between">
                <Button
                  type="button"
                  variant="light"
                  size="sm"
                  className="ml-2"
                  onClick={() => props.toggle()}
                >
                  <Icon className="mr-2" icon={"times"} />
                  <Text>global.buttons.close</Text>
                </Button>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="space-y-4 pr-4">
            {loadingList ? (
              <Fragment>
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
              </Fragment>
            ) : (
              <>
                <InputGroup
                  label="configurations.userManagment.userGroups.name"
                  value={data?.name}
                  setValue={handleSetData("name")}
                  rules={rules.required}
                  disabled={!updatePermission}
                />
                <InputGroup
                  as="short-textarea"
                  label="configurations.userManagment.userGroups.description"
                  value={data?.description}
                  setValue={handleSetData("description")}
                  rules={rules.required}
                  disabled={!updatePermission}
                />
                <MultiSelect
                  label="configurations.userManagment.userGroups.chooseRole"
                  items={chooseRoleItems?.items as []}
                  value={data?.roles}
                  setValue={value => {
                    return setData((p: any) => ({
                      ...p,
                      roles:
                        value.length !== 0
                          ? value?.map((val: any) => val.id)
                          : [],
                    }));
                  }}
                  rules={rules.required}
                />
              </>
            )}
          </Drawer.Body>
          {updatePermission && (
            <Drawer.Footer className="flex justify-between">
              <Button
                type="button"
                className="mr-2"
                onClick={() => props.toggle()}
                variant="light"
              >
                <Text>global.buttons.cancel</Text>
              </Button>
              <Button type="submit" loading={loadingButton}>
                <Text>global.buttons.submit</Text>
              </Button>
            </Drawer.Footer>
          )}
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
