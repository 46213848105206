import {useEffect, useState} from "react";
import {
  Button,
  CheckBox,
  DatePicker,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
} from "components";
import {toggleProps, SalesService} from "types";
import {rules} from "constant";
import {NotificationManager} from "common/notifications";
import {useCreateAuctionMutation} from "services/caching";

type CreateDrawer = toggleProps & {
  getData: () => void;
};

export default function CreateDrawer({isOpen, toggle, getData}: CreateDrawer) {
  const [data, setData] = useState<SalesService.CreateAuctionRequest>({});
  const [createAuction, {isLoading}] = useCreateAuctionMutation();

  const onSubmitHandler = async () => {
    try {
      await createAuction(data).unwrap();
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title",
      );
      toggle();
    } catch (error) {}
  };

  useEffect(() => {
    if (isOpen) {
      const today = new Date();
      const todayISO = today.toISOString();
      const sevenDays = new Date();
      sevenDays.setDate(today.getDate() + 7);
      const sevenDaysISO = sevenDays.toISOString();
      setData({
        startDate: todayISO,
        endDate: sevenDaysISO,
      });
    }
  }, [isOpen]);

  const onChangeHandler = (key: keyof SalesService.CreateAuctionRequest) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Form className="space-y-6" onSubmit={onSubmitHandler}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold text-gray-800">
              <Text>salesManagment.auctions.add</Text>
            </span>
            <Button size="sm" onClick={toggle} variant={"light"} type="button">
              <Icon icon="close" />
              <Text>global.buttons.close</Text>
            </Button>
          </Drawer.Header>
          <Drawer.Body className="space-y-6">
            <InputGroup
              label={"salesManagment.auctions.title"}
              value={data?.title}
              setValue={onChangeHandler("title")}
              rules={rules.required}
              disabled={isLoading}
            />
            <InputGroup
              as={"textarea"}
              label={"salesManagment.auctions.shortDescription"}
              value={data?.shortDescription}
              setValue={onChangeHandler("shortDescription")}
              disabled={isLoading}
            />
            <div className="grid grid-cols-2 gap-x-2 gap-y-1">
              <DatePicker
                label={"salesManagment.auctions.startAt"}
                showYearDropdown
                showMonthDropdown
                rules={rules.required}
                value={data?.startDate}
                minDate={new Date()}
                onChange={onChangeHandler("startDate")}
                maxDate={data.endDate}
                disabled={isLoading}
              />
              <DatePicker
                label={"salesManagment.auctions.endAt"}
                showYearDropdown
                showMonthDropdown
                rules={rules.required}
                value={data.endDate}
                onChange={onChangeHandler("endDate")}
                minDate={data?.startDate}
                disabled={isLoading}
              />
            </div>
            <CheckBox
              label="salesManagment.auctions.disableOptionalDiscounts"
              value={data?.disableOptionalDiscounts as boolean}
              labelClassName="text-gray-800 text-md"
              setValue={onChangeHandler("disableOptionalDiscounts")}
            />
            <CheckBox
              label="salesManagment.auctions.disableIndividualDiscounts"
              value={data?.disableIndividualDiscounts as boolean}
              labelClassName="text-gray-800 text-md"
              setValue={onChangeHandler("disableIndividualDiscounts")}
            />
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-between">
            <Button size="sm" onClick={toggle} variant={"light"} type="button">
              <Icon icon="close" />
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button size="sm" type="submit" loading={isLoading}>
              <Text>global.buttons.submit</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
