import {NotificationManager} from "common/notifications";
import {Button, CheckBox, Drawer, Icon, Text} from "components";
import {useSelector, useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {usePublishTochannelsMutation} from "services/caching";
import {ProductService} from "types";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  params: ProductService.SearchPimRequest;
};
export default function PublishDrawer({isOpen, toggle, params}: Props) {
  const channels = useSelector(s => s.channels);
  const [publishTochannels, {isLoading: loading}] =
    usePublishTochannelsMutation();
  // const [loading, toggleLoading] = useToggleState();
  const [selectedChannel, dispatchSelectedChannels] = useState<string[]>([]);
  const [channelsList, setChannels] = useState<any>([]);

  useEffect(() => {
    if (isOpen) {
      const clone = channels?.map((channel: any) => channel?.code);
      dispatchSelectedChannels(clone);
      setChannels(channels);
    } else {
      dispatchSelectedChannels([]);
    }
  }, [channels, isOpen]);

  const toggleItem = (item: any) => {
    dispatchSelectedChannels(prevSelected => {
      if (prevSelected.some(selected => selected === item.code)) {
        return prevSelected.filter(selected => selected !== item.code);
      } else {
        return [...prevSelected, item.code];
      }
    });
  };

  const toggleAll = (action?: string) => {
    if (action === "select") {
      const clone = channelsList?.map((channel: any) => channel?.code);
      dispatchSelectedChannels(clone);
    } else {
      dispatchSelectedChannels([]);
    }
  };

  const onSubmitHandler = async () => {
    // toggleLoading();
    // const url = URLS.PUBLISH_ALL_PRODUCTS_TO_CHANNELS;
    const body = {
      channelCodes: selectedChannel,
      ...params,
    };
    try {
      await publishTochannels(body).unwrap();
      NotificationManager.success(
        "global.toast.publish-msg",
        "global.toast.publish-title",
      );
      toggle();
    } catch (error) {
      console.log(error);
    }
    // ServiceApi.post(url, body)
    //   .then(() => {
    //     NotificationManager.success(
    //       "global.toast.publish-msg",
    //       "global.toast.publish-title",
    //     );
    //     toggle();
    //     toggleLoading();
    //   })
    //   .catch(() => toggleLoading());
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between">
          <h2>
            <Text>
              productManagement.products.allProducts.publishAllProducts
            </Text>
          </h2>
          <Button size="sm" variant={"light"} onClick={toggle}>
            <Icon size="sm" icon={"close"} />
            <Text>global.buttons.close</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 py-8 *:text-gray-800">
          <p className="text-body-base font-normal">
            <Text>
              productManagement.products.allProducts.chooseChannelsThatYouWantToPublishProducts
            </Text>
            :
          </p>
          <div className="flex items-center justify-between">
            <CheckBox
              label="productManagement.products.allProducts.selectAll"
              value={selectedChannel?.length === channelsList?.length}
              labelClassName="text-heading-5"
              setValue={() => toggleAll("select")}
              disabled={loading}
            />
            <CheckBox
              label="productManagement.products.allProducts.deSelectAll"
              value={selectedChannel?.length === 0}
              labelClassName="text-heading-5"
              setValue={() => toggleAll()}
              disabled={loading}
            />
          </div>
          {channelsList?.map((channel: any) => {
            const isSelected = selectedChannel?.includes(channel?.code);
            return (
              <div
                key={channel?.code}
                className={`flex items-center rounded p-6 ${isSelected ? "bg-primary-light" : "bg-gray-100"}`}
              >
                <CheckBox
                  label={channel.title}
                  value={isSelected}
                  labelClassName="text-heading-5"
                  setValue={() => toggleItem(channel)}
                  disabled={loading}
                />
              </div>
            );
          })}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between">
          <Button
            disabled={loading}
            size="sm"
            variant={"light"}
            onClick={toggle}
          >
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button
            loading={loading}
            disabled={!!!selectedChannel?.length}
            size="sm"
            onClick={onSubmitHandler}
          >
            <Text>global.buttons.publish</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
