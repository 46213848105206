import {Details} from "./details";

export const publishedProduct = {
  list: {
    allAuctionButton: "All",
    auctionsButton: "Auctions",
    noAuctionsButton: "No Auctions",
    auctionBadge: "Auctions",
  },
  Details,
};
