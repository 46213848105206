import {Fragment} from "react/jsx-runtime";

type SkeletonImageListProps = {
  image?: Boolean;
};

const Skeleton = () => {
  return (
    <div className="mt-auto h-2 w-20 animate-pulse rounded bg-slate-200"></div>
  );
};

const SkeletonImageList = ({image = true}: SkeletonImageListProps) => {
  return (
    <div className="border-gray mx-auto w-full min-w-full rounded-md border bg-white p-4 shadow-sm">
      <div className="flex animate-pulse space-x-4">
        {image && <div className="h-20 w-32 rounded-md bg-slate-200"></div>}
        <div className="flex-1 space-y-6 py-1">
          <div className="flex items-center gap-4">
            <div className="flex-1 space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 h-2 rounded bg-slate-200"></div>
                <div className="col-span-2 h-2 rounded bg-slate-200"></div>
                <div className="col-span-1 h-2 rounded bg-slate-200"></div>
                <div className="col-span-2 h-2 rounded bg-slate-200"></div>
                <div className="col-span-1 h-2 rounded bg-slate-200"></div>
              </div>
            </div>
            <div className="h-9 w-20 rounded bg-slate-200"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkeletonInput = ({withLabel = false}) => {
  return (
    <div className="space-y-4">
      {withLabel && (
        <div className="mt-auto h-2 w-20 animate-pulse rounded bg-slate-200"></div>
      )}
      <div className="ml-auto mt-auto h-11 w-full animate-pulse rounded bg-slate-100"></div>
    </div>
  );
};

const SkeletonTextArea = ({size = "", withLabel = false}) => {
  return (
    <div className="space-y-4">
      {withLabel && (
        <div className="mt-auto h-2 w-20 animate-pulse rounded bg-slate-200"></div>
      )}
      <div
        className={`ml-auto mt-auto w-full animate-pulse rounded bg-slate-100 ${size == "sm" ? "h-20" : "h-40"}`}
      ></div>
    </div>
  );
};

const SkeletonButton = () => {
  return <div className="h-9 w-20 animate-pulse rounded bg-slate-200"></div>;
};
const SkeletonButtonWhite = () => {
  return <div className="h-9 w-20 animate-pulse rounded bg-white"></div>;
};

const SkeletonSimpleList = () => {
  return (
    <div className="flex animate-pulse items-center space-x-4 border-b-2 py-4">
      <div className="grid flex-1 grid-cols-4 gap-4">
        <div className="col-span-1 h-2 rounded bg-slate-200"></div>
      </div>
      <div className=" h-[30px] w-10 rounded bg-slate-200"></div>
    </div>
  );
};
const AvailableStatus = () => {
  return (
    <Fragment>
      {[...Array(3).keys()].map(key => (
        <div
          key={key}
          className="h-16 w-full animate-pulse rounded bg-slate-200"
        ></div>
      ))}
    </Fragment>
  );
};

const SkeletonPriceBox = () => {
  return (
    <div className="border-gray mx-auto w-full min-w-full rounded-md border bg-white p-4 shadow-sm">
      <div className="flex animate-pulse space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="flex items-center justify-between gap-4">
            <div className="w-16 space-y-4">
              <div className=" h-2 w-24 rounded bg-slate-200"></div>
              <div className=" h-2 w-44 rounded bg-slate-200"></div>
              <div className=" h-2 w-44 rounded bg-slate-200"></div>
            </div>
            <div className="h-20 w-80 rounded bg-slate-200"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

Skeleton.ImageList = SkeletonImageList;
Skeleton.List = () => <SkeletonImageList image={false} />;
Skeleton.Input = SkeletonInput;
Skeleton.Button = SkeletonButton;
Skeleton.TextArea = SkeletonTextArea;
Skeleton.SimpleList = SkeletonSimpleList;
Skeleton.AvailableStatus = AvailableStatus;
Skeleton.ButtonWhite = SkeletonButtonWhite;
Skeleton.Box = SkeletonPriceBox;
export default Skeleton;
