import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {usePermission, useToggleState} from "hooks";
import {isEmpty} from "lodash";
import {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import AddDrawer from "./add-drawer";
import {useShippingMethodsQuery} from "services/caching";
import {deepClone} from "react-advanced-cropper";
type ShippingItems = {
  code: string;
  title: string;
  shippingMethodId: string;
};

type Params = {
  keyword?: string;
  pageNumber?: string;
  pageSize?: string;
};

function ShippingMethods() {
  const [data, setData] = useState<any>();
  const [isOpenDrawerCreate, toggleDrawerCreate] = useToggleState(false);
  const [shippingMethod, setShippingMethod] = useState<ShippingItems>();
  const permission = usePermission("PS_GetShippingMethod");
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  // const [params, setParams] = useSearchParams(originalFilterData);
  // const queryParams = Object.fromEntries(params.entries());

  const {
    data: shippingMethodsData,
    isLoading,
    isFetching,
    refetch,
  } = useShippingMethodsQuery();

  const loadingList = isFetching || isLoading;

  useEffect(() => {
    if (!!shippingMethodsData) {
      setData(deepClone(shippingMethodsData));
    }
  }, [shippingMethodsData]);
  // const handleSetParams = (key: keyof Params) => {
  //   const isPageNumber = key === "pageNumber";
  //   return (value: string | number | null) => {
  //     setParams(p => {
  //       const hasValue = !!String(value ?? "");
  //       hasValue ? p.set(key, `${value}`) : p.delete(key);
  //       !isPageNumber && p.set("pageNumber", `1`);
  //       return p;
  //     });
  //   };
  // };

  const handleOpenDetail = (item: any) => {
    if (permission) {
      setShippingMethod(item);
      toggleDrawerCreate();
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["PS_CreateShippingMethod"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button
              aria-label="cy-create-shipping-button"
              as={Link}
              type="button"
              onClick={() => {
                setShippingMethod({
                  title: "",
                  code: "",
                  shippingMethodId: "",
                });
                toggleDrawerCreate();
              }}
            >
              <Icon className="mr-2" icon={"plus"} />
              <Text>productManagement.masterData.shipping.addShipping</Text>
            </Button>
          )}
        </WithPermission>
      </div>
      {/* <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
      /> */}
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {!isEmpty(data) ? (
            <>
              <Table>
                {data?.map((item: ShippingItems) => {
                  return (
                    <tr key={item.title}>
                      <td
                        className={`text-left text-body-base text-gray-600 ${permission && "cursor-pointer"}`}
                        onClick={() => handleOpenDetail(item)}
                      >
                        {item.title}
                      </td>
                      <td className="text-left text-body-base text-gray-600">
                        {item.code}
                      </td>
                      <td>
                        <WithPermission permissions={["PS_GetShippingMethod"]}>
                          <Button
                            variant="light"
                            size="sm"
                            onClick={() => handleOpenDetail(item)}
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              {/* <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={+(params.get("pageNumber") ?? 1)}
                pageSize={+(params.get("pageSize") ?? config.pageSize)}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={handleSetParams("pageSize")}
              /> */}
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <AddDrawer
        isOpen={isOpenDrawerCreate}
        toggle={toggleDrawerCreate}
        getData={refetch}
        shippingMethod={shippingMethod}
      />
    </div>
  );
}
export default withPermission(ShippingMethods, ["PS_GetShippingMethod"]);
