import {NotificationManager} from "common/notifications";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  MultiSelect,
  Skeleton,
  Text,
} from "components";
import {config, rules} from "constant";
import {FormEvent, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useCreateUserGroupMutation, useRoleListQuery} from "services/caching";

export default function Add(props: any) {
  const [createUserGroup, {isLoading}] = useCreateUserGroupMutation();
  const originalFilterData: any = {
    pageNumber: "1",
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params] = useSearchParams(originalFilterData);
  const queryParams = Object.fromEntries(params.entries());

  const {
    data: chooseRoleItems,
    isLoading: isLoadingRoles,
    isFetching,
  } = useRoleListQuery(queryParams, {skip: !props.isOpen});

  const loadingList = isLoadingRoles || isFetching;
  const [data, setData] = useState<any>({});

  const formRef = useRef<any>();

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const onSubmitForm = async (formData: any) => {
    try {
      await createUserGroup(formData).unwrap();
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title",
      );
      props.toggle();
    } catch (error) {}
  };

  const submit = (e?: FormEvent) => {
    formRef.current.submitHandler(e, data);
  };

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <h2 className="text-heading-2 text-gray-800">
              <Text>configurations.userManagment.userGroups.addGroup</Text>
            </h2>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                size="sm"
                className="ml-2"
                onClick={() => props.toggle()}
              >
                <Icon className="mr-2" icon={"times"} />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 pr-4">
          <Form
            ref={formRef}
            className="space-y-4 pr-4"
            onSubmit={onSubmitForm}
            id={"create-modal"}
          >
            <InputGroup
              label="configurations.userManagment.userGroups.name"
              value={data.name}
              setValue={handleSetData("name")}
              rules={rules.required}
            />
            <InputGroup
              as="short-textarea"
              label="configurations.userManagment.userGroups.description"
              value={data.description}
              setValue={handleSetData("description")}
              rules={rules.required}
            />
            {loadingList ? (
              <Skeleton.Input />
            ) : (
              <MultiSelect
                label="configurations.userManagment.userGroups.chooseRole"
                items={chooseRoleItems?.items as []}
                value={data?.roles}
                setValue={value => {
                  return setData((p: any) => ({
                    ...p,
                    roles:
                      value.length !== 0
                        ? value?.map((val: any) => val.id)
                        : [],
                  }));
                }}
                rules={rules.required}
              />
            )}
          </Form>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button
            type="button"
            className="mr-2"
            onClick={() => props.toggle()}
            variant="light"
          >
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button type="button" onClick={submit} loading={isLoading}>
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
