import {Button, Drawer, Icon, Text} from "components";
import {useSelector, useTranslate} from "hooks";
import {useContext, useEffect, useState} from "react";
import {toggleProps} from "types";
import {config} from "constant";
import {CreateAuctionContext} from "./add-products";
import Select from "react-select";

type FilterProps = toggleProps;

export default function FilterDrawer({isOpen, toggle}: FilterProps) {
  const {
    data,
    loading,
    isFilter,
    initialParams,
    params: dataParams,
    setParams: setDataParams,
  } = useContext(CreateAuctionContext);

  const [params, setParams] = useState<any>(dataParams);
  const translate = useTranslate();
  const channels = useSelector(s => s.channels);

  const [channelsList, setChannels] = useState<any>([]);

  const getChannels = () => {
    setChannels([{title: "All", code: null}, ...channels]);
  };

  useEffect(() => {
    if (isOpen) {
      setParams(dataParams);
    }
  }, [dataParams, isOpen]);

  useEffect(() => {
    getChannels();
  }, [channels]);

  const createProductFamilyItems = () => {
    const clone = data?.pimProductFamily?.map((item: any) => ({
      value: item.value,
      label: item.title + " (" + item.count + ")",
    }));
    clone?.unshift({value: null, label: translate("global.all")});
    return clone;
  };

  const createChannelsItems = () => {
    const clone = channelsList?.map((item: any) => ({
      value: item.code,
      label: item.title,
    }));
    return clone;
  };

  const createItems = (key: string) => {
    const clone = (data as any)?.[key]?.map((item: any) => ({
      value: item.value,
      label: item.title + "(" + item.count + ")",
    }));
    clone?.unshift({value: null, label: translate("global.all")});
    return clone;
  };
  const onChangeHandler = (key: string, val: any) => {
    setParams((prev: any) => {
      return {...prev, pageNumber: 1, [key]: val};
    });
  };

  const resetFilter = () => {
    setParams(initialParams);
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.products.allProducts.filters</Text>
            </span>
            <div className="flex gap-x-2">
              {isFilter && (
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={resetFilter}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>global.buttons.resetFilters</Text>
                </Button>
              )}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <label
            data-lang-map={"Published Channels"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"Published Channels"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{value: null, label: translate("global.all")}}
            value={createChannelsItems()?.find(
              (opt: any) => opt.value === params?.channelCode,
            )}
            options={createChannelsItems()}
            onChange={(e: any) => {
              onChangeHandler("channelCode", e?.value || null);
            }}
            isDisabled={loading}
          />
          <label
            data-lang-map={
              "productManagement.products.allProducts.productFamily"
            }
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>
              {"productManagement.products.allProducts.productFamily"}
            </Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{value: null, label: translate("global.all")}}
            value={createProductFamilyItems()?.find(
              (opt: any) => opt.value === params?.pimProductFamilyId,
            )}
            options={createProductFamilyItems()}
            onChange={(e: any) => {
              onChangeHandler("pimProductFamilyId", e?.value || null);
            }}
            isDisabled={loading}
          />
          <label
            data-lang-map={"productGroup"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productGroup"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createItems("pimProductGroup")?.find(
              (opt: any) => opt.value === params?.groupId,
            )}
            options={createItems("pimProductGroup")}
            onChange={(e: any) => {
              onChangeHandler("groupId", e?.value || null);
            }}
            isDisabled={loading}
          />
          <label
            data-lang-map={
              "productManagement.products.allProducts.productCategory"
            }
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>
              {"productManagement.products.allProducts.productCategory"}
            </Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createItems("category")?.find(
              (opt: any) => opt.value === params?.pimProductCategoryCode,
            )}
            options={createItems("category")}
            onChange={(e: any) => {
              onChangeHandler("pimProductCategoryCode", e?.value || null);
            }}
            isDisabled={loading}
          />
          <label
            data-lang-map={"productManagement.products.allProducts.brand"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productManagement.products.allProducts.brand"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createItems("brand")?.find(
              (opt: any) => opt.value === params?.brandCode,
            )}
            options={createItems("brand")}
            onChange={(e: any) => {
              onChangeHandler("brandCode", e?.value || null);
            }}
            isDisabled={loading}
          />
          <label
            data-lang-map={"productManagement.products.allProducts.suppliers"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productManagement.products.allProducts.suppliers"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createItems("supplier")?.find(
              (opt: any) => opt.value === params?.supplierId,
            )}
            options={createItems("supplier")}
            onChange={(e: any) => {
              onChangeHandler("supplierId", e?.value || null);
            }}
            isDisabled={loading}
          />
          <label
            data-lang-map={"productManagement.products.allProducts.program"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productManagement.products.allProducts.program"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={createItems("program")?.find(
              (opt: any) => opt.value === params?.programId,
            )}
            options={createItems("program")}
            onChange={(e: any) => {
              onChangeHandler("programId", e?.value || null);
            }}
            isDisabled={loading}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between">
          <Button size="sm" onClick={toggle} variant={"light"} type="button">
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button
            size="sm"
            type="button"
            onClick={() => {
              setDataParams(params);
              toggle();
            }}
          >
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
