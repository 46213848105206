import {Button, Icon, Skeleton, Text, NoItems} from "components";
import {useSelector} from "hooks";
import {useState} from "react";
import {useParams} from "react-router";
import {useSearchParams} from "react-router-dom";
import {Table} from "tabler-icons-react";
import {SalesService} from "types";
import {usePublishedProductInventoryQuery} from "services/caching";

export default function Inventory() {
  const {productId} = useParams();
  const channels = useSelector(s => s.channels);
  let [searchParams] = useSearchParams();
  const [selectedChannel, setSelectedchannel] = useState<any>(
    searchParams.get("channelCode"),
  );

  const {
    data: inventories,
    isLoading,
    isFetching,
  } = usePublishedProductInventoryQuery({
    productId,
    params: {channelCode: selectedChannel},
  });

  const loading = isFetching || isLoading;

  return (
    <>
      {loading ? (
        [...Array(4)].map(index => (
          <Skeleton.List key={`inventoryListSkeleton${index}`} />
        ))
      ) : (
        <>
          <div className="flex flex-wrap items-center gap-2">
            {!!channels?.length
              ? channels?.map((channel: any) => (
                  <Button
                    size="sm"
                    variant={
                      selectedChannel === channel.code ? "primary" : "white"
                    }
                    onClick={() => setSelectedchannel(channel.code)}
                  >
                    {channel?.title}
                  </Button>
                ))
              : [...Array(8)].map(() => <Skeleton.ButtonWhite />)}
          </div>
          {!!inventories?.length ? (
            <Table>
              {inventories?.map(
                (
                  inventory: SalesService.GetPimProductAvailableInInventoryResultDto,
                  index: number,
                ) => {
                  const isAvailable =
                    !!inventory.quantities?.iv?.availabletoreserve;
                  return (
                    <tr key={`inventoryListItem${index}`}>
                      <h6 className="text-secondary w-1/3 text-base font-normal">
                        Stock{" "}
                        <span className="uppercase">
                          #{inventory?.dimensions?.locationid}
                        </span>
                      </h6>
                      {isAvailable ? (
                        <p className="flex-1 text-base font-normal text-gray-900">
                          x{inventory.quantities?.iv?.availabletoreserve ?? 0}
                        </p>
                      ) : (
                        <p className="flex-1 text-base font-normal text-warning">
                          <Icon icon="info-circle" />{" "}
                          <Text>products.details.inventoryNotAvailable</Text>
                        </p>
                      )}
                    </tr>
                  );
                },
              )}
            </Table>
          ) : (
            <NoItems />
          )}
        </>
      )}
    </>
  );
}
