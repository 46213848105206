import {NotificationManager} from "common/notifications";
import {
  Button,
  CheckBox,
  Drawer,
  Icon,
  InputGroup,
  Select,
  Text,
} from "components";
import {useDataState, useSelector, useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {useCreatePrinterDefinitionMutation} from "services/caching";
import {toggleProps, AssetService} from "types";

export default function CreateDrawer({isOpen, toggle}: toggleProps) {
  const [data, setData, setBaseData, isChanged] =
    useDataState<AssetService.CreatePrinterDefinitionRequest>({});

  const [createDefinition, {isLoading}] = useCreatePrinterDefinitionMutation();

  const [channelsList, setChannels] = useState<any>([]);

  const channels = useSelector(s => s.channels);

  const getChannels = () => {
    const data = channels.map((element: any) => ({
      name: element.title,
      id: element.code,
    }));
    setChannels(data);
  };

  useEffect(() => {
    getChannels();
  }, [channels]);

  const [isChecked, toggleCheck] = useToggleState();

  const handleSetValue = (
    key: keyof AssetService.CreatePrinterDefinitionRequest,
  ) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  useEffect(() => {
    setBaseData({});
    if (isChecked) {
      toggleCheck();
    }
  }, [isOpen]);

  const OnSubmitHandler = async () => {
    try {
      await createDefinition(data).unwrap();
      toggle();
      NotificationManager.success(
        "global.toast.add-msg",
        "global.toast.add-title",
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex-between">
          <h2 className="text-heading-2">
            <Text>configurations.printerDefinition.addPrinterDefinition</Text>
          </h2>
          <Button type="button" variant="light" size="sm" onClick={toggle}>
            <Icon icon="close" /> <Text>global.buttons.close</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <InputGroup
            disabled={isLoading}
            label={"configurations.printerDefinition.name"}
            value={data?.name}
            setValue={handleSetValue("name")}
          />
          <InputGroup
            disabled={isLoading}
            label={"configurations.printerDefinition.ip"}
            value={data?.ip}
            setValue={handleSetValue("ip")}
          />
          <InputGroup
            disabled={isLoading}
            label={"configurations.printerDefinition.port"}
            value={data?.port}
            type="number"
            setValue={handleSetValue("port")}
          />
          <InputGroup
            disabled={isLoading}
            label={"configurations.printerDefinition.protocol"}
            value={data?.protocol}
            setValue={handleSetValue("protocol")}
          />
          <InputGroup
            disabled={isLoading}
            label={"configurations.printerDefinition.location"}
            value={data?.location}
            setValue={handleSetValue("location")}
          />
          <InputGroup
            disabled={isLoading}
            label={"configurations.printerDefinition.additionalSettings"}
            value={data?.additionalSettings}
            setValue={handleSetValue("additionalSettings")}
          />
          <CheckBox
            label="configurations.printerDefinition.addChannelCode"
            value={isChecked}
            disabled={isLoading}
            setValue={e => {
              toggleCheck();
              handleSetValue("channelCode")(e);
            }}
          />
          <Select
            label="channelMessage.filter.channel"
            disabled={!isChecked}
            placeholder="global.select"
            value={data?.channelCode}
            items={channelsList}
            setValue={handleSetValue("channelCode")}
          />
          <InputGroup
            disabled={isLoading}
            as="textarea"
            label={"configurations.printerDefinition.description"}
            value={data?.description}
            setValue={handleSetValue("description")}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button size="sm" variant={"light"} onClick={toggle} type="button">
            <Text>global.cancel</Text>
          </Button>
          <Button
            size="sm"
            type="button"
            disabled={!isChanged}
            loading={isLoading}
            onClick={OnSubmitHandler}
          >
            <Text>configurations.printerDefinition.addPrinterDefinition</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
