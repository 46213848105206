import {ChannelManagerService, SalesService} from "types";
import {Icon, NoItems, Skeleton, Status, Text} from "components";
import {useConverter} from "hooks";

type Props = {
  channelMessage?: ChannelManagerService.ChannelMessageQueryDtoPagedDto;
  salesChannelMessage?: SalesService.SaleChannelMessageQueryDtoPagedDto;
  salesChannelMessageLoading?: boolean;
  channelMessageLoading?: boolean;
};

export default function ChannelMessage({
  channelMessage,
  channelMessageLoading,
}: Props) {
  const {convertDate} = useConverter();

  return (
    <>
      {channelMessageLoading ? (
        [...Array(3)].map(() => <Skeleton.List />)
      ) : !channelMessage?.items ? (
        <NoItems />
      ) : (
        <>
          {channelMessage?.items?.map(e => {
            const hasError = !!e.errorCode;
            return (
              <div className="space-y-4 bg-white p-4 shadow-items">
                <div className="flex-between">
                  <div className="space-y-4">
                    <h6 className="text-heading-6 text-gray-800">
                      {e.aggregateType}
                    </h6>
                    <p className="text-body-2 text-gray-500">
                      <Text>channelMessage.list.aggregateId</Text>:{" "}
                      {e.aggregateIdentifier}
                    </p>
                  </div>
                  <Status.PimChannelStatus id={e?.status as number} />
                </div>
                <hr className="bg-gray-200" />
                <div className="space-y-4">
                  <p className="text-body-2 text-gray-600">
                    <Text>channelMessage.list.channel</Text>:{" "}
                    <span className="font-bold uppercase">{e.channelCode}</span>
                  </p>
                  <p className="text-body-2 text-gray-600">
                    <Text>channelMessage.list.createdAt</Text>:{" "}
                    <span className="font-bold">
                      {convertDate(e.createdAt)}
                    </span>
                  </p>
                  <p className="text-body-2 text-gray-600">
                    <Text>channelMessage.list.updatedAt</Text>:{" "}
                    <span className="font-bold">
                      {convertDate(e.updatedAt) || "--"}
                    </span>
                  </p>
                </div>
                {hasError && (
                  <p className="text-wrap text-body-2 leading-5 text-warning-active">
                    <Icon icon="circle-info" />{" "}
                    <Text>{`errors.${e.errorCode}`}</Text>
                  </p>
                )}
              </div>
            );
          })}
        </>
      )}
    </>
  );
}
