import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
} from "components";
import {config} from "constant";
import {useToggleState, usePermission} from "hooks";
import {useState} from "react";
import {useSearchParams} from "react-router-dom";
import AddDrawer from "./add-drawer";
import {useProductGroupsQuery} from "services/caching";

type Params = {
  pageNumber?: string;
  pageSize?: string;
  keyword?: string;
};

export default function ProductGroup() {
  const [isOpenDrawerCreate, toggleDrawerCreate] = useToggleState(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams(originalFilterData);
  const queryParams = Object.fromEntries(params.entries());

  const {data, isLoading, isFetching, refetch} =
    useProductGroupsQuery(queryParams);

  const loadingList = isLoading || isFetching;

  const isFilter = ["keyword"].some(item => params.get(item));

  const handleSetParams = (key: keyof Params) => {
    const isPageNumber = key === "pageNumber";
    return (value: string | number | null) => {
      setParams(p => {
        const hasValue = !!String(value ?? "");
        hasValue ? p.set(key, `${value}`) : p.delete(key);
        !isPageNumber && p.set("pageNumber", `1`);
        return p;
      });
    };
  };

  const handleUpdate = (id: string) => {
    if (GET_DETAIL_PERMISSION) {
      setSelectedId(id);
      toggleDrawerCreate();
    }
  };

  const CREATE_PERMISSION = usePermission("PS_CreatePimProductGroup");
  const GET_DETAIL_PERMISSION = usePermission("PS_GetPimProductGroup");

  const resetFilter = () => {
    setParams(originalFilterData);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        {loadingList ? (
          <Skeleton.Button />
        ) : CREATE_PERMISSION ? (
          <Button
            type="button"
            onClick={toggleDrawerCreate}
            size="sm"
            aria-label="cy-product-group-add"
          >
            <Icon className="mr-2" icon={"plus"} />
            <Text>
              productManagement.masterData.productGroup.addProductGroup
            </Text>
          </Button>
        ) : null}
      </div>
      <div className="flex-between">
        <SearchBox
          value={params?.get("keyword") || ""}
          onSubmit={handleSetParams("keyword")}
          disabled={loadingList}
          totalItems={data?.totalItems}
          onReload={refetch}
          loading={loadingList}
        />
        {isFilter && (
          <Button
            disabled={loadingList}
            variant={"white"}
            textColor="danger"
            size="sm"
            onClick={resetFilter}
          >
            <Icon icon="circle-xmark"></Icon>
            <span className="ml-1">Reset Filter</span>
          </Button>
        )}
      </div>
      {loadingList ? (
        <>
          {[...Array(8)].map(() => (
            <Skeleton.List />
          ))}
        </>
      ) : (
        <>
          {data &&
          Object.keys(data).length !== 0 &&
          data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div
                          className={`space-y-4 ${GET_DETAIL_PERMISSION && "cursor-pointer"}`}
                          onClick={() => handleUpdate(item.id as string)}
                        >
                          <h6 className="text-heading-6">{item.name}</h6>
                          <div>
                            <span className="text-body-2 text-gray-500">
                              {item.code}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-left text-body-base text-gray-600">
                        {item.description}
                      </td>
                      <td>
                        {GET_DETAIL_PERMISSION && (
                          <Button
                            variant="light"
                            size="sm"
                            onClick={() => handleUpdate(item.id as string)}
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams(p => ({...p, pageSize: e}))
                }
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
          <AddDrawer
            isOpen={isOpenDrawerCreate}
            toggle={toggleDrawerCreate}
            getData={refetch}
            id={selectedId}
            clearSelected={setSelectedId}
          />
        </>
      )}
    </div>
  );
}
