import {useEffect, useState} from "react";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {config, calculationLevelEnum} from "constant";
import {InfoSection, LeftSide} from "../common";
import {useSuppliersQuery} from "services/caching";

export default function Supplier() {
  const [data, setData] = useState<any>([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: undefined,
    suppliertype: 0,
  });

  const {data: supplierData, isLoading, isFetching} = useSuppliersQuery(params);

  const loading = isFetching || isLoading;

  useEffect(() => {
    if (!!supplierData) {
      const clone = supplierData?.items?.map((supplier: any) => ({
        id: supplier.supplierId,
        name: supplier.name,
        code: supplier.code,
      }));
      setData(clone);
    }
  }, [supplierData]);

  return (
    <div className="flex gap-8">
      <LeftSide
        title="priceEngine.calculationLevel.suppliers"
        loading={loading}
        list={data}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        setParams={setParams}
        setSelectedItem={setSelectedItem}
      />
      <div className="w-full space-y-4">
        {selectedId && (
          <InfoSection
            refId={selectedId}
            level={calculationLevelEnum.supplier}
            refCode={selectedItem?.code}
            refTitle={selectedItem?.name}
          />
        )}
      </div>
    </div>
  );
}
