import {useContext, useEffect, useState} from "react";
import {
  Button,
  Icon,
  LazyImage,
  NoItems,
  Skeleton,
  Status,
  Table,
  Text,
  Wrapper,
} from "components";
import {useConverter, useSelector, useToggleState} from "hooks";
import {AuctionContext} from "..";
import {SalesService} from "types";
import AddProductsDrawer from "../drawers/add-products";
import UpdateProduct from "../drawers/update-product";

export default function Products() {
  const [isOpen, toggle] = useToggleState();
  const [isOpenEdit, toggleEdit] = useToggleState();
  const {convertAmount, convertDate} = useConverter();
  const channels = useSelector(s => s.channels);
  const [channelsList, setChannels] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] =
    useState<SalesService.AuctionPimProductsQueryDto>();

  const {data, loading} = useContext(AuctionContext);

  const findChannelName = (channelCode: string) => {
    const channel: any = channelsList?.find(
      (channel: any) => channel.code === channelCode,
    );
    return channel ? channel.title : "";
  };

  useEffect(() => {
    setChannels(channels);
  }, [channels]);

  return (
    <>
      <Wrapper>
        <Wrapper.Header className="flex items-center justify-between">
          <span>
            <Text>Items</Text>
          </span>
          <Button size="sm" light onClick={toggle} disabled={loading}>
            <Icon icon="plus" />
            <Text>Add Item</Text>
          </Button>
        </Wrapper.Header>
        <Wrapper.Body className="!px-4">
          {loading ? (
            [...Array(3)].map(() => <Skeleton.ImageList />)
          ) : (
            <>
              {!!data?.pimProducts?.length ? (
                <Table>
                  {data?.pimProducts?.map(
                    (product: SalesService.AuctionPimProductsQueryDto) => {
                      return (
                        <tr className="!shadow-nested">
                          <td>
                            <div
                              className="flex w-fit gap-4"
                              // replace={!getDetailsPermission}
                            >
                              <div className="flex items-center">
                                <Table.Image
                                  className="w-unset"
                                  src={product.avatarUrl}
                                  alt={product.avatarUrl as string}
                                />
                              </div>
                              <div className="ml-2 space-y-2 text-wrap py-2">
                                <h6 className="text-heading-6 font-semibold leading-6 text-gray-800">
                                  {product.originalName}
                                </h6>
                                <Status.PimProductType
                                  id={product.pimProductType as number}
                                />
                                <h6 className="text-heading-6  leading-6 text-gray-600">
                                  Channel :{" "}
                                  <span className="rounded bg-gray-100 px-2 py-1 text-body-3 text-primary">
                                    {findChannelName(
                                      product.channelCode as string,
                                    )}
                                  </span>
                                </h6>
                                <div className="block space-y-2 xl:hidden">
                                  {product.articleNumber && (
                                    <div className="text-body-2 font-medium text-gray-500">
                                      <p>
                                        Article Number#: {product.articleNumber}
                                      </p>
                                    </div>
                                  )}
                                  {product.supplierArticleNumber && (
                                    <div className="text-body-2 font-medium text-gray-500">
                                      <p>
                                        supplierArticleNumber#:{" "}
                                        {product.supplierArticleNumber}
                                      </p>
                                    </div>
                                  )}
                                  {product.pimProductGroupName && (
                                    <div className="text-body-2 font-medium text-gray-500">
                                      <p>
                                        pimProductGroupName:{" "}
                                        {product.pimProductGroupName}
                                      </p>
                                    </div>
                                  )}
                                  {product.lastPublishedDate && (
                                    <div className="text-body-2 font-medium text-gray-500">
                                      <p>
                                        Last published date:{" "}
                                        {convertDate(product.lastPublishedDate)}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                {product.brandName && (
                                  <div className="flex items-center gap-2">
                                    <h6 className="text-heading-6  leading-6 text-gray-800">
                                      {product.brandName}
                                    </h6>
                                    {product.brandLogo && (
                                      <LazyImage
                                        isDynamic
                                        className="h-[30px] w-[40px]"
                                        src={product.brandLogo}
                                        loadingSize="sm"
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className=" hidden space-y-2 text-left text-body-2 font-medium text-gray-600 xl:table-cell">
                            {product.articleNumber && (
                              <div className="text-body-2 font-medium text-gray-500">
                                <p>Article Number#: {product.articleNumber}</p>
                              </div>
                            )}
                            {product.supplierArticleNumber && (
                              <div className="text-body-2 font-medium text-gray-500">
                                <p>
                                  supplierArticleNumber#:{" "}
                                  {product.supplierArticleNumber}
                                </p>
                              </div>
                            )}
                            {product.pimProductGroupName && (
                              <div className="text-body-2 font-medium text-gray-500">
                                <p>
                                  pimProductGroupName:{" "}
                                  {product.pimProductGroupName}
                                </p>
                              </div>
                            )}
                            {product.lastPublishedDate && (
                              <div className="text-body-2 font-medium text-gray-500">
                                <p>
                                  Last published date:{" "}
                                  {convertDate(product.lastPublishedDate)}
                                </p>
                              </div>
                            )}
                          </td>
                          <td className=" space-y-4 text-left">
                            {product?.vk1Price?.amount !==
                              product?.vk2Price?.amount && (
                              <h6 className="text-heading-6 font-normal text-gray-500 line-through">
                                <span className="font-normal">vk1 : </span>
                                {convertAmount(product?.vk1Price?.amount)}
                              </h6>
                            )}
                            <h6 className="text-heading-6 font-bold text-primary ">
                              <span className="font-normal text-gray-800">
                                Auction price :{" "}
                              </span>
                              {convertAmount(product?.auctionPrice?.amount)}
                            </h6>
                          </td>
                          <td className=" space-y-4 ">
                            <Button
                              size="sm"
                              variant={"light"}
                              onClick={() => {
                                toggleEdit();
                                setSelectedProduct(product);
                              }}
                            >
                              <Icon icon="sliders" />
                            </Button>
                          </td>
                        </tr>
                      );
                    },
                  )}
                </Table>
              ) : (
                <NoItems />
              )}
            </>
          )}
        </Wrapper.Body>
      </Wrapper>
      <AddProductsDrawer isOpen={isOpen} toggle={toggle} />
      <UpdateProduct
        isOpen={isOpenEdit}
        toggle={toggleEdit}
        product={selectedProduct}
      />
    </>
  );
}
