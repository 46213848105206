import {
  Button,
  DatePicker,
  Footer,
  InputGroup,
  Skeleton,
  WithPermission,
  Wrapper,
  Text,
  Form,
  CheckBox,
} from "components";
import {AuctionContext} from "..";
import {useContext} from "react";
import {rules} from "constant";
import {useParams} from "react-router";
import {NotificationManager} from "common/notifications";
import {useUpdateAuctionMutation} from "services/caching";

export default function General() {
  const {auctionId} = useParams();

  const [updateAuction, {isLoading}] = useUpdateAuctionMutation();

  const {data, loading, isChanged, onChangeHandler, getData} =
    useContext(AuctionContext);

  const onSubmit = async () => {
    try {
      await updateAuction({data, id: auctionId}).unwrap();
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title",
      );
    } catch (error) {}
  };

  return (
    <Form onSubmit={onSubmit}>
      <Wrapper>
        <Wrapper.Header>
          <Text>salesManagment.auctions.basicInfo</Text>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-2 gap-4">
          <div className="space-y-6">
            {loading ? (
              <Skeleton.Input withLabel />
            ) : (
              <InputGroup
                label={"salesManagment.auctions.title"}
                value={data?.title}
                setValue={onChangeHandler("title")}
                rules={rules.required}
              />
            )}
            <div className="grid grid-cols-2 gap-x-2 gap-y-1">
              {loading ? (
                [...Array(2)].map(() => <Skeleton.Input withLabel />)
              ) : (
                <>
                  <DatePicker
                    label={"salesManagment.auctions.startAt"}
                    showYearDropdown
                    showMonthDropdown
                    rules={rules.required}
                    value={data?.startDate}
                    minDate={new Date()}
                    onChange={onChangeHandler("startDate")}
                    maxDate={data.endDate}
                  />
                  <DatePicker
                    label={"salesManagment.auctions.endAt"}
                    showYearDropdown
                    showMonthDropdown
                    rules={rules.required}
                    value={data.endDate}
                    onChange={onChangeHandler("endDate")}
                    minDate={data?.startDate}
                  />
                </>
              )}
            </div>
            {loading ? (
              <>
                <Skeleton.Input />
                <Skeleton.Input />
              </>
            ) : (
              <>
                <CheckBox
                  label="salesManagment.auctions.disableOptionalDiscounts"
                  value={data?.disableOptionalDiscounts as boolean}
                  setValue={onChangeHandler("disableOptionalDiscounts")}
                  labelClassName="text-gray-800 text-md"
                />
                <CheckBox
                  label="salesManagment.auctions.disableIndividualDiscounts"
                  value={data?.disableIndividualDiscounts as boolean}
                  setValue={onChangeHandler("disableIndividualDiscounts")}
                  labelClassName="text-gray-800 text-md"
                />
              </>
            )}
          </div>
          {loading ? (
            <Skeleton.TextArea withLabel />
          ) : (
            <InputGroup
              as={"textarea"}
              label={"salesManagment.auctions.shortDescription"}
              value={data?.shortDescription}
              setValue={onChangeHandler("shortDescription")}
            />
          )}
        </Wrapper.Body>
        {/* <WithPermission permissions={["SC_UpdateSaleChannel"]}> */}
        {!loading && isChanged && (
          <Footer>
            <Button type="submit" variant="primary" loading={isLoading}>
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        )}
        {/* </WithPermission> */}
      </Wrapper>
    </Form>
  );
}
