import PrinterDefinition from "pages/configurations/printer-definition";

const PrinterDefinitionRuote = [
  {
    path: "",
    element: <PrinterDefinition />,
  },
];

export default PrinterDefinitionRuote;
