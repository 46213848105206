import {
  AddressForm,
  Button,
  Footer,
  InputGroup,
  Text,
  Wrapper,
} from "components";
import {rules} from "constant";
import {usePermission} from "hooks";
import React, {useContext} from "react";
import {ProfileContext} from ".";

const General: React.FunctionComponent = () => {
  const {
    profileData,
    setProfileData,
    isChanged,
    submitLoading,
    addressErrorMsg,
  } = useContext<any>(ProfileContext);
  const updatePermissions = usePermission("AS_UpdateMyCompany");
  const onChangeHandler = (key: any) => {
    return (value: any) => setProfileData((p: any) => ({...p, [key]: value}));
  };

  return (
    <>
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>company.profile.basicInfo</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="space-y-8">
            <InputGroup
              value={profileData.name}
              setValue={onChangeHandler("name")}
              label="company.profile.companyName"
              rules={rules.required}
              disabled={!updatePermissions}
            />
            <InputGroup
              value={profileData.phoneNumber}
              setValue={onChangeHandler("phoneNumber")}
              label="company.profile.phoneNumber"
              disabled={!updatePermissions}
            />
            <InputGroup
              value={profileData.mobileNumber}
              setValue={onChangeHandler("mobileNumber")}
              label="company.profile.mobileNumber"
              disabled={!updatePermissions}
            />
            <InputGroup
              as="short-textarea"
              value={profileData.description}
              setValue={onChangeHandler("description")}
              label="company.profile.shortDescription"
              disabled={!updatePermissions}
            />
          </div>
          <section className="space-y-8">
            <AddressForm
              data={profileData}
              setData={setProfileData}
              errorMsg={addressErrorMsg}
            />
          </section>
        </Wrapper.Body>
      </Wrapper>
      <Footer show={!isChanged}>
        <Button type="submit" variant="primary" loading={submitLoading}>
          <Text>global.buttons.saveChanges</Text>
        </Button>
      </Footer>
    </>
  );
};

export default General;
