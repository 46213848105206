import {AxiosRequestConfig} from "axios";
import {config} from "constant";
import {Dispatch, useCallback, useEffect, useRef, useState} from "react";
import {ServiceApi} from "services";
import {Stimulsoft} from "stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor";
import {toggleProps} from "types";
import {randomId} from "utils";
import Icon from "./Icon";
import LoadingSvg from "./LoadingSvg";
import Modal from "./Modal";

type Value = {type: string; url: string};

type Props = {
  type: number;
  value?: Value | null | undefined;
  setValue?: Dispatch<Value>;
  dataSourceUrl?: string | null;
} & toggleProps;

const license = config.stimulsoft.license;

if (license) {
  Stimulsoft.Base.StiLicense.Key = license;
}

export default function StimulsoftDesigner({
  isOpen,
  toggle,
  type,
  value,
  setValue,
  dataSourceUrl = null,
}: Props) {
  const [loading, setLoading] = useState(false);
  const optionsRef = useRef(new Stimulsoft.Designer.StiDesignerOptions());
  const designerRef = useRef(
    new Stimulsoft.Designer.StiDesigner(
      optionsRef.current,
      "StiDesigner",
      false,
    ),
  );
  const close = () => {
    toggle();
  };
  const save = () => {
    setLoading(true);
    const report = designerRef.current.report;
    //   const json = report.saveDocumentToJsonString();
    const fileBytes = report.savePackedToByteArray();
    const fileName = [report.reportAlias, randomId()].join("-").toLowerCase();
    const fileExtension = "mrt";
    const url = `/assetservice/api/assets/${type}/as-binary`;
    const body = {fileBytes, fileName, fileExtension, metadataRequests: []};
    ServiceApi.post(url, body)
      .then(({data}) => {
        setValue?.({type: data.type, url: data.url});
        toggle();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const reportHandler = useCallback(async () => {
    const report = new Stimulsoft.Report.StiReport();
    const url = value?.url ?? null;
    const baseFile = "/Report.mrt";
    const config: AxiosRequestConfig = {
      responseType: "arraybuffer",
      params: {withoutSubscriptionKey: true},
    };
    if (!url) {
      report.loadFile(baseFile);
      return report;
    }
    if (!url.includes("/get-file")) {
      report.loadDocument(url);
      return report;
    }
    await ServiceApi.get(url, config)
      .then(({data}) => {
        const arrayBuffer = new Uint8Array(data);
        report.loadDocument(arrayBuffer);
      })
      .catch(() => {
        report.loadFile(baseFile);
      });
    return report;
  }, [value?.url]);
  const getDataSource = useCallback(async () => {
    if (!dataSourceUrl) return Promise.resolve(null);
    return await ServiceApi.get(dataSourceUrl)
      .then(({data}) => data as object)
      .catch(() => null);
  }, [dataSourceUrl]);
  useEffect(() => {
    if (!isOpen) return;
    setLoading(true);
    getDataSource()
      .then(async data => {
        const options = optionsRef.current;
        const designer = designerRef.current;
        const report = await reportHandler();
        if (!report) return;
        report.dictionary.clear();
        const jsonString = JSON.stringify(data);
        const dataSet = new Stimulsoft.System.Data.DataSet();
        const variableType = Stimulsoft.System.Drawing.Image;
        const variables = new Stimulsoft.Report.Dictionary.StiVariable(
          undefined,
          "CustomerSign",
          undefined,
          undefined,
          variableType,
        );
        report.dictionary.variables.add(variables);
        designer.report = report;
        dataSet.readJson(jsonString);
        report.regData("DataSource", "DataSource", dataSet);
        report.dictionary.synchronize();
        options.appearance.fullScreenMode = true;
        options.appearance.allowChangeWindowTitle = false;
        designer.onSaveReport = (e: any) => {
          e.preventDefault = true;
          save();
        };
        designer.onCloseReport = () => close();
        designer.renderHtml("designer");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isOpen, reportHandler, getDataSource]);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="relative h-[97.5%] w-[97.5%] !max-w-full"
    >
      {!loading && (
        <button
          type="button"
          className="absolute end-2 top-2 z-[100000] flex size-6 cursor-pointer items-center justify-center rounded-full bg-danger/10 text-danger transition-colors hover:bg-danger hover:text-white"
          onClick={toggle}
        >
          <Icon icon="close" className="size-4" />
        </button>
      )}
      <Modal.Body
        id="designer"
        className="size-full overflow-auto"
      ></Modal.Body>
      {loading && (
        <div className="flex-center absolute inset-0 z-[10000] flex size-full backdrop-blur">
          <LoadingSvg />
        </div>
      )}
    </Modal>
  );
}
