import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Form,
  Icon,
  ImageUploader,
  LazyImage,
  Skeleton,
  Status,
  Tabs,
  Text,
  Wrapper,
} from "components";
import {useDataState, usePermission, useToggleState, useTranslate} from "hooks";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import {useParams} from "react-router";
import {ServiceApi, URLS} from "services";
import {Media} from "types";

//... tabs
import {assetTypes} from "constant";
import {ValidateAddress} from "utils";
import Employee from "./tabs/employee";
import General from "./tabs/general";
import SalesChannels from "./tabs/salesChannels";

export const UserContext = createContext({});
export default function Details() {
  const [isOpen, toggle] = useToggleState(false);
  const {userId} = useParams();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [headerData, setHeaderData] = useState<any>({});
  const [data, setData, setBaseData, isChanged, base] = useDataState<any>({});
  const [
    salesChannelsData,
    setSalesChannelsData,
    setBaseSalesChannelsData,
    isChangedSalesChannels,
    salesChannelBase,
  ] = useDataState<any>({});
  const [storeData, setStoreData, setBaseStoreData, isChangedStore, storeBase] =
    useDataState<any>({});
  const [filterStore, setFilterStore] = useState<any>(null);
  const [addressErrorMsg, setAddressErrorMasg] = useState("");
  const translate = useTranslate();

  const updatePermissions = usePermission("AS_UpdateUser");

  let tabs: any = [];

  tabs = [
    {
      label: "configurations.userManagment.userList.general",
      component: General,
    },
    // {
    //   label: "configurations.userManagment.userList.stores",
    //   component: Store,
    // },
    {
      label: "configurations.userManagment.userList.salesChannels",
      component: SalesChannels,
    },
    {
      label: "configurations.userManagment.userList.employee",
      component: Employee,
    },
  ];

  const getData = () => {
    const url = URLS.B2B_USER_URL;
    ServiceApi.get(url)
      .then(({data}) => {
        let result = JSON.parse(JSON.stringify(data));
        setBaseData(result);
        setHeaderData(result);
        setFilterStore(data?.storeDefaultFilter?.id);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(getData, []);

  const submit = () => {
    setAddressErrorMasg("");
    setSubmitLoading(true);
    if (!data.address) submitRequest();
    else
      ValidateAddress(data.address).then(res => {
        if (!res?.selected && !res.suggested) {
          setAddressErrorMasg(
            translate("global.locations.invalidAddress") as string,
          );
          setSubmitLoading(false);
          return;
        }
        if (res.selected) {
          setData({
            ...data,
            address: {
              ...res.selected,
              name: data.address.name,
              number: data.address.number,
            },
          });
        }
        if (res.suggested) {
          setData({
            ...data,
            address: {
              ...res.suggested,
              name: data.address.name,
              number: data.address.number,
            },
          });
          setAddressErrorMasg(
            translate("global.locations.changedAddress") as string,
          );
          setSubmitLoading(false);
          return;
        }
        submitRequest();
      });
  };

  const submitRequest = () => {
    const url = URLS.UPDATE_B2B_USERS_URL(userId);
    const body = cloneDeep(data);
    body.alternativeEmailAddress ||= null;
    ServiceApi.put(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        setBaseData(data);
      })
      .catch(() => {})
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const onUploadImage = (data: Media) => {
    const url = URLS.UPLOAD_USER_IMAGES(userId);
    const body = {
      url: data.url,
      type: data.type,
      thumbnailUrl: data.thumbnailUrl,
    };
    ServiceApi.patch(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .finally(() => {
        getData();
      });
  };

  return (
    <Tabs
      activeKey={tabs[0].label}
      checkSave={isChanged || isChangedStore}
      onSave={() => {
        if (isChanged) {
          setData(base);
        } else if (isChangedSalesChannels) {
          setBaseSalesChannelsData(salesChannelBase);
        } else if (isChangedStore) {
          setBaseStoreData(storeBase);
        }
      }}
    >
      <Form onSubmit={submit} className="relative space-y-6">
        <Breadcrumb />
        <Wrapper>
          <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row">
            <div className="h-[176px] w-[176px]">
              <LazyImage
                isDynamic
                placeholder="user"
                onClick={() => toggle()}
                src={data?.avatar?.url}
                alt={data?.avatar?.id}
                editor
                className="aspect-image h-full w-full cursor-pointer lg:aspect-auto"
              />
            </div>
            <div className="space-y-4 lg:flex-1">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <h5 className="text-heading-5 font-semibold text-gray-800">
                        {`${headerData.firstName} ${headerData.lastName}`}
                      </h5>
                      <Status.roles id={headerData.userType} />
                    </div>
                    {headerData.emailAddress && (
                      <div className="flex items-center gap-2">
                        <Icon
                          className="font-black text-gray-500"
                          size="1x"
                          icon="envelope"
                        />
                        <div>
                          <span className="text-body-2 font-medium text-gray-500">
                            {headerData.emailAddress}
                          </span>
                        </div>
                      </div>
                    )}
                    {headerData.mobileNumber && (
                      <div className="flex items-center gap-2">
                        <Icon
                          className="font-black text-gray-500"
                          size="1x"
                          icon="mobile"
                        />
                        <div>
                          <span className="text-body-2 font-medium text-gray-500">
                            {headerData.mobileNumber}
                          </span>
                        </div>
                      </div>
                    )}
                    {headerData.userGroupName && (
                      <div className="flex items-center gap-2">
                        <Icon
                          className="font-black text-gray-500"
                          size="1x"
                          icon="users-line"
                        />
                        <div>
                          <span className="text-body-2 font-medium text-gray-500">
                            {headerData.userGroupName}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map((e: any) => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading && (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        )}
        {tabs.map((e: any) => (
          <Tabs.Item key={e.label} eventKey={e.label}>
            <UserContext.Provider
              value={{
                userData: data,
                setUserData: setData,
                loading: loading,
                getData: getData,
                filterStore: filterStore,
                setFilterStore: setFilterStore,
                UPDATE_PERMISSIONS: updatePermissions,
                isChanged: isChanged,
                submitLoading: submitLoading,
                salesChannelsData,
                setSalesChannelsData,
                setBaseSalesChannelsData,
                isChangedSalesChannels,
                storeData,
                setStoreData,
                setBaseStoreData,
                isChangedStore,
                addressErrorMsg,
              }}
            >
              {!isEmpty(data) && createElement(e.component)}
            </UserContext.Provider>
          </Tabs.Item>
        ))}
      </Form>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={data.avatar?.url}
        onUpload={(data: Media) => onUploadImage(data)}
        type={assetTypes.Company}
      />
    </Tabs>
  );
}
