import {NotificationManager} from "common/notifications";
import {
  Button,
  Drawer,
  Icon,
  InputGroup,
  LazyImage,
  Status,
  Text,
  DeleteModal,
} from "components";
import {useConverter, useSelector, useToggleState} from "hooks";
import {useContext, useEffect, useState} from "react";
import {SalesService, toggleProps} from "types";
import {AuctionContext} from "..";
import {
  useRemoveAuctionProductMutation,
  useUpdateAuctionsingleProductMutation,
} from "services/caching";

type Props = toggleProps & {product?: SalesService.AuctionPimProductsQueryDto};

export default function UpdateProduct({
  isOpen,
  toggle,
  product: originalProduct,
}: Props) {
  const {convertAmount} = useConverter();
  const channels = useSelector(s => s.channels);
  const {data} = useContext(AuctionContext);
  const [updateAuctionProduct, {isLoading: submitLoading}] =
    useUpdateAuctionsingleProductMutation();
  const [deleteAuctionProduct, {isLoading: deleteLoading}] =
    useRemoveAuctionProductMutation();
  const [channelsList, setChannels] = useState<any>([]);
  const [product, setProduct] = useState<any>([]);

  const [isOpenDelete, toggleDelete] = useToggleState();

  useEffect(() => {
    setChannels(channels);
  }, [channels]);

  useEffect(() => {
    if (isOpen) {
      setProduct(originalProduct);
    }
  }, [isOpen]);

  const findChannelName = (channelCode: string) => {
    const channel: any = channelsList?.find(
      (channel: any) => channel.code === channelCode,
    );
    return channel ? channel.title : "";
  };

  const onDeleteHandler = async () => {
    const params = {
      channelCode: product?.channelCode,
      pimProductId: product?.id,
    };
    try {
      await deleteAuctionProduct({params, id: data?.id}).unwrap();
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title",
      );
      toggle();
      toggleDelete();
    } catch (error) {}
  };

  const onChangePriceHandler = (value: string) => {
    const price = {
      currency: "EUR",
      amount: value,
      currencySymbol: "€",
    };
    let clone: any = JSON.parse(JSON.stringify(product));
    if (clone.auctionPrice) {
      clone.auctionPrice = price;
    }
    setProduct(clone);
  };

  const onSubmitHandler = async () => {
    const bodyProduct = {
      pimProductId: product.id,
      price: product.auctionPrice,
      channelCode: product.channelCode,
    };
    const body = {product: bodyProduct};

    try {
      await updateAuctionProduct({data: body, id: data?.id}).unwrap();
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title",
      );
      toggle();
    } catch (error) {}
  };
  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header className="flex-between">
            <span className="text-heading-2 font-semibold text-gray-800">
              <Text>salesManagment.auctions.editProduct</Text>
            </span>
            <div className="flex items-center gap-2">
              <Button size="sm" variant={"danger"} light onClick={toggleDelete}>
                <Icon icon="trash" />
                <Text>global.buttons.delete</Text>
              </Button>
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="close" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </Drawer.Header>
          <Drawer.Body>
            <div className={`flex flex-col rounded bg-white px-4 shadow-card`}>
              <div className="flex items-center gap-4 ">
                <div
                  className={` flex-center h-24 w-32 overflow-hidden rounded border border-gray-200 bg-gray-100`}
                >
                  <LazyImage
                    className={`h-full w-full object-cover`}
                    src={product?.avatarUrl}
                    alt={product?.avatarUrl as string}
                    isDynamic
                    imageClassName={"w-full h-full"}
                  />
                </div>
                <div className="flex-1 space-y-2 text-wrap py-2">
                  <h6 className="text-heading-6 font-semibold leading-6 text-gray-800">
                    {product?.originalName}
                  </h6>
                  <Status.PimProductType
                    id={product?.pimProductType as number}
                  />

                  <div className="text-body-2">
                    <p className="text-gray-600">
                      <Text>salesManagment.auctions.articleNumber</Text>
                      #: {product?.articleNumber ?? "----------"}
                    </p>
                  </div>

                  <p className="text-body-2  leading-6 text-gray-600">
                    <Text>salesManagment.auctions.channel</Text> :{" "}
                    <span className="rounded bg-gray-100 px-2 py-1 text-body-3 font-medium text-primary">
                      {findChannelName(product?.channelCode as string)}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col gap-6">
                  <span className="text-body-2 text-gray-700">
                    vk1: {convertAmount(product?.vk1Price?.amount)}
                  </span>
                </div>
              </div>
              {originalProduct?.pimProductType !== 1 && (
                <div className="border-t border-gray-200 py-4">
                  <div className="w-3/4">
                    <InputGroup
                      label={"salesManagment.auctions.overWriteSalePrice"}
                      value={product?.auctionPrice?.amount}
                      setValue={e => {
                        if (+e <= +product?.vk1Price?.amount)
                          onChangePriceHandler(e);
                      }}
                      type="price"
                    />
                  </div>
                </div>
              )}
            </div>
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-between">
            <Button size="sm" onClick={toggle} variant={"light"} type="button">
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button
              size="sm"
              type="button"
              onClick={onSubmitHandler}
              loading={submitLoading}
            >
              <Text>global.buttons.submit</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <DeleteModal
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        selected={{name: product?.originalName}}
        loading={deleteLoading}
        onConfirm={onDeleteHandler}
      />
    </>
  );
}
