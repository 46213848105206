export const printerDefinition = {
  addPrinterDefinition: "Add Printer Definition",
  editPrinterDefinition: "Edit Printer Definition",
  ip: "IP",
  port: "Port",
  protocol: "Protocol",
  location: "Location",
  name: "Name",
  additionalSettings: "Additional Settings",
  addChannelCode: "Add channel Code",
  editChannelCode: "Edit channel Code",
  description: "Description",
  updateInfo: "Update Info",
  channelCode: "Channel Code",
};
