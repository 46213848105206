import {InteractionRequiredAuthError} from "@azure/msal-browser";
import Axios from "axios";
import store from "store";
import {toggleLoading} from "store/loading";
import {authInstance, config, loginRequest} from "../constant";
import {ErrorHandler} from "./error-handler";

let retryCount = 0;
const maxRetryCount = 5;
const data = {};

const canRetry = () => {
  return retryCount <= maxRetryCount;
};
const resetRetryCount = () => {
  retryCount = 0;
};
const incrementRetry = () => {
  retryCount += 1;
};

export const getLocalIdToken = () => {
  const homeAccountId = authInstance?.getAllAccounts()[0]?.homeAccountId;
  const Idtoken = `${homeAccountId}-${`${config.tenant}.b2clogin.com`}-idtoken-${
    config.clientId
  }----`;
  const token = JSON.parse(sessionStorage.getItem(Idtoken)) || "";
  return token;
};

const getExpireTime = () => {
  const homeAccountId = authInstance?.getAllAccounts()[0]?.homeAccountId;
  const Idtoken = `${homeAccountId}-${`${config.tenant}.b2clogin.com`}-`;
  const data = JSON.parse(sessionStorage.getItem(Idtoken)) || "";
  const expDate = data?.idTokenClaims?.exp;
  return expDate;
};

export const getAuthData = async () => {
  try {
    return await authInstance.acquireTokenSilent(loginRequest);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return authInstance.acquireTokenRedirect(loginRequest);
    }
  }
};

const ServiceApi = Axios.create({
  baseURL: config.baseUrl,
});

ServiceApi.interceptors.request.use(
  async req => {
    store.dispatch(toggleLoading(true));
    let currentToken = "";
    const currentDate = Math.floor(new Date().getTime() / 1000);
    const token = getLocalIdToken();
    const expDate = getExpireTime();
    if (expDate > currentDate) {
      currentToken = token?.secret;
    } else {
      const token = await getAuthData();
      currentToken = token?.idToken;
    }
    if (currentToken) {
      req.headers["Authorization"] = `Bearer ${currentToken}`;
      if (!req.params?.withoutSubscriptionKey) {
        req.headers["Ocp-Apim-Subscription-Key"] = config.subscriptionKey;
      }
      return req;
    }
    authInstance.loginRedirect(loginRequest);
    return req;
  },
  err => {
    if (err.code !== "ERR_CANCELED") {
      if (err?.response?.status !== 403 || err?.response?.status !== 401) {
        ErrorHandler(err);
      }
      return Promise.reject(err);
    }
  },
);
ServiceApi.interceptors.response.use(
  res => {
    store.dispatch(toggleLoading(false));
    const key = `${res.config.url}${JSON.stringify(res.config.params)}`;
    const value = res.data;
    data[key] = value;
    resetRetryCount();
    return res;
  },
  async err => {
    store.dispatch(toggleLoading(false));
    if (err.code !== "ERR_CANCELED") {
      if (err?.response?.status === 401) {
        if (canRetry()) {
          incrementRetry();
          const request = err.config;
          const token = await getAuthData();
          request.headers["Authorization"] = `Bearer ${token?.idToken}`;
          return ServiceApi.request(request);
        }
      }
      if (
        ![401, 403].includes(err?.response?.status) &&
        !err.config.url.includes("/get-file")
      ) {
        ErrorHandler(err);
      }
    }
    return Promise.reject(err);
  },
);

export default ServiceApi;
