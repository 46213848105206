import {
  Breadcrumb,
  Tabs,
  Wrapper,
  Text,
  Button,
  Icon,
  DeleteModal,
} from "components";
import {createContext, createElement, useEffect, useState} from "react";
import General from "./tabs/general";
import Products from "./tabs/products";
import {useDataState, useToggleState} from "hooks";
import {useNavigate, useParams} from "react-router";
import {SalesService} from "types";
import {NotificationManager} from "common/notifications";
import {
  useRemoveAuctionMutation,
  useAuctionDetailsQuery,
} from "services/caching";

type ContextType = {
  data: SalesService.AuctionQueryDto;
  loading: boolean;
  isChanged: boolean;
  existedProductsIds: string[];
  onChangeHandler: any;
  getData: any;
};

export const AuctionContext = createContext<ContextType>({
  data: {},
  loading: false,
  isChanged: false,
  existedProductsIds: [],
  onChangeHandler: (key: keyof SalesService.AuctionQueryDto) => {},
  getData: () => {},
});

export default function AuctionDetails() {
  const tabs = [
    {
      label: "salesManagment.auctions.general",
      component: General,
    },
    {
      label: "salesManagment.auctions.products",
      component: Products,
    },
  ];

  const {auctionId} = useParams();
  const navigate = useNavigate();
  const [removeAuction, {isLoading: deleteLoading}] =
    useRemoveAuctionMutation();

  const [existedProductsIds, dispatchExsitedProductIds] = useState<any>([]);
  const [isOpenDelete, toggleDelete] = useToggleState();

  const [data, setData, setBaseData, isChanged, base] =
    useDataState<SalesService.AuctionQueryDto>({});

  const {
    data: auctionDetails,
    isLoading,
    isFetching,
    refetch,
  } = useAuctionDetailsQuery(auctionId);

  const loading = isFetching || isLoading;

  useEffect(() => {
    if (!!auctionDetails) {
      setBaseData(auctionDetails);
      const ids = auctionDetails?.pimProducts?.map(
        (product: SalesService.AuctionPimProductsQueryDto) =>
          product.id + (product?.channelCode as string),
      );
      dispatchExsitedProductIds(ids);
    }
  }, [auctionDetails]);

  const onChangeHandler = (key: keyof SalesService.UpdateAuctionRequest) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const onDeleteHandler = async () => {
    try {
      await removeAuction(data?.id);
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title",
      );
      navigate("/salesmanagment/auction");
    } catch (error) {}
  };

  return (
    <>
      <Tabs activeKey={tabs[0].label}>
        <div className="space-y-6">
          <div className="flex-between">
            <Breadcrumb />
            <Button
              size="sm"
              variant={"white"}
              textColor="danger"
              onClick={toggleDelete}
              disabled={loading}
            >
              <Icon icon="trash" />
              <Text>global.buttons.delete</Text>
            </Button>
          </div>
          <Wrapper>
            <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row">
              <div className="space-y-4 lg:flex-1">
                {loading ? (
                  <div className=" mt-auto h-2 w-20 animate-pulse rounded bg-slate-200" />
                ) : (
                  <h6 className="text-heading-6 text-gray-800">{data.title}</h6>
                )}
              </div>
            </Wrapper.Body>
            <Wrapper.Footer className="!py-0">
              <Tabs.ButtonGroup>
                {tabs.map(e => (
                  <Tabs.Button key={e.label} eventKey={e.label}>
                    <Text>{e.label}</Text>
                  </Tabs.Button>
                ))}
              </Tabs.ButtonGroup>
            </Wrapper.Footer>
          </Wrapper>
          {tabs.map(e => {
            return (
              <Tabs.Item key={e.label} eventKey={e.label}>
                <AuctionContext.Provider
                  value={{
                    data,
                    loading,
                    isChanged,
                    onChangeHandler,
                    getData: refetch,
                    existedProductsIds,
                  }}
                >
                  {createElement(e.component)}
                </AuctionContext.Provider>
              </Tabs.Item>
            );
          })}
        </div>
      </Tabs>
      <DeleteModal
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        selected={{name: data?.title}}
        loading={deleteLoading}
        onConfirm={onDeleteHandler}
      />
    </>
  );
}
