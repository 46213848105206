import {useRef, useState, useEffect, useId} from "react";
import Button from "./Button";
import Text from "./Text";
import Icon from "./Icon";
import {variantType} from "types";
// import { IconName } from "@fortawesome/fontawesome-svg-core";

type DropdownButtonItems = {
  id: string | number | undefined;
  title: string;
  permission?: boolean;
  onClick?: any;
  ariaLabel?: string;
};

type DropdownButtonProps = {
  containerId?: string;
  children?: any;
  content?: string;
  items?: DropdownButtonItems[];
  drop?: string;
  onSelect?: (val: any) => void;
  onClick?: (val: any) => void;
  eventKey?: any;
  isActive?: boolean;
  className?: string;
  iconOnly?: boolean;
  icon?: string;
  variant?: variantType;
  arrowIcon?: boolean;
  light?: boolean;
  size?: "sm" | "base" | "lg";
  textColor?: string;
};

export default function DropdownButton({
  containerId = "",
  children,
  items,
  variant = "primary",
  drop = "left",
  isActive = false,
  onSelect = () => {},
  eventKey = "",
  onClick = () => {},
  className,
  iconOnly = false,
  content,
  arrowIcon = true,
  light,
  textColor = "",
  size = "base",
  ...props
}: DropdownButtonProps) {
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(p => !p);
  };
  const handleClick = (e: MouseEvent) => {
    positionDropdown();
    onSelect(eventKey);
    toggleShow();
    onClick(e);
  };

  const handleClickSelect = (e: MouseEvent) => {
    const outside = !ref.current?.contains(e.target as Node);
    outside && setShow(false);
  };
  useEffect(() => {
    window.addEventListener("click", handleClickSelect);
    return () => {
      window.removeEventListener("click", handleClickSelect);
    };
  }, []);

  function positionDropdown() {
    const dropdownButton = document.getElementById(id) as HTMLElement;
    const dropdownList = document.getElementById(
      `dropdown-list-${id}`,
    ) as HTMLElement;
    const dropdownHeight = dropdownList.clientHeight;
    let windowHeight;
    if (containerId) {
      const container = document.getElementById(containerId) as HTMLElement;
      windowHeight = container.getBoundingClientRect().bottom;
    } else {
      windowHeight = window.innerHeight;
    }
    const dropdownTop = dropdownButton.getBoundingClientRect().top;
    if (windowHeight < dropdownTop + dropdownHeight + 35) {
      dropdownList.style.top = "-100%";
    } else {
      dropdownList.style.top = "100%";
    }
  }

  return (
    <div
      id="dropdown-container"
      ref={ref}
      data-active={show}
      className={`dropdown group relative inline-block ${className}`}
    >
      <Button
        id={id}
        size={size}
        type="button"
        data-active={isActive}
        variant={variant}
        light={light}
        className={`dropdown-item block min-w-[44px] rounded text-left`}
        textColor="primary"
        onClick={handleClick}
        {...props}
      >
        {iconOnly && props.icon ? <Icon icon={props.icon} /> : children}
        {!iconOnly && !children ? (
          content ? (
            content
          ) : (
            <Text>global.buttons.actions</Text>
          )
        ) : null}
        {!iconOnly && arrowIcon ? (
          show ? (
            <i className="bi bi-chevron-up ml-2 text-primary" />
          ) : (
            <i className="bi bi-chevron-down ml-2 text-primary" />
          )
        ) : null}
      </Button>
      <div
        id={`dropdown-list-${id}`}
        style={{transformOrigin: `top ${drop}`}}
        className={`${
          iconOnly ? "right-1/2 mt-1" : "left-0"
        } dropdown-menu verflow-y-hidden pointer-events-none absolute z-20 w-full min-w-[115px] scale-90  space-y-1 rounded border border-gray-200 bg-white  p-1 text-left text-xs opacity-0 shadow-sm transition-[transform,opacity] group-data-active:pointer-events-auto group-data-active:scale-100 group-data-active:opacity-100`}
      >
        {items?.map(item => {
          return (
            item.permission && (
              <div
                aria-label={item?.ariaLabel}
                key={String(item.id)}
                className="mb-2 cursor-pointer rounded p-2 hover:bg-primary-light hover:text-primary"
                id={String(item.id)}
                onClick={() => {
                  item.onClick();
                  setShow(false);
                }}
              >
                <Text>{item.title}</Text>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
