import {ChannelMessage, WithPaging, ChannelManagerService} from "types";
import MainApi from "../mainApi";

type Data = WithPaging<ChannelMessage.Item>;

export const channelMessageSlice = MainApi.injectEndpoints({
  endpoints: builder => ({
    getChannelMessages: builder.query<Data, any>({
      query: params => ({
        url: "/channelmanagerservice/api/channelmessage",
        params,
      }),
    }),
    channelMessageDetails: builder.query<
      ChannelManagerService.ChannelMessageDetailQueryDto,
      any
    >({
      query: ({channelMessageId}) => ({
        url: `/channelmanagerservice/api/channelmessage/${channelMessageId}`,
      }),
    }),
  }),
});

export const {useGetChannelMessagesQuery, useChannelMessageDetailsQuery} =
  channelMessageSlice;
