export const productLabelTemplate = {
  addProductLabelTemplate: "Neue Etikettenvorlage hinzufügen",
  editProductLabelTemplate: "Etikettenvorlage bearbeiten",
  designTemplate: "Designvorlage",
  title: "Titel",
  active: "aktiv",
  isGlobal: "global gültig",
  file: "Ordner",
  metaData: "Metadaten",
  addMetaData: "neue Metadaten hinzufügen",
  key: "Schlüssel",
  value: "Wert",
  add: "hinzufügen",
  duplicateKey: "Schlüssel kann nicht dupliziert werden",
  language: "Sprache",
};
