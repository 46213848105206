import {NotificationManager} from "common/notifications";
import {
  Badge,
  Icon,
  ImageUploader,
  LazyImage,
  LoadingSvg,
  Status,
  Text,
} from "components";
import {useConverter, useToggleState} from "hooks";
import {useEffect, useMemo, useState} from "react";
import {ServiceApi, URLS} from "services";
import ProductFamilyPath from "../helpers/product-family-path";
import InfoModal from "./drawers/info";
import {assetTypes} from "constant";
import {sumBy} from "lodash";
import Completeness from "../all-products-drawers/completeness";

const PimProductProviderOrigin = ["None", "EMV", "Iwofurn", "Excel"];
//Types
type Props = {
  productData: any;
  loading: boolean;
  setData: any;
  setPatchData: any;
  getPIMProduct: any;
  totalVks: any;
  activeKey: any;
  status: boolean;
};

const Info = ({
  productData,
  loading,
  getPIMProduct,
  totalVks,
  status,
}: Props) => {
  const {convertAmount} = useConverter();
  const [isOpen, toggle] = useToggleState();
  const [isOpenInfo, toggleInfo] = useToggleState();
  const [programBrand, setProgramBrand] = useState<any>();
  const [totalPrice, setTotalPrice] = useState({vk1: 0, vk2: 0, linePrice: 0});
  const [openCompleteness, toggleCompleteness] = useToggleState();
  const [percentage, setPercentage] = useState<number>();
  const [percentages, setPercentages] = useState({
    completed: 0,
    inProcess: 0,
    hasProblem: 0,
    notStarted: 0,
  });
  const background = `conic-gradient(
  #B5B5C3 0%, #B5B5C3 var(--notStarted), 
  #50CD89 var(--notStarted), #50CD89 var(--completed), 
  #D9214E var(--completed), #D9214E var(--hasProblem), 
  #F6C000 var(--hasProblem))`;
  const style = useMemo(() => {
    return {
      background,
      "--notStarted": `${percentages.notStarted}%`,
      "--completed": `${percentages.completed + percentages.notStarted}%`,
      "--hasProblem": `${percentages.hasProblem + percentages.notStarted + percentages.completed}%`,
    };
  }, [percentages]);

  useEffect(() => {
    //setBrand
    if (productData?.supplierId && productData?.brandId) {
      findBrandInfo();
    } else {
      setProgramBrand({});
    }
  }, [productData?.brandId]);

  const getPercentage = (value: number) => {
    const amountOffAll = productData.checkListCount || 0;
    return value && amountOffAll
      ? Math.min(Math.round(100 * (value / amountOffAll)), 100)
      : 0;
  };

  const calculateStatistics = (data: any) => {
    const completed = getPercentage(
      data?.checkListCompletionStatus?.completedCheckListCount,
    );
    const inProcess = getPercentage(
      data?.checkListCompletionStatus?.inProcessCheckListCount,
    );
    const hasProblem = getPercentage(
      data?.checkListCompletionStatus?.hasProblemCheckListCount,
    );
    const notStarted = getPercentage(
      data?.checkListCompletionStatus?.notStartCheckListCount,
    );
    return {completed, inProcess, hasProblem, notStarted};
  };

  useEffect(() => {
    setPercentages(calculateStatistics(productData));
  }, [productData]);

  const findBrandInfo = () => {
    const id = URLS.GET_BRAND_DETAILS(productData?.brandId);
    ServiceApi.get(id).then(({data}) => {
      setProgramBrand(data);
    });
  };

  const onUploadHandler = (image: any) => {
    const url = URLS.UPDATE_PIM_PRODUCT_AVATAR_URL(productData?.id);
    ServiceApi.patch(url, image).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title",
      );
      getPIMProduct();
    });
  };

  useEffect(() => {
    if (!!totalVks.length) {
      const result = {
        vk1: sumBy(totalVks, "vk1"),
        vk2: sumBy(totalVks, "vk2"),
        linePrice: sumBy(totalVks, "linePrice"),
      };
      setTotalPrice(result);
    }
  }, [totalVks]);

  return (
    <>
      <div className="flex space-x-4">
        <LazyImage
          isDynamic
          className="h-[191px] w-[249px] cursor-pointer bg-gray-100"
          src={productData?.avatar?.url}
          onClick={toggle}
          editor
          imageClassName="w-[349px] h-[191px]"
        />
        <section className="space-y-4">
          {loading ? (
            <div className="w-96 animate-pulse space-y-4">
              <div className="col-span-1 h-2 rounded bg-slate-200"></div>
              <div className="col-span-2 h-2 rounded bg-slate-200"></div>
              <div className="col-span-1 h-2 rounded bg-slate-200"></div>
              <div className="col-span-2 h-2 rounded bg-slate-200"></div>
              <div className="col-span-1 h-2 rounded bg-slate-200"></div>
              <div className="float-start h-9 w-20 rounded bg-slate-200"></div>
            </div>
          ) : (
            <>
              <div className="flex items-center space-x-4">
                <h5 className="text-gray-800">{productData?.originalName}</h5>
                <Status.PimProductType id={productData?.pimProductType} />
              </div>
              <div className="flex gap-x-10 gap-y-4 text-body-2">
                <div className="space-y-4">
                  {productData?.pimProductFamilyId && (
                    <p className=" w-fit rounded bg-success-light p-2 font-medium leading-5">
                      <span className="font-medium text-gray-600 ">
                        <ProductFamilyPath
                          productData={productData}
                          pimProductFamilyId={productData?.pimProductFamilyId}
                        />
                      </span>
                    </p>
                  )}

                  <p className="font-medium text-gray-500">
                    <Text>
                      productManagement.products.Details.articleNumber
                    </Text>
                    <span className="font-medium text-gray-700">
                      :{" "}
                      {productData?.articleNumber ? (
                        <span aria-label="cy-article-number">{`#${productData?.articleNumber}`}</span>
                      ) : (
                        "--"
                      )}
                    </span>
                  </p>
                  {!productData?.pimProductType && (
                    <p className="font-medium text-gray-500">
                      <Text>
                        productManagement.products.Details.supplierArticleNumber
                      </Text>
                      <span className="font-medium text-gray-700">
                        {" "}
                        :{" "}
                        {productData?.supplierArticleNumber
                          ? productData?.supplierArticleNumber
                          : "--"}
                      </span>
                    </p>
                  )}
                  {productData?.referenceKey && (
                    <div>
                      <Text>
                        productManagement.products.allProducts.referenceKey
                      </Text>
                      :<span className="ml-1">{productData?.referenceKey}</span>
                    </div>
                  )}
                  <p className="font-medium text-gray-500">
                    <Text>
                      productManagement.products.Details.currentProductVersion
                    </Text>
                    <span className="font-medium text-gray-700">
                      {" "}
                      : {productData?.version ? productData?.version : "--"}
                    </span>
                  </p>
                  <p
                    className="min-h-10 cursor-pointer space-x-2 font-semibold text-primary underline"
                    onClick={toggleInfo}
                  >
                    <Icon icon="info-circle" />
                    <span>
                      <Text>productManagement.products.Details.moreInfo</Text>
                    </span>
                  </p>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
      <div className="flex flex-none flex-col flex-wrap items-center justify-center gap-3">
        <div className="flex flex-wrap items-center gap-3 rounded ">
          {productData?.checkListCount !== 0 && (
            <div
              className="inline-flex cursor-pointer flex-col justify-center gap-2 bg-gray-100 p-4"
              onClick={() => {
                toggleCompleteness();
              }}
            >
              <div className=" flex h-[10px] w-[100px] rounded-[3px] bg-gray-500">
                <div
                  className="h-full rounded-l-[3px] bg-success"
                  style={{width: percentages.completed}}
                ></div>
                <div
                  className="h-full rounded-r-[3px] bg-warning"
                  style={{width: percentages.inProcess}}
                ></div>
                <div
                  className="h-full rounded-r-[3px] bg-danger"
                  style={{width: percentages.hasProblem}}
                ></div>
              </div>
              <p className="text-start text-body-4 text-success">
                <Text>productManagement.products.completeness.completed</Text>{" "}
                {percentages.completed + "%"}
              </p>
              <p className="text-start text-body-4 text-warning">
                <Text>productManagement.products.completeness.inprocess</Text>{" "}
                {percentages.inProcess + "%"}
              </p>
              <p className="text-start text-body-4 text-danger">
                <Text>global.types.checkListCompleteness.hasAProblem</Text>{" "}
                {percentages.hasProblem + "%"}
              </p>
              <p className="text-start text-body-4 text-gray-600">
                <Text>global.types.checkListCompleteness.notStarted</Text>{" "}
                {percentages.notStarted + "%"}
              </p>
            </div>
            // <div
            //   className="flex h-full shrink-0 cursor-pointer flex-col items-center justify-center gap-2 p-4"
            //   onClick={() => {
            //     toggleCompleteness();
            //   }}
            // >
            //   <div
            //     style={loading ? {background: "#B5B5C3"} : style}
            //     className={` flex-center m-auto h-[118px] w-[118px] rounded-full bg-gradient-to-t`}
            //   >
            //     <div className="flex-center h-[87px] w-[87px] rounded-full bg-white text-body-4 font-medium text-gray-800 underline">
            //       <Text>
            //         productManagement.products.allProducts.completeness
            //       </Text>
            //     </div>
            //   </div>
            // </div>
          )}
          <>
            {productData?.pimProductType === 1 && (
              <div className="flex h-full min-w-64 flex-col justify-center gap-3 rounded bg-gray-100 px-4 py-2">
                {status ? (
                  <LoadingSvg size="sm" />
                ) : (
                  <>
                    {
                      <div className="flex items-center justify-between">
                        <span className="text-body-base text-heading-6 text-gray-600">
                          <Text>sum vk2</Text> :{" "}
                        </span>
                        <span className="text-heading-4 font-semibold text-primary">
                          {convertAmount(totalPrice?.vk2)}
                        </span>
                      </div>
                    }
                    {
                      <div className="flex items-center justify-between">
                        <span className="text-body-base text-heading-6 text-gray-600">
                          <Text>sum vk1</Text> :{" "}
                        </span>
                        <span className="text-heading-4 font-semibold text-primary">
                          {convertAmount(totalPrice?.vk1)}
                        </span>
                      </div>
                    }

                    {
                      <div className="flex items-center justify-between">
                        <span className="text-body-base text-heading-6 text-gray-600">
                          <Text>sum salesPrice</Text> :{" "}
                        </span>
                        <span className="text-heading-4 font-semibold text-primary">
                          {convertAmount(totalPrice?.linePrice)}
                        </span>
                      </div>
                    }
                  </>
                )}
              </div>
            )}
          </>
        </div>
        <div>
          <div className="flex gap-2">
            {productData?.brandName && (
              <p className="flex items-center gap-2 border-r-[2px] border-gray-300 pr-2">
                {programBrand?.logoUrl && (
                  <LazyImage
                    isDynamic
                    logo
                    src={programBrand?.logoUrl}
                    className="h-6 w-auto !rounded-none border-none"
                  />
                )}
                <span className="font-medium text-gray-700">
                  {" "}
                  {productData?.brandName}
                </span>
              </p>
            )}
            <p className="flex items-center gap-2">
              {productData?.supplierInfo?.logoUrl && (
                <LazyImage
                  isDynamic
                  logo
                  src={productData?.supplierInfo?.logoUrl}
                  className="h-6 w-auto !rounded-none border-none"
                />
              )}
              <span className="font-medium text-gray-700">
                {" "}
                {productData?.supplierName}
              </span>
            </p>
          </div>
        </div>
      </div>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={productData?.avatar?.url}
        onUpload={image => onUploadHandler(image)}
        type={assetTypes.PimProduct}
      />
      <InfoModal isOpen={isOpenInfo} toggle={toggleInfo} data={productData} />
      <Completeness
        isOpen={openCompleteness}
        toggle={toggleCompleteness}
        productId={productData.id}
        updatePIMData={v => setPercentage(v)}
      />
    </>
  );
};

export default Info;
