export const salesMessagesTopics = [
  {
    value: null,
    label: "global.all",
  },
  {
    value: "PublishedSupplier",
    label: "channelMessage.topics.PublishedSupplier",
  },
  {
    value: "PublishedSupplierProgram",
    label: "channelMessage.topics.PublishedSupplierProgram",
  },
  {
    value: "UnPublishedSupplier",
    label: "channelMessage.topics.UnPublishedSupplier",
  },
  {
    value: "PublishedPimProduct",
    label: "channelMessage.topics.PublishedPimProduct",
  },
  {
    value: "UnPublishedPimProduct",
    label: "channelMessage.topics.UnPublishedPimProduct",
  },
  {
    value: "PublishedProductCategory",
    label: "channelMessage.topics.PublishedProductCategory",
  },
  {
    value: "UnPublishedProductCategory",
    label: "channelMessage.topics.UnPublishedProductCategory",
  },
  {
    value: "PublishedSalesOrder",
    label: "channelMessage.topics.PublishedSalesOrder",
  },
  {
    value: "PublishedSalesOrderStatusUpdate",
    label: "channelMessage.topics.PublishedSalesOrderStatusUpdate",
  },
  {
    value: "ErpDocumentLinkAdded",
    label: "channelMessage.topics.ErpDocumentLinkAdded",
  },
  {
    value: "LegalEntity",
    label: "channelMessage.topics.LegalEntity",
  },
  {
    value: "Customer",
    label: "channelMessage.topics.Customer",
  },
  {
    value: "CustomerAccount",
    label: "channelMessage.topics.CustomerAccount",
  },
  {
    value: "Employee",
    label: "channelMessage.topics.Employee",
  },
  {
    value: "UnPublishedSupplierProgram",
    label: "channelMessage.topics.UnPublishedSupplierProgram",
  },
  {
    value: "PublishedSupplierContactPerson",
    label: "channelMessage.topics.PublishedSupplierContactPerson",
  },
  {
    value: "UnPublishedSupplierContactPerson",
    label: "channelMessage.topics.UnPublishedSupplierContactPerson",
  },
  {
    value: "PublishedSupplierHoliday",
    label: "channelMessage.topics.PublishedSupplierHoliday",
  },
  {
    value: "UnPublishedSupplierHoliday",
    label: "channelMessage.topics.UnPublishedSupplierHoliday",
  },
  {
    value: "PublishedSellOffProduct",
    label: "channelMessage.topics.PublishedSellOffProduct",
  },
  {
    value: "RePublishedSalesOrder",
    label: "channelMessage.topics.RePublishedSalesOrder",
  },
  {
    value: "SalesOrderCommissionGroupChange",
    label: "channelMessage.topics.SalesOrderCommissionGroupChange",
  },
  {
    value: "SalesOrderCommissionGroupRemove",
    label: "channelMessage.topics.SalesOrderCommissionGroupRemove",
  },
  {
    value: "Department",
    label: "channelMessage.topics.Department",
  },
  {
    value: "DepartmentRemoved",
    label: "channelMessage.topics.DepartmentRemoved",
  },
  {
    value: "SalesOrderLineItemPickupRequest",
    label: "channelMessage.topics.SalesOrderLineItemPickupRequest",
  },
  {
    value: "PublishedSaleChannel",
    label: "channelMessage.topics.PublishedSaleChannel",
  },
];
export const channelMessagesTopics = [
  {
    value: null,
    label: "global.all",
  },
  {
    value: "Store",
    label: "channelMessage.topics.Store",
  },
  {
    value: "PublishedSupplier",
    label: "channelMessage.topics.PublishedSupplier",
  },
  {
    value: "PublishedSupplierProgram",
    label: "channelMessage.topics.PublishedSupplierProgram",
  },
  {
    value: "UnPublishedSupplier",
    label: "channelMessage.topics.UnPublishedSupplier",
  },
  {
    value: "PublishedPimProduct",
    label: "channelMessage.topics.PublishedPimProduct",
  },
  {
    value: "UnPublishedPimProduct",
    label: "channelMessage.topics.UnPublishedPimProduct",
  },
  {
    value: "PublishedProductCategory",
    label: "channelMessage.topics.PublishedProductCategory",
  },
  {
    value: "UnPublishedProductCategory",
    label: "channelMessage.topics.UnPublishedProductCategory",
  },
  {
    value: "PublishedSalesOrder",
    label: "channelMessage.topics.PublishedSalesOrder",
  },
  {
    value: "PublishedSalesOrderStatusUpdate",
    label: "channelMessage.topics.PublishedSalesOrderStatusUpdate",
  },
  {
    value: "ErpDocumentLinkAdded",
    label: "channelMessage.topics.ErpDocumentLinkAdded",
  },
  {
    value: "LegalEntity",
    label: "channelMessage.topics.LegalEntity",
  },
  {
    value: "Customer",
    label: "channelMessage.topics.Customer",
  },
  {
    value: "CustomerAccount",
    label: "channelMessage.topics.CustomerAccount",
  },
  {
    value: "Employee",
    label: "channelMessage.topics.Employee",
  },
  {
    value: "UnPublishedSupplierProgram",
    label: "channelMessage.topics.UnPublishedSupplierProgram",
  },
  {
    value: "PublishedSupplierContactPerson",
    label: "channelMessage.topics.PublishedSupplierContactPerson",
  },
  {
    value: "UnPublishedSupplierContactPerson",
    label: "channelMessage.topics.UnPublishedSupplierContactPerson",
  },
  {
    value: "PublishedSupplierHoliday",
    label: "channelMessage.topics.PublishedSupplierHoliday",
  },
  {
    value: "UnPublishedSupplierHoliday",
    label: "channelMessage.topics.UnPublishedSupplierHoliday",
  },
  {
    value: "PublishedSellOffProduct",
    label: "channelMessage.topics.PublishedSellOffProduct",
  },
  {
    value: "RePublishedSalesOrder",
    label: "channelMessage.topics.RePublishedSalesOrder",
  },
  {
    value: "SalesOrderCommissionGroupChange",
    label: "channelMessage.topics.SalesOrderCommissionGroupChange",
  },
  {
    value: "SalesOrderCommissionGroupRemove",
    label: "channelMessage.topics.SalesOrderCommissionGroupRemove",
  },
  {
    value: "Department",
    label: "channelMessage.topics.Department",
  },
  {
    value: "DepartmentRemoved",
    label: "channelMessage.topics.DepartmentRemoved",
  },
  {
    value: "SalesOrderLineItemPickupRequest",
    label: "channelMessage.topics.SalesOrderLineItemPickupRequest",
  },
  {
    value: "PublishedSaleChannel",
    label: "channelMessage.topics.PublishedSaleChannel",
  },
];
