//internals job
export const internalJobs = {
  createdAt: "Created at",
  updatedAt: "Updated at",
  failedReason: "Failed Reason",
  pending: "Pending",
  done: "Done",
  canceled: "Canceled",
  failed: "Failed",
};
