import {
  createElement,
  useEffect,
  createContext,
  SetStateAction,
  Dispatch,
} from "react";
import {
  Breadcrumb,
  Wrapper,
  Tabs,
  Text,
  Icon,
  Toggler,
  Footer,
  Button,
  DeleteModal,
  Skeleton,
} from "components";
import General from "./tabs/general";
import CustomizableLevels from "./tabs/customizable-level";
import {useNavigate, useParams} from "react-router";
import {useDataState, useToggleState} from "hooks";
import {PriceEngin} from "types";
import {ServiceApi, URLS} from "services";
import {NotificationManager} from "common/notifications";
import Configurations from "./tabs/configuration";

export type ContextTypes = {
  data: PriceEngin.CalculationUnitItem;
  loading: boolean;
  setData: Dispatch<SetStateAction<PriceEngin.CalculationUnitItem>>;
};

export const CalculationUnitContext = createContext<ContextTypes>({
  data: {
    id: "",
    title: "",
    code: "",
    description: "",
    operationType: 0,
    valueType: 0,
    itemValue: 0,
    calculationLevels: [0],
    availableForPurchase: false,
    availableForSale: false,
    active: false,
    createdBy: "",
    createdByName: "",
    createdAt: "",
    lastModifiedBy: "",
    lastModifiedByName: "",
    lastModifiedAt: "",
    minValue: 0,
    maxValue: 0,
    hasManualValue: false,
    activeForPim: false,
    activeForIwofurn: false,
    activeForManual: false,
  },
  loading: false,
  setData: () => {},
});

export function CalculationUnitDetails() {
  const navigate = useNavigate();
  const {calculationUnitId} = useParams();
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState();
  const [removeLoading, setRemoveLoading] = useToggleState();
  const [loading, toggleLoading] = useToggleState();
  const [submitLoading, toggleSubmit] = useToggleState();
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});

  const tabs = [
    {
      label: "priceEngine.calculationUnits.general",
      component: General,
    },
    // {
    //   label: "priceEngine.calculationUnits.availableInPriceTemplate",
    //   component: AvailableInPriceTemplate,
    // },
    // {
    //   label: "priceEngine.calculationUnits.customizableLevels",
    //   component: CustomizableLevels,
    //   permission: ["PE_GetCalculationUnitCustomization"],
    // },
    {
      label: "priceEngine.calculationLevel.configurations",
      component: Configurations,
    },
  ];

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    toggleLoading();
    const url = URLS.GET_CALCULATION_UNIT_DETAILS(calculationUnitId);
    ServiceApi.get(url)
      .then(({data}) => {
        setBaseData(data);
        toggleLoading();
      })
      .catch(() => toggleLoading());
  };

  const onRemoveUnit = () => {
    setRemoveLoading();
    const url = URLS.REMOVE_CALCULATION_UNITS(calculationUnitId);
    ServiceApi.delete(url)
      .then(() => {
        setRemoveLoading();
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        navigate(-1);
      })
      .catch(() => setRemoveLoading());
  };

  const onSubmitHandler = () => {
    toggleSubmit();
    const url = URLS.UPDATE_CALCULATION_UNIT;
    const body = {...data, calculationLevels: [], calculationUnitId: data.id};
    delete body.id;
    ServiceApi.put(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        toggleSubmit();
        getDetails();
      })
      .catch(() => toggleSubmit());
  };
  return (
    <>
      <div className="space-y-4">
        <Tabs activeKey={tabs[0].label}>
          <div className="flex items-center justify-between">
            <div>
              <Breadcrumb />
            </div>
            {loading ? (
              <Skeleton.Button />
            ) : (
              <Button
                size="sm"
                variant={"danger"}
                light
                onClick={toggleDeleteModal}
              >
                <Icon icon={"trash"} className="mr-2" />
                <Text>priceEngine.calculationUnits.deleteUnit</Text>
              </Button>
            )}
          </div>
          <Wrapper>
            <Wrapper.Body className="flex items-center justify-between">
              {loading ? (
                <>
                  <div className="w-96 animate-pulse space-y-4">
                    <div className="col-span-1 h-2 rounded bg-slate-200"></div>
                    <div className="col-span-2 h-2 rounded bg-slate-200"></div>
                  </div>
                  <Skeleton.Button />
                </>
              ) : (
                <>
                  <div className="flex flex-col gap-4">
                    <span className="text-heading-5 font-semibold text-gray-800">
                      {data?.title}
                    </span>
                    <span className="flex items-center space-x-1">
                      <Icon icon="hashtag" size="sm" />{" "}
                      <span className="text-body-2 text-gray-500">
                        {data?.code}
                      </span>
                    </span>
                  </div>
                  <Toggler
                    className="!w-fit"
                    label="priceEngine.calculationUnits.active"
                    value={data?.active as boolean}
                    setValue={e => {
                      setData((p: any) => ({...p, active: e}));
                    }}
                  />
                </>
              )}
            </Wrapper.Body>
            <Wrapper.Footer className="!py-0">
              <Tabs.ButtonGroup>
                {tabs.map((e: any) => (
                  <Tabs.Button
                    key={e.label}
                    eventKey={e.label}
                    permission={e.permission}
                    disabled={loading}
                  >
                    <Text>{e.label}</Text>
                  </Tabs.Button>
                ))}
              </Tabs.ButtonGroup>
            </Wrapper.Footer>
          </Wrapper>
          {tabs.map((e: any) => (
            <Tabs.Item key={e.label} eventKey={e.label}>
              <CalculationUnitContext.Provider value={{data, loading, setData}}>
                {createElement(e.component)}
              </CalculationUnitContext.Provider>
            </Tabs.Item>
          ))}
          {
            <Footer show={!isChanged || loading}>
              <Button
                type="submit"
                variant="primary"
                onClick={onSubmitHandler}
                loading={submitLoading}
              >
                <Text>global.buttons.saveChanges</Text>
              </Button>
            </Footer>
          }
        </Tabs>
      </div>
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{name: data?.title}}
        loading={removeLoading}
        onConfirm={onRemoveUnit}
      />
    </>
  );
}
